import * as React from "react";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import "./TextEditor.css";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));
export default function TextEditor(props) {
  const [alignment, setAlignment] = React.useState("");
  const [formats, setFormats] = React.useState(() => []);
  const [letterformats, setLetterformats] = React.useState("medium");

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleletterFormat = (event) => {
    setLetterformats(event.target.value);
  };

  const handleChanged=(e)=>{
    props.settexteditor(e.target.value)
  }

  React.useEffect(() => {
    if(props.apidata){
      props.settexteditor(props.apidata)
      }
  }, [props.apidata])
  

  return (
    <>
      <div
        style={{
          paddingRight: "100px",
          backgroundColor: "#F5F5F5",
          borderBottom: "1px solid #E8E8E8",
          marginTop: "6px",
          borderRadius: "8px 8px 0px 0px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <StyledToggleButtonGroup
            sx={{ color: "#3B3B3B" }}
            size="small"
            value={letterformats}
            onChange={handleletterFormat}
            aria-label="letter formatting"
          >
            <select
              style={{
                outline: "none",
                backgroundColor: "#F5F5F5",
                color: "gray",
                fontWeight: "bold",
                fontSize: "large",
              }}
              value={letterformats}
              onSelect={handleletterFormat}
            >
              <option value="large" className="large">
                A
              </option>
              <option value="medium" className="medium">
                A
              </option>
              <option value="small" className="small">
                A
              </option>
            </select>
          </StyledToggleButtonGroup>
          <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />

          <StyledToggleButtonGroup
            sx={{ color: "#3B3B3B" }}
            size="small"
            value={formats}
            onChange={handleFormat}
            aria-label="text formatting"
          >
            <ToggleButton value="bold" aria-label="bold">
              <span class="material-icons">format_bold</span>
            </ToggleButton>
            <ToggleButton value="italic" aria-label="italic">
              <span class="material-icons">format_italic</span>
            </ToggleButton>
            <ToggleButton value="underlined" aria-label="underlined">
              <span class="material-icons">format_underlined</span>
            </ToggleButton>
          </StyledToggleButtonGroup>
          <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
          <StyledToggleButtonGroup
            sx={{ color: "#3B3B3B" }}
            size="small"
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
          >
            <ToggleButton value="left" aria-label="left aligned">
              <span class="material-icons">format_align_left</span>
            </ToggleButton>
            <ToggleButton value="center" aria-label="centered">
              <span class="material-icons">format_align_center</span>
            </ToggleButton>
            <ToggleButton value="right" aria-label="right aligned">
              <span class="material-icons">format_align_right</span>
            </ToggleButton>
            <ToggleButton value="justify" aria-label="justified">
              <span class="material-icons">format_align_justify</span>
            </ToggleButton>
          </StyledToggleButtonGroup>
        </div>
      </div>
      <textarea
        className={`${alignment} ${formats[0]} ${formats[1]} ${formats[2]} ${letterformats}`}
        type="text"
        name="bannername"
         onChange={handleChanged}
         value={props.texteditor}
        //  placeholder="Enter your Name"
        required
        data-emojiable="true"
      />
    </>
  );
}
