import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import DropdownSelect2 from "../../Components/DropdownSelect/DropdownSelect2"
import { useParams } from "react-router-dom";
import useAxios from '../../Helper/Client/useAxios';


function GiftDetails() {
    let { id } = useParams();
    const [value, setValue] = React.useState('1');
    const [giftDetail, setgiftDetail] = useState({})
    const [redemptionlist, setredemptionlist] = useState([])
    const [dropdownvalue, setdropdownvalue] = useState('check')
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [response, isError, isLoading] = useAxios({
        url: `/api/gift_details/${id}`,
        method: "get",
       
    });

    const [Redemptionresponse, RedemptionisError, RedemptionisLoading] = useAxios({
        url: `/api/redemptionlist_by_gift/${id}`,
        method: "get",
       
    });

    const [Viewedresponse, ViewedisError, ViewedisLoading] = useAxios({
        url: `/api/unseen_purchased_count_zero/${id}`,
        method: "get",
       
    });

    useEffect(() => {
        if (response && response.data.results) {
            setgiftDetail(response.data.results)
        }
        if (Redemptionresponse && Redemptionresponse.data.results) {
            setredemptionlist(Redemptionresponse.data.results)
        }
    
    }, [response, Redemptionresponse]);

 
    return (
        <article>
            <h4 style={{width:"70vw"}}>Gift - {giftDetail.gift_name}</h4>
            <TabContext value={value} style={{ padding: '5px' }}>
                <TabList onChange={handleChange} >
                    <Tab label="DETAILS" value="1" />
                    <Tab label="TRANSACTION" value="2" />
                    <Tab label="CONTRACT" value="3" />
                </TabList>
                <TabPanel value="1">

                    <Accordion sx={{ border: '1px solid rgb(232, 232, 232)', boxShadow: 'none', mb: 1 }}>
                        <AccordionSummary
                            expandIcon={<img src='/Images/Arrow-Down.svg' />}

                        >
                            <h5>Gift</h5>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', gap: 20 }}>
                                {giftDetail.gift_image?<img src={giftDetail.gift_image} width='100px' />:<img src='/Images/img.png' />}
                                    <p style={{width:'15vw'}}>{giftDetail.gift_name}</p>
                                </div>

                                <p style={{width:'15vw'}} >{giftDetail.exchangemethod_title}</p>
                                <p style={{width:'15vw'}}>{giftDetail.exchangemethod_description}</p>
                                <img src="../Images/dots.png" />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <div className="venuedetails-table">
                        <Accordion sx={{ border: '1px solid rgb(232, 232, 232)', boxShadow: 'none', mb: 1 }}>
                            <AccordionSummary
                                expandIcon={<img src='/Images/Arrow-Down.svg' />}

                            >
                                <h5>Redemption List</h5>
                            </AccordionSummary>
                            <AccordionDetails>
                                <table style={{ width: '100%', marginBottom: '20px' }}>
                                    <thead>
                                        {redemptionlist.length > 0 && <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Location</th>
                                            <th></th>
                                        </tr>}
                                    </thead>
                                    <tbody>
                                        {redemptionlist.map((item) => (
                                            <tr key={item.id}>
                                                <td style={{ width: '500px' }}>{item.customer_details.user_image ? <img src={item.customer_details.user_image} height='30px' width='30px' /> : <img src='/Images/placeholder.png' height='30px' width='30px' />}{' ' + item.customer_details.first_name + " " + item.customer_details.last_name}</td>
                                                <td>{item.customer_details.email}</td>
                                                <td>{item.customer_details.phone_1}</td>
                                                <td>{item.customer_details.city + ', ' + item.customer_details.country}</td>
                                                <td>
                                                    <DropdownSelect2 apiurl={`/api/redemption_replystatus_update/${item.id}`} setdropDownValue={setdropdownvalue} value={item.reply_status} values={[{ name: 'New', value: 'new' },{ name: 'no reply', value: 'no reply' },{ name: 'replied', value: 'replied' },{ name: 'Done', value: 'Done' }]} />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </TabPanel>
                <TabPanel value="2">

                </TabPanel>
                <TabPanel value="3">

                </TabPanel>
            </TabContext>
        </article>
    );
}
export default GiftDetails;