import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React, { useEffect, useRef, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import DropdownSelect from "../../Components/DropdownSelect/DropdownSelect";
import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import PopupDelete from "../Popup/PopupDelete";
import { useParams } from "react-router-dom";
import useAxios from "../../Helper/Client/useAxios";
import { history } from "../../Helper/History/history";
import { useReactToPrint } from "react-to-print";

function ProductManagementDetails() {
  const [value, setValue] = React.useState("1");
  let { id } = useParams();

  const [transaction, settransaction] = useState(null);
  const [ptransaction, setptransaction] = useState(null);
  const [
    producttransactionresponse,
    producttransactionresponseisError,
    producttransactionresponseisLoading,
  ] = useAxios({
    url: `/api/product_transaction_list_customer/${id}`,
    method: "get",

  });

  useEffect(() => {
    if (producttransactionresponse && producttransactionresponse.data) {
      settransaction(producttransactionresponse.data.results[0]);
      setptransaction(producttransactionresponse.data.results[0].product_list);
    }
  }, [producttransactionresponse]);

  // console.log("DETA", transaction);


  // console.log("PDETA", ptransaction);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const showEditbar = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <article ref={componentRef}>
      <div className="buttondiv print_btn">
        <img src="../Images/Download2.svg" onClick={handlePrint} />
      </div>
      <h4>Product Transaction</h4>

      <div className="venuedetails-table">
        <table style={{ width: "100%", marginBottom: "20px" }}>
          <thead>
            <tr>
              <th>Customer</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Location</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{transaction?.customer_name}</td>
              <td>{transaction?.customer_email}</td>
              <td>{transaction?.phone}</td>
              <td>{transaction?.customer_location}</td>
            </tr>
          </tbody>
        </table>

        <Accordion
          sx={{
            border: "1px solid rgb(232, 232, 232)",
            boxShadow: "none",
            mb: 2,
            pb: 1,
          }}
        >
          <AccordionSummary expandIcon={<img src="/Images/Arrow-Down.svg" />}>
            <h5>Chosen Product</h5>
          </AccordionSummary>
          <AccordionDetails>
            {ptransaction?.length > 0 &&
              ptransaction.map((item) => (
                <div
                  key={item.id}
                  style={{
                        display: "grid",
                        gridTemplateColumns: "10vw 12vw 12vw 120px 100px 30px",
                        justifyContent: "space-around",
                        alignItems: "center",
                        marginBlockEnd: "20px",
                        borderBottom: "1px solid rgb(232, 232, 232)",
                        paddingBlockEnd: "20px",
                  }}
                >
                  <img
                    src={item.product_image}
                    height="107px"
                    width="107px"
                    style={{ borderRadius: "8px" }}
                  />
                 
                  <p>{item.product_name}</p>
                  <p>{item.amount_per_quantity}</p>
                  <p>{item.quantity}</p>
                  <p>{item.price}</p>
                 
                  <></>
                </div>
              ))}
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            border: "1px solid rgb(232, 232, 232)",
            boxShadow: "none",
            mb: 1,
          }}
        >
          <AccordionSummary expandIcon={<img src="/Images/Arrow-Down.svg" />}>
            <h5>Transaction</h5>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{ display: "grid", gridTemplateColumns: "auto auto auto" }}
            >
              <div>
                <p>
                  Transaction Code:{" "}
                  <span className="span"> {transaction?.payment_id}</span>
                </p>
                <p>
                  Order number:{" "}
                  <span className="span">{transaction?.order_id}</span>
                </p>
                <p>
                  Payment id: <span className="span">PAY12345</span>
                </p>
              </div>
              <div>
                <p>
                  Booking date:{" "}
                  <span className="span">
                    {transaction?.date.split("T")[0].split("-")[2] +
                      "." +
                      transaction?.date.split("T")[0].split("-")[1] +
                      "." +
                      transaction?.date.split("T")[0].split("-")[0]}
                  </span>
                </p>
                <p>
                  Total amount:{" "}
                  <span className="span">
                    {transaction && transaction.amount}
                  </span>
                </p>
                <p>
                  Transaction Type:{" "}
                  <span className="span">{transaction?.payment_method}</span>{" "}
                </p>
              </div>
              <div>
                <p></p>
                <p>
                  Status:{" "}
                  <span className="span">
                    {transaction?.status}
                  
                  </span>
                </p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            border: "1px solid rgb(232, 232, 232)",
            boxShadow: "none",
            mb: 1,
          }}
        >
          <AccordionSummary expandIcon={<img src="/Images/Arrow-Down.svg" />}>
            <h5>Remarks</h5>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa
              varius nisl, enim vitae dolor id praesent amet sodales. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit. Massa varius
              nisl, enim vitae dolor id praesent amet sodales. Lorem ipsum dolor
              sit amet, consectetur adipiscing elit. Massa varius nisl, enim
              vitae dolor id praesent amet sodales.{" "}
            </p>
          </AccordionDetails>
        </Accordion>
      </div>
    </article>
  );
}
export default ProductManagementDetails;
