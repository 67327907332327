import * as React from 'react';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { client } from '../../Helper/Client/Client';
import useAxios from '../../Helper/Client/useAxios';
import { useState,useEffect } from 'react';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
 
  '& .MuiInputBase-input': {
    backgroundColor: '#F5F5F5',
    padding: '15px 26px 10px 12px',
    borderRadius: 8,
    fontFamily: [
    ].join(','),
    '&:focus': {
    },
  },
}));

export default function CustomizedSelects(props) {
  const [status, setStatus] = useState(props.value);
  const handleChange = (event) => {
    setStatus(event.target.value)
    const body={
      status:event.target.value
    }
    client.patch(`/api/video_promo_message_status_update/${props.id}`,body).then((response)=>{
      client.get(`api/video_promo_message_list`).then((response)=>{
      props.setvideopromotiondetails(response.data.results)
      })
    })
  };
 
  return (

        <Select
         fullWidth
          value={status}
          label="status"
          onChange={handleChange}
          input={<BootstrapInput />}
          sx={{borderRadius:2}}
          className={(props.validationError && props.value === '') ? "validationerror" : undefined}
        >
          {/* {props.values?.map((item)=>( */}
            <MenuItem value={"Waiting"} disabled>Waiting</MenuItem>
            <MenuItem value={"Finished"}>Finished</MenuItem>
          {/* ))} */}
        </Select>
  );
}