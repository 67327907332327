import React,{useState} from 'react'
import axios from "axios";
import '../Login/Login.css';
import { history } from '../../Helper/History/history';
import { Button } from '@mui/material';

export default function Otpverify() {
  const [otp, setOtp] = useState();
  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setOtp(value);
  };

  function handleOtpVerification(e) {
    e.preventDefault()
    axios.post('http://202.88.234.48:8000/api/verify_otp', {
        "email": localStorage.getItem('recovermail'),
        "otp": otp
    }).then((response) => {
        localStorage.setItem('resetPasswordtoken',response.data.access)
        console.log(response.data)
        history.push('/reset')

    })
    .catch(() => { 
        
    })
}
  return (
    <div className="login">
    <div className="row login-row">
        <div className="bgImage col-md-6">
            <div className="left-login">
                <div style={{textAlign:'center',marginBottom:'20px' }}>
                    <img style={{height:"145px"}} src="../Images/PickMeLogo.png" />
                </div>
                <h1>Welcome to <br/>PickMe Studio</h1>
                <p>Lorem ipsum dolor sit amet, consectetur 
                    adipiscing elit. Vivamus ullamcorper nisl erat, vel convallis elit fermentum pellentesque.
                        Sed mollis velit facilisis facilisis viverra.</p>
            </div>
        </div>
        <div className="right-login col-md-6">
            <div className="right-recover-details">
                <form>
                    <div className="recover-img">
                        <img src="/Images/Vector.png" alt="" />
                    </div>
                    <h4>Enter Otp</h4>
                    <div className="form-group">
                        <label>Enter Otp</label>
                        <input name="otp" className="form-control recover-control" 
                        value={otp} onChange={handleChange} maxLength={6}
                        />
                    </div>
                    <Button type="submit"  style={{width:'100%'}} className='primarybtn' onClick={handleOtpVerification}>Recover Password</Button>
                   
                    <div className="backtologin">
                        <p style={{color:'#636363'}}>Go back to <a href='/login' style={{color:'#FE9900'}}>Login</a></p>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
  )
}
