import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import "./DragDrop.css";

const fileTypes = ["JPG", "PNG", "JPEG"];

function DragDrop(props) {
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleChange = (file) => {
    if(props.inputFields){
      console.log("props.index",props.index)
    let data = [...props.inputFields];
    convertToBase64(file).then(item => data[props.index]["RoomImage"] = props.filelist.concat(item) )
   
    props.setInputFields(data);
  }
    props.setfile(props.filelist.concat(file))
    console.log("props.filelist.concat(item)", props.filelist.concat(file))

  };
 
  
  return (
    <FileUploader 
      handleChange={handleChange}
      label="Drag and Drop or Browse to upload"
      name="file"
      types={fileTypes}
      classes={`${props.filelist.length === 0 && props.validationError ? 'validationerror drop_area' : 'drop_area'}`}
      children={
        <div 
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "50px",
            gap: "20px"
          }}
        >
            <img src="/Images/dropimg.svg" height="24px" />
          <img src="/Images/Droplabel.svg" height="15px" />
        </div>
      }
    />
  );
}

export default DragDrop;
