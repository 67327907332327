import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button, Pagination, Tab } from "@mui/material";
import SearchBar from "../../Components/SearchBar/SearchBar";
import useAxios from "../../Helper/Client/useAxios";
import PopupContact from "../Popup/PopupContact";
import { client } from "../../Helper/Client/Client";
import PopupDelete from "../Popup/PopupDelete";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PopupDeleteAll from "../Popup/PopupDeleteAll";

function Contact() {
  const [contactlist, setContactlist] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [searchvalue, setsearchvalue] = useState("");
  const [pageCount, setpageCount] = useState(0);
  const [page, setpage] = useState(1);
  const [listlength, setlistlength] = useState(0);

  // table view(edit/delete)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [editbar, setEditbar] = useState("");

  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const showEditbar = (event, id) => {
    setAnchorEl(event.currentTarget);
    console.log(id);
    setEditbar(id);
  };
  const handleClose = () => {
    setEditbar(null);
  };

  function CustomPagination() {
    return (
      <Pagination
        className="pagination"
        color="warning"
        count={pageCount}
        page={page}
        onChange={(event, value) => {
          client
            .get(`/api/contactus_paginated_list?page=${value}`)
            .then((response) => {
              setContactlist(response.data.results);
            });
          setpage(value);
        }}
        showFirstButton
        showLastButton
      />
    );
  }

  // top actions
  // const [action, setAction] = React.useState(null);
  const open = Boolean(anchorEl2);
  const handleClickaction = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseaction = () => {
    setAnchorEl2(null);
  };


  useEffect(() => {
    if (contactlist?.length !== undefined) {
      setlistlength(contactlist.length);
    }
  }, [contactlist]);

  useEffect(() => {
    client
      .get(`/api/contactus_search_by_name?search=${searchvalue}`)
      .then((response) => {
        if (response.data.results) {
          setContactlist(response.data.results);
          setpage(1);
          setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          );
        } else {
          setContactlist([]);
        }
      });
  }, [searchvalue]);

  function handleDelete(i) {
    client.delete(`/api/contactus_delete/${i}`).then(() => {
      handleClose();
      client.get("/api/contactus_paginated_list").then((response) => {
        setpage(1);
        setpageCount(
          Math.ceil(response.data.count / response.data.results.length)
        );
        setContactlist(response.data.results);
      });
    });
  }

  const handlebulkDelete = () => {
    client
      .delete(`/api/contactus_bulk_delete`, {
        data: { contactus_id: selectionModel },
      })
      .then(() => {
        setAnchorEl2(null);
        client.get("/api/contactus_paginated_list").then((response) => {
          setpage(1);
          setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          );
          setContactlist(response.data.results);
        });
      });
  };

  function getCustomerProfile(params) {
    const customername = params.row.customer_name;
    const image = params.row.user_image;
    return customername + "+" + image;
  }
  const columns = [
    {
      field: "customer_name",
      headerName: "CUSTOMER NAME",
      width: 300,
      valueGetter: getCustomerProfile,
      renderCell: (params) => (
        <>
          {params.value.split("+")[1] !== "null" ? (
            <img
              src={params.value.split("+")[1]}
              height="30px"
              width="30px"
              alt=""
            />
          ) : (
            <img
              src="/Images/placeholder.png"
              height="30px"
              width="30px"
              alt="profile placholder"
            />
          )}
          <div style={{ marginLeft: "10px" }}>
            <b style={{ color: "rgb(53, 53, 53)" }}>
              {params.value.split("+")[0]}
            </b>
          </div>
        </>
      ),
    },
    {
      field: "email",
      headerName: "EMAIL",
      width: 230,
    },
    {
      field: "date",
      headerName: "DATE",
      headerAlign: "center",
      align: "center",
      type: "date",
      width: 150,
      renderCell: (params) =>
        params.value.split("T")[0].split("-")[2] +
        "." +
        params.value.split("T")[0].split("-")[1] +
        "." +
        params.value.split("T")[0].split("-")[0],
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            width: "100px",
            padding: "7px",
            backgroundColor: params.value === "replied" ? "#e8f9ef" : "#ffe7e6",
            color: params.value === "replied" ? "#20A144" : "#EB5A52",
          }}
        >
          {params.value === "" ? "no reply" : params.value}{" "}
        </span>
      ),
    },
    {
      field: "id",
      headerName: "",
      width: 30,
      renderCell: (params) => (
        <>
          <IconButton onClick={(event) => showEditbar(event, params.value)}>
            <img src="../Images/dots.png" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={editbar === params.value ? true : false}
            onClose={handleClose}
          >
            <MenuItem style={{ padding: "0px" }}>
              <PopupContact
                detailid={params.value}
                handleClose={handleClose}
                setpage={setpage}
                setpageCount={setpageCount}
                setContactlist={setContactlist}
              />
            </MenuItem>
            <PopupDelete delete={() => handleDelete(params.value)} />
          </Menu>
        </>
      ),
    },
  ];

  return (
    <article>
      <h4>Contact Us</h4>
      <TabContext value={value}>
        <TabList onChange={handleChange}>
          <Tab label="All" value="1" />
        </TabList>
        <TabPanel value="1">
          <div
            style={{
              width: "100%",
              paddingTop: "24px",
              borderRadius: "8px",
              border: "1px solid #E8E8E8",
            }}
          >
            <div className="mid-searchbar">
              <SearchBar
                searchvalue={searchvalue}
                setsearchvalue={setsearchvalue}
              />
              <Button
                onClick={handleClickaction}
                className="tertiarybtn"
                endIcon={<img src="../Images/Arrow-Down.svg" />}
              >
                Actions
              </Button>
              <Menu
                anchorEl={anchorEl2}
                open={open}
                onClose={handleCloseaction}
              >
                <PopupDeleteAll delete={handlebulkDelete} />
              </Menu>
            </div>
            <div style={{ height: "650px" }}>
              <DataGrid
                sx={{ mt: 2 }}
                rows={contactlist}
                columns={columns}
                pageSize={listlength}
                rowsPerPageOptions={[listlength]}
                checkboxSelection
                pagination
                // paginationMode="server"
                keepNonExistentRowsSelected
                components={{
                  Pagination: CustomPagination,
                }}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
              />
            </div>
          </div>
        </TabPanel>
      </TabContext>
    </article>
  );
}

export default Contact;
