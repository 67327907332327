import React from 'react';
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";

function Password(props){
    const [values, setValues] = React.useState({
        
        showPassword: false,
      });  
      const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
      };  
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };  
     
    return(
        <div>
            <Input fullWidth sx={{border:"none"}} className="password-reset"
            type={values.showPassword ? "text" : "password"}
            onChange={props.handlePassword}
            endAdornment={
            <InputAdornment position="end">
                <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}>
                {values.showPassword ? <span className="material-icons">
                    visibility
                    </span>: <img src="/hide.png"/>}
                </IconButton>
            </InputAdornment>}
            required
            />
        </div>
    );
}

export default Password;