import * as React from 'react';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { client } from '../../Helper/Client/Client';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
 
  '& .MuiInputBase-input': {
    backgroundColor: '#F5F5F5',
    padding: '15px 26px 10px 12px',
    borderRadius: 8,
    fontFamily: [
    ].join(','),
    '&:focus': {
    },
  },
}));

export default function CustomizedSelects(props) {
  const [age, setAge] = React.useState('');
  const handleChange = (event) => {
    if(props.apiurl){
      // props.setdropDownValue(props.apidata)
      console.log(props.apiurl)
      client.put(props.apiurl,{
        "commission": event.target.value
    })
      }
    props.setdropDownValue(event.target.value)
    setAge(event.target.value)
  };
  React.useEffect(() => {
    if(props.value){
   setAge(props.value)
    }
  }, [props.value])
  
  return (

        <Select
         fullWidth
          value={age}
          onChange={handleChange}
          input={<BootstrapInput />}
          sx={{borderRadius:2}}
          className={(props.validationError && props.value === '') ? "validationerror" : undefined}
        >
          {props.values.map((item)=>(
             <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
          ))}
        </Select>
  );
}