import React, { useState, useEffect } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button, Pagination } from "@mui/material";
import SearchBar from "../../Components/SearchBar/SearchBar";
import { history } from "../../Helper/History/history";
import { client } from "../../Helper/Client/Client";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/system";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Papa from "papaparse";

function TransactionManagement() {
  // tab property
  const [value, setValue] = React.useState("1");
  const [transaction, setTransaction] = useState([]);

  const [pageCount, setpageCount] = useState(0);
  const [page, setpage] = useState(1);
  const [listlength, setlistlength] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // table view(edit/delete)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [editbar, setEditbar] = useState("");
  const [searchvalue, setsearchvalue] = useState("");

  const [filteredtransactions, setFilteredtransactions] = useState([]);
  const [month, setMonth] = useState("1");
  // console.log("Selected Month", month);

  const options = [
    "2022",
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
    "2031",
    "2032",
    "2033",
    "2034",
    "2035",
    "2036",
    "2037",
    "2038",
    "2039",
    "2040",
    "2041",
    "2042",
    "2043",
    "2044",
    "2045",
    "2046",
    "2047",
    "2048",
    "2049",
    "2050",
  ];

  const [year, setYear] = useState(options[0]);
  // console.log("Selected Year", year);

  function handleYYMM() {
    // console.log("786");
    const body = {
      year: year,
      month: month,
    };
    client.post(`/api/transaction_by_year_month`, body).then((response) => {
      if (response.data.results) {
        setTransaction(response.data.results);
      } else {
        setTransaction([]);
      }
      // console.log("Hello");
    });
  }
  // console.log("FIL", filteredtransactions);
  function handledownload(data) {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    saveAs(blob, 'Pick Me studio.xlsx');
  
    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }
  }
  const showEditbar = (event, id) => {
    setAnchorEl(event.currentTarget);
    // console.log(id);
    setEditbar(id);
  };
  const handleClose = () => {
    setEditbar(null);
  };

  function CustomPagination() {
    return (
      <Pagination
        className="pagination"
        color="warning"
        count={pageCount}
        page={page}
        onChange={(event, value) => {
          client
            .get(`/api/transaction_list_paginated?page=${value}`)
            .then((response) => {
              setTransaction(response.data.results);
            });
          setpage(value);
        }}
        showFirstButton
        showLastButton
      />
    );
  }
  useEffect(() => {
    client
      .get(`/api/transaction_searchby_name?search=${searchvalue}`)
      .then((response) => {
        if (response.data.results) {
          setTransaction(response.data.results);

          setpage(1);
          setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          );
        } else {
          setTransaction([]);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401 || 403) {
          history.push("/login");
        }
      });
  }, [searchvalue]);

  const open = Boolean(anchorEl2);
  const handleClickaction = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseaction = () => {
    setAnchorEl2(null);
  };

  useEffect(() => {
    if (transaction?.length !== undefined) {
      setlistlength(transaction.length);
    }
  }, [transaction]);

  const handleDetailView = (i) => {
    history.push(`/transactiondetail/${i}`);
  };

  function getAmountStatus(params) {
    const status = params.row.status;
    const amount = params.row.amount;
    return `${status + "+" + amount}`;
  }

  function getNameEmail(params) {
    const name = params.row.customer_name;
    const email = params.row.customer_email;
    return name + "+" + email;
  }

  function getTime(params) {
    var date = params.row.date;
    var mydate = new Date(date);
    var d = mydate.toTimeString().split(" ")[0];
    return d;
  }
  var date = "2022-12-09T04:19:55.789712Z";

  var mydate = new Date(date);
  var d = mydate.toTimeString().split(" ")[0];
  // console.log("Da", d);

  const columns = [
    {
      field: "date",
      headerName: "DATE",

      type: "date",
      width: 100,
      renderCell: (params) =>
        params.value.split("T")[0].split("-")[2] +
        "." +
        params.value.split("T")[0].split("-")[1] +
        "." +
        params.value.split("T")[0].split("-")[0],
    },
    {
      field: "nameandemail",
      headerName: "CUSTOMER NAME",
      width: 200,
      valueGetter: getNameEmail,
      renderCell: (params) => (
        <>
          <div style={{ marginLeft: "10px" }}>
            <b style={{ color: "rgb(53, 53, 53)" }}>
              {params.value.split("+")[0]}
            </b>
            <small style={{ color: "grey", display: "block" }}>
              {params.value.split("+")[1]}
            </small>
          </div>
        </>
      ),
    },
    {
      field: "venue_name",
      headerName: "VENUE NAME",
      width: 200,
    },
    {
      field: "total_venue_price",
      headerName: "VENUE PRICE",
      width: 110,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "PAYMENT STATUS",
      align: "center",
      headerAlign: "center",
      width: 160,
      renderCell: (params) =>
        params.value == "Partially Paid" ? (
          <span
            style={{
              textAlign: "center",
              width: "150px",
              padding: "10px",
              backgroundColor: "rgba(254, 153, 0,0.08)",
              color: " #FE9900",
            }}
          >
            {params.value}{" "}
          </span>
        ) : params.value == "Fully Paid" ? (
          <span
            style={{
              textAlign: "center",
              width: "150px",
              padding: "10px",
              backgroundColor: "#e8f9ef ",
              color: " #20A144",
            }}
          >
            {params.value}{" "}
            {/* Paid{" "} */}
          </span>
        ) : (
          <span
            style={{
              textAlign: "center",
              width: "150px",
              padding: "10px",
              backgroundColor: "rgba(221,160,221,0.2)",
              color: "#800080",
            }}
          >
            {params.value}{" "}
          </span>
        ),
    },
    {
      field: "AmountStatus",
      headerName: "PAID AMOUNT",
      width: 120,
      valueGetter: getAmountStatus,
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            width: "100px",
            padding: "7px",
            color:
              params.value.split("+")[0] === "paid" ? "#20A144" : "#EB5A52",
          }}
        >
          {params.value.split("+")[1]}{" "}
        </span>
      ),
    },
    {
      field: "payment_method",
      headerName: "TRANSACTION TYPE",
      width: 160,
    },
    {
      field: "paymentid",
      headerName: "PAYMENT ID",
      width: 120,
      renderCell: (params) => "PAY12345",
    },

    {
      field: "id",
      headerName: "",
      width: 50,
      renderCell: (params) => (
        <>
          <IconButton onClick={(event) => showEditbar(event, params.value)}>
            <img src="../Images/dots.png" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={editbar === params.value ? true : false}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleDetailView(params.value)}>
              Detailed View
            </MenuItem>
          </Menu>
        </>
      ),
    },
  ];
  function CustomExportButton({ onClick }) {
    return (
      <button onClick={onClick}>Download Data</button>

      
    );
  }
  function CustomToolbar() {
    const [csv, setCsv] = useState(false);
    const handleExport = (data) => {
      const csvData = Papa.unparse(data, {
        quotes: true,
        delimiter: ",",
        encoding: "utf-8", // Specify UTF-8 encoding
      });
  
      // Create a Blob with UTF-8 encoding
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvData], {
        type: "text/csv;charset=utf-8",
      });
  
      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Pick Me studio.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };
    const handleExportCSV = () => {
      const csvData = transaction.map((item) => ({
        'DATE': item.date.split('T')[0],
      'CUSTOMER NAME': `${item.customer_name} (${item.customer_email})`,
      'VENUE NAME': item.venue_name,
      'VENUE PRICE': item.total_venue_price,
      'PAYMENT STATUS': item.status,
      'PAID AMOUNT': item.amount,
      'TRANSACTION TYPE': item.payment_method,
      'PAYMENT ID': 'PAY12345',
      }));
  
      const csv = Papa.unparse(csvData, {
        quotes: true,
        delimiter: ',',
        encoding: 'utf-8',
      });
  
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], {
        type: 'text/csv;charset=utf-8',
      });
  
      saveAs(blob, 'Pick Me Studio.csv');
    };


    const handleButtonClick = (data) => {
      // Replace with your actual data
      
  
      handledownload(data);
    };

    const handleCustomCsvDownload = () => {
      // Add your custom CSV download logic here
      console.log("Custom CSV Download");
    };
  
    const CustomExportButtons = () => (
      <>
        <CustomExportButton onClick={handleCustomCsvDownload} />

      </>
    );
    return (
      <div style={{ position: "absolute", right: "0px", top: "-210px" }}>
        <GridToolbarContainer>
          <FormControl sx={{ width: 100, marginRight: 3 }}>
            <Select
              name="RoomCapacity"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              displayEmpty
              input={<BootstrapInput />}
            >
              <MenuItem value="2022">2022</MenuItem>
              <MenuItem value="2023">2023</MenuItem>
              <MenuItem value="2024">2024</MenuItem>
              <MenuItem value="2025">2025</MenuItem>
              <MenuItem value="2026">2026</MenuItem>
              <MenuItem value="2027">2027</MenuItem>
              <MenuItem value="2028">2028</MenuItem>
              <MenuItem value="2029">2029</MenuItem>
              <MenuItem value="2030">2030</MenuItem>
              <MenuItem value="2031">2031</MenuItem>
              <MenuItem value="2032">2032</MenuItem>
              <MenuItem value="2033">2033</MenuItem>
              <MenuItem value="2034">2034</MenuItem>
              <MenuItem value="2035">2035</MenuItem>
              <MenuItem value="2036">2036</MenuItem>
              <MenuItem value="2037">2037</MenuItem>
              <MenuItem value="2038">2038</MenuItem>
              <MenuItem value="2039">2039</MenuItem>
              <MenuItem value="2040">2040</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ width: 130, marginRight: 3 }}>
            <Select
              name="RoomCapacity"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
              displayEmpty
              input={<BootstrapInput />}
            >
              <MenuItem value="1">January</MenuItem>
              <MenuItem value="2">February</MenuItem>
              <MenuItem value="3">March</MenuItem>
              <MenuItem value="4">April</MenuItem>
              <MenuItem value="5">May</MenuItem>
              <MenuItem value="6">June</MenuItem>
              <MenuItem value="7">July</MenuItem>
              <MenuItem value="8">August</MenuItem>
              <MenuItem value="9">September</MenuItem>
              <MenuItem value="10">October</MenuItem>
              <MenuItem value="11">November</MenuItem>
              <MenuItem value="12">December</MenuItem>
            </Select>
          </FormControl>

          <Button
            className="primarybtn"
            onClick={handleYYMM}
            style={{ marginRight: "10px" }}
          >
            Show Data
          </Button>
          <div style={{position:"relative"}} 
          onMouseEnter={()=>{
            console.log("its onMouseEnter");
            setCsv(true);
          }}
          onMouseLeave={()=>{
            console.log("its onMouseLeave");
            setCsv(false);
          }}
          >
          <button onClick={() => handleExportCSV()}
          style={{
            position: "absolute",
            inset: "0px 0px auto auto",
            margin: "0px",
            transform: "translate3d(4px, 47.5px, 0px)",
            width: '170px',
            height: "37px",
            // visibility: "hidden",
            zIndex:1500,
            opacity:0,
            display:csv?"":"none",
            // cursor:"default"
         
          }}
          >Download CSV</button> 
          {/* <button onClick={()=>{window.print();}}>Print</button> */}
          {/* <PrintButton targetId="componentToPrint" /> */}
        
          <GridToolbarExport
          // onClick={() => handleExport(transaction)}
        onItemClick={() => handleExportCSV()}
        endIcon={<img src="../Images/Arrow-Down.svg" />}
        sx={{
          color: "black",
          bgcolor: "#F5F5F5",
          textTransform: "capitalize",
          p: 1.2,
          pr: 3,
        }}
        options={{
          bookSST: true,
          bookType: "xlsx",
          mimeType:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
          
        }}
      />
      </div>
          {/* <GridToolbarExport
            sx={{
              color: "black",
              bgcolor: "#F5F5F5",
              textTransform: "capitalize",
              p: 1.2,
              pr: 3,
            }}
          /> */}

          <div></div>
        </GridToolbarContainer>
      </div>
    );
  }

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      backgroundColor: "#F5F5F5",
      padding: "15px 26px 10px 12px",
      borderRadius: 8,
      fontFamily: [].join(","),
      "&:focus": {},
    },
  }));

  return (
    <article>
      <div className="buttondiv"></div>

      <h4>Venue Transaction</h4>

      <TabContext value={value}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab label="All" value="1" />
        </TabList>
        <TabPanel value="1">
          <div
            style={{
              width: "100%",
              paddingTop: "24px",
              borderRadius: "8px",
              border: "1px solid #E8E8E8",
            }}
          >
            <div className="middle-searchbar">
              <SearchBar
                searchvalue={searchvalue}
                setsearchvalue={setsearchvalue}
              />
              {/* <Button
                onClick={handleClickaction}
                className="tertiarybtn"
                endIcon={<img src="../Images/Arrow-Down.svg" />}
              >
                Actions
              </Button> */}
            </div>
            <div style={{ height: "650px" }}>
              <DataGrid
                sx={{ mt: 2 }}
                rows={transaction}
                columns={columns}
                pageSize={listlength}
                rowsPerPageOptions={[listlength]}
                checkboxSelection
                pagination
                // paginationMode="server"
                keepNonExistentRowsSelected
                components={{
                  Pagination: CustomPagination,
                  Toolbar: CustomToolbar,
                }}
              />
            </div>
          </div>
        </TabPanel>
      </TabContext>
    </article>
  );
}

export default TransactionManagement;
