import React, { useEffect, useRef, useState } from "react";
import "../Dashboard/Dashboard.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import useAxios from "../../Helper/Client/useAxios";
import { client } from "../../Helper/Client/Client";
import { history } from "../../Helper/History/history";
import DropdownSelect from "../../Components/DropdownSelect/DropdownSelect";
import { useReactToPrint } from "react-to-print";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Dashboard = () => {
  const [venuestatusStatistic, setvenuestatusStatistic] = useState({});
  const [latestpayment, setlatestpayment] = useState([]);
  const [venuebooked, setvenuebooked] = useState([]);
  const [incomeData, setincomeData] = useState([]);


  const [response, isError, isLoading] = useAxios({
    url: "/api/venue_status",
    method: "get",

  });

  const [latestPayresponse, latestPayisError, latestPayisLoading] = useAxios({
    url: "/api/transaction_dashboard_view",
    method: "get",

  });
  useEffect(() => {
    if (response && response.data) {
      setvenuestatusStatistic(response.data);
    }
    if (latestPayresponse && latestPayresponse.data) {
      setlatestpayment(latestPayresponse.data.results || []);
    }
    return () => {

    };
  }, [response, latestPayresponse]);

  const labelvenuedate =
    venuebooked.length > 0 &&
    venuebooked.map(
      (item) =>
        item.date?.split("T")[0].split("-")[2] +
        "." +
        item.date?.split("T")[0].split("-")[1] +
        "." +
        item.date?.split("T")[0].split("-")[0]
    );
  const noofvenuebooked =
    venuebooked.length > 0 && venuebooked.map((item) => item?.venue_booked);

  const labelincomedate =
    incomeData.length > 0 &&
    incomeData.map(
      (item) =>
        item.date?.split("T")[0].split("-")[2] +
        "." +
        item.date?.split("T")[0].split("-")[1] +
        "." +
        item.date?.split("T")[0].split("-")[0]
    );
  const incomeperdate =
    incomeData.length > 0 && incomeData.map((item) => item?.income);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        padding: {
          top: 30,
          bottom: 30,
        },
      },
    },
  };

  const option = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  const datas = {
    labels: labelincomedate,
    datasets: [
      {
        label: "Income",
        align: "start",
        maxBarThickness: 10,
        data: incomeperdate,
        backgroundColor: "#FE9900",
      },
    ],
  };

  const data = {
    labels: labelvenuedate,
    datasets: [
      {
        label: "Venue Booked",
        maxBarThickness: 10,
        data: noofvenuebooked,
        backgroundColor: "#FE9900",
      },
    ],
  };

  const optiondot = {
    plugins: {
      title: {
        display: true,
        position: "bottom",
        font: {
          size: 14,
        },

        padding: {
          top: 30,
          bottom: 30,
        },
        responsive: true,
        animation: {
          animateScale: true,
        },
      },
    },
  };

  const datadot = {
    labels: ["Disable", "Active"],
    datasets: [
      {
        circumference: 250,
        rotation: 237,
        spacing: 10,
        cutout: "87",
        borderRadius: 10,
        data: [
          venuestatusStatistic["Disabled Venues"],
          venuestatusStatistic["Active Venues"],
        ],
        borderColor: ["rgba(255,206,86,0.2)"],
        backgroundColor: ["#2B3D5F", "#26D054"],
        pointBackgroundColor: "rgba(255,206,86,0.2)",
      },
    ],
  };
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var todayDate = new Date();
 

  todayDate.setDate(todayDate.getDate() - 30);
  const initialData = {
    startdate: todayDate.toISOString().split("T")[0],
    enddate: new Date().toISOString().split("T")[0],
    startdate2: todayDate.toISOString().split("T")[0],
    enddate2: new Date().toISOString().split("T")[0],
  };
  const [inputs, setinputs] = useState(initialData);
  if (inputs.startdate > inputs.enddate) {
    const futuredate = new Date(inputs.startdate);
    futuredate.setDate(futuredate.getDate() + 7);
    setinputs((inputs) => ({
      ...inputs,
      ["enddate"]: futuredate.toISOString().split("T")[0],
    }));
  }
  if (inputs.startdate2 > inputs.enddate2) {
    const futuredate2 = new Date(inputs.startdate2);
    futuredate2.setDate(futuredate2.getDate() + 7);
    setinputs((inputs) => ({
      ...inputs,
      ["enddate2"]: futuredate2.toISOString().split("T")[0],
    }));
  }

  function handleChanged(e) {
    const { name, value } = e.target;
    setinputs((inputs) => ({ ...inputs, [name]: value }));
  }
  useEffect(() => {
    const body = {
      start_date: inputs.startdate,
      end_date: inputs.enddate,
    };
    client
      .post("/api/order_statistics", body)
      .then((response) => setvenuebooked(response.data.data || []));
    return () => {
     
    };
  }, [inputs.startdate, inputs.enddate]);

  useEffect(() => {
    const body = {
      start_date: inputs.startdate2,
      end_date: inputs.enddate2,
    };
    client
      .post("/api/income_statistics", body)
      .then((response) => setincomeData(response.data.data || []));
    return () => {

    };
  }, [inputs.startdate2, inputs.enddate2]);


  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <article id="divcontents">
    
      <div className="buttondiv" style={{marginRight:'7%',justifyContent: "space-evenly"}}>
        <img src="../Images/Download2.svg" onClick={handlePrint} />
      </div>
      {/* <div className="buttondiv">
        
      </div> */}
      <div ref={componentRef}>
        <h4>Overview</h4>
        <br />

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "39vw 370px",
            paddingBottom: "5vh",
            gap: 20,
            justifyContent: "space-evenly",
          }}
        >
          <div
            style={{
              padding: "20px",
              border: "1px solid #E8E8E8",
              marginRight: "5px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h5>Statistics</h5>
              <div
                style={{
                  justifyContent: "end",
                  display: "grid",
                  gridTemplateColumns: "auto auto auto",
                  gap: 10,
                }}
              >
                <input
                  type="date"
                  name="startdate"
                  value={inputs.startdate}
                  onChange={handleChanged}
                  style={{ width: "135px", height: "40px" }}
                />
                <p style={{ margin: "auto" }}>&#8212;</p>
                <input
                  type="date"
                  name="enddate"
                  value={inputs.enddate}
                  onChange={handleChanged}
                  style={{ width: "135px", height: "40px" }}
                />
              </div>
            </div>
            <Bar options={options} data={data} />
          </div>
          <div
            style={{
              padding: "20px",
              border: "1px solid #E8E8E8",
              position: "relative",
            }}
          >
            <h5>Venue</h5>
            <img
              src="Images/More.svg"
              height="40px"
              style={{ position: "absolute", right: "20px", top: "20px" }}
              onClick={() => {
                history.push("/venue");
              }}
            />
          
            <Doughnut data={datadot} options={optiondot} />
            <div
              style={{
                position: "absolute",
                bottom: "38%",
                left: "45%",
                display: "grid",
                gridTemplateColumns: "auto auto",
              }}
            >
              <h2>{venuestatusStatistic["Total Venues"]}</h2>
              <br />
              <span className="span">Venue</span>
            </div>
            <div
              style={{
                position: "absolute",
                display: "grid",
                gridTemplateColumns: "auto auto",
                bottom: "10px",
                left: "32%",
                gap: "40px",
              }}
            >
              <p
                style={{
                  color: "#2B3D5F",
                  textAlign: "center",
                  fontSize: "28px",
                }}
              >
               
                {venuestatusStatistic["Disabled Venues"]}
                <br />
                <span className="span">Disable</span>
              </p>
              <p
                style={{
                  color: "#26D054",
                  textAlign: "center",
                  fontSize: "28px",
                }}
              >
               
                {venuestatusStatistic["Active Venues"]}
                <br />
                <span className="span">Active</span>
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "370px 39vw",
            paddingBottom: "5vh",
            gap: 20,
            justifyContent: "space-evenly",
          }}
        >
          <div
            style={{
              padding: "20px",
              border: "1px solid #E8E8E8",
              marginRight: "5px",
            }}
          >

            <table style={{ width: "100%" }} className="dash-transaction-table">
              <thead>
                <tr>
                  <th>
                    <h5>Transactions</h5>
                  </th>
                  <th style={{ textAlign: "end" }}>
                    <img
                      src="Images/More.svg"
                      height="40px"
                      onClick={() => {
                        history.push("/venuetransaction");
                      }}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {latestpayment &&
                  latestpayment?.length > 0 &&
                  latestpayment.map((item) => (
                    <tr key={item.id}>
                      <td className="transaction-profile">
                        {item.customer_image ? (
                          <img
                            src={item.customer_image}
                            style={{ height: "45px", paddingRight: "10px" }}
                          />
                        ) : (
                          <img
                            src="../Images/placeholder.png"
                            style={{ height: "45px", paddingRight: "10px" }}
                          />
                        )}
                        <p style={{ marginBottom: "0px" }}>
                          {item.customer_name}
                          <br />
                          <span className="span">
                            {item.date?.split("T")[1].split(":")[0] +
                              ":" +
                              item.date?.split("T")[1].split(":")[1] +
                              " " +
                              `${
                                Number(
                                  item.date?.split("T")[1].split(":")[0]
                                ) >= 12
                                  ? "PM"
                                  : "AM"
                              }` +
                              " - " +
                              item.date?.split("T")[0].split("-")[2] +
                              " " +
                              month[
                                Number(item.date?.split("T")[0].split("-")[1])
                              ]}
                          </span>
                        </p>
                      </td>
                      <td style={{ textAlign: "end" }}>
                        <p
                          style={{
                            textAlign: "end",
                            width: "100px",
                            padding: "7px",
                            color: item.amount ? "#20A144" : "#EB5A52",
                          }}
                        >
                          {item.amount} <br />
                          <span className="span">Payment</span>
                        </p>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div style={{ padding: "20px", border: "1px solid #E8E8E8" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h5>Statistics</h5>
              <div
                style={{
                  justifyContent: "end",
                  display: "grid",
                  gridTemplateColumns: "auto auto auto",
                  gap: 10,
                }}
              >
                <input
                  type="date"
                  name="startdate2"
                  style={{ width: "135px", height: "40px" }}
                  value={inputs.startdate2}
                  onChange={handleChanged}
                />
                <p style={{ margin: "auto" }}>&#8212;</p>
                <input
                  type="date"
                  name="enddate2"
                  style={{ width: "135px", height: "40px" }}
                  value={inputs.enddate2}
                  onChange={handleChanged}
                />
              </div>
            </div>
            <Bar options={option} data={datas} />
          </div>
        </div>
      </div>
    </article>
  );
};

export default Dashboard;
