import React, { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import { Button, Pagination } from "@mui/material";
import SearchBar from "../../Components/SearchBar/SearchBar";
import { history } from "../../Helper/History/history";
import { client } from "../../Helper/Client/Client";
import PopupDelete from "../Popup/PopupDelete";
import PopupDeleteAll from "../Popup/PopupDeleteAll";
import PopupPopularVenue from "../Popup/PopupPopularVenue";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Papa from "papaparse";

function Venue() {
  const [customerList, setcustomerList] = useState([]);
  const [searchvalue, setsearchvalue] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [page, setpage] = useState(1);
  const [listlength, setlistlength] = useState(0);
  const [show, setShow] = useState(true);
  const [popularVenueList, setpopularVenueList] = useState([])
  const [value, setValue] = React.useState("1");

  useEffect(() => {
    if(  value === "1"){
    client
      .get(`/api/venue_details?search=${searchvalue}`)
      .then((response) => {
        if (response.data.results) {
          console.log('response.data.results',response.data.results)
          setcustomerList(response.data.results);
          setpage(1);
          setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          );
          setShow(true);
        } else {
          setcustomerList([]);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401 || 403) {
          history.push("/login");
        }
      });
    }
    if(  value === "2"){
      client
      .get(`/api/star_venue_list?search=${searchvalue}`)
      .then((response) => {
        if (response.data.results) {
          setcustomerList(response.data.results);
          setpage(1);
          setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          );
          setShow(true);
        } else {
          setcustomerList([]);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401 || 403) {
          history.push("/login");
        }
      });
    }
  }, [searchvalue, value]);

  function CustomPagination() {
    return (
      <Pagination
        className="pagination"
        color="warning"
        count={pageCount}
        page={page}
        onChange={(event, value) => {
          searchvalue === ""
            ? client
                .get(`/api/venue_paginated_listview?page=${value}`)
                .then((response) => {
                  setcustomerList(response.data.results);
                  setShow(false);
                })
            : client
                .get(`/api/venue_details?page=${value}&search=${searchvalue}`)
                .then((response) => {
                  setcustomerList(response.data.results);
                });
          setpage(value);
        }}
        showFirstButton
        showLastButton
      />
    );
  }

  useEffect(() => {
    if (customerList?.length !== undefined) {
      setlistlength(customerList.length);
    }
  }, [customerList]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // table view(edit/delete)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [editbar, setEditbar] = useState("");
  const showEditbar = (event, id) => {
    setAnchorEl(event.currentTarget);
    setEditbar(id);
  };
  const handleClose = () => {
    setEditbar(null);
  };
  function handledownload(data) {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    saveAs(blob, 'Pick Me Studio.xlsx');
  
    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }
  }

  // top actions
  // const [action, setAction] = React.useState(null);
  const open = Boolean(anchorEl2);
  const handleClickaction = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseaction = () => {
    setAnchorEl2(null);
  };
  // console.log("selectionModel Venue List", selectionModel);
  const handlebulkDelete = () => {
    client
      .delete(`/api/venue_bulk_delete`, { data: { venue_id: selectionModel } })
      .then(() => {
        setAnchorEl2(null);
        client.get(`/api/${ value === "1"? "venue_paginated_listview" : "star_venue_list" }`).then((response) => {
          if (response.data.results) {
            setpage(1);
            setcustomerList(response.data.results);
            setpageCount(
              Math.ceil(response.data.count / response.data.results.length)
            );
          } else {
            setpage(0);
            setpageCount(0);
            setcustomerList([]);
          }
        });
      });
  };

  const handleEdit = (i) => {
    history.push(`/venue_details/${i}`);
  };


     function handleUnstarred(i) {
    client.post(`/api/unstar_venue`,{ venue_id: [i] }).then(() => {
      client.get(`/api/${ value === "1"? "venue_paginated_listview" : "star_venue_list" }`).then((response) => {
        if (response.data.results) {
          setpage(1);
          setcustomerList(response.data.results);
          setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          );
        } else {
          setpage(0);
          setpageCount(0);
          setcustomerList([]);
        }
      });
    });
  }
  function handleDelete(i) {
    client.delete(`/api/venue_delete/${i}`).then(() => {
      handleClose();
      client.get(`/api/${ value === "1"? "venue_paginated_listview" : "star_venue_list" }`).then((response) => {
        if (response.data.results) {
          console.log("val",value)
          setpage(1);
          setcustomerList(response.data.results);
          setShow(false);
          console.log("aa",response.data.results)
          setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          );
        } else {
          setpage(0);
          setpageCount(0);
          setcustomerList([]);
        }
      });
    });
  }

  // console.log("CList", customerList);

  function getTitleDesc(params) {
    const title = params.row.title;
    const desc = params.row.description;
    const strippedHtml = desc?.replace(/<[^>]+>/g, "");
    // console.log("ST", strippedHtml);
    const image = show
      ? params.row.room_image_list[0]?.room_image
      : params.row.room_image_list[0][0]?.room_image;
    // console.log("image", image);
    return title + "+" + strippedHtml + "+" + image;
  }

  const columns = [
    {
      field: "title&desc",
      headerName: "VENUE NAME",
      width: 200,
      valueGetter: getTitleDesc,
      renderCell: (params) => (
        <>
          {params.value.split("+")[2] !== "undefined" ? (
            <img
              src={params.value.split("+")[2]}
              height="30px"
              width="30px"
              alt=""
            />
          ) : (
            <img
              src="/Images/venueplaceholder.png"
              height="30px"
              width="30px"
              alt="profile placholder"
            />
          )}
          <div style={{ marginLeft: "10px" }}>
            <b style={{ color: "rgb(53, 53, 53)" }}>
              {params.value.split("+")[0]}
            </b>
            <small style={{ color: "grey", display: "block" }}>
              {params.value.split("+")[1]}
            </small>
          </div>
        </>
      ),
    },
    {
      field: "location",
      headerName: "LOCATION",
      width: 200,
    },
    {
      field: "phone",
      headerName: "PHONE",
      width: 150,
    },
    {
      field: "vendor_name",
      headerName: "VENDOR NAME",
      width: 150,
    },
    {
      field: "status",
      headerName: "STATUS",
      headerAlign: "center",
      align: "center",
      width: 120,
      valueGetter: (params) => (params.row.status ? "Active" : "Blocked"),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            width: "100px",
            padding: "7px",
            backgroundColor: params.value === "Active" ? "#e8f9ef" : "#ffe7e6",
            color: params.value === "Active" ? "#20A144" : "#EB5A52",
          }}
        >
          {params.value}{" "}
        </span>
      ),
    },
    {
      field: "id",
      type: "actions",
      width: 50,
      align: "center",
      headerAlign: "center",

      renderCell: (params) => (
        <>
          <IconButton onClick={(event) => showEditbar(event, params.value)}>
            <img src="../Images/dots.png" alt="action" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={editbar === params.value ? true : false}
            onClose={handleClose}
          >
            {value==1?<>
            <MenuItem onClick={() => handleEdit(params.value)}>
              Edit Item
            </MenuItem>
            <PopupDelete delete={() => handleDelete(params.value)} />
            </>
            :
            <MenuItem onClick={() => handleUnstarred(params.value)}>
              unstarred Item
            </MenuItem> }

          
          </Menu>
        </>
      ),
    },
  ];
  function CustomExportButton({ onClick }) {
    return (
      <button onClick={onClick}>Download Data</button>

      
    );
  }
  function CustomToolbar() {
    const [csv, setCsv] = useState(false);
    // const handleExport = (data) => {
    //   const csvData = Papa.unparse(data, {
    //     quotes: true,
    //     delimiter: ",",
    //     encoding: "utf-8", // Specify UTF-8 encoding
    //   });
  
    //   // Create a Blob with UTF-8 encoding
    //   const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvData], {
    //     type: "text/csv;charset=utf-8",
    //   });
  
    //   // Create a download link and trigger the download
    //   const url = window.URL.createObjectURL(blob);
    //   const a = document.createElement("a");
    //   a.href = url;
    //   a.download = "Pickmestudio.csv";
    //   document.body.appendChild(a);
    //   a.click();
    //   document.body.removeChild(a);
    //   window.URL.revokeObjectURL(url);
    // };
    const handleExportCSV = () => {
      const csvData = customerList.map((venue) => ({
        'VENUE NAME': `${venue.title} (${venue.location})`,
        'LOCATION': venue.location,
        'PHONE': '\u200B' + venue.phone,
        'VENDOR NAME': venue.vendor_name,
        'STATUS': venue.status ? 'Active' : 'Blocked',
      }));
  
      const csv = Papa.unparse(csvData, {
        quotes: true,
        delimiter: ',',
        encoding: 'utf-8',
      });
  
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], {
        type: 'text/csv;charset=utf-8',
      });
  
      saveAs(blob, 'Pick Me Studio.csv');
    };


    const handleButtonClick = (data) => {
      // Replace with your actual data
      
  
      handledownload(data);
    };

    const handleCustomCsvDownload = () => {
      // Add your custom CSV download logic here
      console.log("Custom CSV Download");
    };
  
    
  
    const CustomExportButtons = () => (
      <>
        <CustomExportButton onClick={handleCustomCsvDownload} />

      </>
    );
    return (
      value === "1" && <div style={{ position: "absolute", right: "30px", top: "-210px" }}>
        <GridToolbarContainer>
        <div style={{position:"relative"}} 
          onMouseEnter={()=>{
            console.log("its onMouseEnter");
            setCsv(true);
          }}
          onMouseLeave={()=>{
            console.log("its onMouseLeave");
            setCsv(false);
          }}
          >
          <button onClick={() => handleExportCSV()}
          style={{
            position: "absolute",
            inset: "0px 0px auto auto",
            margin: "0px",
            transform: "translate3d(4px, 47.5px, 0px)",
            width: '170px',
            height: "37px",
            // visibility: "hidden",
            zIndex:1500,
            opacity:0,
            display:csv?"":"none",
            // cursor:"default"
         
          }}
          >Download CSV</button> 
          {/* <button onClick={()=>{window.print();}}>Print</button> */}
          {/* <PrintButton targetId="componentToPrint" /> */}
        
          <GridToolbarExport
        onItemClick={() => handleExportCSV()}
        endIcon={<img src="../Images/Arrow-Down.svg" />}
        sx={{
          color: "black",
          bgcolor: "#F5F5F5",
          textTransform: "capitalize",
          p: 1.2,
          pr: 3,
        }}
        options={{
          bookSST: true,
          bookType: "xlsx",
          mimeType:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
          
        }}
      />
      </div>
          {/* <GridToolbarExport
            sx={{
              color: "black",
              bgcolor: "#F5F5F5",
              textTransform: "capitalize",
              p: 1.2,
              pr: 3,
            }}
          /> */}
        </GridToolbarContainer>
      </div>
    );
  }
  const handleClearSelection = () => {
    client
      .post(`/api/unstar_venue`,  { venue_id: selectionModel } )
      .then(() => {
        setAnchorEl2(null);
        client.get(`/api/${ value === "1"? "venue_paginated_listview" : "star_venue_list" }`).then((response) => {
          if (response.data.results) {
            setpage(1);
            setcustomerList(response.data.results);
            setpageCount(
              Math.ceil(response.data.count / response.data.results.length)
            );
          } else {
            setpage(0);
            setpageCount(0);
            setcustomerList([]);
          }
        });
      });
  };
  return (
    <article>
      <div className="buttondiv">
        
        <div>
          { value === "2" ? (
            <PopupPopularVenue
              popularVenueList={[]}
              setpopularVenueList={setcustomerList}
              submitUrl='star_venue'
              refreshUrl='star_venue_list'
            />
          ) :  (
            ""
          )}
        </div>
        
      </div>
      <h4>Venue</h4>
      <TabContext value={value}>
        <TabList onChange={handleChange}>
          <Tab label="All" value="1" />
          <Tab label="STARRED" value="2" />
        </TabList>
        <TabPanel value="1">
          <div
            style={{
              width: "100%",
              paddingTop: "24px",
              borderRadius: "8px",
              border: "1px solid #E8E8E8",
            }}
          >
            <div className="mid-searchbar">
              <SearchBar
                searchvalue={searchvalue}
                setsearchvalue={setsearchvalue}
              />
              <Button
                onClick={handleClickaction}
                className="tertiarybtn"
                endIcon={
                  <img src="../Images/Arrow-Down.svg" alt="arrow down" />
                }
              >
                Actions
              </Button>
              <Menu
                anchorEl={anchorEl2}
                open={open}
                onClose={handleCloseaction}
              >
                <PopupDeleteAll delete={handlebulkDelete} />
              </Menu>
            </div>
            <div style={{ height: "650px" }}>
              <DataGrid
                sx={{ mt: 2 }}
                rows={customerList}
                columns={columns}
                pageSize={listlength}
                rowsPerPageOptions={[listlength]}
                getRowId={(row) => row.id}
                checkboxSelection
                pagination
                keepNonExistentRowsSelected
                components={{
                  Pagination: CustomPagination,
                  Toolbar: CustomToolbar,
                }}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value="2">
          <div
            style={{
              width: "100%",
              paddingTop: "24px",
              borderRadius: "8px",
              border: "1px solid #E8E8E8",
            }}
          >
            <div className="mid-searchbar">
              <SearchBar
                searchvalue={searchvalue}
                setsearchvalue={setsearchvalue}
              />
              <Button
                onClick={handleClickaction}
                className="tertiarybtn"
                endIcon={
                  <img src="../Images/Arrow-Down.svg" alt="arrow down" />
                }
              >
                Actions
              </Button>
              <Menu
                anchorEl={anchorEl2}
                open={open}
                onClose={handleCloseaction}
              >
                <div onClick={handleClearSelection}
                style={{padding:'3px 15px',
                color: '#FD7972',
                cursor:'pointer'}}>
                <img src="../Images/Unfavorite icon.svg" alt="img" width="25px" height="25px"/>
                <span style={{marginLeft:"10px"}}>Unstarred</span>
                </div>
                {/* <PopupDeleteAll delete={handlebulkDelete} /> */}
              </Menu>
            </div>
            <div style={{ height: "650px" }}>
              <DataGrid
                sx={{ mt: 2 }}
                rows={customerList}
                columns={columns}
                pageSize={listlength}
                rowsPerPageOptions={[listlength]}
                getRowId={(row) => row.id}
                checkboxSelection
                pagination
                keepNonExistentRowsSelected
                components={{
                  Pagination: CustomPagination,
                  Toolbar: CustomToolbar,
                }}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
              />
            </div>
          </div>
        </TabPanel>
      </TabContext>
    </article>
  );
}

export default Venue;
