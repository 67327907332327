import React, { useEffect, useRef, useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TextEditor from "../../Components/TextEditor/TextEditor";
import DropdownSelect from "../../Components/DropdownSelect/DropdownSelect";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import "./Popup.css";
import useAxios from "../../Helper/Client/useAxios";
import { client } from "../../Helper/Client/Client";
import AlertPopup from "../../Components/AlertPopup/AlertPopup";
import DragDrop from "../../Components/DragDrop/DragDrop";

export default function AddVenue(props) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");
  const initialData = {
    productname: "",
    productprice: "",
    detailsTitle: "",
    settingTitle: "",
  };
  const [inputs, setinputs] = useState(initialData);
  const [status, setstatus] = useState("");
  const [validationError, setvalidationError] = useState(false);
  const [adddetails, setAdddetails] = useState(true);
  const [editdetails, setEditdetails] = useState(true);
  const [addsetting, setAddsetting] = useState(true);
  const [editsetting, setEditsetting] = useState(true);
  const [texteditor, settexteditor] = useState("");
  const [texteditorSetting, settexteditorSetting] = useState("");
  const [producttype, setproducttype] = useState("");
  const [List, setList] = useState([]);
  const [typeList, settypeList] = useState([]);
  const [tagvalue, settagvalue] = useState([]);
  const [equipmentypeList, setequipmentypeList] = useState([]);
  const [equipmenttagvalue, setequipmenttagvalue] = useState([]);

  const [file, setfile] = useState([]);
  const [filelist, setfilelist] = useState([]);
  const [deleteImage, setdeleteImage] = useState(null);
  const [detailsList, setdetailsList] = useState([]);
  const [EditdetailsID, setEditdetailsID] = useState(null);
  const [settingsList, setsettingsList] = useState([]);
  const [EditsettingID, setEditsettingID] = useState(null);

  function handleChanged(e) {
    const { name, value } = e.target;
    setinputs((inputs) => ({ ...inputs, [name]: value }));
  }

  useEffect(() => {
    setfilelist(file);
  }, [file]);

  useEffect(() => {
    deleteImage && setfilelist(filelist.filter((item) => item !== deleteImage));
  }, [deleteImage]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function refreshPage() {
    window.location.reload(false);
  }
  const handleSubmit = () => {
    if (
      inputs.productname === "" ||
      inputs.productprice === "" ||
      producttype === "" ||
      status === ""||filelist.length === 0
    ) {
      setvalidationError(true);
      return;
    } else {
      setvalidationError(false);
    }
    handleClose();
    const body = {
      product_name: inputs.productname,
      product_price: inputs.productprice,
      offer_tag: "Basic Essentials",
      editor: "Admin",
      product_category_id: producttype, 
      product_tags: JSON.stringify(
        tagvalue.map((item) => {
          return item.id;
        })
      ),
      status: status,
    };
    client
      .post(`/api/product_create`, body)
      .then((response) => {
        setinputs(initialData);
        setstatus("");
        setproducttype("");
        settagvalue([]);
        setValue("1");

        let form_data = new FormData();
        form_data.append("product_id", response.data.results.id);
        filelist.length > 0
          ? form_data.append("image_url", filelist[0])
          : form_data.append("image_url", "");
     
        filelist[0] &&
          client
            .post(
              `api/product_image_update/${response.data.results.id}`,
              form_data
            )
            .then(() => {
              window.location.reload(false)
              setfile([]);
              setfilelist([]);
           
              setalertOptions({
                alertType: "success",
                alertOpen: true,
                alertContent: "Product Image Submitted Successfully!",
              });
            })
            .catch(() => {
              setalertOptions({
                alertType: "error",
                alertOpen: true,
                alertContent: "Product Image Submission Failed!",
              });
            });
        client
          .post(
            `api/product_details_add/${response.data.results.id}`,
            detailsList
          )
          .then(() => {
            setalertOptions({
              alertType: "success",
              alertOpen: true,
              alertContent: "Product Details Submitted Successfully!",
            });
            setdetailsList([]);
          })
          .catch(() => {
            setalertOptions({
              alertType: "error",
              alertOpen: true,
              alertContent: "Product Details Submission Failed!",
            });
          });
        client
          .post(
            `api/product_shippingmethods_add`,
            settingsList
          )
          .then(() => {
            client.get("/api/product_list").then((response) => {
              props.setpage(1);
              props.setproductList(response.data.results);
              props.setpageCount(
                Math.ceil(response.data.count / response.data.results.length)
              );
            });
          
            setalertOptions({
              alertType: "success",
              alertOpen: true,
              alertContent: "Product Shipping Submitted Successfully!",
            });
            setsettingsList([]);
          })
          .catch(() => {
            setalertOptions({
              alertType: "error",
              alertOpen: true,
              alertContent: "Product Shipping Submission Failed!",
            });
          });
        setalertOptions({
          alertType: "success",
          alertOpen: true,
          alertContent: "Product Created Successfully!",
        });
      })
      .catch(() => {
        setalertOptions({
          alertType: "error",
          alertOpen: true,
          alertContent: "Submission Failed!",
        });
      });
  };

  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });

  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };

  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    dragItem.current = position;
   
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
   
  };

  const drop = (e) => {
    const copyListItems = [...filelist];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setfilelist(copyListItems);
  };
  // console.log("FileList", filelist);

  const handleAddNewSettings = () => {
    setAddsetting(false);
  };
  const handleAddNewDetails = () => {
    setAdddetails(false);
  };

  function handleSettingAdd() {
    if (inputs.settingTitle === "") {
      setvalidationError(true);
      return;
    } else {
      setvalidationError(false);
    }
    setsettingsList([
      ...settingsList,
      {
        dateid: Date.now(),
        pickup_way: inputs.settingTitle,
        description: texteditorSetting,
      },
    ]);
    setAddsetting(true);
    setinputs((inputs) => ({ ...inputs, ["settingTitle"]: "" }));
    settexteditorSetting("");
  }
 

  function handleEditSetting(i) {
    setEditsetting(false);
    settingsList.map((item) => {
      if (item.dateid === i) {
        setinputs((inputs) => ({
          ...inputs,
          ["settingTitle"]: item.pickup_way,
        }));
        settexteditorSetting(item.description);
        setEditsettingID(i);
      }
    });
  }

  function handleDeleteSetting(i) {
    setsettingsList(settingsList.filter((item) => item.dateid !== i));
  }
  function handleSettingEditSave() {
    if (inputs.settingTitle === "") {
      setvalidationError(true);
      return;
    } else {
      setvalidationError(false);
    }
    setsettingsList(
      settingsList.map((item) => {
        if (item.dateid === EditsettingID) {
          const updatedItem = {
            ...item,
            pickup_way: inputs.settingTitle,
            description: texteditorSetting,
          };
          return updatedItem;
        }
        return item;
      })
    );
    setEditsetting(true);
    setinputs((inputs) => ({ ...inputs, ["settingTitle"]: "" }));
    settexteditorSetting("");
    setEditsettingID(null);
  }

  function handlecanceledit() {
    setEditsetting(!editsetting);
    setEditsettingID(null);
    setinputs((inputs) => ({ ...inputs, ["settingTitle"]: "" }));
    settexteditorSetting("");
  }

  function handleDetailsAdd() {
    if (inputs.detailsTitle === "") {
      setvalidationError(true);
      return;
    } else {
      setvalidationError(false);
    }
    setdetailsList([
      ...detailsList,
      {
        dateid: Date.now(),
        title: inputs.detailsTitle,
        description: texteditorSetting,
      },
    ]);
    setAdddetails(true);
    setinputs((inputs) => ({ ...inputs, ["detailsTitle"]: "" }));
    settexteditorSetting("");
  }


  function handleEditDetails(i) {
    setEditdetails(false);
    detailsList.map((item) => {
      if (item.dateid === i) {
        setinputs((inputs) => ({ ...inputs, ["detailsTitle"]: item.title }));
        settexteditorSetting(item.description);
        setEditdetailsID(i);
      }
    });
  }

  function handleDeleteDetails(i) {
    setdetailsList(detailsList.filter((item) => item.dateid !== i));
  }
  function handleDetailsEditSave() {
    if (inputs.detailsTitle === "") {
      setvalidationError(true);
      return;
    } else {
      setvalidationError(false);
    }
    setdetailsList(
      detailsList.map((item) => {
        if (item.dateid === EditdetailsID) {
          const updatedItem = {
            ...item,
            title: inputs.detailsTitle,
            description: texteditorSetting,
          };
          return updatedItem;
        }
        return item;
      })
    );
    setEditdetails(true);
    setinputs((inputs) => ({ ...inputs, ["detailsTitle"]: "" }));
    settexteditorSetting("");
    setEditdetailsID(null);
  }

  function handlecanceldetailsedit() {
    setEditdetails(!editdetails);
    setEditdetailsID(null);
    setinputs((inputs) => ({ ...inputs, ["detailsTitle"]: "" }));
    settexteditorSetting("");
  }
  const [
    productcategoryresponse,
    productcategoryisError,
    productcategoryisLoading,
  ] = useAxios({
    url: "/api/product_category_list",
    method: "get",
    
  });
  const [producttagsresponse, producttagsisError, producttagsisLoading] =
    useAxios({
      url: "/api/product_tags_list",
      method: "get",
      
    });

  // console.log("Product Category Resp", productcategoryresponse);
  // console.log("Product Tag Resp", producttagsresponse);

  useEffect(() => {
    if (productcategoryresponse && productcategoryresponse.data.results) {
      const alteredcategory = productcategoryresponse.data.results.map(
        (item) => {
          const updatedItem = {
            ...item,
            value: item.id,
            name: item.category,
          };
          // console.log("U", updatedItem);
          return updatedItem;
        }
      );
      setList(alteredcategory);
    }

    if (producttagsresponse && producttagsresponse.data.results) {
      settypeList(producttagsresponse.data.results);
    }

    return () => {
   
    };
  }, [productcategoryresponse, producttagsresponse]);

  // console.log("Product Category List", List);
  // console.log("Ptype", producttype);
  // console.log("Ptag", typeList);


  return (
    <div>
      <div style={{ float: "right" }}>
      
        <button className="exportbtnpluslg" onClick={handleClickOpen}>
          <span
            className="material-icons"
            style={{
              color: "white",
              fontSize: "15px",
              paddingTop: "4px",
            }}
          >
            add
          </span>
        </button>
      </div>
      <Dialog fullWidth open={open} onClose={handleClose} className="dialog">
        <Box>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="ALL" value="1" />
                <Tab label="IMAGES" value="2" />
                <Tab label="DETAILS" value="3" />
               
                <img
                  onClick={handleClose}
                  src="/Images/close.png"
                  height="32px"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "8px",
                  }}
                />
              </TabList>
            </Box>
            <TabPanel sx={{ p: 0 }} value="1">
              <DialogTitle></DialogTitle>
              <DialogContent
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label className="mt20">
                  Product Name
                  <input
                    className={
                      validationError &&
                      inputs.productname === "" &&
                      "validationerror"
                    }
                    style={{ width: "100%" }}
                    type="text"
                    maxlength="45"
                    required
                    name="productname"
                    onChange={handleChanged}
                    value={inputs.productname}
                  />
                </label>

                <label className="mt20">
                  Product price
                  <input
                    className={
                      validationError &&
                      inputs.productprice === "" &&
                      "validationerror"
                    }
                    style={{ width: "100%" }}
                    type="number"
                    required
                    name="productprice"
                    onChange={handleChanged}
                    value={inputs.productprice}
                  />
                </label>

                <label className="mt20" style={{ width: "100%" }}>
                  Category
                  <DropdownSelect
                    validationError={validationError}
                    value={producttype}
                    setdropDownValue={setproducttype}
                    values={List}
                  />
                </label>

                <label className="mt20">
                  Tag
                  <Autocomplete
                    className="multiselect"
                    multiple
                    style={{
                      backgroundColor: "#f5f5f5",
                      padding: "10px",
                      borderRadius: "8px",
                    }}
                    options={typeList}
                    value={tagvalue}
                    onChange={(event, newValue) => {
                      settagvalue(newValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.tag === value.tag
                    }
                    getOptionLabel={(option) => option.tag}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" />
                    )}
                  />
                </label>

                <label className="mt20">
                  Status
                  <DropdownSelect
                    validationError={validationError}
                    value={status}
                    setdropDownValue={setstatus}
                    values={[
                      { name: "Active", value: true },
                      { name: "Blocked", value: false },
                    ]}
                  />
                </label>
              </DialogContent>
              <DialogActions style={{ textAlign: "end", padding: "20px" }}>
                <Button
                  className="primarybtn"
                  onClick={() => {
                    if (
                      inputs.productname === "" ||
                      inputs.productprice === "" ||
                      producttype === "" ||
                      status === ""
                    ) {
                      setvalidationError(true);
                      return;
                    } else {
                      setvalidationError(false);
                    }
                    setValue("2");
                  }}
               
                >
                  Next Step
                </Button>
              </DialogActions>
            </TabPanel>
            <TabPanel sx={{ p: 0 }} value="2">
              <DialogTitle>Images</DialogTitle>
              <DialogContent
                style={{ display: "flex", flexDirection: "column" }}
              >
                {!filelist.length > 0 && (
                  <DragDrop
                    deleteImage={deleteImage}
                    setfile={setfile}
                    filelist={filelist}
                    validationError={validationError}
                  />
                )}
                {filelist.length > 0 && (
                  <>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "30px 100px 180px 150px 50px",
                        justifyItems: "center",
                        marginTop: "30px",
                      }}
                    >
                     
                    </div>
                    {filelist.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "grid",
                          gridTemplateColumns: "30px 100px 180px 150px  50px",
                          justifyItems: "center",
                          verticalAlign: "center",
                          border: "1px solid #636363",
                          padding: "8px",
                          borderRadius: "5px",
                          marginBottom: "10px",
                        }}
                      >
                        
                        <p></p>
                        <img
                          src={URL.createObjectURL(item)}
                          style={{ margin: "auto" }}
                          height="74px"
                          width="74px"
                        />
                       
                        <p></p>
                        <p></p>
                        <img
                          style={{ margin: "auto" }}
                          src="/Images/Delete.svg"
                          height="16px"
                          onClick={() => setdeleteImage(item)}
                        />
                      </div>
                    ))}
                  </>
                )}
              </DialogContent>
              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "20px",
                }}
              >
                <Button
                  className="secondarybtn"
                  onClick={() => {
                    setValue("1");
                  }}
                >
                  Previous
                </Button>
                <Button
                  className="primarybtn"
                  onClick={() => {
                    if (
                      filelist.length === 0
                    ) {
                      setvalidationError(true);
                      return;
                    } else {
                      setvalidationError(false);
                    }
                    setValue("3");
                  }}
                >
                  Next Step
                </Button>
              </DialogActions>
            </TabPanel>
            <TabPanel sx={{ p: 0 }} value="3">
              <DialogTitle>Details</DialogTitle>
              <DialogContent
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div
                  style={{ color: "#3B3B3B" }}
                  className={
                    adddetails && editdetails === true
                      ? "settings-display"
                      : "settings-none"
                  }
                >
                  {detailsList &&
                    detailsList.map((item) => (
                      <div className="setting_section">
                        <p>{item.title}</p>
                        <ul>
                          <li>
                            <img
                              src="../Images/edit.png"
                              onClick={() => handleEditDetails(item.dateid)}
                              height="16px"
                            />
                          </li>
                          <li>
                            <img
                              src="../Images/Delete.svg"
                              onClick={() => handleDeleteDetails(item.dateid)}
                              height="16px"
                            />
                          </li>
                        </ul>
                      </div>
                    ))}
                  <button
                    onClick={handleAddNewDetails}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      color: "#FE9900",
                      marginBlockStart: "15px",
                      marginBlockEnd: "15px",
                    }}
                  >
                    <img src="../Images/plus.svg" /> Add
                  </button>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      className="secondarybtn"
                      onClick={() => setValue("2")}
                    >
                      Previous
                    </Button>
                    <Button
                      className="primarybtn"
                      onClick={
                        handleSubmit
                       
                      }
                    >
                      Submit
                    </Button>
                  </div>
                </div>
                <div
                  className={
                    adddetails === false ? "settings-display" : "settings-none"
                  }
                >
                  <label style={{ width: "100%" }}>
                    Title
                    <input
                      className={
                        validationError && inputs.detailsTitle === ""
                          ? "validationerror"
                          : ""
                      }
                      style={{ width: "100%" }}
                      name="detailsTitle"
                      onChange={handleChanged}
                      value={inputs.detailsTitle}
                      maxLength={30}
                      required
                    />
                  </label>
                  <label
                    style={{
                      color: "grey",
                      marginBlockEnd: "20px",
                      width: "100%",
                    }}
                  >
                    Description
                    <TextEditor
                      settexteditor={settexteditorSetting}
                      texteditor={texteditorSetting}
                    />
                  </label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: "10px",
                      gap: "10px",
                    }}
                  >
                    <Button className="primarybtn" onClick={handleDetailsAdd}>
                      Save
                    </Button>
                    <Button
                      className="secondarybtn"
                      onClick={() => setAdddetails(!adddetails)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
                <div
                  className={
                    editdetails === false ? "settings-display" : "settings-none"
                  }
                >
                  <label style={{ width: "100%" }}>
                    Title
                    <input
                      className={
                        validationError && inputs.detailsTitle === ""
                          ? "validationerror"
                          : ""
                      }
                      style={{ width: "100%" }}
                      name="detailsTitle"
                      onChange={handleChanged}
                      value={inputs.detailsTitle}
                      maxLength={30}
                      required
                    />
                  </label>
                  <label
                    style={{
                      color: "grey",
                      marginBlockEnd: "20px",
                      width: "100%",
                    }}
                  >
                    Description
                    <TextEditor
                      settexteditor={settexteditorSetting}
                      texteditor={texteditorSetting}
                    />
                  </label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: "10px",
                      gap: "10px",
                    }}
                  >
                    <Button
                      className="primarybtn"
                      onClick={handleDetailsEditSave}
                    >
                      Save
                    </Button>
                    <Button
                      className="secondarybtn"
                      onClick={handlecanceldetailsedit}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </DialogContent>
            </TabPanel>


          





           
          </TabContext>
        </Box>
      </Dialog>
      <AlertPopup alertOptions={alertOptions} handleClose={handleClosep} />
    </div>
  );
}
