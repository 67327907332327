import React, { useState } from 'react'
import {
  Button,
  Dialog,
} from "@mui/material";
import './Popup.css'
import DropdownSelect from '../../Components/DropdownSelect/DropdownSelect';
import RangeSlider from '../../Components/RangeSlider/RangeSlider';

const PopupFilter = () => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");
  const [status, setstatus] = useState('')

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <button className="exportbtnplus" onClick={handleClickOpen}>
        <span className="material-icons" style={{
          color: "white", fontSize: '15px',
          paddingTop: '4px'
        }}>add</span>
      </button>
      <Dialog open={open} onClose={handleClose} className='dialog'>
        <div style={{ width: '400px', height: '567px', padding: '24px' }}>
          <h4>Filter</h4>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label className="mt20">
              Category
              <DropdownSelect value={status} setdropDownValue={setstatus} values={[{ name: 'Active', value: true }, { name: 'Blocked', value: false }]} />
            </label>
            <label className="mt20">
              Status
              <DropdownSelect value={status} setdropDownValue={setstatus} values={[{ name: 'Active', value: true }, { name: 'Blocked', value: false }]} />
            </label>
            <label className="mt20">Date
              <input type="date" id="birthday" name="birthday"></input>
            </label>
            <label className="mt20">Price
              <RangeSlider />
            </label>
            <Button className='primarybtn' style={{ width: '100%', marginTop: '20px' }}>Save</Button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default PopupFilter