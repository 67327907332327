import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import "./Popup.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { client } from "../../Helper/Client/Client";
import AlertPopup from "../../Components/AlertPopup/AlertPopup";
import PopupCustomerVendorList from "./PopupCustomerVendorList";

const PopupNotification = (props) => {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(true);
  const [isChecked, setIsChecked] = React.useState(false);
  const [value, setValue] = React.useState("1");
  const [inputs, setinputs] = useState({
    title: "",
    description: "",
    // uploaddate: null,
    // uploadtime: null,
  });
  const [validationError, setvalidationError] = useState(false);
  const [notificationtype, setNotificationtype] = useState("All");
  const [optionvalue, setOptionvalue] = useState("");
  const [data, setData] = useState([]);
  const [Obj, setObj] = useState([]);
  const validstartdate=new Date().toISOString().split('T')[0]

  useEffect(() => {
    try {
      setObj(JSON.parse(data));
    } catch (err) {
      // console.log("error", err);
    }
  }, [data]);

  const [defaultList, setDefaultList] = useState([]);

  useEffect(() => {
    setDefaultList(Obj.map(({ id, full_name }) => ({ id, full_name })));
    setIsChecked(true);
  }, [Obj]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };

  function handleChanged(e) {
    const { name, value } = e.target;
    setinputs((inputs) => ({ ...inputs, [name]: value }));
  }

  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });

  const handleHide = (e) => {
    // setShow(e.target.value===""?true:false);
    // console.log(e.target.value);
    // if(e.target.value){
    // setShow(false)
    // }
    console.log("date",inputs.uploaddate)
    console.log("time",inputs.uploadtime)
    if(e.target.value==="" || e.target.value===null){
      setShow(true)
    }
    if(inputs.uploaddate==="" && inputs.uploadtime===""){
      setShow(true)
    }
    if(inputs.uploaddate===null && inputs.uploadtime===null){
      setShow(true)
    }
    if(inputs.uploaddate || inputs.uploadtime===""){
      setShow(true)
    }
    if(inputs.uploaddate===""&&  inputs.uploadtime){
      setShow(true)
    }
    else{
      setShow(false)
    }
  };

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      backgroundColor: "#F5F5F5",
      padding: "15px 26px 10px 12px",
      borderRadius: 8,
      fontFamily: [].join(","),
      "&:focus": {},
    },
  }));

  function handleSubmit() {
    if (inputs.title === "" || inputs.description === ""||notificationtype === "") {
      setvalidationError(true);
      return;
    } else {
      setvalidationError(false);
    }
    if(notificationtype === "Individual"){
      // console.log("ntt");
      if(defaultList?.length===0){
        // console.log("errerererer")
        setalertOptions({
          alertType: "error",
          alertOpen: true,
          alertContent: "please select individuals",
        });
        return;
      }else{
        // console.log("erererere222222")
      }
    }
    const body = {
      title: inputs.title,
      content: inputs.description,
      upload_date:inputs.uploaddate==""?null:inputs.uploaddate,
      upload_time:inputs.uploadtime==""?null:inputs.uploadtime,
      send_immediately: isChecked,
      notification_type: notificationtype,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      users_list: defaultList?.map((item) => item.id),
    };
    console.log("default list",defaultList)
    handleClose();
    client
      .post("/api/notification_send_create", body)
      .then(() => {
        setalertOptions({
          alertType: "success",
          alertOpen: true,
          alertContent: "Submitted Successfully!",
        });
        setValue("1");
        setinputs({ title: "", description: "" });
        setNotificationtype("All");
        setIsChecked(true);
        setOptionvalue("");
        setShow(true);
        setDefaultList([]);
        client.get(`/api/notification_send_list`).then((response) => {
          props.setpage(1);
          props.setNotificationList(response.data.results);
          props.setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          );
        });
      })
      .catch(() => {
        setalertOptions({
          alertType: "error",
          alertOpen: true,
          alertContent: "Submission Failed!",
        });
      });
  }
  // console.log("notificationtype", notificationtype);
  // console.log("data", data);
  // console.log("Array of Objects", Obj);
  // console.log("defaultList", defaultList);

  return (
    <div>
      <button className="exportbtnpluslg" onClick={handleClickOpen}>
        <span
          className="material-icons"
          style={{ color: "white", fontSize: "15px", paddingTop: "4px" }}
        >
          add
        </span>
      </button>
      <Dialog fullWidth open={open} onClose={handleClose} className="dialog">
        <Box>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="NOTIFICATION" value="1" />
                <img
                  onClick={handleClose}
                  src="/Images/close.png"
                  height="32px"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "8px",
                  }}
                />
              </TabList>
            </Box>
            <TabPanel sx={{ p: 0 }} value="1">
              <DialogTitle>Notification</DialogTitle>
              <DialogContent
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label className="mt20">
                  Title
                  <input
                    className={
                      validationError && inputs.title === ""
                        ? "validationerror"
                        : ""
                    }
                    value={inputs.title}
                    type="text"
                    name="title"
                    onChange={handleChanged}
                    required
                  />
                </label>
                <label className="mt20">
                  Content
                  <textarea
                    className={
                      validationError && inputs.description === ""
                        ? "validationerror"
                        : ""
                    }
                    name="description"
                    value={inputs.description}
                    onChange={handleChanged}
                    style={{ borderRadius: "8px" }}
                  />
                </label>
                <label className="mt20">
                  Upload date & Time
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "150px 200px",
                      gap: 10,
                    }}
                    onChange={handleHide}
                  >
                    <input
                      type="time"
                      id="birthday"
                      name="uploadtime"
                      value={inputs.uploadtime}
                      onChange={handleChanged}
                      disabled={isChecked}
                    ></input>
                    <input
                      type="date"
                      id="birthday"
                      name="uploaddate"
                      value={inputs.uploaddate}
                      onChange={handleChanged}
                      disabled={isChecked}
                      min={validstartdate}
                    ></input>
                  </div>
                </label>
                {show ? (
                  <FormControl component="fieldset" sx={{width:'180px'}}>
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          checked={isChecked}
                          onChange={(e) => setIsChecked(e.target.checked)}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{
                            "&.Mui-checked": {
                              color: "#FE9900",
                            },
                          }}
                        />
                      }
                      label="Send Immediately"
                      labelPlacement="end"
                    />
                  </FormControl>
                ) : null}
                <label className="mt20">Notification Type</label>

                <FormControl sx={{ minWidth: 120 }}>
                  <Select
                    value={notificationtype}
                    onChange={(e) => setNotificationtype(e.target.value)}
                    displayEmpty
                    input={<BootstrapInput />}
                  >
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="All Customers">All Customers</MenuItem>
                    <MenuItem value="All Vendors">All Vendors</MenuItem>
                    <MenuItem
                      value="Individual"
                      onClick={() => setOptionvalue("12")}
                    >
                      Individual
                    </MenuItem>
                  </Select>
                </FormControl>
                {optionvalue === "12" ? (
                  <PopupCustomerVendorList setData={setData} />
                ) : null}
              </DialogContent>
              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginBlockStart: "20px",
                  padding: "20px",
                }}
              >
                <Button className="primarybtn" onClick={handleSubmit}>
                  Save
                </Button>
                <Button className="secondarybtn" onClick={handleClose}>
                  Cancel
                </Button>
              </DialogActions>
            </TabPanel>
          </TabContext>
        </Box>
      </Dialog>
      <AlertPopup alertOptions={alertOptions} handleClose={handleClosep} />
    </div>
  );
};

export default PopupNotification;
