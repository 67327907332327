import React, {useState} from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button } from '@mui/material';
import SearchBar from '../../Components/SearchBar/SearchBar';
import Popup from '../Popup/Popup';

function Product() {
  
  // tab property
  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // table view(edit/delete)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [editbar, setEditbar] = useState('');
  const showEditbar = (event ,id) => {
    setAnchorEl(event.currentTarget);
    setEditbar(id);
  };
  const handleClose = () => {
    setEditbar(null);
  };
  
  const open = Boolean(anchorEl2);
  const handleClickaction = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseaction = () => {
    setAnchorEl2(null);
  };

 
  const columns = [
    {
      field: 'productname',
      headerName: 'Product Name',
      width: 200,
    },
    {
      field: 'codeno',
      headerName: 'Code No',
      width: 150,
    },
    {
      field: 'price',
      headerName: 'Price',
      width: 150,
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 160
    },
    {
      field: "id",
      headerName: "",
      width: 100,
      renderCell: (params) => (
        <>
        <IconButton onClick={(event)=>showEditbar(event,params.value)}>
          <img src="../Images/dots.png" />
        </IconButton>
        <Menu anchorEl={anchorEl}
        open={editbar === params.value ? true :false}
        onClose={handleClose}>
          <MenuItem onClick={handleClose}>Edit Item</MenuItem>
          <MenuItem onClick={handleClose}>Delete Item</MenuItem>
      </Menu>
      </>
      ),
    },
  ];
  
  const rows = [
    { id: 1, productname: 'cleaning machine1',codeno: '#00001', price: '$90.00', date: '20/12/21',status:'Finished' },
    { id: 2, productname: 'cleaning machine2',codeno: '#00002', price: '$90.00', date: '20/12/21',status:'unfinished' },
    { id: 3, productname: 'cleaning machine3',codeno: '#00003', price: '$90.00', date: '20/12/21',status:'Finished' },
  ];

  return (
    <article> 
        <div className="buttondiv">
        <Button className='tertiarybtn' startIcon={<img src="../Images/download.svg" />} endIcon={<img src="../Images/Arrow-Down.svg"/>}>Export</Button>
        <Popup/>
      </div>
      <h4>Product</h4>
        <TabContext value={value}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="All" value="1" />
              <Tab label="ACTIVE" value="2" />
              <Tab label="BLOCKED" value="3" />
            </TabList>
          <TabPanel value="1">
            <div style={{ width: '100%',paddingTop:'24px',borderRadius:'8px',border:'1px solid #E8E8E8' }}>
              <div className="mid-searchbar">
              <SearchBar/>
                <Button   onClick={handleClickaction} 
                className='tertiarybtn' endIcon={<img src="../Images/Arrow-Down.svg"/>}>Actions</Button>
                <Menu anchorEl={anchorEl2}
                open={open}
                onClose={handleCloseaction}>
                  <MenuItem onClick={handleCloseaction}>Edit Item</MenuItem>
                  <MenuItem onClick={handleCloseaction}>Delete Item</MenuItem>
              </Menu>
              </div>
              <div style={{height:'500px'}}>
              <DataGrid
                sx={{mt:2}}
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[10]}
                checkboxSelection
                disableSelectionOnClick
              />
              </div>
            </div>
          </TabPanel>
          <TabPanel value="2">Item Two</TabPanel>
          <TabPanel value="3">Item Three</TabPanel>
        </TabContext>
    </article>
  )
}

export default Product;