import React, { useEffect, useRef, useState } from 'react'
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  TextField,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import './Popup.css'
import DragDrop from '../../Components/DragDrop/DragDrop';
import DropdownSelect from '../../Components/DropdownSelect/DropdownSelect';
import useAxios from '../../Helper/Client/useAxios';
import { client } from '../../Helper/Client/Client';
import AlertPopup from '../../Components/AlertPopup/AlertPopup';

const PopupPromotionBanner = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("1");
  const [file, setfile] = useState([])
  const [filelist, setfilelist] = useState([])
  const [deleteImage, setdeleteImage] = useState(null)
  const [status, setstatus] = useState('')
  const [Cover, setCover] = useState(0)
  const [vendorlist, setVendorlist] = useState([])
  const [vendorname,setVendorname]= useState('')
  const initialData={ bannertitle: "", startdate: "",enddate: ""}
  const [inputs, setinputs] = useState(initialData);
  const [validationError, setvalidationError] = useState(false)

  function handleChanged(e) {
    const { name, value } = e.target;
    setinputs((inputs) => ({ ...inputs, [name]: value }));
  }

  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    dragItem.current = position;
    
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    
  };

  const drop = (e) => {
    const copyListItems = [...filelist];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setfilelist(copyListItems);
  };


  useEffect(() => {
    setfilelist(file)
  }, [file])

  useEffect(() => {
    deleteImage && setfilelist(filelist.filter(item => item !== deleteImage))
  }, [deleteImage])

  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });
  
  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


const [response, isError, isLoading] = useAxios({
  url: '/api/vendor_list',
  method: "get",

});
useEffect(() => {
  if (response && response.data.data) {
    const vendornamelist = response.data.data.map((item) => {
      const updatedItem = {
        ...item,
        value: item.id,
        name: item.email
      }
      return updatedItem
    })
    setVendorlist(vendornamelist);
  }
  return () => {
    
  }
}, [response]);


function handleSubmit(){
  if (inputs.startdate===''||inputs.enddate===''||
  status===''||filelist.length == 0||
  vendorname===null ||vendorname===''){
    setvalidationError(true)
    console.log("true",vendorname)
    return
  }
  else{
    setvalidationError(false)
    console.log("false",vendorname)
  }
  let form_data = new FormData();
       form_data.append("banner_title", inputs.bannertitle);
       form_data.append("start_date", inputs.startdate);
       form_data.append("end_date", inputs.enddate);
       form_data.append("status", status?1:0);
       form_data.append("vendor_id", vendorlist.filter(item=>item.name===vendorname)[0]?.value);
       filelist.length > 0 && filelist.map(item=>(
        form_data.append("image_list", item)
       ))
       handleClose()
  client.post('/api/banner_create',form_data,{headers: {
    'content-type': 'multipart/form-data'
  }}).then(()=>{
    setinputs(initialData)
    setstatus('')
    setfile([])
    setfilelist([])
    setVendorname('')
    setValue("1")
    setalertOptions({
    alertType: "success",
    alertOpen: true,
    alertContent: "Submitted Successfully!",
  })
  
  client.get('/api/banner_list').then((response) => {
    props.setbannerList(response.data.data);
  })
}).catch(()=>{
  setalertOptions({
    alertType: "error",
    alertOpen: true,
    alertContent: "Submission Failed!",
  })
})
}



function handleNext(){
  if (inputs.startdate===''||inputs.enddate===''||status===''||
  vendorname=== null||vendorname===''){
    setvalidationError(true)
    console.log("true",vendorname)
    return
  }
  else{
    setvalidationError(false)
    console.log("false",vendorname)
  }
  setValue("2")
}

const todayDate=new Date()
  todayDate.setDate(todayDate.getDate()+1)
  var validenddate=todayDate.toISOString().split('T')[0]
  const validstartdate=new Date().toISOString().split('T')[0]
  if(inputs.startdate!==""){
    const futuredate=new Date(inputs.startdate)
    futuredate.setDate(futuredate.getDate()+1)
    validenddate=futuredate.toISOString().split('T')[0]
  }
  if(inputs.startdate>inputs.enddate){
    inputs.enddate=''
  }

  return (
    <div>
      <button className="exportbtnpluslg" onClick={handleClickOpen}>
      <span className="material-icons" style={{color:"white",fontSize: '15px',
    paddingTop: '4px'}}>add</span>
      </button>
      <Dialog fullWidth open={open} onClose={handleClose} className='dialog'>
        <Box >
          <TabContext value={value} >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="PROFILE" value="1" />
                <Tab label="IMAGES" value="2" />
             
                <img onClick={handleClose} src='/Images/close.png' height='32px' style={{
                  position: 'absolute',
                  right: '10px',
                  top: '8px'
                }} />
              </TabList>
            </Box>
            <TabPanel sx={{ p: 0 }} value="1">
            <DialogTitle >INFORMATION</DialogTitle>
              <DialogContent style={{ display: 'flex', flexDirection: 'column' }} >
                <label className="mt20">Banner Title
                  <input
                    value={inputs.bannertitle}
                    type="text"
                    name="bannertitle"
                    onChange={handleChanged}
                   
                    // required
                    // className={validationError && inputs.bannertitle==='' && "validationerror"}
                  />
                </label>
                <label className="mt20">
                  Vendor Email 
                 
                     <Autocomplete
                      className={validationError && (vendorname===null || vendorname==='')
                       && "validationerror"}
                     value={vendorname}
                     onChange={(event, newValue) => {
                      setVendorname(newValue);
                      console.log("hai",vendorname)
                     }}
        options={vendorlist.map(
          (item) => `${item.name}`
        )}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <input type="text" {...params.inputProps}/>
          </div>
        )}
      />

                  </label>
                <div style={{display:'grid',gridTemplateColumns:'auto auto',gap:'30px'}}>
                <label className="mt20">Start Date 
                <input  type="date" id="birthday" name="startdate" value={inputs.startdate} min={validstartdate}
                  className={validationError && inputs.startdate==='' && "validationerror"} onChange={handleChanged}></input>
                </label>
                <label className="mt20">End Date 
                <input  type="date" id="birthday"  name="enddate" value={inputs.enddate} min={validenddate}
                     className={validationError && inputs.enddate==='' && "validationerror"} onChange={handleChanged}></input>
                </label>
                </div>
                <label className="mt20">Status          
                <DropdownSelect   validationError={validationError} value={status} setdropDownValue={setstatus} values={[{name:'Active',value:true},{name:'Disable',value:false}]}/>
                </label>
                </DialogContent>
                <DialogActions style={{ textAlign: 'end', padding: '20px' }}>
                  <Button className='primarybtn' onClick={handleNext}>Next Step</Button>
                </DialogActions>
              
            </TabPanel>
            <TabPanel sx={{ p: 0 }} value="2">
              <DialogTitle >Images</DialogTitle>
              <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
              {!filelist.length > 0 &&   <DragDrop deleteImage={deleteImage} setfile={setfile} filelist={filelist} validationError={validationError}/>}
              {filelist.length > 0 &&
                    <><div style={{ display: 'grid', gridTemplateColumns: '30px 100px 180px 150px 50px', justifyItems: 'center', marginTop: '30px' }}>
                     
                    </div>
                      {filelist.map((item, index) => (<div key={index}
                        draggable onDragStart={(e) => dragStart(e, index)} onDragEnter={(e) => dragEnter(e, index)} onDragEnd={drop} style={{ display: 'grid', gridTemplateColumns: '30px 100px 180px 150px  50px', justifyItems: 'center', verticalAlign: "center", border: '1px solid #636363', padding: '8px', borderRadius: '5px', marginBottom: '10px' }}>
                        <p></p>
                        
                        <img src={URL.createObjectURL(item)} style={{ margin: 'auto' }} height='74px' width="74px" />
                    
                        <p></p>
                        <p></p>
                        <img style={{ margin: 'auto' }} src='/Images/Delete.svg' height='16px' onClick={() => setdeleteImage(item)} />
                      </div>))}</>}
              </DialogContent>
              <DialogActions style={{ display: 'flex', justifyContent: 'space-between',padding:'20px' }}>
                  <Button className='secondarybtn' onClick={()=>{setValue("1")}}>Previous</Button>
                  <Button className='primarybtn'  onClick={handleSubmit}>Submit</Button>
                </DialogActions>
            </TabPanel>
          </TabContext>
        </Box>
      </Dialog>
      <AlertPopup alertOptions={alertOptions} handleClose={handleClosep} />
    </div>
  )
}

export default PopupPromotionBanner