import './App.css';
import Login from './Pages/Login/Login';
import Recover from './Pages/Login/Recover';
import Reset from './Pages/Login/Reset';
import New from './Components/Navbar/New';
import Venue from './Pages/Venue/Venue';
import Customer from './Pages/Customer/Customer';
import {
  unstable_HistoryRouter as HistoryRouter,
  Route,
  Routes, Navigate
} from "react-router-dom";
import { history } from './Helper/History/history';
import PrivateRoute from './Helper/Redirection/PrivateRoute';
import Dashboard from './Pages/Dashboard/Dashboard';
import Popup from './Pages/Popup/Popup';
import Venuedetails from './Pages/Venue/Venuedetails'
import Otpverify from './Pages/Login/Otpverify';
import HomePageManagement from './Pages/HomePageManagement/HomePageManagement';
import VideoPromoMsg from './Pages/HomePageManagement/VideoPromoMsg';
import HomePagedetails from './Pages/HomePageManagement/HomePagedetails';
import HomeDetailsVideo from './Pages/HomePageManagement/HomeDetailsVideo';
import ProductFlowScreens from './Pages/ProductFlowScreens/ProductFlowScreens';
import Product from './Pages/ProductManagement/Product';
import OrderListScreen from './Pages/ProductFlowScreens/OrderListScreen';
import OrderDetails from './Pages/ProductFlowScreens/OrderDetails';
import BlogPost from './Pages/BlogPost/BlogPost';
import Gift from './Pages/GiftScreens/Gift';
import GiftDetails from './Pages/GiftScreens/GiftDetails';
import Contact from './Pages/Contact/Contact';
import Customerdetails from './Pages/Customer/Customerdetails';
import TransactionManagement from './Pages/TransactionManagement/TransactionManagement';
import TransactionDetails from './Pages/TransactionManagement/TransactionDetails';
import VendorTransaction from './Pages/TransactionManagement/VendorTransaction';
import Vendor from './Pages/Vendor/Vendor';
import Vendordetails from './Pages/Vendor/Vendordetails';
import FAQ from './Pages/FAQ/FAQ';
import TCEdit from './Pages/T&CEdit/T&CEdit';
import SearchResult from './Pages/SearchResult/SearchResult';
import PopupFilter from './Pages/Popup/PopupFilter';
import Categories from './Pages/Categories/Categories';
import ProductDetails from './Pages/ProductFlowScreens/ProductDetails';
import Categorydetails from './Pages/Categories/Categorydetails';
import Payment from './Pages/Payment/Payment';
import Notification from './Pages/Notification/Notification';
import CreatedCouponList from './Pages/Coupon/CreatedCouponList';
import SendingCouponList from './Pages/Coupon/SendingCouponList';
import PopupCustomerList from './Pages/Popup/PopupCustomerList';
import ProductTransaction from './Pages/TransactionManagement/ProductTransaction';
import ProductManagementDetails from './Pages/TransactionManagement/ProductManagementDetails';

function App() {
  return (
    <div>
      <HistoryRouter history={history}>
        <Routes>
          <Route exact path="" element={
            <PrivateRoute redirectTo="/login" >
              <New component={<Dashboard />} />
            </PrivateRoute>
          } />
          <Route path="/venue" element={<PrivateRoute redirectTo="/login" >
            <New component={<Venue />} />
          </PrivateRoute>} />
          <Route path="/pro" element={<PrivateRoute redirectTo="/login" >
            <New component={<Product />} />
          </PrivateRoute>} />
          <Route path="/payment" element={<PrivateRoute redirectTo="/login" >
            <New component={<Payment />} />
          </PrivateRoute>} />
          <Route path="/category" element={<PrivateRoute redirectTo="/login" >
            <New component={<Categories />} />
          </PrivateRoute>} />
          <Route path="/categorydetails/:id" element={<PrivateRoute redirectTo="/login" >
            <New component={<Categorydetails />} />
          </PrivateRoute>} />
          <Route path="/createcoupon" element={<PrivateRoute redirectTo="/login" >
            <New component={<CreatedCouponList />} />
          </PrivateRoute>} />
          <Route path="/sendcoupon" element={<PrivateRoute redirectTo="/login" >
            <New component={<SendingCouponList />} />
          </PrivateRoute>} />
          <Route path="/popupcustomer" element={<PrivateRoute redirectTo="/login" >
            <New component={<PopupCustomerList />} />
          </PrivateRoute>} />
          <Route path="/customer" element={<PrivateRoute redirectTo="/login" >
            <New component={<Customer />} />
          </PrivateRoute>} />
          <Route path="/customerdetails/:id" element={<PrivateRoute redirectTo="/login" >
            <New component={<Customerdetails />} />
          </PrivateRoute>} />
          
          <Route path="/venue_details/:id" element={<PrivateRoute redirectTo="/login" >
            <New component={<Venuedetails />} />
          </PrivateRoute>} />
          <Route path="/vendor" element={<PrivateRoute redirectTo="/login" >
            <New component={<Vendor />} />
          </PrivateRoute>} />
          <Route path="/vendor_details/:id" element={<PrivateRoute redirectTo="/login" >
            <New component={<Vendordetails />} />
          </PrivateRoute>} />
          <Route path="/homepagemanagement" element={<PrivateRoute redirectTo="/login" >
            <New component={<HomePageManagement />} />
          </PrivateRoute>} />
          <Route path="/videopromotionmessage" element={<PrivateRoute redirectTo="/login" >
            <New component={<VideoPromoMsg />} />
          </PrivateRoute>} />
          <Route path="/productflowscreens" element={<PrivateRoute redirectTo="/login" >
            <New component={<ProductFlowScreens />} />
          </PrivateRoute>} />
          <Route path="/productdetails/:id" element={<PrivateRoute redirectTo="/login" >
            <New component={<ProductDetails />} />
          </PrivateRoute>} />
          <Route path="/orderlistscreens" element={<PrivateRoute redirectTo="/login" >
            <New component={<OrderListScreen />} />
          </PrivateRoute>} />
          <Route path="/orderdetails/:id" element={<PrivateRoute redirectTo="/login" >
            <New component={<OrderDetails />} />
          </PrivateRoute>} />
          <Route path="/blogpost" element={<PrivateRoute redirectTo="/login" >
            <New component={<BlogPost />} />
          </PrivateRoute>} />
          <Route path="/gift" element={<PrivateRoute redirectTo="/login" >
            <New component={<Gift />} />
          </PrivateRoute>} />
          <Route path="/giftdetail" element={<PrivateRoute redirectTo="/login" >
            <New component={<GiftDetails />} />
          </PrivateRoute>} />
          <Route path="/contact" element={<PrivateRoute redirectTo="/login" >
            <New component={<Contact />} />
          </PrivateRoute>} />
           <Route path="/FAQ" element={<PrivateRoute redirectTo="/login" >
            <New component={<FAQ />} /></PrivateRoute>}/>
          <Route path="/venuetransaction" element={<PrivateRoute redirectTo="/login" >
            <New component={<TransactionManagement />} />
          </PrivateRoute>} />
          <Route path="/vendortransaction" element={<PrivateRoute redirectTo="/login" >
            <New component={<VendorTransaction />} />
          </PrivateRoute>} />
          <Route path="/producttransaction" element={<PrivateRoute redirectTo="/login" >
            <New component={<ProductTransaction />} />
          </PrivateRoute>} />
          <Route path="/transactiondetail/:id" element={<PrivateRoute redirectTo="/login" >
            <New component={<TransactionDetails />} />
          </PrivateRoute>} />
          <Route path="/producttransactiondetail/:id" element={<PrivateRoute redirectTo="/login" >
            <New component={<ProductManagementDetails />} />
          </PrivateRoute>} />
          <Route path="/notification" element={<PrivateRoute redirectTo="/login" >
            <New component={<Notification />} />
          </PrivateRoute>} />
          <Route path="/termsandcondition" element={<PrivateRoute redirectTo="/login" >
            <New component={<TCEdit />} />
          </PrivateRoute>} />
          <Route path="/homepagedetails/:id" element={<PrivateRoute redirectTo="/login" >
            <New component={<HomePagedetails />} />
          </PrivateRoute>} />
          <Route path="/homedetailsvideo/:id" element={<PrivateRoute redirectTo="/login" >
            <New component={<HomeDetailsVideo />} />
          </PrivateRoute>} />
          <Route path="/giftdetails/:id" element={<PrivateRoute redirectTo="/login" >
            <New component={<GiftDetails />} />
          </PrivateRoute>} />
          <Route path="/search" element={<PrivateRoute redirectTo="/login" >
            <New component={<SearchResult />} />
          </PrivateRoute>} />


          <Route path="/login" element={<Login />} />
          <Route path="/recover" element={<Recover />} />
          <Route path="/reset" element={<Reset />} />
       
          <Route path="/popup" element={<Popup />} />
          <Route path="/verify" element={<Otpverify />} />          
          <Route path="*" element={<Navigate to="/" />} />
          <Route path='/filter' element={<PopupFilter />} />
        </Routes>
      </HistoryRouter>

    </div>
  );
}

export default App;
