import React, { useEffect, useState } from 'react';
import '../Login/Login.css'
import Password from './Password';
import { history } from '../../Helper/History/history';
import axios from 'axios';
import { Button } from '@mui/material';
import { client } from '../../Helper/Client/Client';
import baseURL from "../../Helper/Client/baseURL";

function Login() {
    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [message, setMessage] = useState('');
    useEffect(() => {
        localStorage.clear()
    }, [])

    function handleLogin(e) {
        e.preventDefault()
        axios.post(`${baseURL}/api/userlogin`, {
            "email": email,
            "password": password
        }).then((response) => {
            if(response.data.role_id == 'Admin'){
            localStorage.setItem('userhk', email);
            localStorage.setItem('hk-token', response.data.access);
            client.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access ;
            console.log('token',response.data.access)
            history.push('/')
            }
        })
            .catch(() => {
                setMessage("Some Error Occured");
             })
    }

    function handleEmail(e) {
        setemail(e.target.value)
    }
    function handlePassword(e) {
        setpassword(e.target.value)
    }
    return (
        <div className="login">
            <div className="row login-row">
                <div className="bgImage col-md-6">
                    <div className="left-login">
                        <div style={{ textAlign: 'center',marginBottom:'20px' }}>
                            <img height="145px" src={`${process.env.PUBLIC_URL}/Images/PickMeLogo.png`} />
                        </div>
                        <h1>Welcome to <br />PickMe Studio</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit. Vivamus ullamcorper nisl erat, vel convallis elit fermentum pellentesque.
                            Sed mollis velit facilisis facilisis viverra.</p>
                    </div>
                </div>

                <div className="right-login col-md-6">
                    <div className="right-login-details">
                        <form onSubmit={handleLogin} >
                            <h4>登錄您的賬戶 </h4>
                            <div className="form-group">
                                <label>電郵地址</label>
                                <input type="text" name='email' className="form-control login-control" onChange={handleEmail}
                                    placeholder="Regina Cooper" required/>
                            </div>
                            <div className="form-group">
                                <label>密碼</label>
                                <Password handlePassword={handlePassword} required/>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className="form-group form-check">

                                    <label className="form-check-label">
                                        <input type="checkbox" className="form-check-input" />
                                        記住</label>
                                </div>
                                <a href='/recover' style={{ color: '#FE9900', textDecoration: 'none' }} className='text-end'>Forgot Password ?</a>
                            </div>
                            <Button type="submit" style={{width:'100%'}} className='primarybtn'>Login</Button>
                            <div className="message" style={{padding:'10px 0px',color:'red'}}>{message ? <p>{message}</p> : null}</div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    );
}
export default Login;