
import React, { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";

import { Button, Pagination } from "@mui/material";

import { history } from "../../Helper/History/history";
import { client } from "../../Helper/Client/Client";
import PopupDelete from "../Popup/PopupDelete";
import PopupDeleteAll from "../Popup/PopupDeleteAll";
import AddProduct from "../Popup/AddProduct";
import Addshipping from "../Popup/AddShipping";
import PopupProductDetail from "../Popup/PopupProductDetail";

import ProductSearch from "../../Components/SearchBar/ProductSearch";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Papa from "papaparse";

function ProductFlowScreens() {
  const [productList, setproductList] = useState([]);
  const [searchvalue, setsearchvalue] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [page, setpage] = useState(1);
  const [listlength, setlistlength] = useState(0);

  useEffect(() => {
    client.get(`/api/product_name_code?search=${searchvalue}`).then((response) => {
      if (response.data.results) {
        setproductList(response.data.results);
        setpage(1)
        setpageCount(
          Math.ceil(response.data.count / response.data.results.length)
        );
      } else {
        setproductList([]);
      }
    }).catch((err)=>{
      if ( err?.response?.status === 401||403) {
        history.push('/login')
      }
  })
  }, [searchvalue])
// *****
  function CustomPagination() {
    return (
    
      <Pagination
        className="pagination"
        color="warning"
        count={pageCount}
        page={page}
        onChange={(event, value) => {
          searchvalue === ""
          ?
           client
              .get(`/api/product_list?page=${value}`)
              .then((response) => {
                setproductList(response.data.results);
              }) 
              : client
              .get(`/api/product_name_code?page=${value}&search=${searchvalue}`)
              .then((response) => {
                setproductList(response.data.results);
              });
          setpage(value);
        }}
        showFirstButton
        showLastButton
      />
    );
  }

  useEffect(() => {
    if (productList?.length !== undefined) {
      setlistlength(productList.length);
    }
  }, [productList]);
// console.log("P",productList);
    // tab property
    const [value, setValue] = React.useState("1");
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

      // table view(edit/delete)
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [editbar, setEditbar] = useState("");

  const showEditbar = (event, id) => {
    setAnchorEl(event.currentTarget);
    // console.log(id);
    setEditbar(id);
  };
  const handleClose = () => {
    setEditbar(null);
  };
  function handledownload(data) {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    saveAs(blob, 'Pick Me Studio.xlsx');
  
    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }
  }
  const open = Boolean(anchorEl2);
  const handleClickaction = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseaction = () => {
    setAnchorEl2(null);
  };

  const handlebulkDelete = () => {
    setAnchorEl2(null);
    client
      .delete(`/api/product_bulk_delete`, {
        data: { product_id: selectionModel },
      })
      .then(() => {
        setAnchorEl2(null);
        client.get("/api/product_list").then((response) => {
          if(  response.data.results){
            setpage(1);
          setproductList(response.data.results);
        setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          )
          }
          else{
            setpage(0);
            setpageCount(0)
            setproductList([])
          }
        });
      });
  };

  const handleEdit = (i) => {
    history.push(`/productdetails/${i}`);
  };

  function handleDelete(i) {
    client.delete(`/api/product_delete/${i}`).then(() => {
      handleClose();
      client.get("/api/product_list").then((response) => {
        if(  response.data.results){
          setpage(1);
        setproductList(response.data.results);
      setpageCount(
          Math.ceil(response.data.count / response.data.results.length)
        )
        }
        else{
          setpage(0);
          setpageCount(0)
          setproductList([])
        }
      });
    });
  }

  function getProductDetails(params) {
    const productname = params.row.product_name;
    const productcode = params.row.product_code_number;
    const productprice = params.row.product_price;
    const productdate = params.row.buildup_date;
    const productimage = params.row.product_image;
    // console.log('Pimage',productimage)
    return productname + "+" + productcode + "+" + productprice + "+" + productdate + "+" + productimage;
  }

  const columns = [
    {
      field: 'product_name',
      headerName: "PRODUCT NAME",

      headerAlign: "center",
      width: 250,
      valueGetter: getProductDetails,
      renderCell: (params) => (
        <>
          {params.value.split("+")[4] !== "undefined" ? (
            <img
              src={params.value.split("+")[4]}
              height="30px"
              width="30px"
              alt=""
            />
          ) : 
    
          null
          }
          <div style={{ marginLeft: "10px" }}>
            <b style={{ color: "rgb(53, 53, 53)" }}>
              {params.value.split("+")[0]} 
            
            </b>
       
          </div>
        </>
      ),
    },
    {
      field: 'product_code_number',
      headerName: 'CODE No',
      align: "center",
      headerAlign: "center",
      width: 150,

    },
    {
      field: 'product_price',
      headerName: "PRICE",
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: 'buildup_date',
      headerName: "DATE",
      align: "center",
      headerAlign: "center",
      type: "date",
      width: 150,
      renderCell: (params) =>
        params.value.split("T")[0].split("-")[2] +
        "." +
        params.value.split("T")[0].split("-")[1] +
        "." +
        params.value.split("T")[0].split("-")[0],
    },
    {
      field: "status",
      headerName: "STATUS",
      headerAlign: "center",
      align: "center",
      width: 120,
      valueGetter: (params) => (params.row.status ? "Active" : "Blocked"),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            width: "100px",
            padding: "7px",
            backgroundColor: params.value === "Active" ? "#e8f9ef" : "#ffe7e6",
            color: params.value === "Active" ? "#20A144" : "#EB5A52",
          }}
        >
          {params.value}{" "}
        </span>
      ),
    },
    {
      field: "id",
      type: "actions",
      width: 50,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <IconButton onClick={(event) => showEditbar(event, params.value)}>
            <img src="../Images/dots.png" alt="action" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={editbar === params.value ? true : false}
            onClose={handleClose}
          >
        
            <PopupProductDetail detailid={params.value} handleClose={handleClose} setpage={setpage} setpageCount={setpageCount} setproductList={setproductList} />
       
            <MenuItem onClick={() => handleEdit(params.value)}>
              Edit Item
            </MenuItem>
            <PopupDelete onClick={handleClose}
            delete={() => handleDelete(params.value)} 
            />
          </Menu>
        </>
      ),
    },
  ];
  function CustomExportButton({ onClick }) {
    return (
      <button onClick={onClick}>Download Data</button>

      
    );
  }
  function CustomToolbar() {
    const [csv, setCsv] = useState(false);
    // const handleExport = (data) => {
    //   const csvData = Papa.unparse(data, {
    //     quotes: true,
    //     delimiter: ",",
    //     encoding: "utf-8", 
    //   });

    //   const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvData], {
    //     type: "text/csv;charset=utf-8",
    //   });
    //   const url = window.URL.createObjectURL(blob);
    //   const a = document.createElement("a");
    //   a.href = url;
    //   a.download = "Pickmestudio.csv";
    //   document.body.appendChild(a);
    //   a.click();
    //   document.body.removeChild(a);
    //   window.URL.revokeObjectURL(url);
    // };
    const handleExportCSV = () => {
      const csvData = productList.map((product) => ({
        'PRODUCT NAME': `${product.product_name} (${product.product_code_number})`,
        'CODE No': product.product_code_number,
        'PRICE': product.product_price,
        'DATE': new Date(product.buildup_date).toLocaleDateString(),
        'STATUS': product.status ? 'Active' : 'Blocked',
      }));
  
      const csv = Papa.unparse(csvData, {
        quotes: true,
        delimiter: ',',
        encoding: 'utf-8',
      });
  
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], {
        type: 'text/csv;charset=utf-8',
      });
      
      saveAs(blob, 'Pick Me Studio.csv');
    };
  

    const handleButtonClick = (data) => {
      // Replace with your actual data
      
  
      handledownload(data);
    };

    const handleCustomCsvDownload = () => {
      // Add your custom CSV download logic here
      console.log("Custom CSV Download");
    };
  
    
  
    const CustomExportButtons = () => (
      <>
        <CustomExportButton onClick={handleCustomCsvDownload} />

      </>
    );
    return (
      <div style={{ position: "absolute", right: "136px",

      top: "-210px" }}>
        <GridToolbarContainer>
        <div style={{position:"relative"}} 
          onMouseEnter={()=>{
            console.log("its onMouseEnter");
            setCsv(true);
          }}
          onMouseLeave={()=>{
            console.log("its onMouseLeave");
            setCsv(false);
          }}
          >
          <button onClick={() => handleExportCSV()}
          style={{
            position: "absolute",
            inset: "0px 0px auto auto",
            margin: "0px",
            transform: "translate3d(4px, 47.5px, 0px)",
            width: '170px',
            height: "37px",
            // visibility: "hidden",
            zIndex:1500,
            opacity:0,
            display:csv?"":"none",
            // cursor:"default"
         
          }}
          >Download CSV</button> 
          {/* <button onClick={()=>{window.print();}}>Print</button> */}
          {/* <PrintButton targetId="componentToPrint" /> */}
        
          <GridToolbarExport
        onItemClick={() => handleExportCSV()}
        endIcon={<img src="../Images/Arrow-Down.svg" />}
        sx={{
          color: "black",
          bgcolor: "#F5F5F5",
          textTransform: "capitalize",
          p: 1.2,
          pr: 3,
        }}
        options={{
          bookSST: true,
          bookType: "xlsx",
          mimeType:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
          
        }}
      />
      </div>
          {/* <GridToolbarExport
            sx={{
              color: "black",
              bgcolor: "#F5F5F5",
              textTransform: "capitalize",
              p: 1.2,
              pr: 3,
            }}
          /> */}
        </GridToolbarContainer>
      </div>
    );
  }

  

  

  
  
  
  

  function getTitleDesc(params) {
    const title = params.row.title
    const desc = params.row.description
    const image = params.row.image_list[0]?.image_url

    return title + '+' + desc + '+' + image;
  }

  


  

  
  

  return (
    <article>
      <div className="buttondiv">
       

        <Addshipping setproductList={setproductList}
          setpage={setpage}
          setpageCount={setpageCount}/>

        <AddProduct setproductList={setproductList}
          setpage={setpage}
          setpageCount={setpageCount}/>
    
      </div>
      <h4>Product</h4>
      <TabContext value={value}>
        <TabList onChange={handleChange}>
          <Tab label="All" value="1" />
         
        </TabList>
        <TabPanel value="1">
          <div
            style={{
              width: "100%",
              paddingTop: "24px",
              borderRadius: "8px",
              border: "1px solid #E8E8E8",
            }}
          >
            <div className="mid-searchbar">
              <ProductSearch searchvalue={searchvalue}
                setsearchvalue={setsearchvalue} setproductList={setproductList} />
              
              <Button
                onClick={handleClickaction}
                className="tertiarybtn"
                endIcon={
                  <img src="../Images/Arrow-Down.svg" alt="arrow down" />
                }
              >
                Actions
              </Button>
              <Menu
                anchorEl={anchorEl2}
                open={open}
                onClose={handleCloseaction} 
              >
                <PopupDeleteAll 
                delete={handlebulkDelete} 
                />
               
              </Menu>
            </div>
            <div style={{ height: "650px" }}>
              <DataGrid
                sx={{ mt: 2 }}
                rows={productList}
                columns={columns}
                pageSize={listlength}
                rowsPerPageOptions={[listlength]}
                getRowId={(row) => row.id}
                checkboxSelection
                pagination
                // paginationMode="server"
                keepNonExistentRowsSelected
                components={{
                  Pagination: CustomPagination,
                  Toolbar: CustomToolbar,
                }}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
              />
            </div>
          </div>
        </TabPanel>
      
      </TabContext>
    </article>
  );
}

export default ProductFlowScreens;
