import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React, { useEffect, useRef, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Button, styled, Pagination } from "@mui/material";
import DropdownSelect from "../../Components/DropdownSelect/DropdownSelect3";
import IconButton from "@mui/material/IconButton";
import useAxios from "../../Helper/Client/useAxios";
import { useParams } from "react-router-dom";
import { client } from "../../Helper/Client/Client";
import AlertPopup from "../../Components/AlertPopup/AlertPopup";
import { history } from "../../Helper/History/history";

function Categorydetails() {
  let { id } = useParams();

  const [categoryDetails, setcategoryDetails] = useState([]);

  const initialData = {
    categoryname: "",
    status: "",
  };
  const [inputs, setinputs] = useState(initialData);
  const [status, setstatus] = useState(0);
  const [validationError, setvalidationError] = useState(false);
  const [tags, setTags] = React.useState([]);
  const [list, updateList] = useState();

  useEffect(() => {
    setinputs((inputs) => ({
      ...inputs,
      categoryname: categoryDetails.type,
    }));
    // setgetimage(categoryDetails.icon);
    setstatus(categoryDetails.status ? 1 : 0);
    setTags(categoryDetails.venue_theme);
    updateList(categoryDetails.venue_theme);
  }, [categoryDetails]);

  // console.log("Tags", tags);
  // console.log("TAgs List", list);

  function handleChanged(e) {
    const { name, value } = e.target;
    setinputs((inputs) => ({ ...inputs, [name]: value }));
  }

  const Input = styled("input")({
    display: "none",
  });

  const [response, isError, isLoading] = useAxios({
    url: `/api/venue_type_details/${id}`,
    method: "get",
    // signal: controller.signal
  });

  useEffect(() => {
    if (response && response.data) {
      setcategoryDetails(response.data.results);
    }

    // return () => {
    // controller.abort()
    // };
  }, [response]);

  // console.log('profile',inputs.profile && inputs.profile.replace('/media',''))

  // console.log("categoryDetails", categoryDetails);
  // console.log("Status", status);
  // console.log("Category Theme", themes);

  function handleCancel() {
    setinputs((inputs) => ({
      ...inputs,
      categoryname: categoryDetails.type,
    }));
    setstatus(categoryDetails.status ? 1 : 0);
    setTags(categoryDetails.venue_theme);
    updateList(categoryDetails.venue_theme);
    setThemelist(list);
  }

  function handleSubmit() {
    if (inputs.categoryname === null && filelist.length !== 0) {
      handleClose();
      setvalidationError(true);
      return;
    } else {
      setvalidationError(false);
    }
    let form_data = new FormData();
    form_data.append("type", inputs.categoryname);
    form_data.append("status", status);
    // (getimage || filelist[0]) &&
    //   form_data.append(
    //     "icon",
    //     filelist[0] ? filelist[0] : getimage?.replace(`${baseURL}/media`, "")
    //   );
    filelist[0] && form_data.append("icon", filelist[0]);
    form_data.append(
      "venue_theme",
      JSON.stringify(
        themelist.map((item) => {
          return item.theme;
        })
      )
    );
    client
      .put(`api/venue_type_update/${id}`, form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(() => {
        setalertOptions({
          alertType: "success",
          alertOpen: true,
          alertContent: "Submitted Successfully!",
        });
        setfilelist([]);

        client
          .get(`/api/venue_type_details/${id}`)
          .then((response) => setcategoryDetails(response.data.results));
      })
      .catch(() => {
        setalertOptions({
          alertType: "error",
          alertOpen: true,
          alertContent: "Submission Failed!",
        });
      });
  }

  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });

  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };

  // tab property
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // table view(edit/delete)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);

  const [editbar, setEditbar] = useState("");
  const [editbar2, setEditbar2] = useState("");
  const showEditbar = (event, id) => {
    setAnchorEl(event.currentTarget);
    // console.log(id);
    setEditbar(id);
  };
  const showEditbar2 = (event, id) => {
    setAnchorEl3(event.currentTarget);
    // console.log(id);
    setEditbar2(id);
  };

  const handleClose = () => {
    setEditbar(null);
  };

  // top actions
  // const [action, setAction] = React.useState(null);
  const open = Boolean(anchorEl2);
  const handleClickaction = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseaction = () => {
    setAnchorEl2(null);
    // setEditbar(null);
  };
  const handleCloseaction2 = () => {
    setAnchorEl3(null);
    setEditbar2("");
  };

  const [filelist, setfilelist] = useState([]);

  const fileChange = (event) => {
    setfilelist(filelist.concat(event.target.files[0]));
    // console.log(event.target.files[0].name)
  };
  // console.log("filelist", filelist);
  const componentRef = useRef([]);
  // const handlePrint =
  const componentRef2 = useRef();

  //
  const [val, setVal] = useState("");
  const [themelist, setThemelist] = useState();

  useEffect(() => {
    setThemelist(list);

    // return () => {
    //   second
    // }
  }, [list]);

  const handleAddTheme = (obj) => {
    if(val!==""){
    setThemelist((current) => [...current, obj]);
    setVal("");
    setvalidationError(false)
    }else{
    setvalidationError(true)
      setalertOptions({
        alertType: "error",
        alertOpen: true,
        alertContent: "Submission Failed.!",
      });
    }
  };

  const handleRemoveTheme = (e) => {
    const name = e.target.getAttribute("name");
    setThemelist(themelist.filter((item) => item.theme !== name));
  };

  // console.log("themelist", themelist);
  //
  return (
    <article>
      <h4>Category - {categoryDetails.type}</h4>

      <TabContext value={value} style={{ padding: "5px" }}>
        <TabList onChange={handleChange} aria-label="Customer details">
          <Tab label="Details" value="1" />
        </TabList>

        <TabPanel value="1">
          <Accordion
            ref={componentRef2}
            sx={{ border: "1px solid rgb(232, 232, 232)", boxShadow: "none" }}
          >
            <AccordionSummary
              expandIcon={<img src="../Images/Arrow-Down.svg" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h5>Information</h5>
            </AccordionSummary>

            <AccordionDetails>
              <div
                className="venue-tab-details"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "15px",
                }}
              >
                <div className="center">
                  {categoryDetails?.icon ? (
                    <img
                      src={categoryDetails?.icon}
                      width="250px"
                      height="250px"
                    />
                  ) : null}
                </div>
                <label className="mt20">
                  Category Name
                  <input
                    className={
                      validationError && inputs.categoryname === ""
                        ? "validationerror mt10"
                        : "mt10"
                    }
                    style={{ width: "100%" }}
                    type="text"
                    required
                    name="categoryname"
                    value={inputs.categoryname}
                    onChange={handleChanged}
                  />
                </label>
                <label className="mt20">
                  Status
                  <DropdownSelect
                    value={status}
                    setdropDownValue={setstatus}
                    values={[
                      { name: "Active", value: 1 },
                      { name: "Blocked", value: 0 },
                    ]}
                  />
                </label>
                <div>
                  <input
                   className={
                    validationError && val === ""
                      ? "validationerror mt10"
                      : "mt10"
                  }
                    name="newtheme"
                    placeholder="Add New Theme"
                    onChange={(e) => setVal(e.target.value)}
                    value={val}
                    // className="mt10"
                    style={{ width: "20%", marginRight: "2%" }}
                    type="text"
                    required
                  />
                  <Button
                    className="primarybtn"
                    onClick={() =>
                      handleAddTheme({
                        theme: val,
                      })
                    }
                  >
                    Add Theme
                  </Button>
                  <label className="mt20" style={{ width: "100%" }}>
                    Category Theme
                  </label>
                  {themelist && (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto auto auto",
                        gap: 5,
                        marginTop: 20,
                      }}
                    >
                      {themelist.map((item) => {
                        return (
                          <>
                            <p key={item.id} className="button">
                              {item.theme}
                              <span
                                className="close"
                                name={item.theme}
                                onClick={handleRemoveTheme}
                              >
                                &times;
                              </span>
                            </p>
                          </>
                        );
                      })}
                    </div>
                  )}
                </div>
                <br />
              </div>

              <div
                className="dcenter"
                style={{
                  width: "25%",
                }}
              >
                <div
                  className="print_btn"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f5f5f5",
                    height: "130px",
                    borderRadius: "8px",
                    paddingTop: "25px",
                  }}
                >
                  {!filelist.length > 0 ? (
                    <label htmlFor="icon-button-file">
                      <Input
                        id="icon-button-file"
                        type="file"
                        onChange={fileChange}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        sx={{ borderRadius: 0 }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <img src="../Images/image.svg" height="18px" />
                          <p style={{ padding: "10px", fontSize: "12px" }}>
                            Upload
                          </p>
                        </div>
                      </IconButton>
                    </label>
                  ) : (
                    <>
                      <img src="../Images/image.svg" height="18px" />
                      <p style={{ padding: "10px", fontSize: "12px" }}>
                        {filelist[0].name}
                      </p>
                    </>
                  )}

                  {/* <img src="../Images/image.svg" />
    <input type='file'/>
    <p style={{ padding: '20px', fontSize: '12px' }}>上傳檔案拖曳至此(upload)</p> */}
                </div>
              </div>
              <div
                className="print_btn"
                style={{ float: "right", paddingBottom: "12px" }}
              >
                <Button
                  className="secondarybtn"
                  style={{ marginRight: "20px" }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="primarybtn"
                  style={{ marginRight: "10px" }}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </div>
            </AccordionDetails>
          </Accordion>
        </TabPanel>
      </TabContext>
      <AlertPopup alertOptions={alertOptions} handleClose={handleClosep} />
    </article>
  );
}
export default Categorydetails;
