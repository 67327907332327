import React, { useEffect, useState } from "react";
import {
  Box,

  Dialog,

  DialogTitle,
  MenuItem,

} from "@mui/material";

import "./Popup.css";

import DropdownProduct from "../../Components/DropdownSelect/DropdownProduct";
// import DropdownSelect from "../../Components/DropdownSelect/DropdownSelect";

import useAxios from "../../Helper/Client/useAxios";

const VideoPromoMessageDetails = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");
  const [status, setstatus] = useState('')
  const [videoPromotionDetails, setvideoPromotionDetails] = useState(null)

  
  const [response, isError, isLoading] = useAxios({
    url: `/api/video_promo_message_details/${props.id}`,
    method: "get",
    
  });


    useEffect(() => {
      if (response && response.data.results) {
        setvideoPromotionDetails(response.data.results)
      }

    }, [response]);
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    window.location.reload(false)
  };



  return (
    <div>
      <MenuItem onClick={handleClickOpen}>DetailView</MenuItem>
   
      <Dialog fullWidth open={open} onClose={handleClose} className="dialog">
        <Box>
            <img
                onClick={handleClose}
                src="/Images/close.png"
                height="32px"
                style={{
                position: "absolute",
                right: "10px",
                top: "8px",
                }}
            />
           <DialogTitle>Details</DialogTitle>
           {videoPromotionDetails &&    
           <div style={{padding:'20px'}}>
                <p style={{borderBottom: '1px solid #2118182b',paddingBottom: '15px'}}> Vendor name  : <span className='span'>{videoPromotionDetails.vendor_name}</span></p>
                <p style={{borderBottom: '1px solid #2118182b',paddingBottom: '15px'}}> Start Date  : <span className='span'>{videoPromotionDetails.start_date.split('T')[0].split('-')[2]+'.'+videoPromotionDetails.start_date.split('T')[0].split('-')[1]+'.'+videoPromotionDetails.start_date.split('T')[0].split('-')[0]
}</span></p>
                <p style={{borderBottom: '1px solid #2118182b',paddingBottom: '15px'}}> End Date  : <span className='span'>{videoPromotionDetails.end_date.split('T')[0].split('-')[2]+'.'+videoPromotionDetails.end_date.split('T')[0].split('-')[1]+'.'+videoPromotionDetails.end_date.split('T')[0].split('-')[0]
}</span></p>
                <p style={{borderBottom: '1px solid #2118182b',paddingBottom: '15px'}}> Video Link : <span className='span'>{videoPromotionDetails.video_link}</span></p>
                <p style={{borderBottom: '1px solid #2118182b',paddingBottom: '15px'}}> Create Date : <span className='span'>{videoPromotionDetails.created_date.split('T')[0].split('-')[2]+'.'+videoPromotionDetails.created_date.split('T')[0].split('-')[1]+'.'+videoPromotionDetails.created_date.split('T')[0].split('-')[0]
}</span></p>
                <p>Status</p><div style={{width:'200px'}}>
                <DropdownProduct  setvideoPromotionDetails={setvideoPromotionDetails} value={videoPromotionDetails.status} setdropDownValue={setstatus} id={props.id} values={[{name:videoPromotionDetails.status?'Finished':'Unfinished',value:videoPromotionDetails.status}]}/>
                
                </div>
            </div>} 
        </Box>
      </Dialog>
    </div>
  );
};

export default VideoPromoMessageDetails;
