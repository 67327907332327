import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Tab,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import './Popup.css'
import TextEditor from '../../Components/TextEditor/TextEditor';
import { client } from '../../Helper/Client/Client';
import AlertPopup from '../../Components/AlertPopup/AlertPopup';
import useAxios from '../../Helper/Client/useAxios';
import DropdownSelect from "../../Components/DropdownSelect/DropdownSelect";
import WysiwygEditor from "../../Components/TextEditor/WysiwygEditor";

const PopupCouponEdit = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");
  const [status, setstatus] = useState('')
  const [texteditor, settexteditor] = useState('')
  const [texteditor2, settexteditor2] = useState('')
  const [texteditor3, settexteditor3] = useState('')
  const [inputs, setinputs] = useState({couponname:'',couponvalue:'',ExchangeTitle:'',TermsTitle:''})
  const [validationError, setvalidationError] = useState(false)

  const [response, isError, isLoading] = useAxios({
    url: `/api/coupon_details/${props.detailid}`,
    method: "get",
  
  });
  

  useEffect(() => {
    if (response && response.data.results) {
      setinputs({couponname:response.data.results.name,couponvalue:response.data.results.value,ExchangeTitle:response.data.results.em_title,TermsTitle:response.data.results.tc_title,pointsrequired:response.data.results.points_required,expiry:response.data.results.expiry   })
      setstatus(response.data.results.active)
      settexteditor(response.data.results.description)
      settexteditor2(response.data.results.em_description)
      settexteditor3(response.data.results.tc_description)

    }

  }, [response]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.handleClose()
    setOpen(false);
  };

  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };
  
  function handleChanged(e) {
    const { name, value } = e.target;
    setinputs((inputs) => ({ ...inputs, [name]: value }));
  }

  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });

  function handleSubmit(){
    if (inputs.couponname === ''||inputs.ExchangeTitle===''||inputs.TermsTitle===''||status === '') {
      setvalidationError(true)
      return
    }
    else {
      setvalidationError(false)
    } 
    
    let form_data = new FormData();
    form_data.append("name", inputs.couponname);
    form_data.append("value", inputs.couponvalue);
    form_data.append("description", texteditor);
    form_data.append("points_required", inputs.pointsrequired);
    form_data.append("expiry", inputs.expiry); 
    form_data.append("active", status ? 1 : 0);   
    form_data.append("em_title", inputs.ExchangeTitle);
    form_data.append("em_description", texteditor2);
    form_data.append("tc_title", inputs.TermsTitle);
    form_data.append("tc_description", texteditor3); 
   
    handleClose()
    client.put(`/api/coupon_update/${props.detailid}`,form_data).then(()=>{
      setalertOptions({
        alertType: "success",
        alertOpen: true,
        alertContent: "Submitted Successfully!",
      })
      setinputs({couponname:'',couponvalue:'',ExchangeTitle:'',TermsTitle:''})
      settexteditor('')
      settexteditor2('')
      settexteditor3('')
      setstatus('')
      client.get(`/api/coupons_list?page=${props.page}`).then((response) => {
        props.setcouponList(response.data.results);
      })
    }).catch(()=>{
      setalertOptions({
        alertType: "error",
        alertOpen: true,
        alertContent: "Submission Failed!",
      })
    })
  }
  return (
    <div>
      <MenuItem  onClick={handleClickOpen}>
        Edit Item
      </MenuItem>
      <Dialog fullWidth open={open} onClose={handleClose} className='dialog'>
        <Box >
          <TabContext value={value} >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="INFORMATION" value="1" />
                <Tab label="EXCHANGE METHOD" value="2" />
                <Tab label="TERMS AND CONDITIONS" value="3" />
                <img onClick={handleClose} src='/Images/close.png' height='32px' style={{
                  position: 'absolute',
                  right: '10px',
                  top: '8px'
                }} />
              </TabList>
            </Box>
            <TabPanel sx={{ p: 0 }} value="1">
              <DialogTitle >Information</DialogTitle>
              <DialogContent style={{ display: 'flex', flexDirection: 'column' }} >
                <label className="mt20">Coupon type </label>
                  <input
                    className={(validationError && inputs.couponname === '') ? "validationerror" : ""}
                    type="text"
                    name="couponname"
                    onChange={handleChanged}
                    value={inputs.couponname}
                   
                    required
                  />
                  <label className="mt20">Coupon value </label>
                  <input
                    className={(validationError && inputs.couponvalue === '') ? "validationerror" : ""}
                    type="text"
                    name="couponvalue"
                    onChange={handleChanged}
                    value={inputs.couponvalue}
                   
                    required
                  />
               <div
                  style={{ display: "grid", gridTemplateColumns: "auto auto" }}
                >
                <label className="mt20" style={{ marginRight: "15px" }}>Points Required
                  <input
                    className={(validationError && inputs.pointsrequired === '') ? "validationerror" : ""}
                    type="number"
                    name="pointsrequired"
                    onChange={handleChanged}
                    value={inputs.pointsrequired}
                   
                    required
                  />
                </label>
                <label className="mt20">Expiry
                  <input
                    className={(validationError && inputs.expiry === '') ? "validationerror" : ""}
                    type="number"
                    name="expiry"
                    onChange={handleChanged}
                    value={inputs.expiry}
                   
                    required
                  />
                </label>
                <label className="mt20">
            Status
            <DropdownSelect
              validationError={validationError} value={status} setdropDownValue={setstatus} values={[{ name: 'Active', value: true }, { name: 'Blocked', value: false }]} />
          </label>
                </div>
                <label className="mt20">Coupon Description </label>
               
                {texteditor !== null && (
                    <WysiwygEditor
                      settexteditor={settexteditor}
                      texteditor={texteditor}
                    />
                  )}
              </DialogContent>
              <DialogActions style={{ textAlign: "end",padding:'20px' }}>
                  <Button
                  className="primarybtn"
                    onClick={() => {
                      if (inputs.couponname === '' ||
                      inputs.couponvalue === ''){
                        setvalidationError(true)
                        return
                      }
                      else{
                        setvalidationError(false)
                      }
                      setValue("2");
                    }}
                  >
                    Next Step
                  </Button>
                </DialogActions>
            </TabPanel>
            <TabPanel sx={{ p: 0 }} value="2">
              <DialogTitle >Exchange method</DialogTitle>
              <DialogContent style={{ display: 'flex', flexDirection: 'column' }} >
                <label className="mt20">Title
                  <input
                   className={(validationError && inputs.ExchangeTitle === '') ? "validationerror" : ""}
                   value={inputs.ExchangeTitle}
                    type="text"
                    name="ExchangeTitle"
                     onChange={handleChanged}
                   
                    required
                  />
                </label>
                <label className="mt20">Description
                
                {texteditor2 !== null && (
                    <WysiwygEditor
                    settexteditor={settexteditor2} texteditor={texteditor2}
                    />
                  )}
                </label>
              </DialogContent>
              <DialogActions
                  style={{ display: "flex", justifyContent: "space-between",padding:"20px" }}
                >
                  <Button
                   className="secondarybtn"
                    onClick={() => {
                      setValue("1");
                    }}
                  >
                    Previous
                  </Button>
                  <Button
                  className="primarybtn"
                    onClick={() => {
                      if (inputs.ExchangeTitle===''){
                        setvalidationError(true)
                        return
                      }
                      else{
                        setvalidationError(false)
                      }
                      setValue("3");
                    }}
                  >
                    Next Step
                  </Button>
                </DialogActions>
            </TabPanel>
            <TabPanel sx={{ p: 0 }} value="3">
              <DialogTitle >Terms and Conditions</DialogTitle>
              <DialogContent style={{ display: 'flex', flexDirection: 'column' }} >
                <label className="mt20">Title
                  <input
                    className={(validationError && inputs.TermsTitle === '') ? "validationerror" : ""}
                    value={inputs.TermsTitle}
                    type="text"
                    name="TermsTitle"
                     onChange={handleChanged}
                   
                    required
                  />
                </label>
                <label className="mt20">Description
             
                {texteditor3 !== null && (
                    <WysiwygEditor
                    settexteditor={settexteditor3} texteditor={texteditor3}
                    />
                  )}
               </label>
              </DialogContent>
              <DialogActions style={{display:'flex',justifyContent:'space-between',
                  marginBlockStart: "20px",
                  padding: "20px",}}>

                <Button
                className="secondarybtn"
                  onClick={() => {
                    setValue("2");
                  }}
                >
                  Previous
                </Button>
                <Button
                className="primarybtn"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
      </DialogActions>
            </TabPanel>
          </TabContext>
        </Box>
      </Dialog>
      <AlertPopup alertOptions={alertOptions} handleClose={handleClosep} />
    </div>
  )
}

export default PopupCouponEdit