import React, { useEffect, useState } from 'react'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid } from '@mui/x-data-grid';
import IconButton from "@mui/material/IconButton";
import PopupFAQ from '../Popup/PopupFAQ';
import PopupFAQedit from '../Popup/PopupFAQedit';
import { Button, Pagination } from '@mui/material';
import SearchBar from '../../Components/SearchBar/SearchBar';
import useAxios from '../../Helper/Client/useAxios';
import { client } from '../../Helper/Client/Client';
import PopupDelete from '../Popup/PopupDelete';
import PopupDeleteAll from '../Popup/PopupDeleteAll';



function FAQ() {
  const [customerList, setcustomerList] = useState([])
  const [selectionModel, setSelectionModel] = useState([]);
  const [pageCount, setpageCount] = useState(0)
  const [page, setpage] = useState(1)
  const [listlength, setlistlength] = useState(0)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [editbar, setEditbar] = useState('');
  const [value, setValue] = React.useState('1');
  const [searchvalue, setsearchvalue] = useState('')

  
  useEffect(() => {
    if (customerList?.length !== undefined) {
      setlistlength(customerList.length)
    }
  }, [customerList])

  useEffect(() => {
    client.get(`/api/faq_search_by_title?search=${searchvalue}`).then((response) => {
      if(response.data.results){
      setcustomerList(response.data.results) 
      setpage(1)
        setpageCount(Math.ceil(response.data.count / response.data.results.length))}
       else{setcustomerList([])}
    })
  }, [searchvalue])


  function CustomPagination() {

    return (
      <Pagination
        className='pagination'
        color='warning'
        count={pageCount}
        page={page}
        onChange={(event, value) => {
          client.get(`/api/faq_pagination?page=${value}`).then((response) => {
            setcustomerList(response.data.results);
          })
          setpage(value)
        }}
        showFirstButton showLastButton

      />
    );
  }


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const showEditbar = (event, id) => {
    setAnchorEl(event.currentTarget);
    console.log(id);
    setEditbar(id);
  };
  const handleClose = () => {
    setEditbar(null);
  };

  // top actions
  
  const open = Boolean(anchorEl2);
  const handleClickaction = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseaction = () => {
    setAnchorEl2(null);
  };

  function handleDelete(i) {
    client.delete(`/api/faq_delete/${i}`).then(() => {
      handleClose()
      client.get(`/api/faq_pagination`).then((response) => {
        if(response.data.results){
          setpage(1);
          setcustomerList(response.data.results);
      setpageCount(
          Math.ceil(response.data.count / response.data.results.length)
        )
        }
        else{
          setpage(0);
          setpageCount(0)
          setcustomerList([])
        }

      })
    })
  }

  const handlebulkDelete = () => {
    client.delete(`/api/faq_bulk_delete`, { data: { "faq_id": selectionModel } }).then(() => {
      setAnchorEl2(null);
      client.get(`/api/faq_pagination`).then((response) => {
        if(response.data.results){
          setpage(1);
          setcustomerList(response.data.results);
      setpageCount(
          Math.ceil(response.data.count / response.data.results.length)
        )
        }
        else{
          setpage(0);
          setpageCount(0)
          setcustomerList([])
        }
      })
    })
  }


  const columns = [
    {
      field: 'title',
      headerName: 'TITLE',
      width: 230
    },
    {
      field: 'date',
      headerName: 'UPLOAD DATE',
      align: 'center', headerAlign: 'center', type: 'date',
      width: 200,
      renderCell: (params) => (params.value.split('T')[0].split('-')[2] + '.' + params.value.split('T')[0].split('-')[1] + '.' + params.value.split('T')[0].split('-')[0])

    },
    {
      field: 'status',
      headerName: 'STATUS', headerAlign: 'center', align: 'center',
      width: 200,
      renderCell: (params) => (
        <span style={{ textAlign: 'center', width: '100px', padding: '7px', backgroundColor: params.value === "Active" ? "#e8f9ef" : "#ffe7e6", color: params.value === 'Active' ? '#20A144' : '#EB5A52' }}>{params.value} </span>)

    },
    {
      field: "id",
      type: 'actions',
      width: 70,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          <IconButton onClick={(event) => showEditbar(event, params.value)}>
            <img src="../Images/dots.png" alt='action' />
          </IconButton>
          <Menu anchorEl={anchorEl}
            open={editbar === params.value ? true : false}
            onClose={handleClose}>
            <MenuItem style={{ padding: '0px' }} ><PopupFAQedit setcustomerList={setcustomerList} page={page} detailid={params.value} handleClose={handleClose} /></MenuItem>
          
            <PopupDelete delete={() => handleDelete(params.value)} />
          </Menu>
        </>
      ),
    },
  ];
  function CustomToolbar() {
    return (
      <div style={{ position: 'absolute', right: '30px', top: '-210px' }}>
        
      </div>
    );
  }


  return (
    <article>
      <div className="buttondiv">
       
        <PopupFAQ setcustomerList={setcustomerList} setpage={setpage} setpageCount={setpageCount} />
      </div>
      <h4>FAQ</h4>
      <TabContext value={value}>
        <TabList onChange={handleChange}>
          <Tab label="All" value="1" />
        </TabList>
        <TabPanel value="1">
          <div style={{ width: '100%', paddingTop: '24px', borderRadius: '8px', border: '1px solid #E8E8E8' }}>
            <div className="mid-searchbar">
              <SearchBar searchvalue={searchvalue} setsearchvalue={setsearchvalue}  />
              <Button onClick={handleClickaction}
                className='tertiarybtn' endIcon={<img src="../Images/Arrow-Down.svg" alt='arrow down' />}>Actions</Button>
              <Menu anchorEl={anchorEl2}
                open={open}
                onClose={handleCloseaction}>
                <PopupDeleteAll delete={handlebulkDelete} />
              </Menu>
            </div>
            <div style={{ height: '650px' }}>
              <DataGrid
                sx={{ mt: 2 }}
                rows={customerList}
                columns={columns}
                pageSize={listlength}
                rowsPerPageOptions={[listlength]}
                getRowId={(row) => row.id}
                checkboxSelection
                pagination
                // paginationMode="server"
                keepNonExistentRowsSelected
                components={{
                  Pagination: CustomPagination,
                  Toolbar: CustomToolbar,
                }}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
              />
            </div>
          </div>
        </TabPanel>
      </TabContext>

    </article>
  )
}

export default FAQ