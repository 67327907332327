import { useState } from 'react';
import './Tags.css'

export const TagInput = ({ tags }) => {
  const [tagData, setTagData] = useState(tags);
  const removeTagData = indexToRemove => {
    setTagData([...tagData.filter((_, index) => index !== indexToRemove)]);
  };
  const addTagData = event => {
    if (event.target.value !== '') {
      setTagData([...tagData, event.target.value]);
      event.target.value = '';
    }
  };
  return (
    <div className="tag-input" style={{ backgroundColor: '#F5F5F5' }}>
      <ul className="tags">
        {tagData.map((tag, index) => (
          <li key={index} className="tag">
            <span className="tag-title">{tag}</span>
            <span
              className="tag-close-icon"
              onClick={() => removeTagData(index)}
            >
              x
            </span>
          </li>
        ))}
      </ul>
      <input
        style={{ backgroundColor: '#F5F5F5' }}
        type="text"
        onKeyUp={event => (event.key === 'Enter' ? addTagData(event) : null)}

      />
    </div>
  );
};