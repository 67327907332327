import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Tab,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import "./Popup.css";
import DropdownSelect from "../../Components/DropdownSelect/DropdownSelect";
import TextEditor from "../../Components/TextEditor/TextEditor2";
import DragDrop from "../../Components/DragDrop/DragDrop";
import useAxios from "../../Helper/Client/useAxios";
import { client } from "../../Helper/Client/Client";
import AlertPopup from "../../Components/AlertPopup/AlertPopup";
import WysiwygEditor from "../../Components/TextEditor/WysiwygEditor";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

const PopupBlogEdit = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");
  const [file, setfile] = useState([])
  const [filelist, setfilelist] = useState([])
  const [deleteImage, setdeleteImage] = useState(null)
  const [Cover, setCover] = useState(0)
  const initialData = { title: "", uploaddate: "", uploadtime: '',tag:'' }
  const [inputs, setinputs] = useState(initialData);
  const [validationError, setvalidationError] = useState(false)
  const [texteditor, settexteditor] = useState(null)
  const [vendorlist, setVendorlist] = useState([])
  const [vendorname, setVendorname] = useState('')
  const [status, setstatus] = useState('')
  const [coverImage, setcoverImage] = useState(null)
  const validstartdate=new Date().toISOString().split('T')[0]
  const [areatype, setareatype] = useState("");
  const [areaList, setareaList] = useState([]);

  function handleChanged(e) {
    const { name, value } = e.target;
    setinputs((inputs) => ({ ...inputs, [name]: value }));
  }

  useEffect(() => {
    setfilelist(file)
  }, [file])

  useEffect(() => {
    deleteImage && setfilelist(filelist.filter(item => item !== deleteImage))
  }, [deleteImage])

  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });

  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.handleClose()
    setOpen(false);

  };

  function handleNext() {
    if (inputs.title === ''||inputs.uploaddate===''||inputs.uploadtime===''
    ||vendorname==='') {
      setvalidationError(true)
      return
    }
    else {
      setvalidationError(false)
    }
    setValue("2")
  }


  const [arearesponse, areaisError, areaisLoading] = useAxios({
    url: "/api/blog_category_listview",
    method: "get",

  });
  useEffect(() => {
    if (arearesponse && arearesponse.data.results) {
      const alteredcategory = arearesponse.data.results.map((item) => {
        const updatedItem = {
          ...item,
          value: item.id,
          name: item.type,
        };
    
        return updatedItem;
      });
      setareaList(alteredcategory);
    }
    return () => {

    };
  }, [arearesponse]);

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      backgroundColor: "#F5F5F5",
      padding: "15px 26px 10px 12px",
      borderRadius: 8,
      fontFamily: [].join(","),
      "&:focus": {},
    },
  }));
  

  useEffect(() => {
    client.get( `/api/blog_details/${props.blogid}?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`).then((blogDetailresponse)=>{
      setinputs({ title: blogDetailresponse.data.data.blog_title, uploaddate: blogDetailresponse.data.data.blog_date, uploadtime: blogDetailresponse.data.data.blog_time && blogDetailresponse.data.data.blog_time.split(':')[0]+':'+blogDetailresponse.data.data.blog_time.split(':')[1],tag:blogDetailresponse.data.data.blog_tag })
      setstatus(blogDetailresponse.data.data.status)
      setVendorname(blogDetailresponse.data.data.vendor_id)
      settexteditor(blogDetailresponse.data.data.blog_content)
      setcoverImage(blogDetailresponse.data.data.cover_image)
      setareatype(blogDetailresponse.data.data.blog_type)
  })
  }, [])



  function handleSubmit() {
    if (inputs.title === ''||inputs.uploaddate===''||inputs.uploadtime===''
        ||vendorname==='') {
      setvalidationError(true)
      return
    }
    else {
      setvalidationError(false)
    }
    let form_data = new FormData();
    form_data.append("blog_title", inputs.title);
    inputs.uploaddate && form_data.append("blog_date", inputs.uploaddate);
    inputs.uploadtime && form_data.append("blog_time", inputs.uploadtime);
    form_data.append("vendor_id", vendorname);
    form_data.append("blog_content", texteditor);
    form_data.append("blog_type", areatype);
    form_data.append("blog_tag", inputs.tag);
    form_data.append("status", (inputs.uploaddate || inputs.uploadtime) === '' ? "Draft" : 'Scheduled');
    filelist.length > 0 ?
      form_data.append("cover_image", filelist[0]) : form_data.append("cover_image", '')
    form_data.append("timezone", Intl.DateTimeFormat().resolvedOptions().timeZone);
    handleClose()
    client.put(`/api/blog_update/${props.blogid}`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }).then(() => {
      setalertOptions({
        alertType: "success",
        alertOpen: true,
        alertContent: "Submitted Successfully!",
      })

      client.get(`/api/blog_paginated_list?page=${props.page}`).then((response) => {
        props.setblogList(response.data.results);
      })
    }).catch(() => {
      setalertOptions({
        alertType: "error",
        alertOpen: true,
        alertContent: "Submission Failed!",
      })
    })
  }

  return (
    <div>
      <MenuItem onClick={handleClickOpen} >Edit Item</MenuItem>
      <Dialog fullWidth open={open} onClose={handleClose} className="dialog">
        <Box>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="CONTENT" value="1" />
                <Tab label="COVER IMAGE" value="2" />
                <img
                  onClick={handleClose}
                  src="/Images/close.png"
                  height="32px"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "8px",
                  }}
                />
              </TabList>
            </Box>
            <TabPanel sx={{ p: 0 }} value="1">
              <DialogTitle>CONTENT</DialogTitle>
              <DialogContent
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label className="mt20">
                  Title
                  <input
                    type="text"
                    name="title"
                    value={inputs.title}
                    onChange={handleChanged}
            
                    required
                    className={validationError && inputs.title === '' && "validationerror"}
                  />
                </label>
                <label className="mt20">
                  Tag
                <input
                   
                      name='tag'
                      type="text" 
                      value={inputs.tag}
                      onChange={handleChanged}
                    /> 
                    </label>
                    <label
                    className="mt20"
                    style={{ marginRight: "30px", width: "100%" }}
                  >
                    Blog Category
                    <DropdownSelect
                      value={areatype}
                      setdropDownValue={setareatype}
                      values={areaList}
                    />{" "}
                  </label>
                <label className="mt20">
                  Content
                  
                  {texteditor!==null && <WysiwygEditor settexteditor={settexteditor} texteditor={texteditor} />}
                
                </label>
                

                <label className="mt20">
                  Upload Date and Time
                  <div style={{ display: 'grid', gridTemplateColumns: '150px 200px', gap: 2 }}>
                    <input type="time" id="birthday" name="uploadtime" value={inputs.uploadtime}
                      className={validationError && inputs.uploadtime === '' && "validationerror"} onChange={handleChanged}></input>
                      <input type="date" id="birthday" name="uploaddate" value={inputs.uploaddate} min={validstartdate}
                        className={validationError && inputs.uploaddate === '' && "validationerror"} onChange={handleChanged}></input>
                  </div>
                </label>
               
              </DialogContent>
              <DialogActions style={{ textAlign: 'end', padding: '20px' }}>
                <Button className='primarybtn' onClick={handleNext}>Next Step</Button>
              </DialogActions>
            </TabPanel>
            <TabPanel sx={{ p: 0 }} value="2">
              <DialogTitle >Cover image</DialogTitle>
              <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
                {!filelist.length > 0 && <DragDrop deleteImage={deleteImage} setfile={setfile} filelist={filelist} />}
                {filelist.length > 0 &&
                  <><div style={{ display: 'grid', gridTemplateColumns: '30px 100px 180px 150px 50px', justifyItems: 'center', marginTop: '30px' }}>
                   
                  </div>
                    {filelist.map((item, index) => (<div key={index}
                      style={{ display: 'grid', gridTemplateColumns: '30px 100px 180px 150px  50px', justifyItems: 'center', verticalAlign: "center", border: '1px solid #636363', padding: '8px', borderRadius: '5px', marginBottom: '10px' }}>
                      
                      <p></p>
                      <img src={URL.createObjectURL(item)} style={{ margin: 'auto' }} height='74px' width="74px" />
                    
                      <p></p>
                      <p></p>
                      <img style={{ margin: 'auto' }} src='/Images/Delete.svg' height='16px' onClick={() => setdeleteImage(item)} />
                    </div>))}</>}
              </DialogContent>
              <DialogActions style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
                <Button className='secondarybtn' onClick={() => { setValue("1") }}>Previous</Button>
                <Button className='primarybtn' onClick={handleSubmit}>Submit</Button>
              </DialogActions>
            </TabPanel>
          </TabContext>
        </Box>
      </Dialog>
      <AlertPopup alertOptions={alertOptions} handleClose={handleClosep} />
    </div>
  );
};

export default PopupBlogEdit;