import { Box, Dialog } from "@mui/material";
import "./Popup.css";
import React, { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { Button, Pagination } from "@mui/material";
import SearchBar from "../../Components/SearchBar/SearchBar";
import { history } from "../../Helper/History/history";
import { client } from "../../Helper/Client/Client";
import AlertPopup from "../../Components/AlertPopup/AlertPopup";

const PopupCustomerVendorList = (props) => {
  const [customerList, setcustomerList] = useState([]);
  const [searchvalue, setsearchvalue] = useState("");
  const [opendialog, setopendialog] = useState(false);
  const [pageCount, setpageCount] = useState(0);
  const [page, setpage] = useState(1);
  const [listlength, setlistlength] = useState(0);

  // table view(edit/delete)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [editbar, setEditbar] = useState("");

  useEffect(() => {
    client
      .get(`/api/active_users_search?search=${searchvalue}`)
      .then((response) => {
        if (response.data.results) {
          setcustomerList(response.data.results);
          setpage(1);
          setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          );
        } else {
          setcustomerList([]);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401 || 403) {
          history.push("/login");
        }
      });
  }, [searchvalue]);

  function CustomPagination() {
    return (
      <Pagination
        className="pagination"
        color="warning"
        count={pageCount}
        page={page}
        onChange={(event, value) => {
          client
            .get(`/api/active_users_list?page=${value}`)
            .then((response) => {
              setcustomerList(response.data.results);
            });
          setpage(value);
        }}
        showFirstButton
        showLastButton
      />
    );
  }

  useEffect(() => {
    if (customerList?.length !== undefined) {
      setlistlength(customerList.length);
    }
  }, [customerList]);

  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const open = Boolean(anchorEl2);

  const handleCloseaction = () => {
    setAnchorEl2(null);
  };
  const handleClickOpen = () => {
    setopendialog(true);
  };
  const handleClosedialog = () => {
    props.setData(JSON.stringify(selectedRows));
    setopendialog(false);
  };
  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };

  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });

  function getNameEmail(params) {
    const firstname = params.row.first_name;
    const lastname = params.row.last_name;
    const email = params.row.email;
    const image = params.row.user_image;
    return firstname + "+" + lastname + "+" + email + "+" + image;
  }

  const columns = [
    {
      field: "name&email",
      headerName: "NAME",
      width: 200,
      valueGetter: getNameEmail,
      renderCell: (params) => (
        <>
          {params.value.split("+")[3] !== "null" ? (
            <img
              src={params.value.split("+")[3]}
              height="30px"
              width="30px"
              alt=""
            />
          ) : (
            <img
              src="/Images/placeholder.png"
              height="30px"
              width="30px"
              alt="profile placholder"
            />
          )}
          <div style={{ marginLeft: "10px" }}>
            <b style={{ color: "rgb(53, 53, 53)" }}>
              {params.value.split("+")[0]} {params.value.split("+")[1]}
            </b>
            <small style={{ color: "grey", display: "block" }}>
              {params.value.split("+")[2]}
            </small>
          </div>
        </>
      ),
    },
    {
      field: "phone_1",
      headerName: "PHONE",
      width: 150,
    },
    {
      field: "is_active",
      headerName: "STATUS",
      headerAlign: "center",
      align: "center",
      width: 120,
      valueGetter: (params) => (params.row.is_active ? "Active" : "Blocked"),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            width: "100px",
            padding: "7px",
            backgroundColor: params.value === "Active" ? "#e8f9ef" : "#ffe7e6",
            color: params.value === "Active" ? "#20A144" : "#EB5A52",
          }}
        >
          {params.value}{" "}
        </span>
      ),
    },
  ];
  function CustomToolbar() {
    return (
      <div style={{ position: "absolute", right: "30px", top: "-210px" }}>
        <GridToolbarContainer></GridToolbarContainer>
      </div>
    );
  }

  const [selectedRows, setSelectedRows] = React.useState([]);
  console.log("selected Rows", selectedRows);

  return (
    <div>
      <Button
        className="primarybtn"
        style={{
          marginTop: "25px",
          width: "250px",
        }}
        onClick={handleClickOpen}
      >
        Select customer / vendor
      </Button>
      <Dialog
        fullWidth
        open={opendialog}
        onClose={handleClosedialog}
        className="dialog"
      >
        <Box>
          <TabContext value={value}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Choose Customer / Vendor" value="1" />
            </TabList>
            <TabPanel value="1">
              <div
                style={{
                  width: "100%",
                  paddingTop: "24px",
                  borderRadius: "8px",
                  border: "1px solid #E8E8E8",
                }}
              >
                <div className="mid-searchbar">
                  <SearchBar
                    searchvalue={searchvalue}
                    setsearchvalue={setsearchvalue}
                  />
                </div>
                <div style={{ height: "500px" }}>
                  <DataGrid
                    sx={{ mt: 2 }}
                    rows={customerList}
                    columns={columns}
                    pageSize={listlength}
                    rowsPerPageOptions={[listlength]}
                    getRowId={(row) => row.id}
                    checkboxSelection
                    onSelectionModelChange={(ids) => {
                      const selectedIDs = new Set(ids);
                      const selectedRows = customerList.filter((row) =>
                        selectedIDs.has(row.id)
                      );
                      setSelectedRows(selectedRows);
                    }}
                    pagination
                    // paginationMode="server"
                    keepNonExistentRowsSelected
                    components={{
                      Pagination: CustomPagination,
                      Toolbar: CustomToolbar,
                    }}
                   
                  />
                  <Button
                    className="primarybtn"
                    style={{
                      marginTop: "25px",
                      marginBottom: "25px",
                      marginLeft: "400px",
                    }}
                    onClick={handleClosedialog}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </TabPanel>
          </TabContext>
        </Box>
      </Dialog>
      <AlertPopup alertOptions={alertOptions} handleClose={handleClosep} />
    </div>
  );
};

export default PopupCustomerVendorList;
