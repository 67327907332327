import React, { useState } from 'react'
import {
  Button,
  Dialog,
  MenuItem,
} from "@mui/material";
import './Popup.css'

const PopupDelete2 = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <button className="exportbtnplus" onClick={handleClickOpen}>
                <span
                  className="material-icons"
                  style={{
                    color: "white",
                    fontSize: "15px",
                    paddingTop: "4px",
                  }}
                >
                  remove
                </span>
              </button>
      <Dialog open={open} onClose={handleClose} className='dialog'>
        <div style={{ width: '500px', height: '174px', padding: '24px' }}>
          <h4>Delete</h4>
          <p>Are you sure to delete this item?</p>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: 'end', gap: '20px' }}>
            <Button className='secondarybtn' onClick={handleClose} >NO</Button>
            <Button className='confirmbtn' onClick={props.delete} >YES</Button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default PopupDelete2