import axios from "axios";
import React, { useState } from "react";
import '../Login/Login.css'
import '../Login/Password.css';
import Otpverify from '../Login/Otpverify';
import { history } from '../../Helper/History/history';
import { Button } from "@mui/material";
function Recover(){
    const [email, setEmail] = useState('')
    function handleChange(e){
        setEmail(e.target.value)
    }

    function handleRecoverPassword(e) {
        e.preventDefault()
        axios.post('https://pickme-django.sociusigb.com/api/forgot_password', {
            "email": email
        }).then(() => {
            localStorage.setItem('recovermail', email);
            history.push('/verify')
        })
        .catch(() => { 
            
        })
    }
    return(
        <div className="login">
            <div className="row login-row">
                <div className="bgImage col-md-6">
                    <div className="left-login">
                        <div style={{textAlign:'center',marginBottom:'20px' }}>
                            <img style={{height:"145px"}} src="../Images/PickMeLogo.png" />
                        </div>
                        <h1>Welcome to <br/>PickMe Studio</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur 
                            adipiscing elit. Vivamus ullamcorper nisl erat, vel convallis elit fermentum pellentesque.
                                Sed mollis velit facilisis facilisis viverra.</p>
                    </div>
                </div>
                <div className="right-login col-md-6">
                    <div className="right-recover-details">
                        <form>
                            <div className="recover-img">
                                <img src="/Images/Vector.png" alt="" />
                            </div>
                            <h4>Recover Your Password</h4>
                            <div className="form-group">
                                <label>email</label>
                                <input type="text" name="recoveremail" className="form-control recover-control" onChange={handleChange}
                                placeholder="cooper@example.com" />
                            </div>
                            <Button type="submit" style={{width:'100%'}} className='primarybtn' onClick={handleRecoverPassword}>Recover Password</Button>
                            <div className="backtologin">
                                <p style={{color:'#636363'}}>Go back to <a href='/login' style={{color:'#FE9900'}}>Login</a></p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Recover;