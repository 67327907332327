import React, { useState, useRef, useEffect } from 'react';
import './../HomePageManagement/HomePageManagement.css';
import DropdownSelect from "../../Components/DropdownSelect/DropdownSelect";
import DragDrop from '../../Components/DragDrop/DragDrop';
import useAxios from '../../Helper/Client/useAxios';
import { history } from '../../Helper/History/history';
import { client } from '../../Helper/Client/Client';
import { useParams } from "react-router-dom";
import { Button } from '@mui/material';
import AlertPopup from '../../Components/AlertPopup/AlertPopup';

export default function HomePagedetails() {
  let { id } = useParams();
  const [file, setfile] = useState([])
  const [filelist, setfilelist] = useState([])
  const [imagelist, setimagelist] = useState([])
  const [deleteImage, setdeleteImage] = useState(null)

  const [vendorname, setVendorname] = useState('')
  const [bannerDetails, setbannerDetails] = useState(null)
  const initialData = { bannertitle: "", startdate: "", enddate: "" }
  const [inputs, setinputs] = useState(initialData);
  const [validationError, setvalidationError] = useState(false)
  const [status, setstatus] = useState('')
  // const [Cover, setCover] = useState(0)
  const [removeImageIdList, setremoveImageIdList] = useState([])

  const dragItem = useRef();
  const dragOverItem = useRef();
  const dragStart = (e, position) => {
    dragItem.current = position;

  };

  const [bannerDetailresponse, bannerDetailisError, bannerDetailisLoading] = useAxios({
    url: `/api/banner_details/${id}`,
    method: "get",
  
  });
  useEffect(() => {
   
    if (bannerDetailresponse && bannerDetailresponse.data.data) {
      setbannerDetails(bannerDetailresponse.data.data)
      setinputs({ bannertitle: bannerDetailresponse.data.data.banner_title, startdate: bannerDetailresponse.data.data.start_date, enddate: bannerDetailresponse.data.data.end_date })
      setstatus(bannerDetailresponse.data.data.status)
      setVendorname(bannerDetailresponse.data.data.vendor_id)
      setimagelist(bannerDetailresponse.data.data.image_list)
    }
    return () => {
    }
  }, [bannerDetailresponse]);
  function handleChanged(e) {
    const { name, value } = e.target;
    setinputs((inputs) => ({ ...inputs, [name]: value }));
  }

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
   
  };

  const drop = (e) => {
    const copyListItems = [...filelist];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setfilelist(copyListItems);
  };

  useEffect(() => {
    setfilelist(file)
  }, [file])

  useEffect(() => {
    deleteImage && setfilelist(filelist.filter(item => item !== deleteImage))
  }, [deleteImage])
  function handleRemoveImage(id) {
    setimagelist(imagelist.filter(item => item.id !== id))
    setremoveImageIdList(removeImageIdList.concat(id))
  }

  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });

  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };

  function handleSubmit() {
    if (inputs.startdate === '' || inputs.enddate === '' || status === ''
    ||(filelist.length === 0 && imagelist.length === 0)) {
      setvalidationError(true)
      return;
    }
    else {
      setvalidationError(false)
    }
    let form_data = new FormData();
    form_data.append("banner_title", inputs.bannertitle);
    form_data.append("start_date", inputs.startdate);
    form_data.append("end_date", inputs.enddate);
    form_data.append("status", status ? 1 : 0);
    form_data.append("vendor_id", vendorname);
    removeImageIdList.length > 0 ?
      form_data.append("image_rm_list", JSON.stringify(removeImageIdList))
      : form_data.append("image_rm_list", '')
    form_data.append("cover_image", '')
    filelist.length > 0 && filelist.map(item => (
      form_data.append("image_list", item)
    ))
    client.put(`/api/banner_update/${id}`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }).then(() => {
      setinputs(initialData)
      setstatus('')
      setfile([])
      setfilelist([])
      setVendorname('')
      setalertOptions({
        alertType: "success",
        alertOpen: true,
        alertContent: "Submitted Successfully!",
      })

      client.get(`/api/banner_details/${id}`).then((response) => {
        setbannerDetails(response.data.data)
        setinputs({ bannertitle: response.data.data.banner_title, startdate: response.data.data.start_date, enddate: response.data.data.end_date })
        setstatus(response.data.data.status)
        setVendorname(response.data.data.vendor_id)
        setimagelist(response.data.data.image_list)
      })
    }).catch(() => {
      setalertOptions({
        alertType: "error",
        alertOpen: true,
        alertContent: "Submission Failed!",
      })
    })
  }
 
  function handleCancel() {
    history.push('/homepagemanagement')
  }

  const todayDate = new Date()
  todayDate.setDate(todayDate.getDate() + 1)
  var validenddate = todayDate.toISOString().split('T')[0]
  const validstartdate = new Date().toISOString().split('T')[0]
  if (inputs.startdate !== "") {
    const futuredate = new Date(inputs.startdate)
    futuredate.setDate(futuredate.getDate() + 1)
    validenddate = futuredate.toISOString().split('T')[0]
  }
  if (inputs.startdate > inputs.enddate) {
    inputs.enddate = ''
  }

  return (
    <article>
      <h2>Banner</h2>
      <div className="bannerDetails"  >
        <button className={bannerDetails?.status ? "bannerDetails-btnG" : "bannerDetails-btnR"}>{bannerDetails?.status ? 'Active' : 'Disable'}</button>
        <div className="bannerImage" style={{ textAlign: 'center', paddingTop: '20px' }}>
          {bannerDetails?.image_list[0]?.image_url ? <img src={bannerDetails?.image_list[0]?.image_url} height='251px' />
            : <img src='../Images/bannerplacehonder.svg' height='251px' />}
        
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: '50%' }}>
          <label className="mt20">
            Title </label>
          <input
            value={inputs.bannertitle}
            type="text"
            name="bannertitle"
            onChange={handleChanged}
            // className={(validationError && inputs.bannertitle === '') ? "validationerror" : undefined}
          />
         
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto",
              gap: "30px",
            }}
          >
            <label className="mt20" style={{ display: "flex", flexDirection: "column" }}>
              Start Date
              <input type="date" id="birthday" name="startdate" value={inputs.startdate?.split('T')[0]} min={validstartdate}
                className={(validationError && !inputs.startdate ) ? "validationerror" : undefined} onChange={handleChanged}></input>
            </label>
            <label className="mt20" style={{ display: "flex", flexDirection: "column" }}>
              End Date
              <input type="date" id="birthday" name="enddate" value={inputs.enddate?.split('T')[0]} min={validenddate}
                className={(validationError && !inputs.enddate ) ? "validationerror" : undefined} onChange={handleChanged}></input>
            </label>
          </div>
          <label className="mt20">
            Status
            <DropdownSelect
              validationError={validationError} value={status} setdropDownValue={setstatus} values={[{ name: 'Active', value: true }, { name: 'Disable', value: false }]} />
          </label>
        </div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", marginTop: '30px', gap: '2vw' }}>
          <div style={{ display: 'grid', gridTemplateColumns: "auto", width: '50%' }}>
            {(!filelist.length > 0 && !imagelist.length > 0) && <DragDrop deleteImage={deleteImage} setfile={setfile} filelist={filelist} validationError={validationError}/>}
            {filelist.length > 0 && filelist.map((item, index) => (
              <div key={index}
                draggable onDragStart={(e) => dragStart(e, index)} onDragEnter={(e) => dragEnter(e, index)} onDragEnd={drop} style={{ display: 'grid', gridTemplateColumns: '2vw 9vw 9vw 5vw 4vw', justifyItems: 'center', verticalAlign: "center", border: '1px solid #636363', padding: '8px', borderRadius: '5px', marginBottom: '10px' }}>
             
                <p></p>
                <img src={URL.createObjectURL(item)} style={{ margin: 'auto' }} height='74px' width="74px" />
               
                <p></p>
                <p></p>
                <img style={{ margin: 'auto' }} src='/Images/Delete.svg' height='16px' onClick={() => setdeleteImage(item)} />
              </div>))}
            {(imagelist.length > 0 || filelist.length > 0) &&
              <>
               
                {imagelist.length > 0 && imagelist.map((item, index) => (<div key={index}
                  style={{ display: 'grid', gridTemplateColumns: '2vw 9vw 9vw 5vw 4vw', justifyItems: 'center', verticalAlign: "center", border: '1px solid #636363', padding: '8px', borderRadius: '5px', marginBottom: '10px' }}>          {/* draggable onDragStart={(e) => dragStart(e, index)}  onDragEnter={(e) => dragEnter(e, index)}  onDragEnd={drop}  */}
                  <p></p>
                 
                  <img src={item.image_url} style={{ margin: 'auto' }} height='74px' width="74px" />
                
                  <p></p>
                  <p></p>
                  <img style={{ margin: 'auto' }} src='/Images/Delete.svg' height='16px' onClick={() => handleRemoveImage(item.id)} />
                </div>))}
              </>}
          </div>
          <div>

          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: "10px"
          }}
        >
          <Button className="primarybtn" onClick={handleSubmit} style={{ marginRight: "10px" }}>
            Save
          </Button>
          <Button className="secondarybtn" onClick={handleCancel}>Cancel</Button>
        </div>
        <AlertPopup alertOptions={alertOptions} handleClose={handleClosep} />
      </div>
    </article>
  )
}
