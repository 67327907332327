import React, { useEffect, useRef, useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TextEditor from "../../Components/TextEditor/TextEditor";
import DropdownSelect from "../../Components/DropdownSelect/DropdownSelect";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import "./Popup.css";
import useAxios from "../../Helper/Client/useAxios";
import { client } from "../../Helper/Client/Client";
import AlertPopup from "../../Components/AlertPopup/AlertPopup";
import DragDrop from "../../Components/DragDrop/DragDrop";
import WysiwygEditor from "../../Components/TextEditor/WysiwygEditor";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Menu from "@mui/material/Menu";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Venuesettingpopup from "../Popup/Venuesettingpopup";
import Venuesettingpopupedit from "../Popup/Venuesettingpopupedit";

export default function AddVenue(props) {
  const options = ["1", "2", "3", "4", "5", "6", "7", "8"];


  const [numberofrooms, setnumberofrooms] = useState("1");


  const [addsetting, setAddsetting] = useState(true);
  const [editsetting, setEditsetting] = useState(true);
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");
  const initialData = {
    venuename: "",
    venuearea: "",
    venueaddress: "",
    settingTitle: "",
    venuerooms: null,
  };
  const [inputs, setinputs] = useState(initialData);
  const [nu, setnu] = useState();
  const [texteditor, settexteditor] = useState("");
  const [texteditorSetting, settexteditorSetting] = useState("");
  const [venuetype, setvenuetype] = useState("");
  const [areatype, setareatype] = useState("");
  const [featureList, setfeatureList] = useState([]);
  const [areaList, setareaList] = useState([]);
  const [typeList, settypeList] = useState([]);
  const [tagvalue, settagvalue] = useState([]);
  const [equipmentypeList, setequipmentypeList] = useState([]);
  const [equipmenttagvalue, setequipmenttagvalue] = useState([]);
  const [status, setstatus] = useState("");
  const [validationError, setvalidationError] = useState(false);
  const [deleteImage, setdeleteImage] = useState(null);
  const [settingsList, setsettingsList] = useState([]);
  const [EditsettingID, setEditsettingID] = useState(null);
  const [edit, setedit] = useState(false);
  const [settingsEdited, setsettingsEdited] = useState(false);

  // Room Tab Duplication

  const [inputfieldsToAdd, setInputfieldsToAdd] = React.useState(1);
  const [committedFieldsToAdd, setCommittedFieldsToAdd] = React.useState(1);
  const [Capacity, setCapacity] = useState("");
  const [Price, setPrice] = useState("");
  const [Addlist, setAddlist] = useState(["1"]);
  const [roomdet, setroomdet] = useState([
    {
      Capacity: "",
      Price: "",
    },
  ]);

  const [inputFields, setInputFields] = useState([
    {
      RoomName: "",
      RoomCapacity: "1-3人",
      RoomPrice: "60",
      RoomImage:[]
    },
  ]);



  useEffect(() => {

  }, [inputFields]);

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  };
  function handleChanged(e) {
    const { name, value } = e.target;
    setinputs((inputs) => ({ ...inputs, [name]: value }));
  }

  function handleNumChange(e) {
    setInputfieldsToAdd(parseInt(e.target.value, 10));
    handleNumChanged(e);
  }

  function handleNumChanged(e) {
  
    setCommittedFieldsToAdd(parseInt(e.target.value, 10));
    let newfield1=[]
    newfield1=[...Array(parseInt(e.target.value, 10))].map((item,i)=>(
        {
          RoomName: "",
          RoomCapacity: "1-3人",
          RoomPrice: "60",
          RoomImage:[]
        })
    )
        setInputFields(newfield1);
  }

  const handleOptionChange = (event) => {
    setCapacity(event.target.value);
  };

  const handleOptionChange2 = (event) => {
    setPrice(event.target.value);
  };

  const [file, setfile] = useState([]);
  const [filelist, setfilelist] = useState([]);

  //
  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    dragItem.current = position;

  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;

  };

  const drop = (e) => {
    const copyListItems = [...filelist];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setfilelist(copyListItems);
  };


  useEffect(() => {
    setfilelist(file);
    const timer = setTimeout(() => {
      // console.log("This will run after 1 second!");
      setInputFields([...inputFields]);
    }, 1000);
    return () => clearTimeout(timer);
  }, [file]);

  useEffect(() => {

    if (deleteImage) {
      let data = inputFields.map((item, i) => {
        const updatedItem = {
          ...item,
          RoomImage: item.RoomImage.filter(
            (item1) => item1 + i !== deleteImage
          ),
        };
        return updatedItem;
      });

      setInputFields(data);
      setdeleteImage(null);
    }
  }, [deleteImage]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleAddNewSettings = () => {
    setAddsetting(false);
  };

  function handleSettingAdd() {
    if (inputs.settingTitle === "") {
      setvalidationError(true);
      return;
    } else {
      setvalidationError(false);
    }
    setsettingsList([
      ...settingsList,
      {
        dateid: Date.now(),
        tab_title: inputs.settingTitle,
        tab_description: texteditorSetting,
      },
    ]);
    setAddsetting(true);
    setinputs((inputs) => ({ ...inputs, ["settingTitle"]: "" }));
    settexteditorSetting("");
  }

  function handleEditSetting(i) {
    setEditsetting(false);
    settingsList.map((item) => {
      if (item.dateid === i) {
        setinputs((inputs) => ({
          ...inputs,
          ["settingTitle"]: item.tab_title,
        }));
        settexteditorSetting(item.tab_description);
        setEditsettingID(i);
      }
    });
  }

  function handleDeleteSetting(i) {
    setsettingsList(settingsList.filter((item) => item.id !== i));
  }
  function handleSettingEditSave() {
    if (inputs.settingTitle === "") {
      setvalidationError(true);
      return;
    } else {
      setvalidationError(false);
    }
    setsettingsList(
      settingsList.map((item) => {
        if (item.dateid === EditsettingID) {
          const updatedItem = {
            ...item,
            tab_title: inputs.settingTitle,
            tab_description: texteditorSetting,
          };
          return updatedItem;
        }
        return item;
      })
    );
    setEditsetting(true);
    setinputs((inputs) => ({ ...inputs, ["settingTitle"]: "" }));
    settexteditorSetting("");
    setEditsettingID(null);
  }

  function handlecanceledit() {
    setEditsetting(!editsetting);
    setEditsettingID(null);
    setinputs((inputs) => ({ ...inputs, ["settingTitle"]: "" }));
    settexteditorSetting("");
  }
 
  function handleSettingDelete(i) {
    
    setsettingsList(settingsList.filter((item) => item.dateid !== i));
  }

  function handleVenueSettingSubmit() {
  
  }


  const [categoryresponse, categoryisError, categoryisLoading] = useAxios({
    url: "/api/venue_type_listview",
    method: "get",
   
  });
  const [featureresponse, featureisError, featureisLoading] = useAxios({
    url: "/api/venue_features_listview",
    method: "get",
   
  });
  const [equipmentresponse, equipmentisError, equipmentisLoading] = useAxios({
    url: "/api/venue_equipment_list",
    method: "get",
   
  });
  const [arearesponse, areaisError, areaisLoading] = useAxios({
    url: "/api/venue_area_listview",
    method: "get",
   
  });
 

  useEffect(() => {
    if (categoryresponse && categoryresponse.data.results) {
      const alteredcategory = categoryresponse.data.results.map((item) => {
        const updatedItem = {
          ...item,
          value: item.id,
          name: item.type,
        };
       
        return updatedItem;
      });
      setfeatureList(alteredcategory);
    }

    if (featureresponse && featureresponse.data.data) {
      settypeList(featureresponse.data.data);
    }

    if (equipmentresponse && equipmentresponse.data.data) {
      setequipmentypeList(equipmentresponse.data.data);
    }

    if (arearesponse && arearesponse.data.data) {
      const alteredcategory = arearesponse.data.data.map((item) => {
        const updatedItem = {
          ...item,
          value: item.id,
          name: item.area,
        };
      
        return updatedItem;
      });
      setareaList(alteredcategory);
    }
    return () => {

    };
  }, [categoryresponse, featureresponse, equipmentresponse, arearesponse]);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      backgroundColor: "#F5F5F5",
      padding: "15px 26px 10px 12px",
      borderRadius: 8,
      fontFamily: [].join(","),
      "&:focus": {},
    },
  }));
 

  // console.log("settings List", settingsList);

  const handleSubmit = () => {
    if (
      inputs.venuename === "" ||
      inputs.venueaddress === "" ||
      inputs.venuerooms === "" ||
      areatype === "" ||
      venuetype === "" ||
      status === ""||inputFields[0].RoomImage.length === 0
    ) {
      setvalidationError(true);
      return;
    } else {
      setvalidationError(false);
    }
    handleClose();
    let datas = inputFields.map((item) => ({
      room_name: item.RoomName,
      room_capacity: item.RoomCapacity,
      room_price: item.RoomPrice,
      room_image: item.RoomImage.map((item) => item.split(",")[1]),
    }));
    const body = {
      title: inputs.venuename,
      description: texteditor,
      capacity: 0,
      location: inputs.venueaddress,
      district: "",
      venue_area: areatype,
      partition: "",
      venue_type_id: venuetype, 
      feature_list: JSON.stringify(
        tagvalue.map((item) => {
          return item.id;
        })
      ),
      venue_owner: props.vendorid,
      status: status,
    
      rooms: JSON.stringify(datas),
      offer_tag: inputs.tag,
      equipment_list: JSON.stringify(
        equipmenttagvalue.map((items) => {
       
          return items.id;
        })
      ),
      number_of_rooms: inputfieldsToAdd,
 
    };
    client
      .post(`api/venue`, body)
      .then((response) => {
        setinputs(initialData);
        settexteditor("");
        setstatus("");
        setareatype("");
        setvenuetype("");
        settagvalue([]);
        setequipmenttagvalue([]);
        setValue("1");
       

        client
          .post(
            `api/settings_create_by_venue/${response.data.data.id}`,
            settingsList
          )
          .then(() => {
            setalertOptions({
              alertType: "success",
              alertOpen: true,
              alertContent: "Submitted Successfully!",
            });
            setsettingsList([]);
            client
              .get(`/api/venuelist_by_vendor/${props.vendorid}`)
              .then((response) => {
                props.setvenueList(response.data.data);
              });
          });
      })
      .catch(() => {
        setalertOptions({
          alertType: "error",
          alertOpen: true,
          alertContent: "Submission Failed!",
        });
      });
  };

  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });

  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };

  function handleCancel() {
    setedit(false);
    setvalidationError(false);
  }
 

  return (
    <div>
      <div style={{ float: "right" }}>
        <Button
          className="primarybtn"
          startIcon={
            <span
              className="material-icons"
              style={{
                color: "white",
                fontSize: "20px",
              }}
            >
              add
            </span>
          }
          onClick={handleClickOpen}
        >
          Add Venue
        </Button>
      </div>
      <Dialog fullWidth open={open} onClose={handleClose} className="dialog">
        <Box>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Informations" value="1" />
                <Tab label="Rooms" value="2" />
                <Tab label="Settings" value="3" />
                <img
                  onClick={handleClose}
                  src="/Images/close.png"
                  height="32px"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "8px",
                  }}
                />
              </TabList>
            </Box>
            <TabPanel sx={{ p: 0 }} value="1">
              <DialogTitle>Information</DialogTitle>
              <DialogContent
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label className="mt20">
                  Venue Name
                  <input
                    className={
                      validationError &&
                      inputs.venuename === "" &&
                      "validationerror"
                    }
                    style={{ width: "100%" }}
                    type="text"
                    required
                    name="venuename"
                    onChange={handleChanged}
                    value={inputs.venuename}
                    
                  />
                </label>

                <label className="mt20">
                  Venue Description
            
                  {texteditor !== null && (
                    <WysiwygEditor
                      settexteditor={settexteditor}
                      texteditor={texteditor}
                    />
                  )}
                </label>
                <div style={{ display: "grid", gridTemplateColumns: "auto 240px" }}>
                  <label
                    className="mt20"
                    style={{ marginRight: "30px", width: "230px" }}
                  >
                    Venue area
                    <DropdownSelect
                      validationError={validationError}
                      value={areatype}
                      setdropDownValue={setareatype}
                      values={areaList}
                    />
                  </label>
                  
                  <label
                    className="mt20"
                    // style={{ width: "230px" }}
                  >
                    Number of rooms
                    <br />
                    <FormControl sx={{ width: 240 }}>
                      <Select
                        value={inputfieldsToAdd}
                        onChange={handleNumChange}
                   
                        displayEmpty
                        input={<BootstrapInput />}
                      >
                        <MenuItem
                          value="1"
                     
                        >
                          1
                        </MenuItem>
                        <MenuItem
                          value="2"
                       
                        >
                          2
                        </MenuItem>
                        <MenuItem
                          value="3"
                       
                        >
                          3
                        </MenuItem>
                        <MenuItem
                          value="4"
                    
                        >
                          4
                        </MenuItem>
                        <MenuItem
                          value="5"
                      
                        >
                          5
                        </MenuItem>
                        <MenuItem
                          value="6"
                        
                        >
                          6
                        </MenuItem>
                        <MenuItem
                          value="7"
                
                        >
                          7
                        </MenuItem>
                        <MenuItem
                          value="8"
                        
                        >
                          8
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </label>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                 
                </div>

                <label className="mt20">
                  Tag
                  <input
                    className={
                      validationError && inputs.tag === "" && "validationerror"
                    }
                    name="tag"
                    style={{ width: "100%" }}
                    type="number"
                    required
                    value={inputs.tag}
                    onChange={handleChanged}
                  />
                </label>
                <label className="mt20">
                  Venue Address
                  <input
                    className={
                      validationError &&
                      inputs.venueaddress === "" &&
                      "validationerror"
                    }
                    name="venueaddress"
                    style={{ width: "100%" }}
                    type="text"
                    required
                    value={inputs.venueaddress}
                    onChange={handleChanged}
                  />
                </label>
                <label className="mt20" style={{ width: "100%" }}>
                  Venue Category
                  <DropdownSelect
                    validationError={validationError}
                    value={venuetype}
                    setdropDownValue={setvenuetype}
                    values={featureList}
                  />
                </label>
                <label className="mt20">
                  Venue Features
                  <Autocomplete
                    className="multiselect"
                    multiple
                    style={{
                      backgroundColor: "#f5f5f5",
                      padding: "10px",
                      borderRadius: "8px",
                    }}
                    options={typeList}
                    value={tagvalue}
                    onChange={(event, newValue) => {
                      settagvalue(newValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.feature === value.feature
                    }
                    getOptionLabel={(option) => option.feature}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" />
                    )}
                  />
                </label>
                <label className="mt20">
                  Venue Equipment
                  <Autocomplete
                    className="multiselect"
                    multiple
                    style={{
                      backgroundColor: "#f5f5f5",
                      padding: "10px",
                      borderRadius: "8px",
                    }}
                    options={equipmentypeList}
                    value={equipmenttagvalue}
                    onChange={(event, newValue) => {
                      setequipmenttagvalue(newValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.equipment === value.equipment
                    }
                    getOptionLabel={(option) => option.equipment}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" />
                    )}
                  />
                </label>
                <label className="mt20">
                  Status
                  <DropdownSelect
                    validationError={validationError}
                    value={status}
                    setdropDownValue={setstatus}
                    values={[
                      { name: "Active", value: true },
                      { name: "Blocked", value: false },
                    ]}
                  />
                </label>
              </DialogContent>
              <DialogActions style={{ textAlign: "end", padding: "20px" }}>
                <Button
                  className="primarybtn"
                  onClick={() => {
                    if (
                      inputs.venuename === "" ||
                      inputs.venueaddress === "" ||
                      inputs.venuerooms === "" ||
                      areatype === "" ||
                      venuetype === "" ||
                      status === ""
                    ) {
                      setvalidationError(true);
                      return;
                    } else {
                      setvalidationError(false);
                    }
                  
                    setValue("2");
          
                  }}
                >
                  Next Step
                </Button>
              </DialogActions>
            </TabPanel>
            <TabPanel xl={{ p: 0 }} value="2">
              <DialogTitle>Rooms</DialogTitle>

            
                <div
                  className="venue-tab-details"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  {inputFields.map((item, i) => (
                    <>
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <h5>Room {i + 1} Details</h5>

                       
                      </div>

                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "auto auto",
                        }}
                      >
                        <label className="mt20" style={{ marginRight: "20px" }}>
                          Room Name
                          <input
                            className={
                              validationError &&
                              !inputFields[0].RoomName === true &&
                              "validationerror"
                            }
                            type="text"
                            name="RoomName"
                            value={item.RoomName}
                            onChange={(event) => handleFormChange(i, event)}
                            required
                          />
                          
                        </label>
                        <label className="mt20">
                          Capacity
                          <br />
                          <FormControl sx={{ minWidth: 50 }}>
                            <Select
                              name="RoomCapacity"
                              value={item.RoomCapacity}
                              onChange={(event) => handleFormChange(i, event)}
                             
                              displayEmpty
                              input={<BootstrapInput />}
                            >
                              <MenuItem value="1-3人">1-3人</MenuItem>
                              <MenuItem value="3-6人">3-6人</MenuItem>
                              <MenuItem value="6-10人">6-10人</MenuItem>
                              <MenuItem value="10-15人">10-15人</MenuItem>
                              <MenuItem value="15-20人">15-20人</MenuItem>
                              <MenuItem value="20人或以上">20人或以上</MenuItem>
                            </Select>
                          </FormControl>
                        </label>

                        <label className="mt20">
                          Price
                          <br />
                          <FormControl sx={{ minWidth: 150 }}>
                            <Select
                              name="RoomPrice"
                              value={item.RoomPrice}
                              onChange={(event) => handleFormChange(i, event)}
                         
                              displayEmpty
                              input={<BootstrapInput />}
                            >
                              <MenuItem value="60">人均$60以下</MenuItem>
                              <MenuItem value="80">人均$80以下</MenuItem>
                              <MenuItem value="100">人均$100以下</MenuItem>
                              <MenuItem value="120">人均$120以下</MenuItem>
                              <MenuItem value="140">人均$140以下</MenuItem>
                              <MenuItem value="180">人均$180以下</MenuItem>
                              <MenuItem value="200">人均$200或以上</MenuItem>
                              
                            </Select>
                          </FormControl>
                        </label>
                      </div>

                      <DialogContent
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                       
                        {!item.RoomImage[7] && (
                          <DragDrop  
                            inputFields={inputFields}
                            index={i}
                            setInputFields={setInputFields}
                            validationError={validationError}
                            deleteImage={deleteImage}
                            setfile={setfile}
                            filelist={item.RoomImage}
                          />
                        )}
                        <div>
                          {item.RoomImage && (
                            <>
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns:
                                    "30px 100px 180px 150px 50px",
                                  justifyItems: "center",
                                  marginTop: "30px",
                                }}
                              ></div>
                              {item.RoomImage.map((item1, index) => (
                                <div
                                  key={index}
                                  draggable
                                  onDragStart={(e) => dragStart(e, index)}
                                  onDragEnter={(e) => dragEnter(e, index)}
                                  onDragEnd={drop}
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns:
                                      "30px 100px 100px 150px  50px",
                                    justifyItems: "center",
                                    verticalAlign: "center",
                                    border: "1px solid #636363",
                                    padding: "8px",
                                    borderRadius: "5px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <p></p>
                                  <img
                                    src={item1}
                                    style={{ margin: "auto" }}
                                    height="74px"
                                    width="74px"
                                  />
                                  <p></p>
                                  <p></p>
                                  <img
                                    style={{ margin: "auto" }}
                                    src="/Images/Delete.svg"
                                    height="16px"
                                    onClick={() => setdeleteImage(item1 + i)}
                                  />
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      </DialogContent>
                    </>
                  ))}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: "10px",
                    }}
                  >
                    
                  </div>
                </div>
              

              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "20px",
                }}
              >
                <Button
                  className="secondarybtn"
                  onClick={() => {
                    setValue("1");
                  }}
                >
                  Previous
                </Button>
                <Button
                  className="primarybtn"
                  onClick={() => {
                    if (
                      !inputFields[0].RoomName === true||inputFields[0].RoomImage.length === 0
                    ) {
                      setvalidationError(true);
                      return;
                    } else {
                      setvalidationError(false);
                    }
                    setValue("3");
                  }}
                >
                  Next Step
                </Button>
              </DialogActions>
            </TabPanel>
            <TabPanel
              value="3"
              style={{
                padding: "0px",
                padding: "15px",
                border: "1px solid #e8e8e8",
              }}
            >
              <div style={{ color: "#3B3B3B" }}>
                <h5>Setting</h5>
                {settingsList.length > 0 &&
                  settingsList.map((item) => (
                    <div key={item.id}
                    className="setting_section">
                      <p>{item.tab_title}</p>
                      <ul>
                        <li>
                          <Venuesettingpopupedit
                            setsettingsEdited={setsettingsEdited}
                            setsettingsList={setsettingsList}
                            settingsList={settingsList}
                            item={item}
                          />
                        </li>
                        <li>
                          <img
                            src="../Images/Delete.svg"
                            height="16px"
                            onClick={() => handleDeleteSetting(item.id)}
                            className="pointer"
                          />
                        </li>
                      </ul>
                    </div>
                  ))}
                <Venuesettingpopup
                  setsettingsEdited={setsettingsEdited}
                  setsettingsList={setsettingsList}
                  settingsList={settingsList}
                />
                {/* {settingsEdited && ( */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      className="secondarybtn"
                      onClick={() => setValue("2")}
                    >
                      Previous
                    </Button>
                    <Button
                      className="primarybtn"
                      onClick={handleSubmit}
                      style={{ marginRight: "10px" }}
                    >
                      Submit
                    </Button>
                  </div>
                {/* )} */}
              </div>
            </TabPanel>
          </TabContext>
        </Box>
      </Dialog>
      <AlertPopup alertOptions={alertOptions} handleClose={handleClosep} />
    </div>
  );
}