import React, { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  DataGrid,
} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { Button, Pagination } from "@mui/material";
import SearchBar from "../../Components/SearchBar/SearchBar";
import useAxios from "../../Helper/Client/useAxios";
import VideoPromoMessageDetails from "../Popup/VideoPromoMessageDetails";
import { client } from "../../Helper/Client/Client";
import PopupDeleteAll from "../Popup/PopupDeleteAll";

function VideoPromoMsg() {
  // tab property
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // table view(edit/delete)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [editbar, setEditbar] = useState("");
  const [videopromotionList, setvideopromotionList] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [page, setpage] = useState(1);
  const [listlength, setlistlength] = useState(0);
  const [searchvalue, setsearchvalue] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);

  
  
    useEffect(() => {
    client.get(`api/video_promo_message_list?search=${searchvalue}`).then((response) => {
      if (response.data.results) {
        setvideopromotionList(response.data.results.reverse());
        setpage(1)
        setpageCount(
          Math.ceil(response.data.count / response.data.results.length)
        );
      } else {
        setvideopromotionList([]);
      }
  })}, [searchvalue])

  useEffect(() => {
    if (videopromotionList?.length !== undefined) {
      setlistlength(videopromotionList.length);
    }
  }, [videopromotionList]);

  function CustomPagination() {
    return (
      <Pagination
        className="pagination"
        color="warning"
        count={pageCount}
        page={page}
        onChange={(event, value) => {
          searchvalue === ""
            ? client
                .get(`/api/video_promo_paginated_list?page=${value}`)
                .then((videoPromotionresponse) => {
                  setvideopromotionList(videoPromotionresponse.data.results);
                })
            : client
                .get(
                  `/api/video_promo_searchby_vendor?page=${value}&search=${searchvalue}`
                )
                .then((videoPromotionresponse) => {
                  setvideopromotionList(videoPromotionresponse.data.results);
                });
          setpage(value);
        }}
        showFirstButton
        showLastButton
      />
    );
  }

  const showEditbar = (event, id) => {
    setAnchorEl2(event.currentTarget);
    // console.log(id);
    setEditbar(id);
  };
  const handleClose = () => {
    setEditbar(null);
  };

  const open = Boolean(anchorEl);
  const handleClickaction = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseaction = () => {
    setAnchorEl(null);
  };

  const handlebulkDelete =()=>{
    client.delete(`api/video_promo_message_bulk_delete`,{ data: { "promo_message_id": selectionModel }}).then(() => {
      setAnchorEl(null);
      client.get(`api/video_promo_message_list`).then((response) => {
        setpage(1)
        setvideopromotionList(response.data.results);
        setpageCount(Math.ceil(response.data.count/response.data.results.length))
      })
    })
  }

  function getNameEmail(params) {
    const name = params.row.vendor_name;
    const email = params.row.vendor_email;
    const image = params.row.vendor_image;
    return name + "+" + email + "+" + image;
  }

  const columns = [
    {
      field: "vendor_name",
      headerName: "VENDOR NAME",
      width: 200,
      valueGetter: getNameEmail,
      renderCell: (params) => (
        <>
          {params.value.split("+")[2] !== "null" ? (
            <img
              src={params.value.split("+")[2]}
              height="30px"
              width="30px"
              alt=""
            />
          ) : (
            <img
              src="/Images/placeholder.png"
              height="30px"
              width="30px"
              alt="profile placholder"
            />
          )}
          <div style={{ marginLeft: "10px" }}>
            <b style={{ color: "rgb(53, 53, 53)" }}>
              {params.value.split("+")[0]}
            </b>
            <small style={{ color: "grey", display: "block" }}>
              {params.value.split("+")[1]}
            </small>
          </div>
        </>
      ),
    },
    {
      field: "start_date",
      headerName: "START DATE",
      align: "center",
      headerAlign: "center",
      type: "date",
      width: 150,
      renderCell: (params) =>
        params.value.split("T")[0].split("-")[2] +
        "." +
        params.value.split("T")[0].split("-")[1] +
        "." +
        params.value.split("T")[0].split("-")[0],
    },
    {
      field: "end_date",
      headerName: "END DATE",
      align: "center",
      headerAlign: "center",
      type: "date",
      width: 150,
      renderCell: (params) =>
        params.value.split("T")[0].split("-")[2] +
        "." +
        params.value.split("T")[0].split("-")[1] +
        "." +
        params.value.split("T")[0].split("-")[0],
    },
    {
      field: "created_date",
      headerName: "ENTRY DATE",
      align: "center",
      headerAlign: "center",
      type: "date",
      width: 150,
      renderCell: (params) =>
        params.value.split("T")[0].split("-")[2] +
        "." +
        params.value.split("T")[0].split("-")[1] +
        "." +
        params.value.split("T")[0].split("-")[0],
    },
    {
      field: "status",
      headerName: "STATUS",
      align: "center",
      headerAlign: "center",
      width: 140,
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            width: "100px",
            padding: "7px",
            backgroundColor: params.value =="Waiting"? "#ffe7e6":"#e8f9ef" ,
            color: params.value==="Waiting" ? "#EB5A52":"#20A144",
          }}
        >
          {params.value}{" "}
        </span>
      ),
    },
    {
      field: "id",
      headerName: "",
      width: 100,
      renderCell: (params) => (

        <>
          <IconButton onClick={(event) => showEditbar(event, params.value)}>
            <img src="../Images/dots.png" />
          </IconButton>
          <Menu
            anchorEl={anchorEl2}
            open={editbar === params.value ? true : false}
            onClose={handleClose}
          >
            <VideoPromoMessageDetails id={params.value} />
          </Menu>
        </>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      customername: "Regina Cooper",
      startdate: "14.12.21",
      enddate: "20.12.21",
      entrydate: "20.12.21",
      status: "Finished",
    },
    {
      id: 2,
      customername: "Regina Cooper",
      startdate: "15.12.21",
      enddate: "20.12.21",
      entrydate: "20.12.21",
      status: "unfinished",
    },
    {
      id: 3,
      customername: "Regina Cooper",
      startdate: "16.12.21",
      enddate: "20.12.21",
      entrydate: "20.12.21",
      status: "Finished",
    },
  ];

  return (
    <article>
      <h4>Video Promotion Message</h4>
      <TabContext value={value}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab label="All" value="1" />
         
        </TabList>
        <TabPanel value="1">
          <div
            style={{
              width: "100%",
              paddingTop: "24px",
              borderRadius: "8px",
              border: "1px solid #E8E8E8",
            }}
          >
            <div className="mid-searchbar">
              <SearchBar
                searchvalue={searchvalue}
                setsearchvalue={setsearchvalue}
              />
              <Button
                onClick={handleClickaction}
                className="tertiarybtn"
                endIcon={<img src="../Images/Arrow-Down.svg" />}
              >
                Actions
              </Button>
              <Menu anchorEl={anchorEl} open={open} onClose={handleCloseaction}>
              <PopupDeleteAll delete={handlebulkDelete} />
              </Menu>
            </div>
            <div style={{ height: "650px" }}>
              <DataGrid
                sx={{ mt: 2 }}
                rows={videopromotionList}
                columns={columns}
                pageSize={listlength}
                rowsPerPageOptions={[listlength]}
                getRowId={(row) => row.id}
                checkboxSelection
                pagination
                // paginationMode="server"
                keepNonExistentRowsSelected
                components={{
                  Pagination: CustomPagination,
                }}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
              />
            </div>
          </div>
        </TabPanel>
        
      </TabContext>
    </article>
  );
}

export default VideoPromoMsg;