import React,{useState} from "react";
import '../Login/Login.css'
import '../Login/Password.css';
import Password from "./Password";
import axios from "axios";
import { history } from '../../Helper/History/history';
import { Button } from "@mui/material";

function Reset(){
    const [password, setPassword] = useState('')
    const [confirmpassword, setConfirmpassword]=useState('')
    function handleNewPassword(e){
        setPassword(e.target.value)
    }
    function handleConfirmPassword(e){
        setConfirmpassword(e.target.value)
    }
    function handleChangePassword(e) {
        e.preventDefault()
        const resettoken = localStorage.getItem('resetPasswordtoken')
        axios.post('http://202.88.234.48:8000/api/update_password',{
            "email": localStorage.getItem('recovermail'),
            "new_password": password
        },{
            headers: {
                "Content-type": "Application/json",
                "Authorization": 'Bearer ' + resettoken
                }   
        }).then((response) => {
            history.push('/login')
        })
        .catch(() => { 
            
        })

    }
    return(
        <div className="login">
            <div className="row login-row">
                <div className="bgImage col-md-6">
                        <div className="left-login">
                            <div style={{textAlign:'center',marginBottom:'20px' }}>
                                <img style={{height:"145px"}} src="/Images/PickMeLogo.png" />
                            </div>
                            <h1>Welcome to <br/>PickMe Studio</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur 
                                adipiscing elit. Vivamus ullamcorper nisl erat, vel convallis elit fermentum pellentesque.
                                    Sed mollis velit facilisis facilisis viverra.</p>
                        </div>
                </div>
                <div className="right-register col-md-6">
                    <div className="right-reset-details">
                        <form>
                            <div className="recover-img">
                                <img src="/Images/Vector.png" alt="" />
                            </div>
                            <h4>Reset Your Password</h4>
                          
                            <div className="form-group">
                                <label>密碼</label>
                                <Password handlePassword={handleNewPassword} required/>
                            </div>
                            <div className="form-group">
                                <label>密碼</label>
                                <Password handleConfirmPassword={handleConfirmPassword} required/>
                            </div>
                            <Button type="submit" style={{width:'100%'}} className='primarybtn'
                            onClick={handleChangePassword}>Reset Password</Button>
                            <div className="backtologin">
                                <p style={{color:'#636363'}}>Go back to
                                    <a href='/login' style={{color:'#FE9900'}}>Login</a>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Reset;