import React, { useState, useEffect } from "react";
import { Button, Dialog, MenuItem } from "@mui/material";
import "./Popup.css";
import DropdownSelect from "../../Components/DropdownSelect/DropdownSelect";
import useAxios from "../../Helper/Client/useAxios";
import { client } from "../../Helper/Client/Client";

const PopupContact = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");
  const [status, setstatus] = useState("");
  const [getcontact, setGetcontact] = useState([]);
  // console.log(status);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.handleClose();
    setOpen(false);
  };

  const [response, isError, isLoading] = useAxios({
    url: `/api/contactus_details/${props.detailid}`,
    method: "get",
  });
  useEffect(() => {
    if (response && response.data.data) {
   
      setGetcontact(response.data.data);
      setstatus(
        response.data.data.status === ""
          ? "no reply"
          : response.data.data.status
      );
    }
   
  }, [response]);
  // console.log("Contact API Get", getcontact);
  function handleSubmit() {
    const body = {
      customer_id: getcontact.customer_id,
      status: status,
      address: getcontact.address,
      message: getcontact.message,
    };
    client
      .put(`/api/contactus_update/${props.detailid}`, body)
      .then((response) => {
        handleClose();
        client.get("/api/contactus_paginated_list").then((response) => {
          props.setpage(1);
          props.setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          );
          props.setContactlist(response.data.results);
        });
      });
  }


  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <div>
      <MenuItem onClick={handleClickOpen}>View Details</MenuItem>
      <Dialog fullWidth open={open} onClose={handleClose} className="dialog">
        <div style={{ height: "796px", padding: "50px" }}>
          <img
            onClick={handleClose}
            src="/Images/OrangeClose.png"
            height="32px"
            style={{
              position: "absolute",
              right: "30px",
              top: "30px",
            }}
          />
          <div style={{ display: "flex", flexDirection: "row", gap: "50px" }}>
            {getcontact.user_image ? (
              <img src={getcontact.user_image} height="74px" />
            ) : (
              <img src="/Images/placeholder.png" height="74px" />
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <h5 style={{ color: "black" }}>{getcontact.customer_name}</h5>
              <span className="span">
                {getcontact.date?.split("T")[0].split("-")[2] +
                  " " +
                  month[
                    Number(getcontact.date?.split("T")[0].split("-")[1]) - 1
                  ] +
                  " " +
                  getcontact.date?.split("T")[0].split("-")[0] +
                  ", " +
                  getcontact.date?.split("T")[1].split(":")[0] +
                  ":" +
                  getcontact.date?.split("T")[1].split(":")[1] +
                  " " +
                  `${
                    Number(getcontact.date?.split("T")[1].split(":")[0]) >= 12
                      ? "PM"
                      : "AM"
                  }`}
              </span>
              <DropdownSelect
                value={status}
                setdropDownValue={setstatus}
                values={[
                  { name: "no reply", value: "no reply" },
                  { name: "replied", value: "replied" },
                ]}
              />
            </div>
          </div>
          <br />
          <p className="p-15">Email:</p>
          <p>{getcontact.email}</p>
          <hr />
          <p className="p-15">Contact No:</p>
          <p>{getcontact.customer_phone}</p>

          <p className="p-15">Comment:</p>
          <p>{getcontact.message}</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: "10px",
            paddingLeft: "20px",
            marginBottom: "20px",
          }}
        >
          <Button
            className="primarybtn"
            onClick={handleSubmit}
            style={{ marginRight: "10px" }}
          >
            Save
          </Button>
          <Button className="secondarybtn" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default PopupContact;
