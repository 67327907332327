import React, { useEffect, useState } from 'react';
import './../HomePageManagement/HomePageManagement.css';
import DropdownSelect from "../../Components/DropdownSelect/DropdownSelect";
import {
  Button,
} from "@mui/material";
import useAxios from '../../Helper/Client/useAxios';
import { useParams } from 'react-router-dom';
import { client } from '../../Helper/Client/Client';
import { history } from '../../Helper/History/history';
import AlertPopup from '../../Components/AlertPopup/AlertPopup';


export default function HomeDetailsVideo() {
  let { id } = useParams();

  const [filelists, setfilelists] = useState([])
  const [vendorname, setVendorname] = useState('')
  const [bannerDetails, setbannerDetails] = useState(null)
  const initialData = { videotitle: "", startdate: "", enddate: "", videolink: '' }
  const [inputs, setinputs] = useState(initialData);
  const [validationError, setvalidationError] = useState(false)
  const [status, setstatus] = useState('')
  const [Cover, setCover] = useState(0)

  const [bannerDetailresponse, bannerDetailisError, bannerDetailisLoading] = useAxios({
    url: `/api/video_promo_details/${id}`,
    method: "get",
    // signal: controller.signal
  });

  useEffect(() => {
    if (bannerDetailresponse && bannerDetailresponse.data.results) {
      setbannerDetails(bannerDetailresponse.data.results)
      setinputs({ videotitle: bannerDetailresponse.data.results.video_title, startdate: bannerDetailresponse.data.results.start_date, enddate: bannerDetailresponse.data.results.end_date, videolink: bannerDetailresponse.data.results.video_link })
      setstatus(bannerDetailresponse.data.results.status)
      setVendorname(bannerDetailresponse.data.results.vendor_id)
    }

    return () => {

    }
  }, [bannerDetailresponse])

  function handleChanged(e) {
    const { name, value } = e.target;
    setinputs((inputs) => ({ ...inputs, [name]: value }));
  }

  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });

  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };

  function handleSubmit() {
    if (inputs.bannertitle === '' || inputs.startdate === '' || inputs.enddate === '' || status === '') {
      setvalidationError(true)
      return
    }
    else {
      setvalidationError(false)
    }
    let form_data = new FormData();
    form_data.append("video_title", inputs.videotitle);
    form_data.append("start_date", inputs.startdate);
    form_data.append("end_date", inputs.enddate);
    form_data.append("status", status ? 1 : 0);
    form_data.append("vendor_id", vendorname);
    form_data.append("video_link", inputs.videolink);
    form_data.append("thumbnail_image", '')
    client.put(`/api/video_promo_update/${id}`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }).then(() => {
      setinputs(initialData)
      setstatus('')
  
      setVendorname('')
      setalertOptions({
        alertType: "success",
        alertOpen: true,
        alertContent: "Submitted Successfully!",
      })

      client.get(`/api/video_promo_details/${id}`).then((response) => {
        setbannerDetails(response.data.results)
        setinputs({ videotitle: response.data.results.video_title, startdate: response.data.results.start_date, enddate: response.data.results.end_date, videolink: response.data.results.video_link })
        setstatus(response.data.results.status)
        setVendorname(response.data.results.vendor_id)
       
      })
    }).catch(() => {
      setalertOptions({
        alertType: "error",
        alertOpen: true,
        alertContent: "Submission Failed!",
      })
    })
  }

  const todayDate = new Date()
  todayDate.setDate(todayDate.getDate() + 1)
  var validenddate = todayDate.toISOString().split('T')[0]
  const validstartdate = new Date().toISOString().split('T')[0]
  if (inputs.startdate !== "") {
    const futuredate = new Date(inputs.startdate)
    futuredate.setDate(futuredate.getDate() + 1)
    validenddate = futuredate.toISOString().split('T')[0]
  }
  if (inputs.startdate > inputs.enddate) {
    inputs.enddate = ''
  }

  return (
    <article>
      <h2>Video</h2>
      <div className="bannerDetails" >
        <button className={bannerDetails?.status ? "bannerDetails-btnG" : "bannerDetails-btnR"}>{bannerDetails?.status ? 'Active' : 'Disable'}</button>
        
        <div style={{ display: "flex", flexDirection: "column", width: '50%' }}>
          <label className="mt20">
            Video Title </label>
          <input
            value={inputs.videotitle}
            type="text"
            name="videotitle"
            onChange={handleChanged}
            className={(validationError && inputs.videotitle === '') ? "validationerror" : undefined}
          />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto",
              gap: "30px",
            }}
          >
            <label className="mt20" style={{ display: "flex", flexDirection: "column" }}>
              Start Date
              <input type="date" id="birthday" name="startdate" value={inputs.startdate?.split('T')[0]} min={validstartdate}
                className={(validationError && inputs.startdate === '') ? "validationerror" : undefined} onChange={handleChanged}></input>
            </label>
            <label className="mt20" style={{ display: "flex", flexDirection: "column" }}>
              End Date
              <input type="date" id="birthday" name="enddate" value={inputs.enddate?.split('T')[0]} min={validenddate}
                className={(validationError && inputs.enddate === '') ? "validationerror" : undefined} onChange={handleChanged}></input>
            </label>
          </div>
          <label className="mt20">
            Status
            <DropdownSelect
              validationError={validationError} value={status} setdropDownValue={setstatus} values={[{ name: 'Active', value: true }, { name: 'Disable', value: false }]} />
          </label>
          <label className="mt20">
            Video Link </label>
          <input
            value={inputs.videolink}
            onChange={handleChanged}
            type='url'
            name="videolink"
            required
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: "30px"
          }}
        >
          <Button className='primarybtn' onClick={handleSubmit} style={{ marginRight: "10px" }}>Save</Button>
          <Button className='secondarybtn' style={{ marginLeft: '10px' }} onClick={() => { history.push('/homepagemanagement') }}>Close</Button>
        </div>
      </div>
      <AlertPopup alertOptions={alertOptions} handleClose={handleClosep} />
    </article>
  )
}
