import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import "./Popup.css";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import DragDrop from "../../Components/DragDrop/DragDrop";
import DropdownSelect from "../../Components/DropdownSelect/DropdownSelect";
import useAxios from "../../Helper/Client/useAxios";
import { client } from "../../Helper/Client/Client";
import AlertPopup from "../../Components/AlertPopup/AlertPopup";
import WysiwygEditor from "../../Components/TextEditor/WysiwygEditor";

const PopupBlog = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");
  const [file, setfile] = useState([]);
  const [filelist, setfilelist] = useState([]);
  const [deleteImage, setdeleteImage] = useState(null);
  const [Cover, setCover] = useState(0);
  const initialData = { title: "", uploaddate: "", uploadtime: "", tag: "" };
  const [inputs, setinputs] = useState(initialData);
  const [validationError, setvalidationError] = useState(false);
  const [texteditor, settexteditor] = useState("");
  const [vendorlist, setVendorlist] = useState([]);
  const [vendorname, setVendorname] = useState("");
  const validstartdate=new Date().toISOString().split('T')[0]

  function handleChanged(e) {
    const { name, value } = e.target;
    setinputs((inputs) => ({ ...inputs, [name]: value }));
  }

  const [month, setMonth] = useState("1");
  // console.log("Selected Month", month);
  useEffect(() => {
    setfilelist(file);
  }, [file]);

  useEffect(() => {
    deleteImage && setfilelist(filelist.filter((item) => item !== deleteImage));
  }, [deleteImage]);

  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });

  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleNext() {
    if (inputs.title === "" || vendorname === ""||inputs.uploaddate===''
    ||inputs.uploadtime==='') {
      setvalidationError(true);
      return;
    } else {
      setvalidationError(false);
    }
    setValue("2");
  }

  const [response, isError, isLoading] = useAxios({
    url: "/api/vendor_list",
    method: "get",

  });
  useEffect(() => {
    if (response && response.data.data) {
      const vendornamelist = response.data.data.map((item) => {
        const updatedItem = {
          ...item,
          value: item.id,
          name: item.email,
        };
        return updatedItem;
      });
      setVendorlist(vendornamelist);
    }
    return () => {
      
    };
  }, [response]);

  function handleSubmit() {
    if (inputs.title === "" || vendorname === ""||filelist.length === 0
        ||inputs.uploaddate===''
        ||inputs.uploadtime==='') {
      console.log("true")
      setvalidationError(true);
      return;
    } else {
      console.log("false")
      setvalidationError(false);
    }
    let form_data = new FormData();
    form_data.append("blog_title", inputs.title);
    inputs.uploaddate && form_data.append("blog_date", inputs.uploaddate);
    inputs.uploadtime && form_data.append("blog_time", inputs.uploadtime);
    form_data.append(
      "vendor_id",
      vendorlist.filter((item) => item.name === vendorname)[0].value
    );
    form_data.append("blog_content", texteditor);
    form_data.append("blog_tag", inputs.tag);

    form_data.append(
      "status",
      (inputs.uploaddate && inputs.uploadtime) === "" ? "Draft" : "Scheduled"
    );
    filelist.length > 0
      ? form_data.append("cover_image", filelist[0])
      : form_data.append("cover_image", "");
    form_data.append("blog_type", month);
    form_data.append(
      "timezone",
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    handleClose();
    client
      .post("/api/blog_create", form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(() => {
        setinputs(initialData);
        setfile([]);
        setfilelist([]);
        setVendorname("");
        setValue("1");
        settexteditor("");
        setalertOptions({
          alertType: "success",
          alertOpen: true,
          alertContent: "Submitted Successfully!",
        });

        client.get("/api/blog_paginated_list").then((response) => {
          props.setpage(1);
          props.setblogList(response.data.results);
          props.setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          );
        });
      })
      .catch(() => {
        setalertOptions({
          alertType: "error",
          alertOpen: true,
          alertContent: "Submission Failed!",
        });
      });
  }
  // console.log("Time", inputs.uploadtime);
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      backgroundColor: "#F5F5F5",
      padding: "15px 26px 10px 12px",
      borderRadius: 8,
      fontFamily: [].join(","),
      "&:focus": {},
    },
  }));
  return (
    <div>
      <button className="exportbtnpluslg" onClick={handleClickOpen}>
        <span
          className="material-icons"
          style={{ color: "white", fontSize: "15px", paddingTop: "4px" }}
        >
          add
        </span>
      </button>
      <Dialog fullWidth open={open} onClose={handleClose} className="dialog" sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
         
          maxWidth: "700px",
        },
      },
    }}>
        <Box>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="CONTENT" value="1" />
                <Tab label="COVER IMAGE" value="2" />
                <img
                  onClick={handleClose}
                  src="/Images/close.png"
                  height="32px"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "8px",
                  }}
                />
              </TabList>
            </Box>
            <TabPanel sx={{ p: 0 }} value="1">
              <DialogTitle>CONTENT</DialogTitle>
              <DialogContent
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label className="mt20">
                  Title
                  <input
                    type="text"
                    name="title"
                    value={inputs.title}
                    onChange={handleChanged}
                  
                    required
                    className={
                      validationError &&
                      inputs.title === "" &&
                      "validationerror"
                    }
                  />
                </label>
                <label className="mt20">
                  Tag
                  <input
                   
                    name="tag"
                    type="text"
                    value={inputs.tag}
                    onChange={handleChanged}
                  />
                </label>
                <label className="mt20">Blog Category</label>

                <FormControl sx={{ minWidth: 120 }}>
                  <Select
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                   
                    displayEmpty
                    input={<BootstrapInput />}
                  >
                    <MenuItem
                      value="1"
                      
                    >
                     郊外活動
                    </MenuItem>
                    <MenuItem
                      value="2"
                     
                    >
                      室內活動
                    </MenuItem>
                    <MenuItem
                      value="3"
                    
                    >
                      食好西
                    </MenuItem>
                  </Select>
                </FormControl>
                <label className="mt20">
                  Content
                  <WysiwygEditor
                    settexteditor={settexteditor}
                    texteditor={texteditor}
                  />
                 
                </label>
                <label className="mt20">
                  Vendor Email
                  <Autocomplete
                    className={
                      validationError && vendorname === "" && "validationerror"
                    }
                    value={vendorname}
                    onChange={(event, newValue) => {
                      setVendorname(newValue);
                    }}
                    options={vendorlist.map((item) => `${item.name}`)}
                    renderInput={(params) => (
                      <div ref={params.InputProps.ref}>
                        <input type="text" {...params.inputProps} />
                      </div>
                    )}
                  />
                </label>

                <label className="mt20">
                  Upload Date and Time
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "150px 200px",
                      gap: 2,
                    }}
                  >
                    <input
                      className={validationError && inputs.uploadtime===''
                      && "validationerror"}
                      type="time"
                      id="birthday"
                      name="uploadtime"
                      value={inputs.uploadtime}
                      onChange={handleChanged}
                    ></input>
                    <input
                      className={validationError && inputs.uploaddate===''
                      && "validationerror"}
                      type="date"
                      id="birthday"
                      name="uploaddate"
                      value={inputs.uploaddate}
                      onChange={handleChanged} min={validstartdate}
                    ></input>
                  </div>
                </label>
              </DialogContent>
              <DialogActions style={{ textAlign: "end", padding: "20px" }}>
                <Button className="primarybtn" onClick={handleNext}>
                  Next Step
                </Button>
              </DialogActions>
            </TabPanel>
            <TabPanel sx={{ p: 0 }} value="2">
              <DialogTitle>Cover image</DialogTitle>
              <DialogContent
                style={{ display: "flex", flexDirection: "column" }}
              >
                {!filelist.length > 0 && (
                  <DragDrop 
                    deleteImage={deleteImage}
                    setfile={setfile}
                    filelist={filelist}
                    validationError={validationError}
                  />
                )}
                {filelist.length > 0 && (
                  <>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "30px 100px 180px 150px 50px",
                        justifyItems: "center",
                        marginTop: "30px",
                      }}
                    >
                   
                    </div>
                    {filelist.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "grid",
                          gridTemplateColumns: "30px 100px 180px 150px  50px",
                          justifyItems: "center",
                          verticalAlign: "center",
                          border: "1px solid #636363",
                          padding: "8px",
                          borderRadius: "5px",
                          marginBottom: "10px",
                        }}
                      >
                    
                        <p></p>
                        <img
                          src={URL.createObjectURL(item)}
                          style={{ margin: "auto" }}
                          height="74px"
                          width="74px"
                        />
                       
                        <p></p>
                        <p></p>
                        <img
                          style={{ margin: "auto" }}
                          src="/Images/Delete.svg"
                          height="16px"
                          onClick={() => setdeleteImage(item)}
                        />
                      </div>
                    ))}
                  </>
                )}
              </DialogContent>
              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "20px",
                }}
              >
                <Button
                  className="secondarybtn"
                  onClick={() => {
                    setValue("1");
                  }}
                >
                  Previous
                </Button>
                <Button className="primarybtn" onClick={handleSubmit}>
                  Submit
                </Button>
              </DialogActions>
            </TabPanel>
          </TabContext>
        </Box>
      </Dialog>
      <AlertPopup alertOptions={alertOptions} handleClose={handleClosep} />
    </div>
  );
};

export default PopupBlog;
