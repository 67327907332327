import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React, { useEffect, useState, useRef } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import TextEditor from "../../Components/TextEditor/TextEditor";
import { TagInput } from "../../Components/Tags/Tags";
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Pagination,
} from "@mui/material";
import DropdownSelect from "../../Components/DropdownSelect/DropdownSelect";
import { useParams } from "react-router-dom";
import useAxios from "../../Helper/Client/useAxios";
import Venuesettingpopup from "../Popup/Venuesettingpopup";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";

import SearchBar from "../../Components/SearchBar/SearchBar";
import MultiSelect from "../../Components/MultiSelect/MultiSelect";
import { client } from "../../Helper/Client/Client";
import AlertPopup from "../../Components/AlertPopup/AlertPopup";
import { styled } from "@mui/system";
import Venuesettingpopupedit from "../Popup/Venuesettingpopupedit";
import { history } from "../../Helper/History/history";
import ReactToPrint from "react-to-print";
import WysiwygEditor from "../../Components/TextEditor/WysiwygEditor";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";

function Venuedetails() {
  let { id } = useParams();
  const ref = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [customerDetail, setcustomerDetail] = useState([]);
  const [value, setValue] = React.useState("1");
  const [filelist, setfilelist] = useState([]);
  const [getroomimagelist, setgetroomimagelist] = useState([]);

  const [venuetype, setvenuetype] = useState("");
  const [featureList, setfeatureList] = useState([]);

  const [areatype, setareatype] = useState("");
  const [areaList, setareaList] = useState([]);

  const [texteditor, settexteditor] = useState("");

  const [edit, setedit] = useState(false);

  const [typeList, settypeList] = useState([]);
  const [tagvalue, settagvalue] = useState([]);

  const [equipmentypeList, setequipmentypeList] = useState([]);
  const [equipmenttagvalue, setequipmenttagvalue] = useState([]);

  const [status, setstatus] = useState(false);
  const [validationError, setvalidationError] = useState(false);

  const [settingsList, setsettingsList] = useState([]);
  const [settingsEdited, setsettingsEdited] = useState(false);

  const [monthlyIncomeList, setmonthlyIncomeList] = useState([]);
  const [paymentList, setpaymentList] = useState([]);

  const [pageCount, setpageCount] = useState(0);
  const [page, setpage] = useState(1);
  const [listlength, setlistlength] = useState(0);
  const [searchvalue, setsearchvalue] = useState("");
  const [roomid, setroomid] = useState();
  const [roomsEdited, setroomsEdited] = useState(false);

  useEffect(() => {
    client
      .get(`/api/transaction_list_by_venue/${id}?search=${searchvalue}`)
      .then((response) => {
        if (response.data.results) {
          setpaymentList(response.data.results);
          setpage(1);
          setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          );
        } else {
          setpaymentList([]);
        }
      });
  }, [searchvalue]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [editbar, setEditbar] = useState("");
  const showEditbar = (event, id) => {
    setAnchorEl(event.currentTarget);
    // console.log(id);
    setEditbar(id);
  };
  const handleClose = () => {
    setEditbar(null);
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl2);
  const handleClickaction = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseaction = () => {
    setAnchorEl2(null);
  };

  const fileChange = (event) => {
    setfilelist(filelist.concat(event?.target.files[0]));
    // console.log("hello");
  };

  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });

  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };

  function CustomPagination() {
    return (
      <Pagination
        className="pagination"
        color="warning"
        count={pageCount}
        page={page}
        onChange={(event, value) => {
          client
            .get(
              `/api/transaction_list_by_venue/${id}?search=${searchvalue}&page=${value}`
            )
            .then((response) => {
              setpaymentList(response.data.results);
            });
          setpage(value);
        }}
        showFirstButton
        showLastButton
      />
    );
  }

  const [RoomImages, setRoomImages] = useState([
    {
      RoomImg: "",
    },
  ]);
  const handleSubmit = () => {
    if (
      inputs.venuename === "" ||
      inputs.venueaddress === "" ||
      inputs.numberofrooms === "" ||
      inputs.tag === "" ||
      areatype === "" ||
      venuetype === "" ||
      status === ""
    ) {
      setvalidationError(true);
      return;
    } else {
      setvalidationError(false);
    }

    const body = {
      title: inputs.venuename,
      description: texteditor,
      capacity: customerDetail.capacity,

      location: inputs.venueaddress,
      district: customerDetail.district,
      venue_area: areatype,
      number_of_rooms: inputs.numberofrooms,
      partition: customerDetail.partition,
      venue_type_id: venuetype,
      offer_tag: inputs.tag,
      feature_list: tagvalue.map((item) => {
        const updatedItem = {
          ...item,
          feature_id: item.id,
          feature: undefined,
          id: undefined,
        };
        return updatedItem;
      }),
      equipment_list: equipmenttagvalue.map((item) => {
        const updatedItem = {
          ...item,
          equipment_id: item.id,
          equipment: undefined,
          id: undefined,
        };
        return updatedItem;
      }),
      status: status,
    };
    client
      .put(`api/venue_update/${id}`, body)
      .then(() => {
        setalertOptions({
          alertType: "success",
          alertOpen: true,
          alertContent: "Submitted Successfully!",
        });
        setedit(false);
        client
          .get(`/api/venue_details/${id}`)
          .then((response) => setcustomerDetail(response.data.data));
      })
      .catch(() => {
        setalertOptions({
          alertType: "error",
          alertOpen: true,
          alertContent: "Submission Failed!",
        });
      });
  };
  const handleSubmitimage = () => {
    let form_data = new FormData();
    form_data.append("room_id", roomid);
    filelist[0] && form_data.append("room_image", filelist[0]);
    client
      .post(`api/venue_room_image/${roomid}`, form_data)
      .then(() => {
        client.get(`/api/venue_details/${id}`).then((response) => {
          setalertOptions({
            alertType: "success",
            alertOpen: true,
            alertContent: "Submitted Successfully!",
          });
          setfilelist([]);

          setRoomImages(response.data.data.room_image_list);
        });
      })
      .catch(() => {
        setalertOptions({
          alertType: "error",
          alertOpen: true,
          alertContent: "Submission Failed!",
        });
      });
  };

  useEffect(() => {
    settexteditor(customerDetail.description);
  }, [customerDetail]);

  var myHTML = texteditor;

  var strippedHtml = myHTML?.replace(/<[^>]+>/g, "");

  function handleCancel() {
    setedit(false);
  }

  const [response, isError, isLoading] = useAxios({
    url: `/api/venue_details/${id}`,
    method: "get",
  });
  const [responseSetting, isErrorSetting, isLoadingSetting] = useAxios({
    url: `/api/settings_list_by_venue/${id}`,
    method: "get",
  });

  useEffect(() => {
    if (response && response.data.data) {
      setcustomerDetail(response.data.data);
      settagvalue(
        response.data.data.feature_list.map((item) => {
          const updatedItem = {
            id: item.feature_id,
            feature: item.feature_name,
          };
          return updatedItem;
        })
      );
      setequipmenttagvalue(
        response.data.data.equipment_list.map((item) => {
          const updatedItem = {
            id: item.equipment_id,
            equipment: item.equipment_name,
          };
          return updatedItem;
        })
      );
      setvenuetype(response.data.data.venue_type_id);
      setareatype(response.data.data.venue_area);
      setstatus(response.data.data.status);
      setsettingsList(response.data.data.tab_list);
    }
  }, [response]);

  // console.log("Customer Details", customerDetail);
  // console.log("Customer Settings", settingsList);

  useEffect(() => {
    if (paymentList?.length !== undefined) {
      setlistlength(paymentList.length);
    }
  }, [paymentList]);

  const [categoryresponse, categoryisError, categoryisLoading] = useAxios({
    url: "/api/venue_type_listview",
    method: "get",
  });
  const [featureresponse, featureisError, featureisLoading] = useAxios({
    url: "/api/venue_features_listview",
    method: "get",
  });

  const [mothlyIncomeresponse, mothlyIncomeisError, mothlyIncomeisLoading] =
    useAxios({
      url: `/api/monthly_venue_income/${id}`,
      method: "get",
    });

  const [equipmentresponse, equipmentisError, equipmentisLoading] = useAxios({
    url: "/api/venue_equipment_list",
    method: "get",
  });

  const [arearesponse, areaisError, areaisLoading] = useAxios({
    url: "/api/venue_area_listview",
    method: "get",
  });

  useEffect(() => {
    if (categoryresponse && categoryresponse.data.results) {
      const alteredcategory = categoryresponse.data.results.map((item) => {
        const updatedItem = {
          ...item,
          value: item.id,
          name: item.type,
        };
        return updatedItem;
      });
      setfeatureList(alteredcategory);
    }

    if (featureresponse && featureresponse.data.data) {
      settypeList(featureresponse.data.data);
    }
    if (mothlyIncomeresponse && mothlyIncomeresponse.data.data) {
      setmonthlyIncomeList(mothlyIncomeresponse.data.data);
    }
    if (equipmentresponse && equipmentresponse.data.data) {
      setequipmentypeList(equipmentresponse.data.data);
    }
    if (arearesponse && arearesponse.data.data) {
      const alteredcategory = arearesponse.data.data.map((item) => {
        const updatedItem = {
          ...item,
          value: item.id,
          name: item.area,
        };

        return updatedItem;
      });
      setareaList(alteredcategory);
    }
    return () => {};
  }, [
    categoryresponse,
    featureresponse,
    mothlyIncomeresponse,
    equipmentresponse,
    arearesponse,
  ]);

  const initialData = {
    venuename: "",
    venuearea: "",
    venueaddress: "",
    tag: "",
    capacity: "",
    numberofrooms: "",
  };
  const [inputs, setinputs] = useState(initialData);

  useEffect(() => {
    setinputs((inputs) => ({
      ...inputs,
      venuename: customerDetail.title,
      venuearea: customerDetail.venue_area,
      venueaddress: customerDetail.location,
      venuecategory: customerDetail.venue_type_name,
      tag: customerDetail.offer_tag,
      capacity: customerDetail.capacity,
      numberofrooms: customerDetail.number_of_rooms,
    }));
  }, [customerDetail]);

  function handleChanged(e) {
    const { name, value } = e.target;
    setinputs((inputs) => ({ ...inputs, [name]: value }));
  }

  const Input = styled("input")({
    display: "none",
  });

  const handleDetailView = (i) => {
    history.push(`/transactiondetail/${i}`);
  };

  const componentRef = useRef([]);

  const columns = [
    {
      field: "id",
      headerName: "TRANSACTION CODE ",
      width: 200,
    },
    {
      field: "customer_name",
      headerName: "CUSTOMER NAME",
      width: 150,
    },
    {
      field: "amount",
      headerName: "PRICE",
      width: 150,
      align: "center",
      headerAlign: "center",
      type: "number",
    },
    {
      field: "date",
      headerName: "DATE",
      align: "center",
      headerAlign: "center",
      type: "date",
      width: 180,
      renderCell: (params) =>
        params.value.split("T")[0].split("-")[2] +
        "." +
        params.value.split("T")[0].split("-")[1] +
        "." +
        params.value.split("T")[0].split("-")[0],
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 160,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            width: "110px",
            padding: "7px",
            backgroundColor: params.value === "paid" ? "#e8f9ef" : "#ffe7e6",
            color: params.value === "paid" ? "#20A144" : "#EB5A52",
          }}
        >
          {"Completed"}{" "}
        </span>
      ),
    },
    {
      field: "ID",
      headerName: "",
      width: 10,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.row.id,
      renderCell: (params) => (
        <>
          <IconButton onClick={(event) => showEditbar(event, params.value)}>
            <img src="../Images/dots.png" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={editbar === params.value ? true : false}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleDetailView(params.value)}>
              Detailed View
            </MenuItem>
          </Menu>
        </>
      ),
    },
  ];

  function CustomToolbar() {
    return (
      <div style={{ position: "absolute", right: "0px", top: "-210px" }}>
        <GridToolbarContainer>
          <GridToolbarExport
            sx={{
              color: "black",
              bgcolor: "#F5F5F5",
              textTransform: "capitalize",
              p: 1.2,
              pr: 3,
            }}
          />
        </GridToolbarContainer>
      </div>
    );
  }

  function handleImageDelete(i) {
    if(RoomImages[0].length!==1){
     client.delete(`/api/venue_roomimage_delete/${i}`).then(() =>
       client.get(`/api/venue_details/${id}`).then((response) => {
         setRoomImages(response.data.data.room_image_list);
         setalertOptions({
           alertType: "success",
           alertOpen: true,
           alertContent: "Submitted Successfully!",
         });
       })
     );
    }else{
     setalertOptions({
       alertType: "error",
       alertOpen: true,
       alertContent: "Submission failed. Image field is mandatory!",
     });
    }
   }

  function handlefiledelete() {
    setfilelist([]);
  }
  function handleSettingDelete(settingid) {
    setsettingsList(settingsList.filter((item) => item.id !== settingid));
    setsettingsEdited(true);
  }

  function handleVenueSettingSubmit() {
    client
      .post(`api/settings_create_by_venue/${id}`, settingsList)
      .then(() => {
        setalertOptions({
          alertType: "success",
          alertOpen: true,
          alertContent: "Submitted Successfully!",
        });
        setsettingsEdited(false);
        client
          .get(`/api/settings_list_by_venue/${id}`)
          .then((response) => setsettingsList(response.data.data || []));
      })
      .catch(() => {
        setalertOptions({
          alertType: "error",
          alertOpen: true,
          alertContent: "Submission Failed!",
        });
      });
  }
  function handleSettingsCancel() {
    client.get(`/api/settings_list_by_venue/${id}`).then((response) => {
      setsettingsEdited(false);
      setsettingsList(response.data.data);
    });
  }

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [roomlist, setRoomlist] = useState([{}]);
  const [cardlist, setCardlist] = useState([{}]);
  const [inputFields, setInputFields] = useState([
    {
      RoomId: "",
      RoomName: "",
      RoomCapacity: "",
      RoomPrice: "",
      ImageList: "",
    },
  ]);

  const [RoomFields, setRoomFields] = useState([
    {
      RoomId: "",
      RoomName: "",
      RoomCapacity: "",
      RoomPrice: "",
      ImageList: "",
    },
  ]);

  useEffect(() => {
    if (customerDetail && customerDetail.room_list) {
      setRoomlist(customerDetail.room_list);
    }
  }, [customerDetail]);
  // console.log("roomlist", roomlist);

  useEffect(() => {
    if (customerDetail && customerDetail.room_image_list) {
      setCardlist(customerDetail.room_image_list);
    }
  }, [customerDetail]);

  // console.log("cardlist", cardlist);

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    // console.log("Event name", event.target.name);
    setInputFields(data);
    setRoomFields(inputFields[index]);
    setroomid(inputFields[index].id);
    // console.log("IP", inputFields.id);
  };

  // console.log("Input Fields", inputFields);
  // console.log("Roomid", roomid);
  // console.log("Room Fields", RoomFields);

  useEffect(() => {
    setInputFields(() =>
      roomlist.map((el) => ({
        ...el,
        id: el.id,
        RoomName: el.room_name,
        RoomCapacity: el.room_capacity,
        RoomPrice: el.room_price,
      }))
    );

    setRoomImages(() => cardlist);
  }, [roomlist, cardlist]);
  // console.log("inputFields : ", inputFields);
  // console.log("Room images1", RoomImages);

  function handleUpdate() {
    const updatebody = {
      rooms: inputFields
        .filter((items) => items.id === roomid)
        .map((item) => ({
          room_name: item.RoomName,
          room_capacity: item.RoomCapacity,
          room_price: item.RoomPrice,
        })),
    };

    // console.log("roooooom", updatebody?.rooms[0]);
    if (updatebody?.rooms[0]?.room_name === "") {
      setvalidationError(true);
      return;
    } else {
      setvalidationError(false);
      client
        .put(`api/venue_room_update/${roomid}`, updatebody)
        .then((response) => {
          // console.log(response);
          setalertOptions({
            alertType: "success",
            alertOpen: true,
            alertContent: "Submitted Successfully!",
          });
        })

        .catch((error) => {
          // console.log(error);
          setalertOptions({
            alertType: "error",
            alertOpen: true,
            alertContent: "Submission Failed!",
          });
        });
    }
  }

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      backgroundColor: "#F5F5F5",
      padding: "15px 26px 10px 12px",
      borderRadius: 8,
      fontFamily: [].join(","),
      "&:focus": {},
    },
  }));
// console.log("Status",status);
  return (
    <article>
      <h4>Venue - {customerDetail.title}</h4>
      <TabContext value={value} style={{ padding: "5px" }}>
        <TabList onChange={handleChange} aria-label="Venue details">
          <Tab label="Details" value="1" />
          <Tab label="Rooms" value="2" />
          <Tab label="Setting" value="3" />
          <Tab label="Transaction Records" value="4" />
          <Tab label="Total Amount" value="5" />
        </TabList>
        <TabPanel value="1">
          <DialogContent>
            <div className="venuedetails-table">
              <table
                style={{
                  width: "100%",
                  borderBottom: "1px solid #e8e8e8",
                  marginBottom: "20px",
                }}
              >
                <thead>
                  <tr>
                    <th>OWNER</th>
                    <th>EMAIL</th>
                    <th>PHONE</th>
                    <th>LOCATION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{customerDetail.owner_name}</td>
                    <td>{customerDetail.email}</td>
                    <td>{customerDetail.phone}</td>
                    <td>{customerDetail.address}</td>
                  </tr>
                </tbody>
              </table>
              {!edit && (
                <Accordion
                  sx={{
                    border: "1px solid rgb(232, 232, 232)",
                    boxShadow: "none",
                    mb: 2,
                    pb: 1,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<img src="../Images/Arrow-Down.svg" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h5>Information</h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "60vw",
                      }}
                    >
                      <p>
                        Venue Name :{" "}
                        <span className="span">{customerDetail.title}</span>
                      </p>
                      <p>
                        Venue Description :{" "}
                        <span className="span">{strippedHtml} </span>
                      </p>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "28vw 20vw 10vw",
                          gap: 20,
                        }}
                      >
                        <p>
                          Venue Area :{" "}
                          <span className="span">
                            {customerDetail.venue_area_name}
                          </span>
                        </p>
                        <p>
                          Venue Category :{" "}
                          <span className="span">
                            {customerDetail.venue_type_name}
                          </span>
                        </p>

                        <p>
                          {" "}
                          Status :{" "}
                          <span className="span">
                            {customerDetail.status ? "Active" : "Blocked"}
                          </span>
                        </p>
                      </div>
                      <p>
                        Venue Address :{" "}
                        <span className="span"> {customerDetail.location}</span>
                      </p>
                      <p>
                        Venue equipments :{" "}
                        {customerDetail.equipment_list?.map((item) => (
                          <span key={item.id}>#{item.equipment_name}</span>
                        ))}
                      </p>
                      <p>
                        Venue tags :{" "}
                        {customerDetail.feature_list?.map((item) => (
                          <span key={item.id}>#{item.feature_name} </span>
                        ))}
                      </p>
                    </div>
                    <img
                      src="/Images/edit.svg"
                      style={{ float: "right" }}
                      onClick={() => setedit(true)}
                    />
                  </AccordionDetails>
                </Accordion>
              )}
            </div>
            {edit && (
              <div
                className="venue-tab-details"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "15px",
                }}
              >
                <h5>Information</h5>
                <label className="mt20">
                  Venue Name
                  <input
                    className={
                      validationError && inputs.venuename === ""
                        ? "validationerror mt10"
                        : "mt10"
                    }
                    
                    style={{ width: "100%" }}
                    type="text"
                    required
                    name="venuename"
                    value={inputs.venuename}
                    onChange={handleChanged}
                  />
                </label>

                <label className="mt20">
                  {" "}
                  Venue Description
                  {texteditor !== null && (
                    <WysiwygEditor
                      settexteditor={settexteditor}
                      texteditor={texteditor}
                    />
                  )}
                </label>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "220px 220px 200px 200px",
                    gridGap: "15px",
                  }}
                >
                  <label className="mt20" style={{ marginRight: "10px" }}>
                    Venue area
                    <DropdownSelect
                      value={areatype}
                      setdropDownValue={setareatype}
                      values={areaList}
                    />{" "}
                  </label>
                  <label
                    className="mt20"
                    style={{
                      width: "210px",
                    }}
                  >
                    Venue Category
                    <DropdownSelect
                      value={venuetype}
                      setdropDownValue={setvenuetype}
                      values={featureList}
                    />
                  </label>

                  <label
                    className="mt20"
                    style={{ marginRight: "20px", width: "230px" }}
                  >
                    Number of rooms
                    <input
                      className={
                        validationError && inputs.numberofrooms === ""
                          ? "validationerror"
                          : undefined
                      }
                      name="numberofrooms"
                      type="text"
                      required
                      readOnly
                      value={inputs.numberofrooms}
                      size="11"
                    />{" "}
                  </label>
                  <label className="mt20">
                    Tag
                    <br />
                    <input
                      className={
                        validationError && inputs.tag === ""
                          ? "validationerror"
                          : undefined
                      }
                      name="tag"
                      type="text"
                      value={inputs.tag}
                      onChange={handleChanged}
                      size="12"
                    />
                  </label>
                </div>

                <label className="mt20">
                  Venue Address
                  <input
                    className={
                      validationError && inputs.venueaddress === ""
                        ? "validationerror mt10"
                        : "mt10"
                    }
                    name="venueaddress"
                    style={{ width: "100%" }}
                    type="text"
                    required
                    value={inputs.venueaddress}
                    onChange={handleChanged}
                  />
                </label>
                <label className="mt20">
                  Venue Equipment
                  <Autocomplete
                    className="multiselect"
                    multiple
                    style={{
                      backgroundColor: "#f5f5f5",
                      borderRadius: "8px",
                    }}
                    options={equipmentypeList}
                    value={equipmenttagvalue}
                    onChange={(event, newValue) => {
                      setequipmenttagvalue(newValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.equipment === value.equipment
                    }
                    getOptionLabel={(option) => option.equipment}
                    renderInput={(params) => (
                      // <TextField {...params} variant="standard" />
                      <TextField
                      sx={{
                        "& fieldset": { border: 'none' },
                      }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableunderline: "true",
                        }}
                      />
                    )}
                  />
                </label>
                <label className="mt20">
                  Venue Tags
                  <Autocomplete
                    className="multiselect"
                    multiple
                    style={{
                      backgroundColor: "#f5f5f5",
                     
                      borderRadius: "8px",
                    }}
                    options={typeList}
                    value={tagvalue}
                    onChange={(event, newValue) => {
                      settagvalue(newValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.feature === value.feature
                    }
                    getOptionLabel={(option) => option.feature}
                    renderInput={(params) => (
                      <TextField
                      sx={{
                        "& fieldset": { border: 'none' },
                      }}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableunderline: "true",
                        }}
                      />
                    )}
                  />
                </label>
                <label className="mt20">
                  Status
                  <DropdownSelect
                    value={status}
                    setdropDownValue={setstatus}
                    values={[
                      { name: "Active", value: true },
                      { name: "Blocked", value: false },
                    ]}
                  />
                </label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "10px",
                  }}
                >
                  <Button
                    className="primarybtn"
                    onClick={handleSubmit}
                    style={{ marginRight: "10px" }}
                  >
                    Save
                  </Button>
                  <Button className="secondarybtn" onClick={handleCancel}>
                    Cancel
                  </Button>
                </div>
              </div>
            )}
          </DialogContent>
        </TabPanel>
        <TabPanel value="2">
          {inputFields.map((item, i) => (
            <div key={item.id}
              style={{
                border: "dotted",
                padding: "10px",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto auto",
                }}
              >
                <label
                  className="mt20"
                  style={{
                    marginRight: "130px",
                    marginLeft: "30px",
                  }}
                >
                  Room Name
                  <input
                    className={
                      validationError &&
                      inputFields[i]?.RoomName === "" &&
                      "validationerror"
                    }
                    style={{ width: "100%" }}
                    type="text"
                    name="RoomName"
                    value={inputFields[i]?.RoomName}
                    onChange={(event) => handleFormChange(i, event)}
                    required
                  />
                </label>
                <label className="mt20">
                  Capacity
                  <br />
                  <FormControl sx={{ width: 200 }}>
                    <Select
                      name="RoomCapacity"
                      value={inputFields[i].RoomCapacity}
                      onChange={(event) => handleFormChange(i, event)}
                      displayEmpty
                      input={<BootstrapInput />}
                    >
                      <MenuItem value="3">1-3人</MenuItem>
                      <MenuItem value="6">3-6人</MenuItem>
                      <MenuItem value="10">6-10人</MenuItem>
                      <MenuItem value="15">10-15人</MenuItem>
                      <MenuItem value="20">15-20人</MenuItem>
                      <MenuItem value="30">20人或以上</MenuItem>
                    </Select>
                  </FormControl>
                </label>
                <label
                  className="mt20"
                  style={{
                    marginLeft: "130px",
                  }}
                >
                  Price
                  <br />
                  <FormControl sx={{ minWidth: 150 }}>
                    <Select
                      name="RoomPrice"
                      value={inputFields[i].RoomPrice}
                      onChange={(event) => handleFormChange(i, event)}
                      displayEmpty
                      input={<BootstrapInput />}
                    >
                      <MenuItem value="60">人均$60以下</MenuItem>
                      <MenuItem value="80">人均$80以下</MenuItem>
                      <MenuItem value="100">人均$100以下</MenuItem>
                      <MenuItem value="120">人均$120以下</MenuItem>
                      <MenuItem value="140">人均$140以下</MenuItem>
                      <MenuItem value="180">人均$180以下</MenuItem>
                      <MenuItem value="200">人均$200或以上</MenuItem>
                    </Select>
                  </FormControl>
                </label>
              </div>
              <DialogContent>
                <h5>
                  Images <span className="span">(maximum 8 photos)</span>
                </h5>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "175px 175px 175px 175px",
                    gap: 20,
                    justifyContent: "space-between",
                  }}
                >
                  {!filelist[0] && !RoomImages[i][7] ? (
                    <div className="venuedetails-image">
                      <div
                        style={{
                          height: "175px",
                          width: "175px",
                          borderRadius: "7px",
                          backgroundColor: "gray",
                        }}
                      />
                      <div style={{ opacity: 1 }}>
                        <div
                          style={{
                            display: "block",
                            position: "absolute",
                            top: "40px",
                            left: "40px",
                            textAlign: "center",
                            borderRadius: "8px",
                          }}
                        >
                          <label htmlFor="icon-button-file">
                            <Input
                              accept="image/png, image/jpeg"
                              id="icon-button-file"
                              type="file"
                              onChange={fileChange}
                            />
                            <IconButton
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                              onClick={() => setroomid(item.id)}
                            >
                              <img
                                src="../Images/AddImage.svg"
                                // onClick={() => setroomid(item.id)}
                              />
                            </IconButton>
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    !RoomImages[i][7] &&
                    roomid === item.id && (
                      <div className="venuedetails-image">
                        <img
                          src={URL.createObjectURL(filelist[0])}
                          style={{
                            height: "175px",
                            width: "175px",
                            borderRadius: "7px",
                          }}
                        />
                      </div>
                    )
                  )}
                  {RoomImages[i][0] && (
                    <div className="venuedetails-image">
                      <img
                        src={RoomImages[i][0].room_image}
                        style={{
                          height: "175px",
                          width: "175px",
                          borderRadius: "7px",
                        }}
                      />
                      <div className="overlay">
                        <div className="venue-imagedelete">
                          <img
                            src="../Images/Delete.svg"
                            onClick={() =>
                              handleImageDelete(RoomImages[i][0].id)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {RoomImages[i][1] && (
                    <div className="venuedetails-image">
                      <img
                        src={RoomImages[i][1].room_image}
                        style={{
                          height: "175px",
                          width: "175px",
                          borderRadius: "7px",
                        }}
                      />
                      <div className="overlay">
                        <div className="venue-imagedelete">
                          <img
                            src="../Images/Delete.svg"
                            onClick={() =>
                              handleImageDelete(RoomImages[i][1].id)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {RoomImages[i][2] && (
                    <div className="venuedetails-image">
                      <img
                        src={RoomImages[i][2].room_image}
                        style={{
                          height: "175px",
                          width: "175px",
                          borderRadius: "7px",
                        }}
                      />
                      <div className="overlay">
                        <div className="venue-imagedelete">
                          <img
                            src="../Images/Delete.svg"
                            onClick={() =>
                              handleImageDelete(RoomImages[i][2].id)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {RoomImages[i][3] && (
                    <div className="venuedetails-image">
                      <img
                        src={RoomImages[i][3].room_image}
                        style={{
                          height: "175px",
                          width: "175px",
                          borderRadius: "7px",
                        }}
                      />
                      <div className="overlay">
                        <div className="venue-imagedelete">
                          <img
                            src="../Images/Delete.svg"
                            onClick={() =>
                              handleImageDelete(RoomImages[i][3].id)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {RoomImages[i][4] && (
                    <div className="venuedetails-image">
                      <img
                        src={RoomImages[i][4].room_image}
                        style={{
                          height: "175px",
                          width: "175px",
                          borderRadius: "7px",
                        }}
                      />
                      <div className="overlay">
                        <div className="venue-imagedelete">
                          <img
                            src="../Images/Delete.svg"
                            onClick={() =>
                              handleImageDelete(RoomImages[i][4].id)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {RoomImages[i][5] && (
                    <div className="venuedetails-image">
                      <img
                        src={RoomImages[i][5].room_image}
                        style={{
                          height: "175px",
                          width: "175px",
                          borderRadius: "7px",
                        }}
                      />
                      <div className="overlay">
                        <div className="venue-imagedelete">
                          <img
                            src="../Images/Delete.svg"
                            onClick={() =>
                              handleImageDelete(RoomImages[i][5].id)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {RoomImages[i][6] && (
                    <div className="venuedetails-image">
                      <img
                        src={RoomImages[i][6].room_image}
                        style={{
                          height: "175px",
                          width: "175px",
                          borderRadius: "7px",
                        }}
                      />
                      <div className="overlay">
                        <div className="venue-imagedelete">
                          <img
                            src="../Images/Delete.svg"
                            onClick={() =>
                              handleImageDelete(RoomImages[i][6].id)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {RoomImages[i][7] && (
                    <div className="venuedetails-image">
                      <img
                        src={RoomImages[i][7].room_image}
                        style={{
                          height: "175px",
                          width: "175px",
                          borderRadius: "7px",
                        }}
                      />
                      <div className="overlay">
                        <div className="venue-imagedelete">
                          <img
                            src="../Images/Delete.svg"
                            onClick={() =>
                              handleImageDelete(RoomImages[i][7].id)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {RoomImages[i]?.length == 0 && (
                    <div className="venuedetails-image">
                      <img
                        src={"../Images/venueplaceholder.png"}
                        style={{
                          height: "175px",
                          width: "175px",
                          borderRadius: "7px",
                        }}
                      />
                      <div className="overlay" style={{ visibility: "hidden" }}>
                        <div className="venue-imagedelete">
                          <img
                            src="../Images/Delete.svg"
                            onClick={() =>
                              handleImageDelete(RoomImages[i][1].id)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </DialogContent>
              {filelist[0] && roomid === item.id && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "10px",
                    paddingLeft: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  <Button
                    className="primarybtn"
                    onClick={handleSubmitimage}
                    style={{ marginRight: "10px" }}
                  >
                    Save
                  </Button>
                  <Button className="secondarybtn" onClick={handlefiledelete}>
                    Cancel
                  </Button>
                </div>
              )}
              <Button
                className="primarybtn"
                onClick={handleUpdate}
                style={{ marginRight: "10px" }}
              >
                Save
              </Button>
              <Button className="secondarybtn" onClick={handlefiledelete}>
                Cancel
              </Button>
            </div>
          ))}
        </TabPanel>
        <TabPanel
          value="3"
          style={{
            padding: "0px",
            padding: "15px",
            border: "1px solid #e8e8e8",
          }}
        >
          <div style={{ color: "#3B3B3B" }}>
            <h5>Setting</h5>
            {settingsList.length > 0 &&
              settingsList.map((item) => (
                <div key={item.id} className="setting_section" style={{ width: "70%" }}>
                  <p>{item.tab_title}</p>
                  <ul>
                    <li>
                      <Venuesettingpopupedit
                        setsettingsEdited={setsettingsEdited}
                        setsettingsList={setsettingsList}
                        settingsList={settingsList}
                        item={item}
                      />
                    </li>
                    <li>
                      <img
                        src="../Images/Delete.svg"
                        height="16px"
                        onClick={() => handleSettingDelete(item.id)}
                        className="pointer"
                      />
                    </li>
                  </ul>
                </div>
              ))}
            <Venuesettingpopup
              setsettingsEdited={setsettingsEdited}
              setsettingsList={setsettingsList}
              settingsList={settingsList}
            />
            {settingsEdited && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "20px",
                }}
              >
                <Button
                  className="primarybtn"
                  onClick={handleVenueSettingSubmit}
                  style={{ marginRight: "10px" }}
                >
                  Save
                </Button>
                <Button className="secondarybtn" onClick={handleSettingsCancel}>
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </TabPanel>
        <TabPanel value="4">
          <div
            style={{
              width: "100%",
              paddingTop: "24px",
              borderRadius: "8px",
              border: "1px solid #E8E8E8",
            }}
          >
            <div className="mid-searchbar">
              <SearchBar
                searchvalue={searchvalue}
                setsearchvalue={setsearchvalue}
              />
              <Button
                onClick={handleClickaction}
                className="tertiarybtn"
                endIcon={<img src="../Images/Arrow-Down.svg" />}
              >
                Actions
              </Button>
              <Menu
                anchorEl={anchorEl2}
                open={open}
                onClose={handleCloseaction}
              >
                <MenuItem onClick={handleCloseaction}>Edit Item</MenuItem>
                <MenuItem onClick={handleCloseaction}>Delete Item</MenuItem>
              </Menu>
            </div>
            <div style={{ height: "650px" }}>
              <DataGrid
                sx={{ mt: 2 }}
                rows={paymentList}
                columns={columns}
                pageSize={listlength}
                rowsPerPageOptions={[listlength]}
                getRowId={(row) => row.id}
                checkboxSelection
                pagination
                keepNonExistentRowsSelected
                components={{
                  Pagination: CustomPagination,
                  Toolbar: CustomToolbar,
                }}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value="5">
          {monthlyIncomeList.map((item, i) => (
            <Accordion 
              sx={{
                border: "1px solid rgb(232, 232, 232)",
                boxShadow: "none",
                pb: 1,
                mb: 1,
              }}
              ref={(el) => (componentRef.current[i] = el)}
              
            >
              <AccordionSummary
                expandIcon={<img src="../Images/Arrow-Down.svg" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h5>
                  Total Amount - {month[Number(item.date?.split("/")[1]) - 1]}{" "}
                  2022
                </h5>
              </AccordionSummary>
              <AccordionDetails>
                <div  key={item.id}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto auto",
                  }}
                >
                  <p className="accordion_p">
                    Income: <span className="span">{item.income}</span>
                  </p>
                  <p className="accordion_p"></p>
                  <p className="accordion_p">
                    Total: <span className="span">{item.total}</span>
                  </p>
                  <span className="accordion_p">
                    Commision
                    <br />{" "}
                    <DropdownSelect
                      value={item.commission * 100}
                      setdropDownValue={item.commission * 100}
                      values={[
                        {
                          name: `${item.commission * 100}%`,
                          value: item.commission * 100,
                        },
                      ]}
                    />
                  </span>
                  <div
                    style={{
                      textAlign: "center",
                      margin: "10px",
                    }}
                  >
                    <ReactToPrint
                      trigger={() => (
                        <img src="../Images/print.svg" className="print_btn" />
                      )}
                      content={() => componentRef.current[i]}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </TabPanel>
      </TabContext>
      <AlertPopup alertOptions={alertOptions} handleClose={handleClosep} />
    </article>
  );
}
export default Venuedetails;
