import React, { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import { Button, Pagination } from "@mui/material";
import SearchBar from "../../Components/SearchBar/SearchBar";
import { history } from "../../Helper/History/history";
import { client } from "../../Helper/Client/Client";
import PopupDelete from "../Popup/PopupDelete";
import PopupDeleteAll from "../Popup/PopupDeleteAll";
import AddCategory from "../Popup/AddCategory";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Papa from "papaparse";

function Categories() {
  const [categoriesList, setcategoriesList] = useState([]);
  const [searchvalue, setsearchvalue] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [page, setpage] = useState(1);
  const [listlength, setlistlength] = useState(0);

  useEffect(() => {
    client
      .get(`/api/venue_category_list_search?search=${searchvalue}`)
      .then((response) => {
        if (response.data.results) {
          setcategoriesList(response.data.results);
          setpage(1);
          setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          );
        } else {
          setcategoriesList([]);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401 || 403) {
          history.push("/login");
        }
      });
  }, [searchvalue]);

  function CustomPagination() {
    return (
      <Pagination
        className="pagination"
        color="warning"
        count={pageCount}
        page={page}
        onChange={(event, value) => {
          searchvalue === ""
            ? client
                .get(`/api/venue_type_listview?page=${value}`)
                .then((response) => {
                  setcategoriesList(response.data.results);
                })
            : client
                .get(
                  `/api/venue_category_list_search?page=${value}&search=${searchvalue}`
                )
                .then((response) => {
                  setcategoriesList(response.data.results);
                });
          setpage(value);
        }}
        showFirstButton
        showLastButton
      />
    );
  }

  useEffect(() => {
    if (categoriesList?.length !== undefined) {
      setlistlength(categoriesList.length);
    }
  }, [categoriesList]);

  // tab property
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // table view(edit/delete)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [editbar, setEditbar] = useState("");
  const showEditbar = (event, id) => {
    setAnchorEl(event.currentTarget);
    setEditbar(id);
  };
  const handleClose = () => {
    setEditbar(null);
  };
  function handledownload(data) {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    saveAs(blob, 'Pickmestudio.xlsx');
  
    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }
  }
  // top actions
  // const [action, setAction] = React.useState(null);
  const open = Boolean(anchorEl2);
  const handleClickaction = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseaction = () => {
    setAnchorEl2(null);
  };

  const handlebulkDelete = () => {
    setAnchorEl2(null);
    client
      .delete(`/api/venue_category_bulk_delete`, {
        data: { venue_category_id: selectionModel },
      })
      .then(() => {
        setAnchorEl2(null);
        client.get("/api/venue_type_listview").then((response) => {
          if (response.data.results) {
            setpage(1);
            setcategoriesList(response.data.results);
            setpageCount(
              Math.ceil(response.data.count / response.data.results.length)
            );
          } else {
            setpage(0);
            setpageCount(0);
            setcategoriesList([]);
          }
        });
      });
  };

  const handleEdit = (i) => {
    history.push(`/categorydetails/${i}`);
  };

  function handleDelete(i) {
    client.delete(`/api/venue_category_delete/${i}`).then(() => {
      handleClose();
      client.get("/api/venue_type_listview").then((response) => {
        if (response.data.results) {
          setpage(1);
          setcategoriesList(response.data.results);
          setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          );
        } else {
          setpage(0);
          setpageCount(0);
          setcategoriesList([]);
        }
      });
    });
  }

  function getCategoryDetails(params) {
    const categoryname = params.row.type;
    // const categoryid = params.row.id;

    return categoryname 
    // + "+" + categoryid
    ;
  }

  const columns = [
    // {
    //   field: 'id',
    //   headerName: '#',
    //   align: "center",
    //   headerAlign: "center",
    //   width: 150,
    //   // valueGetter: getCityCountry,
    // },
    {
      field: "type",
      headerName: "CATEGORY NAME",
      // align: "center",
      // headerAlign: "center",
      width: 250,
      valueGetter: getCategoryDetails,
      renderCell: (params) => (
        <>
          {/* {params.value.split("+")[4] !== "undefined" ? (
            <img
              src={params.value.split("+")[4]}
              height="30px"
              width="30px"
              alt=""
            />
          ) : (
            <img
              src="/Images/placeholder.png"
              height="30px"
              width="30px"
              alt="profile placholder"
            />
          )} */}
          <div style={{ marginLeft: "10px" }}>
            <b style={{ color: "rgb(53, 53, 53)" }}>
              {params.value.split("+")[0]}
              {/* {params.value.split("+")[1]} */}
            </b>
            {/* <small style={{ color: "grey", display: "block" }}>
              {params.value.split("+")[2]}
            </small> */}
          </div>
        </>
      ),
    },
    {
      field: "update_date",
      headerName: "UPDATE DATE",
      align: "center",
      headerAlign: "center",
      type: "date",
      width: 150,
      renderCell: (params) =>
        params.value.split("T")[0].split("-")[2] +
        "." +
        params.value.split("T")[0].split("-")[1] +
        "." +
        params.value.split("T")[0].split("-")[0],
    },
    {
      field: "status",
      headerName: "STATUS",
      headerAlign: "center",
      align: "center",
      width: 120,
      valueGetter: (params) => (params.row.status ? "Active" : "Blocked"),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            width: "100px",
            padding: "7px",
            backgroundColor: params.value === "Active" ? "#e8f9ef" : "#ffe7e6",
            color: params.value === "Active" ? "#20A144" : "#EB5A52",
          }}
        >
          {params.value}{" "}
        </span>
      ),
    },
    {
      field: "id",
      type: "actions",
      width: 50,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <IconButton onClick={(event) => showEditbar(event, params.value)}>
            <img src="../Images/dots.png" alt="action" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={editbar === params.value ? true : false}
            onClose={handleClose}
          >
            {/* <PopupProductDetail /> */}
            {/* 1<PopupProductDetail detailid={params.value} handleClose={handleClose} setpage={setpage} setpageCount={setpageCount} setcategoriesList={setcategoriesList} /> */}
            {/* <MenuItem onClick={handleClose}
          onClick={() => handleEdit(params.value)}
          >
            Edit Item
          </MenuItem> */}
            <MenuItem onClick={() => handleEdit(params.value)}>
              Edit Item
            </MenuItem>
            <PopupDelete
              onClick={handleClose}
              delete={() => handleDelete(params.value)}
            />
          </Menu>
        </>
      ),
    },
  ];

  // const columns = [
  //   {
  //     field: 'categoryid',
  //     headerName: '#',
  //     width: 200,
  //     // align: "center",
  //     // headerAlign: "center",
  //   },
  //   {
  //     field: 'categoryname',
  //     headerName: 'Category Name',
  //     width: 250,
  //     // align: "center",
  //     // headerAlign: "center",
  //   },
  //   {
  //     field: "id",
  //     headerName: "",
  //     width: 200,
  //     // align: "center",
  //     // headerAlign: "center",
  //     renderCell: (params) => (
  //       <>
  //       <IconButton onClick={(event)=>showEditbar(event,params.value)}>
  //         <img src="../Images/dots.png" />
  //       </IconButton>
  //       <Menu anchorEl={anchorEl}
  //       open={editbar === params.value ? true :false}
  //       onClose={handleClose}>
  //         <MenuItem onClick={handleClose}>Edit Item</MenuItem>
  //         <MenuItem onClick={handleClose}>Delete Item</MenuItem>
  //     </Menu>
  //     </>
  //     ),
  //   },
  // ];
  function CustomExportButton({ onClick }) {
    return (
      <button onClick={onClick}>Download Data</button>

      
    );
  }
  function CustomToolbar() {
    const [csv, setCsv] = useState(false);
    const handleExport = (data) => {
      const csvData = Papa.unparse(data, {
        quotes: true,
        delimiter: ",",
        encoding: "utf-8", // Specify UTF-8 encoding
      });
  
      // Create a Blob with UTF-8 encoding
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvData], {
        type: "text/csv;charset=utf-8",
      });
  
      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Pick Me Studio.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };

    const handleExportCSV = () => {
      const csvData = categoriesList.map((item) => ({
        "Category Name": item.type,
        "Update Date": item.update_date.split("T")[0].split("-").reverse().join("."),
        "Status": item.status ? "Active" : "Blocked",
    }));
  
      const csv = Papa.unparse(csvData, {
        quotes: true,
        delimiter: ',',
        encoding: 'utf-8',
      });
  
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], {
        type: 'text/csv;charset=utf-8',
      });
  
      saveAs(blob, 'Pick Me Studio.csv');
    };
    const handleButtonClick = (data) => {
      // Replace with your actual data
      
  
      handledownload(data);
    };

    const handleCustomCsvDownload = () => {
      // Add your custom CSV download logic here
      console.log("Custom CSV Download");
    };
  
    
  
    const CustomExportButtons = () => (
      <>
        <CustomExportButton onClick={handleCustomCsvDownload} />

      </>
    );
    return (
      <div style={{ position: "absolute", right: "30px", top: "-210px" }}>
        <GridToolbarContainer>
        <div style={{position:"relative"}} 
          onMouseEnter={()=>{
            console.log("its onMouseEnter");
            setCsv(true);
          }}
          onMouseLeave={()=>{
            console.log("its onMouseLeave");
            setCsv(false);
          }}
          >
          <button onClick={() => handleExportCSV()}
          style={{
            position: "absolute",
            inset: "0px 0px auto auto",
            margin: "0px",
            transform: "translate3d(4px, 47.5px, 0px)",
            width: '170px',
            height: "37px",
            // visibility: "hidden",
            zIndex:1500,
            opacity:0,
            display:csv?"":"none",
            // cursor:"default"
         
          }}
          >Download CSV</button> 
          {/* <button onClick={()=>{window.print();}}>Print</button> */}
          {/* <PrintButton targetId="componentToPrint" /> */}
        
          <GridToolbarExport
        onItemClick={() => handleExportCSV()}
        endIcon={<img src="../Images/Arrow-Down.svg" />}
        sx={{
          color: "black",
          bgcolor: "#F5F5F5",
          textTransform: "capitalize",
          p: 1.2,
          pr: 3,
        }}
        options={{
          bookSST: true,
          bookType: "xlsx",
          mimeType:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
          
        }}
      />
      </div>
          {/* <GridToolbarExport
            sx={{
              color: "black",
              bgcolor: "#F5F5F5",
              textTransform: "capitalize",
              p: 1.2,
              pr: 3,
            }}
          /> */}
        </GridToolbarContainer>
      </div>
    );
  }

  const rows = [
    {
      id: 1,
      categorycode: "1",
      categoryname: "Party Room",
      date: "14.12.21",
      status: "Active",
    },
    {
      id: 2,
      categorycode: "2",
      categoryname: "Mahjong Room",
      date: "14.12.21",
      status: "Active",
    },
    {
      id: 3,
      categorycode: "3",
      categoryname: "Workshop",
      date: "14.12.21",
      status: "Active",
    },
    {
      id: 4,
      categorycode: "4",
      categoryname: "Food Catering",
      date: "14.12.21",
      status: "Active",
    },
    {
      id: 5,
      categorycode: "5",
      categoryname: "Featured Itinerary",
      date: "14.12.21",
      status: "Active",
    },
    {
      id: 6,
      categorycode: "6",
      categoryname: "Place for Couples",
      date: "14.12.21",
      status: "Active",
    },
    {
      id: 7,
      categorycode: "7",
      categoryname: "Others",
      date: "14.12.21",
      status: "Active",
    },
    {
      id: 8,
      categorycode: "8",
      categoryname: "Others",
      date: "14.12.21",
      status: "Active",
    },
    {
      id: 9,
      categorycode: "9",
      categoryname: "Others",
      date: "14.12.21",
      status: "Active",
    },
    {
      id: 10,
      categorycode: "10",
      categoryname: "Others",
      date: "14.12.21",
      status: "Active",
    },
  ];
// console.log("Cat Respo",categoriesList);
  return (
    <article>
      <div className="buttondiv">
        {/* <Button className='tertiarybtn' startIcon={<img src="../Images/download.svg" />} endIcon={<img src="../Images/Arrow-Down.svg" />}>Export</Button> */}
        <AddCategory setcategoriesList={setcategoriesList}
          setpage={setpage}
          setpageCount={setpageCount}/>
      </div>
      <h4>Categories</h4>
      <TabContext value={value}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab label="All" value="1" />
        </TabList>
        <TabPanel value="1">
          <div
            style={{
              width: "100%",
              paddingTop: "24px",
              borderRadius: "8px",
              border: "1px solid #E8E8E8",
            }}
          >
            <div className="mid-searchbar">
              <SearchBar
                searchvalue={searchvalue}
                setsearchvalue={setsearchvalue}
              />
              <Button
                onClick={handleClickaction}
                className="tertiarybtn"
                endIcon={<img src="../Images/Arrow-Down.svg" />}
              >
                Actions
              </Button>
              {/* <Menu anchorEl={anchorEl2}
                open={open}
                onClose={handleCloseaction}>
                <MenuItem onClick={handleCloseaction}>Edit Item</MenuItem>
                <MenuItem onClick={handleCloseaction}>Delete Item</MenuItem>
              </Menu> */}
              <Menu
                anchorEl={anchorEl2}
                open={open}
                onClose={handleCloseaction}
              >
                <PopupDeleteAll delete={handlebulkDelete} />
                {/* <MenuItem onClick={handleCloseaction}>Edit Item</MenuItem> */}
                {/*  <MenuItem onClick={handleCloseaction}>Delete Item</MenuItem> */}
              </Menu>
            </div>
            <div style={{ height: "650px" }}>
              <DataGrid
                sx={{ mt: 2 }}
                rows={categoriesList}
                columns={columns}
                pageSize={listlength}
                rowsPerPageOptions={[listlength]}
                getRowId={(row) => row.id}
                checkboxSelection
                pagination
                // paginationMode="server"
                keepNonExistentRowsSelected
                components={{
                  Pagination: CustomPagination,
                  Toolbar: CustomToolbar,
                }}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
              />
            </div>
          </div>
        </TabPanel>
      </TabContext>
    </article>
  );
}

export default Categories;
