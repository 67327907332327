import React,{useState} from 'react'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TextEditor from '../../Components/TextEditor/TextEditor';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import "./Popup.css";
import { client } from '../../Helper/Client/Client';
import AlertPopup from '../../Components/AlertPopup/AlertPopup';

export default function Productdetailspopup(props) {
    const [open, setOpen] = useState(false);
    const [value, setValue] = React.useState("1");
    const [texteditor, settexteditor] = useState('')
    const [inputs, setinputs] = useState({title:''})
    const [validationError, setvalidationError] = useState(false)

    function handleChanged(e) {
      const { name, value } = e.target;
      setinputs((inputs) => ({ ...inputs, [name]: value }));
    }
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleSubmit=()=>{
      if (inputs.title === '') {
        setvalidationError(true)
        return
      }
      else {
        setvalidationError(false)
      }
      props.setdetailsList([...props.detailsList, { 'id': Date.now(), "title": inputs.title, 'description': texteditor }])
      props.setdetailsEdited(true)
      handleClose()
      setinputs({title:''})
      settexteditor('')
    }

  return (
    <div>
      <button className="add-venuedetails" onClick={handleClickOpen}><span className='span'>
        <img src="../Images/plus.svg" style={{paddingRight:'10px'}} /></span>
        Add</button>
      <Dialog  open={open} onClose={handleClose} className='settingsdialog'>
        <TabContext value={value} style={{padding:'5px'}}>
            <TabList onChange={handleChange} aria-label="Venue details">
         
                <Tab label="Details" value="1" />
            </TabList>
            
            <TabPanel value="1" className="setting-addnew">
              <h4>
              Details
              </h4>
              <label style={{ color: 'grey', marginBlockEnd: '20px',width:'100%'}}>Title
               <br/> <input
               value={inputs.title}
               className={(validationError && inputs.title === '') ? "validationerror" : ""}
                      style={{ width:'100%'}}
                      name="title"
                      maxLength={30}
                      required
                      onChange={handleChanged}
                    />
              </label>
              <label style={{ color: 'grey', marginBlockEnd: '20px',width:'100%' }}>Description
              <TextEditor  settexteditor={settexteditor} texteditor={texteditor} />
              </label>
              <div style={{display:'flex',flexDirection:'row',paddingTop:'10px',gap:10}}>
                  <Button className="primarybtn" onClick={handleSubmit}>Save</Button>
                  <Button className="secondarybtn" onClick={handleClose}>Cancel</Button>
              </div>
            </TabPanel>
        </TabContext>
      </Dialog>
    </div>
  )
}
