import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import "./Popup.css";
import DropdownSelect from "../../Components/DropdownSelect/DropdownSelect";
import { client } from "../../Helper/Client/Client";
import AlertPopup from "../../Components/AlertPopup/AlertPopup";

const PopupFAQ = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");
  const [status, setstatus] = useState('')
  const [texteditor, settexteditor] = useState('')
  const [inputs, setinputs] = useState({ title: '', description: '' })
  const [validationError, setvalidationError] = useState(false)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

  };

  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };

  function handleChanged(e) {
    const { name, value } = e.target;
    setinputs((inputs) => ({ ...inputs, [name]: value }));
  }

  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });

  function handleSubmit() {
    if (inputs.title === '' || status === '') {
      setvalidationError(true)
      return
    }
    else {
      setvalidationError(false)
    }
    const body = {
      "title": inputs.title,
      "description": inputs.description,
      "status": status
    }
    handleClose()
    client.post('/api/faq_create', body).then(() => {
      setalertOptions({
        alertType: "success",
        alertOpen: true,
        alertContent: "Submitted Successfully!",
      })
      setinputs({ title: '', description: '' })
      setstatus('')
      client.get(`/api/faq_pagination`).then((response) => {
        props.setpage(1)
        props.setcustomerList(response.data.results);
        props.setpageCount(Math.ceil(response.data.count / response.data.results.length))
      })
    }).catch(() => {
      setalertOptions({
        alertType: "error",
        alertOpen: true,
        alertContent: "Submission Failed!",
      })
    })
  }

  return (
    <div>
      <button className="exportbtnpluslg" onClick={handleClickOpen}>
        <span
          className="material-icons"
          style={{ color: "white", fontSize: "15px", paddingTop: "4px" }}
        >
          add
        </span>
      </button>
      <Dialog fullWidth open={open} onClose={handleClose} className="dialog">
        <Box>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="ADD FAQ" value="1" />
                <img
                  onClick={handleClose}
                  src="/Images/close.png"
                  height="32px"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "8px",
                  }}
                />
              </TabList>
            </Box>
            <TabPanel sx={{ p: 0 }} value="1">
              <DialogTitle>ADD FAQ</DialogTitle>
              <DialogContent
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label className="mt20">
                  Title
                  <input
                    className={(validationError && inputs.title === '') ? "validationerror" : ""}
                    value={inputs.title}
                    type="text"
                    name="title"
                    onChange={handleChanged}
                    required
                  />
                </label>
                <label className="mt20">
                  Description
                  <textarea name="description" value={inputs.description} onChange={handleChanged} style={{ borderRadius: '8px' }} />
                </label>

                <label className="mt20">
                  Status
                  <DropdownSelect validationError={validationError} value={status} setdropDownValue={setstatus} values={[{ name: 'Active', value: 'Active' }, { name: 'Disable', value: 'Disable' }]} />
                </label>
              </DialogContent>
              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginBlockStart: "20px",
                  padding: "20px",
                }}
              >
                <Button className="primarybtn" onClick={handleSubmit}>
                  Save
                </Button>
                <Button className="secondarybtn" onClick={handleClose}>
                  Cancel
                </Button>
              </DialogActions>
            </TabPanel>
          </TabContext>
        </Box>
      </Dialog>
      <AlertPopup alertOptions={alertOptions} handleClose={handleClosep} />

    </div>
  );
};

export default PopupFAQ;
