import React, { Component } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class WysiwygEditor extends Component {
  constructor(props) {
    super(props);
    this.placeholder = "     Type something...";
    // const html = ;
    const contentBlock = htmlToDraft(this.props.texteditor);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
        uploadedImages: [],
      };
      this._uploadImageCallBack = this._uploadImageCallBack.bind(this);
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    this.props.settexteditor(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };

  _uploadImageCallBack(file) {
    // long story short, every time we upload an image, we
    // need to save it to the state so we can get it's data
    // later when we decide what to do with it.

    // Make sure you have a uploadImages: [] as your default state
    let uploadedImages = this.state.uploadedImages;

    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    };
    console.log(imageObject.file);
    uploadedImages.push(imageObject);

    this.setState({ uploadedImages: uploadedImages });

    var reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onloadend = function () {
        resolve({ data: { link: reader.result } });
      };
    });
  }

  render() {
    const { editorState } = this.state;
    return (
      <div
        style={{
          backgroundColor: "#f5f5f5",
          height: "250px",
          borderRadius: "8px",
        }}
        className="advancedTxtEditor"
      >
        <Editor
          placeholder={this.placeholder}
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            options: [
              "inline",
              "list",
              "textAlign",
              "emoji",
              "image",
              "link",
              "fontSize",
            ],
            inline: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ["bold", "italic", "underline"],
            },
            list: { inDropdown: false, options: ["ordered", "unordered"] },
            textAlign: { inDropdown: false },
            link: { inDropdown: true },
            history: { inDropdown: true },
            image: { uploadCallback: this._uploadImageCallBack },
          }}
        />
      </div>
    );
  }
}
export default WysiwygEditor;
