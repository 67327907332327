import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import InputUnstyled, { inputUnstyledClasses } from "@mui/base/InputUnstyled";
import { styled } from "@mui/system";
import { Popover, Typography } from "@mui/material";
import DropdownSelect from "../../Components/DropdownSelect/DropdownSelect";
import RangeSlider from "../../Components/RangeSlider/RangeSlider";
import { Button, Dialog } from "@mui/material";
import useAxios from "../../Helper/Client/useAxios";
import { client } from "../../Helper/Client/Client";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";

const StyledInputRoot = styled("div")(
  ({ theme }) => `
  display: flex;
  border-radius: 8px;
  background:#f5f5f5;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
  &.${inputUnstyledClasses.focused} {
    outline: solid #fe9900;
  }
`
);

const StyledInputElement = styled("input")(
  ({ theme }) => `
 
  flex-grow: 1;
  background: #f5f5f5!important;
  padding: 10px 10px;
  outline: 0;
`
);

const InputAdornment = styled("div")`
  margin: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const CustomInput = React.forwardRef(function CustomInput(props, ref) {
  const { components, ...other } = props;
  return (
    <InputUnstyled
      components={{
        Root: StyledInputRoot,
        Input: StyledInputElement,
        ...components,
      }}
      {...other}
      ref={ref}
    />
  );
});

CustomInput.propTypes = {
  /**
   * The components used for each slot inside the InputBase.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  components: PropTypes.shape({
    Input: PropTypes.elementType,
    Root: PropTypes.elementType,
    Textarea: PropTypes.elementType,
  }),
};

export default function ProductSearch(props) {
  // const [values, setValues] = React.useState({
  //   amount: '',
  //   weight: '',
  //   weightRange: '',
  // });
  function onhandleChange(e) {
    props.setsearchvalue(e.target.value);
  }
  const [anchorElPop, setAnchorElPop] = React.useState(null);

  const handleClickPop = (event) => {
    setAnchorElPop(event.currentTarget);
  };

  // console.log("anchorElPop", anchorElPop);

  const handleClosePop = () => {
    setAnchorElPop(null);
  };

  const openPop = Boolean(anchorElPop);
  const idPop = openPop ? "simple-popover" : undefined;

  const [status, setstatus] = React.useState("");

  // console.log("Popover Dropdown", status);

  const [startdate, setStartdate] = React.useState(null);

  // console.log("Startdate", startdate);

  const [enddate, setEnddate] = React.useState(null);

  // console.log("Enddate", enddate);

  const [validationError, setvalidationError] = useState(false);
  const [producttype, setproducttype] = useState("");
  const [a, setA] = useState("");
  const [b, setB] = useState("");
  const [List, setList] = useState([]);
  const [isChecked, setIsChecked] = React.useState();

  const [
    productcategoryresponse,
    productcategoryisError,
    productcategoryisLoading,
  ] = useAxios({
    url: "/api/product_category_list",
    method: "get",
    // signal: controller.signal
  });
  
  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });
  useEffect(() => {
    if (productcategoryresponse && productcategoryresponse.data.results) {
      const alteredcategory = productcategoryresponse.data.results.map(
        (item) => {
          const updatedItem = {
            ...item,
            value: item.id,
            name: item.category,
          };
          // console.log("U", updatedItem);
          return updatedItem;
        }
      );
      setList(alteredcategory);
      
    }
    return () => {
      // controller.abort()
    };
  }, [productcategoryresponse]);
  // console.log("product_category_list", producttype);
  // console.log("Product Category Resp", productcategoryresponse);
  // console.log("Product Category List", List);

  // console.log("ProductSearchMin", a);
  // console.log("ProductSearchMax", b);

  const handleSubmit = () => {
    const body = {
      category: producttype,
      // status: true,
      status: status,
      date_from: startdate,
      date_to: enddate,
      min_price: localStorage.getItem("date_from"),
      max_price: localStorage.getItem("date_to"),
    };
    client
      .post(`/api/product_filter`, body)
      .then((response) => {
        // console.log("Hello", response.data.results);
        setStartdate("");
        setEnddate("");
        setstatus("");
        setproducttype("");
        handleClosePop();
        if (response.data.results) {
          props.setproductList(response.data.results);
        } else {
          props.setproductList([]);
        }
        // console.log("Hello");
        setalertOptions({
          alertType: "success",
          alertOpen: true,
          alertContent: "Product Filter Post API Successfull!",
        });
      })
      .catch(() => {
        setalertOptions({
          alertType: "error",
          alertOpen: true,
          alertContent: "Submission Failed!",
        });
      });
  };

  // console.log("Hello", isChecked);


  return (
    <CustomInput
      value={props.searchvalue}
      onChange={onhandleChange}
      onKeyPress={props.onKeyPress}
      id="outlined-start-adornment"
      startAdornment={
        <InputAdornment>
          <img src="/Images/search.svg" />
        </InputAdornment>
      }
      endAdornment={
        <>
          <InputAdornment>
            <img
              src="/Images/Filter.svg"
              aria-describedby={idPop}
              variant="contained"
              onClick={handleClickPop}
            />
            <Popover
              id={idPop}
              open={openPop}
              anchorEl={anchorElPop}
              onClose={handleClosePop}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div 
              style={{ width: "280px", height: "700px", padding: "24px" }}
              >
                <h4>Filter</h4>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label className="mt20">
                    Category
                    <DropdownSelect
                      validationError={validationError}
                      value={producttype}
                      setdropDownValue={setproducttype}
                      values={List}
                    />
                  </label>
                  <label className="mt20">
                    Status
                    <DropdownSelect
                      value={status}
                      setdropDownValue={setstatus}
                      values={[
                        { name: "Active", value: true },
                        { name: "Blocked", value: false },
                      ]}
                    />
                  </label>
                  <label className="mt20">
                    Date From
                    <input
                      style={{ width: "100%" }}
                      type="date"
                      value={startdate}
                      id="birthday"
                      name="birthday"
                      onChange={(e) => setStartdate(e.target.value)}
                    ></input>
                  </label>
                  <label className="mt20">
                    Date To
                    <input
                      style={{ width: "100%" }}
                      type="date"
                      value={enddate}
                      id="birthday"
                      name="birthday"
                      onChange={(e) => setEnddate(e.target.value)}
                    ></input>
                  </label>
                  
                  <label className="mt20">
                    Price
                    <RangeSlider />
                    {/* setA={setA} */}
                  </label>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          checked={isChecked}
                          // onChange={handleCheckbox}
                          onChange={(e) => setIsChecked("All")}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{
                            "&.Mui-checked": {
                              color: "#FE9900",
                            },
                          }}
                        />
                      }
                      label="All Category"
                      labelPlacement="end"
                    />
                  </FormControl>
                  <Button
                    className="primarybtn"
                    style={{ width: "100%", marginTop: "20px" }}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Popover>
          </InputAdornment>
        </>
      }
    />
  );
}
