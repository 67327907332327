import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import "./Popup.css";
import React, { useEffect, useState } from 'react'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Pagination } from '@mui/material';
import SearchBar from '../../Components/SearchBar/SearchBar';
import useAxios from '../../Helper/Client/useAxios';
import { client } from '../../Helper/Client/Client';
import AlertPopup from "../../Components/AlertPopup/AlertPopup";



const PopupPopularBlogs = (props) => {
  const [blogList, setblogList] = useState([])
  const [searchvalue, setsearchvalue] = useState('')
  const [opendialog, setopendialog] = useState(false)
  const [pageCount, setpageCount] = useState(0)
  const [page, setpage] = useState(1)
  const [listlength, setlistlength] = useState(0)
  

 
  const [response, isError, isLoading] = useAxios({
    url: '/api/blog_paginated_list',
    method: "get",
    
  });

  useEffect(() => {
    client.get(`/api/blog_search_by_title?search=${searchvalue}`).then((response) => {
      response.data.results ?
        setblogList(response.data.results) &&
        setpageCount(Math.ceil(response.data.count / response.data.results.length))
        : setblogList([])
    })
  }, [searchvalue])


  useEffect(() => {
    if (response && response.data.results) {
      setblogList(response.data.results);
      setpageCount(Math.ceil(response.data.count / response.data.results.length))
    }
    return () => {

    }
  }, [response]);
  useEffect(() => {
    if (blogList?.length !== undefined) {
      setlistlength(blogList.length)
    }
  }, [blogList])

  function CustomPagination() {

    return (
      <Pagination
        className='pagination'
        color='warning'
        count={pageCount}
        page={page}
        onChange={(event, value) => {
          client.get(`/api/blog_paginated_list?page=${value}`).then((response) => {
            setblogList(response.data.results);
          })
          setpage(value)
        }}
        showFirstButton showLastButton
      />
    );
  }
 

  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };

  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });
 

  const columns = [
    {
      field: 'blog_title',
      headerName: 'TITLE',
      width: 250,
    },
    {
      field: 'cover_image',
      headerName: 'IMAGE',
      align: 'center', headerAlign: 'center',
      width: 150,
      renderCell: (params) => (
        <>{params.value ?<img src={params.value} height={70} style={{borderRadius:'8px'}} />:<img src='../Images/img.png' height={70} style={{borderRadius:'8px'}} />}</>)
   
    },
    {
      field: 'blog_date',
      headerName: 'DATE',
      width: 150,
      align: 'center', headerAlign: 'center', type: 'date',
      renderCell: (params) => (params.value? params.value.split('T')[0].split('-')[2]+'.'+params.value.split('T')[0].split('-')[1]+'.'+params.value.split('T')[0].split('-')[0]:'')
    },
    {
      field: 'status',
      headerName: 'STATUS',
      align: 'center', headerAlign: 'center',
      width: 150,
      renderCell: (params) => (
        <span style={{ textAlign: 'center', width: '110px', padding: '7px', backgroundColor: params.value ? "#e8f9ef" : "#ffe7e6", color: params.value ? '#20A144' : '#EB5A52' }}>{params.value?'Active':'Disable'} </span>)

    },
  ];

  const handleClickOpen = () => {
    setopendialog(true);
  };
  const handleClosedialog=()=>{
    setopendialog(false);
  }

  const [selectionModel, setSelectionModel] = useState([]);


  const handleSubmit=()=>{
    const body={

  "blog_id": selectionModel,
}
handleClosedialog()
client.post('/api/top10_blog_add',body).then(()=>{
  setalertOptions({
    alertType: "success",
    alertOpen: true,
    alertContent: "Submitted Successfully!",
  })
  client.get('/api/top10_blog_get').then((response) => {
    props.settopBlogList(response.data.data);
  })
}).catch(()=>{
  setalertOptions({
    alertType: "error",
    alertOpen: true,
    alertContent: "Submission Failed!",
  })
})
  }
  const [topBlogresponse, topBlogisError, topBlogisLoading] = useAxios({
    url: '/api/top10_blog_get',
    method: "get",

  });

  useEffect(() => {
    let bloglistid=[]
    props.topBlogList.map((item)=>{
      bloglistid.push(item.id)
    })
    setSelectionModel(bloglistid)
  }, [props.topBlogList])

  function handleSelection(newSelectionModel){
    if (newSelectionModel.length>10){
      newSelectionModel.pop()
     
    }
    setSelectionModel(newSelectionModel)
  }

  return (
    <div>
      <button className="exportbtnpluslg" onClick={handleClickOpen}>
        <span
          className="material-icons"
          style={{ color: "white", fontSize: "15px", paddingTop: "4px" }}
        >
          add
        </span>
      </button>
      <Dialog fullWidth open={opendialog} onClose={handleClosedialog} className="dialog">
        <Box>
        <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList onChange={handleChange}>
          <Tab label="CHOOSE BLOG" value="1" />
        </TabList>
        </Box>
        <TabPanel sx={{ p: 0 }} value="1">
        <DialogTitle >Choose Blog</DialogTitle>
        <DialogContent>
          <div style={{ width: '100%', paddingTop: '24px', borderRadius: '8px', border: '1px solid #E8E8E8' }}>
          <div className="mid-searchbar">
                  <SearchBar
                    searchvalue={searchvalue}
                    setsearchvalue={setsearchvalue}
                  />
                </div>
            <div style={{ height: '650px' }}>
              <DataGrid
                
              rowHeight={100}
                sx={{ mt: 2 }}
                rows={blogList}
                columns={columns}
                pageSize={listlength}
                rowsPerPageOptions={[listlength]}
                getRowId={(row) => row.id}
                checkboxSelection
                pagination
                // paginationMode="server"
                keepNonExistentRowsSelected
                components={{
                  Pagination: CustomPagination,
                }}
                onSelectionModelChange={(newSelectionModel) => {
                  handleSelection(newSelectionModel);
                }}
                selectionModel={selectionModel}
              
              />
            </div>
          </div>
          </DialogContent>
          <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginBlockStart: "20px",
                  padding: "20px",
                }}
              >
                <Button className="primarybtn" onClick={handleSubmit}>
                  Save
                </Button>
                <Button className="secondarybtn" onClick={handleClosedialog}>
                  Cancel
                </Button>
              </DialogActions>
        </TabPanel>
      </TabContext>
        </Box>
      </Dialog>
      <AlertPopup alertOptions={alertOptions} handleClose={handleClosep} />
    </div>
  );
};

export default PopupPopularBlogs;
