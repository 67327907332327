import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import WysiwygEditor from "../../Components/TextEditor/WysiwygEditor";
import { history } from "../../Helper/History/history";
import useAxios from "../../Helper/Client/useAxios";
import { client } from "../../Helper/Client/Client";

function TCEdit() {
  const [edit, setedit] = useState(false);
  const [termsandcondition, setTermsandcondition] = useState([]);
  const [texteditor, settexteditor] = useState("");
  const [inputs, setinputs] = useState({ termscondition: "" });

  function handleClickOpen() {
    setedit(true);
  }

  function handleClose() {
    setedit(false);
  }
  useEffect(() => {
    setinputs((inputs) => ({
      ...inputs,
      termscondition: termsandcondition.title,
    }));
  }, [termsandcondition]);

  function handleChanged(e) {
    const { name, value } = e.target;
    setinputs((inputs) => ({ ...inputs, [name]: value }));
  }

  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });

  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };

  const [response, isError, isLoading] = useAxios({
    url: "/api/terms_condition_view",
    method: "get",
  });
  useEffect(() => {
    if (response && response.data.data) {
      setTermsandcondition(response.data.data[0]);
      settexteditor(response.data.data[0].content);
    }
    return () => {};
  }, [response]);

  // console.log("TC Resp",termsandcondition)

  const handleSubmit = () => {
    let form_data = new FormData();
    form_data.append("title", inputs.termscondition);
    form_data.append("content", texteditor);

    client
      .post(`api/terms_condition_post`, form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(() => {
        setalertOptions({
          alertType: "success",
          alertOpen: true,
          alertContent: "Submitted Successfully!",
        });

        setedit(false);
        client
          .get(`/api/terms_condition_view`)
          .then((response) => setTermsandcondition(response.data.data[0]));
      })
      .catch(() => {
        setalertOptions({
          alertType: "error",
          alertOpen: true,
          alertContent: "Submission Failed!",
        });
      });
  };
  // console.log("texted", texteditor);

  return (
    <article>
      {termsandcondition.title === "" && termsandcondition.content === "" ? (
        <button
          className="exportbtnpluslg"
          id={edit == true ? "tc-none" : "tc-display"}
          onClick={handleClickOpen}
          style={{ float: "right" }}
        >
          <span
            className="material-icons"
            style={{
              color: "white",
              fontSize: "15px",
              paddingTop: "4px",
            }}
          >
            add
          </span>
        </button>
      ) : (
        <img
          src="/Images/edit.svg"
          onClick={handleClickOpen}
          style={{ float: "right", display: edit ? "none" : "" }}
        />
      )}
      <h4 style={{ height: "40px" }}>
        {termsandcondition.title ? termsandcondition.title : "T&C"}
      </h4>
      <hr />
      {edit && (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label className="mt20">
              Title
              <input
                style={{ width: "100%" }}
                type="text"
                name="termscondition"
                value={inputs.termscondition}
                onChange={handleChanged}
                maxLength={40}
                required
              />
            </label>
            <label className="mt20">
              Content
             
              <WysiwygEditor
                settexteditor={settexteditor}
                texteditor={texteditor}
              />
            </label>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              marginBlockStart: "20px",
              padding: "20px",
              gap: 10,
            }}
          >
            <Button className="primarybtn" onClick={handleSubmit}>
              Save
            </Button>
            <Button className="secondarybtn" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </>
      )}
      {!edit && (
        <div style={{ width: "66vw" }}>
          <p dangerouslySetInnerHTML={{ __html: termsandcondition.content }} />
        </div>
      )}
    </article>
  );
}
export default TCEdit;
