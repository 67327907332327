

import React, { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button, Pagination, InputAdornment, TextField } from "@mui/material";

import { history } from "../../Helper/History/history";
import { client } from "../../Helper/Client/Client";
import PopupDelete from "../Popup/PopupDelete";
import PopupDeleteAll from "../Popup/PopupDeleteAll";


import OrderSearch from "../../Components/SearchBar/OrderSearch";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Papa from "papaparse";


function OrderListScreen() {
  const [orderList, setorderList] = useState([]);
  const [searchvalue, setsearchvalue] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [page, setpage] = useState(1);
  const [listlength, setlistlength] = useState(0);


  useEffect(() => {
    client
      .get(`/api/product_order_searchby_ordernumber?search=${searchvalue}`)
      .then((response) => {
        if (response.data.results) {
          setorderList(response.data.results);
          setpage(1);
          setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          );
        } else {
          setorderList([]);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401 || 403) {
          history.push("/login");
        }
      });
  }, [searchvalue]);

  function CustomPagination() {
    return (
      
      <Pagination
        className="pagination"
        color="warning"
        count={pageCount}
        page={page}
        onChange={(event, value) => {
          searchvalue === ""
            ? client.get(`/api/product_order_list_all?page=${value}`)
            .then((response) => {
                setorderList(response.data.results);
              })
            : client
            .get(`/api/product_order_searchby_ordernumber?page=${value}&search=${searchvalue}`)
            .then((response) => {
              setorderList(response.data.results);
            });
            setpage(value);
        }}
        showFirstButton
        showLastButton
      />
    );
  }

  useEffect(() => {
    if (orderList?.length !== undefined) {
      setlistlength(orderList.length);
    }
  }, [orderList]);

  // tab property
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // table view(edit/delete)
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [editbar, setEditbar] = useState("");
  const showEditbar = (event, id) => {
    setAnchorEl(event.currentTarget);
    // console.log(id);
    setEditbar(id);
  };
  function handledownload(data) {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    saveAs(blob, 'Pick Me Studio.xlsx');
  
    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }
  }
  const handleClose = () => {
    setEditbar(null);
    console.log("itsssstststs");
  };

// top actions
  // const [action, setAction] = React.useState(null);
  const open = Boolean(anchorEl2);
  const handleClickaction = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseaction = () => {
    setAnchorEl2(null);
    
  };



  const handlebulkDelete = () => {
    client
      .delete(`/api/product_order_bulk_delete`, {
        data: { product_order_id: selectionModel },
      })
      .then(() => {
        setAnchorEl2(null);
        client.get("/api/product_order_list_all").then((response) => {
          if (response.data.results) {
            setpage(1);
            setorderList(response.data.results);
            setpageCount(
              Math.ceil(response.data.count / response.data.results.length)
            );
          } else {
            setpage(0);
            setpageCount(0);
            setorderList([]);
          }
        });
      });
  };

  const handleEdit = (i) => {
    history.push(`/orderdetails/${i}`);
  };

  const handlerefubndFunction = (id) => {
    console.log("handlerefubndFunctionhandlerefubndFunction",id);
      client
    .put(`/api/product_order_update/${id}`, {
      "order_status": "Refund" 
  })
    .then(() => {
      handleClose();
      console.log("Refund");
      client.get("/api/product_order_list_all").then((response) => {
        if (response.data.results) {
          setpage(1);
          setorderList(response.data.results);
          setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          );
        } else {
          setpage(0);
          setpageCount(0);
          setorderList([]);
        }
      });
    });
  };

  function handleDelete(i) {
    client.delete(`/api/product_order_delete/${i}`).then(() => {
      handleClose();
      client.get("/api/product_order_list_all").then((response) => {
        if (response.data.results) {
          setpage(1);
          setorderList(response.data.results);
          setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          );
        } else {
          setpage(0);
          setpageCount(0);
          setorderList([]);
        }
      });
    });
  }


  function getOrderDetails(params) {
    const ordernumber = params.row.order_number;
    const customername = params.row.customer_name;
    const orderamount = params.row.order_amount;
    const orderdate = params.row.order_date;
    const orderstatus = params.row.order_status;
    // console.log("Order Det", customername);
    return (
      ordernumber + "+" + customername + "+" + orderamount + "+" + orderdate + "+" + orderstatus
    );
  }

  function getstatus(params) {
    const id = params.row.id;
    const orderstatus = params.row.order_status;
    return (
      id + "+" + orderstatus
    );
  }

  const columns = [
    {
      field: "order_number",
      headerName: "Order Number",
      headerAlign: "center",
      width: 150,
      valueGetter: getOrderDetails,
      renderCell: (params) => (
        <>
         
          <div style={{ marginLeft: "10px" }}>
            <b style={{ color: "rgb(53, 53, 53)" }}>
              {params.value.split("+")[0]}
            
            </b>
           
          </div>
        </>
      ),
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "order_amount",
      headerName: "Price",
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "order_date",
      headerName: "DATE",
      align: "center",
      headerAlign: "center",
      type: "date",
      width: 150,
      renderCell: (params) =>
        params.value.split("T")[0].split("-")[2] +
        "." +
        params.value.split("T")[0].split("-")[1] +
        "." +
        params.value.split("T")[0].split("-")[0],
    },
    {
      field: "order_status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      width: 150,
      renderCell: (params) =>
        params.value == "Processing" ? (
          <span
            style={{
              textAlign: "center",
              width: "100px",
              padding: "7px",
              backgroundColor: "rgba(255, 144, 84,0.08)",
              color: " #FF9054",
            }}
          >
            {params.value}{" "}
          </span>
        ) : params.value == "Finished" ? (
          <span
            style={{
              textAlign: "center",
              width: "100px",
              padding: "7px",
              backgroundColor: "#e8f9ef ",
              color: " #20A144",
            }}
          >
            {params.value}{" "}
          </span>
        ) : (
          <span
            style={{
              textAlign: "center",
              width: "100px",
              padding: "7px",
              backgroundColor: "#ffe7e6 ",
              color: " #EB5A52",
            }}
          >
            {params.value== "Refund Request" ?"Refunding":params.value}{" "}
          </span>
        ),
    },
    {
      field: "id",
      type: "actions",
      headerName: "",
      width: 100,
      align: "center",
      headerAlign: "center",
      valueGetter: getstatus,
      renderCell: (params) => (
        <>
          <IconButton onClick={(event) => showEditbar(event, params.value.split("+")[0])}>
            <img src="../Images/dots.png" alt="action" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={editbar === params.value.split("+")[0] ? true : false}
            onClose={handleClose}
          >
            
           
            <MenuItem onClick={() => handleEdit(params.value.split("+")[0])}>View Details</MenuItem>
            {params.value.split("+")[1]== "Refund Request"&& 
            <MenuItem onClick={() =>
              handlerefubndFunction(params.value.split("+")[0])
              }>Refund </MenuItem>
             }
            <PopupDelete onClick={handleClose}
            delete={() => handleDelete(params.value.split("+")[0])} 
            />
           
            
          </Menu>
        </>
      ),
    },
  ];
  function CustomExportButton({ onClick }) {
    return (
      <button onClick={onClick}>Download Data</button>

      
    );
  }
  function CustomToolbar() {
    const [csv, setCsv] = useState(false);
    const handleExport = (data) => {
      // Replace data with your actual data
      // const data = [
      //   { name: "张三", age: 30, city: "北京" },
      //   { name: "李四", age: 25, city: "上海" },
      // ];
  
      // Convert data to CSV
      const csvData = Papa.unparse(data, {
        quotes: true,
        delimiter: ",",
        encoding: "utf-8", // Specify UTF-8 encoding
      });
  
      // Create a Blob with UTF-8 encoding
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvData], {
        type: "text/csv;charset=utf-8",
      });
  
      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Pickmestudio.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };
    const handleExportCSV = () => {
      function getStatusText(status) {
        if (status === 'Processing') {
          return 'Processing';
        } else if (status === 'Finished') {
          return 'Finished';
        } else if (status === 'Refund Request') {
          return 'Refunding';
        } else {
          return status;
        }
      }
      const csvData = orderList.map((item) => ({
        'Order Number': item.order_number,
        'Customer Name': item.customer_name,
        'Price': item.order_amount,
        'Date': item.order_date.split("T")[0].split("-").reverse().join("."),
        'Status': getStatusText(item.order_status),
      }));
  
      const csv = Papa.unparse(csvData, {
        quotes: true,
        delimiter: ',',
        encoding: 'utf-8',
      });
  
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], {
        type: 'text/csv;charset=utf-8',
      });
      
      saveAs(blob, 'Pick Me Studio.csv');
    };

    const handleButtonClick = (data) => {
      // Replace with your actual data
      
  
      handledownload(data);
    };

    const handleCustomCsvDownload = () => {
      // Add your custom CSV download logic here
      console.log("Custom CSV Download");
    };
  
    
  
    const CustomExportButtons = () => (
      <>
        <CustomExportButton onClick={handleCustomCsvDownload} />

      </>
    );
    return (
      <div style={{ position: "absolute", right: "30px", top: "-210px" }}>
        <GridToolbarContainer>
        <div style={{position:"relative"}} 
          onMouseEnter={()=>{
            console.log("its onMouseEnter");
            setCsv(true);
          }}
          onMouseLeave={()=>{
            console.log("its onMouseLeave");
            setCsv(false);
          }}
          >
          <button onClick={() => handleExportCSV()}
          style={{
            position: "absolute",
            inset: "0px 0px auto auto",
            margin: "0px",
            transform: "translate3d(4px, 47.5px, 0px)",
            width: '170px',
            height: "37px",
            // visibility: "hidden",
            zIndex:1500,
            opacity:0,
            display:csv?"":"none",
            // cursor:"default"
         
          }}
          >Download CSV</button> 
          {/* <button onClick={()=>{window.print();}}>Print</button> */}
          {/* <PrintButton targetId="componentToPrint" /> */}
        
          <GridToolbarExport
        onItemClick={() => handleExportCSV()}
        endIcon={<img src="../Images/Arrow-Down.svg" />}
        sx={{
          color: "black",
          bgcolor: "#F5F5F5",
          textTransform: "capitalize",
          p: 1.2,
          pr: 3,
        }}
        options={{
          bookSST: true,
          bookType: "xlsx",
          mimeType:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
          
        }}
      />
      </div>
          {/* <GridToolbarExport
            sx={{
              color: "black",
              bgcolor: "#F5F5F5",
              textTransform: "capitalize",
              p: 1.2,
              pr: 3,
            }}
          /> */}
        </GridToolbarContainer>
      </div>
    );
  }

  const adjustmenthandleCloseaction = () => {
    setAnchorEl(null);
    history.push(`/orderdetails`);
  };


  const rows = [
    {
      id: 1,
      ordernumber: "020220212001",
      customername: "Regina Cooper",
      price: "$90.00",
      date: "20/12/21",
      status: "Finished",
    },
    {
      id: 2,
      ordernumber: "020220212001",
      customername: "Regina Cooper",
      price: "$90.00",
      date: "20/12/21",
      status: "unfinished",
    },
    {
      id: 3,
      ordernumber: "020220212001",
      customername: "Regina Cooper",
      price: "$90.00",
      date: "20/12/21",
      status: "Finished",
    },
  ];

  return (
    <article>
      {/* <div className="buttondiv">
        <Button
          className="tertiarybtn"
          startIcon={<img src="../Images/download.svg" alt="download" />}
          endIcon={<img src="../Images/Arrow-Down.svg" alt="arrow down" />}
        >
          Export
        </Button>
        
      </div> */}
      <h4>Order</h4>
      <TabContext value={value}>
        <TabList onChange={handleChange} aria-label="Order lists">
          <Tab label="All" value="1" />
        </TabList>
        <TabPanel value="1">
          <div
            style={{
              width: "100%",
              paddingTop: "24px",
              borderRadius: "8px",
              border: "1px solid #E8E8E8",
            }}
          >
            <div className="mid-searchbar">
            <OrderSearch
                searchvalue={searchvalue}
                setsearchvalue={setsearchvalue} setorderList={setorderList}
              />
              <Button
                onClick={handleClickaction}
                className="tertiarybtn"
                endIcon={<img src="../Images/Arrow-Down.svg" />}
              >
                Actions
              </Button>
              <Menu
                anchorEl={anchorEl2}
                open={open}
                onClose={handleCloseaction}
              >
                <PopupDeleteAll 
                delete={handlebulkDelete} 
                />
                {/* <MenuItem onClick={handleCloseaction}>Edit Item</MenuItem>
                  <MenuItem onClick={handleCloseaction}>Delete Item</MenuItem> */}
              </Menu>
            </div>
            <div style={{ height: "650px" }}>
              <DataGrid
                sx={{ mt: 2 }}
                rows={orderList}
                columns={columns}
                pageSize={listlength}
                rowsPerPageOptions={[listlength]}
                getRowId={(row) => row.id}
                checkboxSelection
                pagination
                // paginationMode="server"
                keepNonExistentRowsSelected
                components={{
                  Pagination: CustomPagination,
                  Toolbar: CustomToolbar,
                }}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
              />
            </div>
          </div>
        </TabPanel>
      </TabContext>
    </article>
  );
}

export default OrderListScreen;
