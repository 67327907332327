import React, { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import Popup from "../Popup/Popup";
import { Button, Pagination } from "@mui/material";
import SearchBar from "../../Components/SearchBar/SearchBar";
import useAxios from "../../Helper/Client/useAxios";
import { history } from "../../Helper/History/history";
import { client } from "../../Helper/Client/Client";
import PopupDelete from "../Popup/PopupDelete";
import PopupDeleteAll from "../Popup/PopupDeleteAll";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Papa from "papaparse";

function Customer() {
  const [customerList, setcustomerList] = useState([]);
  const [searchvalue, setsearchvalue] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [page, setpage] = useState(1);
  const [listlength, setlistlength] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [editbar, setEditbar] = useState("");


  useEffect(() => {
    client.get(`/api/user_details?search=${searchvalue}&role_id=3`).then((response) => {
      if (response.data.results) {
        setcustomerList(response.data.results);
        console.log('customer',response.data.results)
        setpage(1)
        setpageCount(
          Math.ceil(response.data.count / response.data.results.length)
        );
      } else {
        setcustomerList([]);
      }
    }).catch((err)=>{
      if ( err?.response?.status === 401||403) {
        history.push('/login')
      }
  })
  }, [searchvalue])

  function CustomPagination() {
    return (
      <Pagination
        className="pagination"
        color="warning"
        count={pageCount}
        page={page}
        onChange={(event, value) => {
          searchvalue === ""
            ?
            client
              .get(`/api/customer_list_paginated?page=${value}`)
              .then((response) => {
                console.log('customerrrrrrr',response.data.results)
                setcustomerList(response.data.results);
              }) :
            client
              .get(`/api/user_details?page=${value}&role_id=3&search=${searchvalue}`)
              .then((response) => {
                console.log('customeeeer',response.data.results)
                setcustomerList(response.data.results);
              })
          setpage(value);
        }}
        showFirstButton
        showLastButton
      />
    );
  }


  useEffect(() => {
    if (customerList?.length !== undefined) {
      setlistlength(customerList.length);
    }
  }, [customerList]);

  // tab property
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const showEditbar = (event, id) => {
    setAnchorEl(event.currentTarget);
    console.log(id);
    setEditbar(id);
  };
  const handleClose = () => {
    setEditbar(null);
  };
  function handledownload(data) {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    saveAs(blob, 'Pickmestudio.xlsx');
  
    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }
  }
  // top actions

  const open = Boolean(anchorEl2);
  const handleClickaction = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseaction = () => {
    setAnchorEl2(null);
  };
  const handleEdit = (i) => {
    history.push(`/customerdetails/${i}`);
  };

  function handleDelete(i) {
    client.delete(`/api/customer_delete/${i}`).then(() => {
      handleClose();
      client.get("/api/customer_list_paginated").then((response) => {
        if(  response.data.results){
          setpage(1);
        setcustomerList(response.data.results);
      setpageCount(
          Math.ceil(response.data.count / response.data.results.length)
        )
        }
        else{
          setpage(0);
          setpageCount(0)
          setcustomerList([])
        }
      });
    });
  }

  
  const handlebulkDelete = () => {
    client
      .delete(`/api/customer_bulk_delete`, {
        data: { customer_id: selectionModel },
      })
      .then(() => {
        setAnchorEl2(null);
        client.get("/api/customer_list_paginated").then((response) => {
          if(  response.data.results){
            setpage(1);
          setcustomerList(response.data.results);
        setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          )
          }
          else{
            setpage(0);
            setpageCount(0)
            setcustomerList([])
          }
        });
      });
  };

  function getNameEmail(params) {
    const firstname = params.row.first_name;
    const lastname = params.row.last_name;
    const email = params.row.email;
    const image = params.row.user_image;
    return firstname + "+" + lastname + "+" + email + "+" + image;
  }

  function getCityCountry(params) {
    const city = params.row.city;
    const country = params.row.country && ", " + params.row.country;
    return `${city + country}`;
  }

  const columns = [
    {
      field: "name&email",
      headerName: "CUSTOMER NAME",
      width: 200,
      valueGetter: getNameEmail,
      renderCell: (params) => (
        <>
          {params.value.split("+")[3] !== "null" ? (
            <img
              src={params.value.split("+")[3]}
              height="30px"
              width="30px"
              alt=""
            />
          ) : (
            <img
              src="/Images/placeholder.png"
              height="30px"
              width="30px"
              alt="profile placholder"
            />
          )}
          <div style={{ marginLeft: "10px" }}>
            <b style={{ color: "rgb(53, 53, 53)" }}>
              {params.value.split("+")[0]} {params.value.split("+")[1]}
            </b>
            <small style={{ color: "grey", display: "block" }}>
              {params.value.split("+")[2]}
            </small>
          </div>
        </>
      ),
    },
    {
      field: "city&country",
      headerName: "LOCATION",
      width: 200,
      valueGetter: getCityCountry,
    },
    {
      field: "phone_1",
      headerName: "PHONE",
      width: 150,
    },
    {
      field: "date_joined",
      headerName: "DATE",
      align: "center",
      headerAlign: "center",
      type: "date",
      width: 150,
      renderCell: (params) =>
        params.value.split("T")[0].split("-")[2] +
        "." +
        params.value.split("T")[0].split("-")[1] +
        "." +
        params.value.split("T")[0].split("-")[0],
    },
    {
      field: "is_active",
      headerName: "STATUS",
      headerAlign: "center",
      align: "center",
      width: 120,
      valueGetter: (params) => (params.row.is_active ? "Active" : "Blocked"),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            width: "100px",
            padding: "7px",
            backgroundColor: params.value === "Active" ? "#e8f9ef" : "#ffe7e6",
            color: params.value === "Active" ? "#20A144" : "#EB5A52",
          }}
        >
          {params.value}{" "}
        </span>
      ),
    },
    {
      field: "id",
      type: "actions",
      width: 50,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <IconButton onClick={(event) => showEditbar(event, params.value)}>
            <img src="../Images/dots.png" alt="action" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={editbar === params.value ? true : false}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleEdit(params.value)}>
              Edit Item
            </MenuItem>

            <PopupDelete delete={() => handleDelete(params.value)} />
          </Menu>
        </>
      ),
    },
  ];
  function CustomExportButton({ onClick }) {
    return (
      <button onClick={onClick}>Download Data</button>

      
    );
  }
  function CustomToolbar() {
    const [csv, setCsv] = useState(false);
    const handleExport = (data) => {
      // Replace data with your actual data
      // const data = [
      //   { name: "张三", age: 30, city: "北京" },
      //   { name: "李四", age: 25, city: "上海" },
      // ];
  
      // Convert data to CSV
      const csvData = Papa.unparse(data, {
        quotes: true,
        delimiter: ",",
        encoding: "utf-8", // Specify UTF-8 encoding
      });
  
      // Create a Blob with UTF-8 encoding
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvData], {
        type: "text/csv;charset=utf-8",
      });
  
      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Pick Me Studio.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    };
    const handleExportCSV = () => {
      const csvData = customerList.map((item) => ({
        "Customer Name": `${item.first_name} ${item.last_name}`,
        "Location": `${item.city}${item.country ? `, ${item.country}` : ''}`,
        "Phone": '\u200B' +item.phone_1,
        "Date": item.date_joined.split("T")[0].split("-").reverse().join("."),
        "Status": item.is_active ? "Active" : "Blocked",
       }));
  
      const csv = Papa.unparse(csvData, {
        quotes: true,
        delimiter: ',',
        encoding: 'utf-8',
      });
  
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], {
        type: 'text/csv;charset=utf-8',
      });
  
      saveAs(blob, 'Pick Me Studio.csv');
    };


    const handleButtonClick = (data) => {
      // Replace with your actual data
      
  
      handledownload(data);
    };

    const handleCustomCsvDownload = () => {
      // Add your custom CSV download logic here
      console.log("Custom CSV Download");
    };
  
    
  
    const CustomExportButtons = () => (
      <>
        <CustomExportButton onClick={handleCustomCsvDownload} />

      </>
    );
    return (
      <div style={{ position: "absolute", right: "30px", top: "-210px" }}>
        <GridToolbarContainer>
        <div style={{position:"relative"}} 
          onMouseEnter={()=>{
            console.log("its onMouseEnter");
            setCsv(true);
          }}
          onMouseLeave={()=>{
            console.log("its onMouseLeave");
            setCsv(false);
          }}
          >
          <button onClick={() => handleExportCSV()}
          style={{
            position: "absolute",
            inset: "0px 0px auto auto",
            margin: "0px",
            transform: "translate3d(4px, 47.5px, 0px)",
            width: '170px',
            height: "37px",
            // visibility: "hidden",
            zIndex:1500,
            opacity:0,
            display:csv?"":"none",
            // cursor:"default"
         
          }}
          >Download CSV</button> 
          {/* <button onClick={()=>{window.print();}}>Print</button> */}
          {/* <PrintButton targetId="componentToPrint" /> */}
        
          <GridToolbarExport
        onItemClick={() => handleExportCSV()}
        endIcon={<img src="../Images/Arrow-Down.svg" />}
        sx={{
          color: "black",
          bgcolor: "#F5F5F5",
          textTransform: "capitalize",
          p: 1.2,
          pr: 3,
        }}
        options={{
          bookSST: true,
          bookType: "xlsx",
          mimeType:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
          
        }}
      />
      </div>
          {/* <GridToolbarExport
            sx={{
              color: "black",
              bgcolor: "#F5F5F5",
              textTransform: "capitalize",
              p: 1.2,
              pr: 3,
            }}
          /> */}
        </GridToolbarContainer>
      </div>
    );
  }

  return (
    <article>
      <div className="buttondiv">
     
        <Popup
          roleid={3}
          setcustomerList={setcustomerList}
          setpage={setpage}
          setpageCount={setpageCount}
        />
      </div>
      <h4>Customer</h4>
      <TabContext value={value}>
        <TabList onChange={handleChange}>
          <Tab label="All" value="1" />
        </TabList>
        <TabPanel value="1">
          <div
            style={{
              width: "100%",
              paddingTop: "24px",
              borderRadius: "8px",
              border: "1px solid #E8E8E8",
            }}
          >
            <div className="mid-searchbar">
              <SearchBar
                searchvalue={searchvalue}
                setsearchvalue={setsearchvalue}
              />
              <Button
                onClick={handleClickaction}
                className="tertiarybtn"
                endIcon={
                  <img src="../Images/Arrow-Down.svg" alt="arrow down" />
                }
              >
                Actions
              </Button>
              <Menu
                anchorEl={anchorEl2}
                open={open}
                onClose={handleCloseaction}
              >
                <PopupDeleteAll delete={handlebulkDelete} />
              </Menu>
            </div>
            <div style={{ height: "650px" }}>
              <DataGrid
                sx={{ mt: 2 }}
                rows={customerList}
                columns={columns}
                pageSize={listlength}
                rowsPerPageOptions={[listlength]}
                getRowId={(row) => row.id}
                checkboxSelection
                pagination
                // paginationMode="server"
                keepNonExistentRowsSelected
                components={{
                  Pagination: CustomPagination,
                  Toolbar: CustomToolbar,
                }}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
              />
            </div>
          </div>
        </TabPanel>
      </TabContext>
    </article>
  );
}

export default Customer;
