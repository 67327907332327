import React, { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button, Pagination } from "@mui/material";
import SearchBar from "../../Components/SearchBar/SearchBar";
import { client } from "../../Helper/Client/Client";
import PopupDelete from "../Popup/PopupDelete";
import PopupDeleteAll from "../Popup/PopupDeleteAll";
import { history } from "../../Helper/History/history";
import PopupNotification from "../Popup/PopupNotification";
function Notification() {
  // tab property
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [editbar, setEditbar] = useState("");
  const [notificationList, setNotificationList] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [page, setpage] = useState(1);
  const [listlength, setlistlength] = useState(0);
  const [searchvalue, setsearchvalue] = useState("");

  useEffect(() => {
    if (notificationList?.length !== undefined) {
      setlistlength(notificationList.length);
    }
  }, [notificationList]);

  useEffect(() => {
    client
      .get(`/api/notification_send_searchby_title?search=${searchvalue}`)
      .then((response) => {
        if (response.data.results) {
          setNotificationList(response.data.results);
          setpage(1);
          setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          );
        } else {
          setNotificationList([]);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401 || 403) {
          history.push("/login");
        }
      });
  }, [searchvalue]);

  const showEditbar = (event, id) => {
    setAnchorEl(event.currentTarget);
    // console.log(id);
    setEditbar(id);
  };
  const handleClose = () => {
    setEditbar(null);
  };

  const open = Boolean(anchorEl2);
  const handleClickaction = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseaction = () => {
    setAnchorEl2(null);
  };

  function CustomPagination() {
    return (
      <Pagination
        className="pagination"
        color="warning"
        count={pageCount}
        page={page}
        onChange={(event, value) => {
          client
            .get(`/api/notification_send_list?page=${value}`)
            .then((response) => {
              setNotificationList(response.data.results);
            });
          setpage(value);
        }}
        showFirstButton
        showLastButton
      />
    );
  }

  function handleDeleteNotification(i) {
    client.delete(`/api/notification_send_delete/${i}`).then(() => {
      handleClose();
      client.get("/api/notification_send_list").then((response) => {
        if (response.data.results) {
          setpage(1);
          setNotificationList(response.data.results);
          setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          );
        } else {
          setpage(0);
          setpageCount(0);
          setNotificationList([]);
        }
      });
    });
  }

  const handlebulkDelete = () => {
    client
      .delete(`/api/notification_send_bulk_delete`, {
        data: { notification_send_id: selectionModel },
      })
      .then(() => {
        setAnchorEl2(null);
        client.get(`/api/notification_send_list`).then((response) => {
          if (response.data.results) {
            setpage(1);
            setNotificationList(response.data.results);
            setpageCount(
              Math.ceil(response.data.count / response.data.results.length)
            );
          } else {
            setpage(0);
            setpageCount(0);
            setNotificationList([]);
          }
        });
      });
  };

  const columns = [
    {
      field: 'upload_date',
      headerName: 'SEND OUT DATE',
      width: 140,
      renderCell: (params) => (params.value && (params.value.split('T')[0].split('-')[2] + '.' + params.value.split('T')[0].split('-')[1] + '.' + params.value.split('T')[0].split('-')[0]))
    },
    {
      field: "upload_time",
      headerName: "SEND OUT TIME",
      width: 140,
    },
    {
      field: 'title',
      headerName: 'TITLE',
      width: 450,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      width: 120,
      renderCell: (params) =>
        params.value == "Pending" ? (
          <span
            style={{
              textAlign: "center",
              width: "100px",
              padding: "7px",
              backgroundColor: "rgba(0,0,0,0.08)",
              color: " #898989",
            }}
          >
            {params.value}{" "}
          </span>
        ) : params.value == "Success" ? (
          <span
            style={{
              textAlign: "center",
              width: "100px",
              padding: "7px",
              backgroundColor: "#e8f9ef ",
              color: " #20A144",
            }}
          >
            {params.value}{" "}
          </span>
        ) : (
          <span
            style={{
              textAlign: "center",
              width: "100px",
              padding: "7px",
              backgroundColor: "rgba(255,0,10,0.08)",
              color: " #FF000A",
            }}
          >
            {params.value}{" "}
          </span>
        ),
    },

    {
      field: "id",
      headerName: "",
      width: 30,
      renderCell: (params) => (
        <>
          <IconButton onClick={(event) => showEditbar(event, params.value)}>
            <img src="../Images/dots.png" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={editbar === params.value ? true : false}
            onClose={handleClose}
          >
            
            <PopupDelete delete={() => handleDeleteNotification(params.value)} />
          </Menu>
        </>
      ),
    },
  ];
  // console.log("Notification List", notificationList);

  return (
    <article>
      <div className="buttondiv">
        <PopupNotification setNotificationList={setNotificationList} setpage={setpage} setpageCount={setpageCount} />
      </div>
      <h4>Notification</h4>
      <TabContext value={value}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab label="All" value="1" />
        </TabList>
        <TabPanel value="1">
          <div
            style={{
              width: "100%",
              paddingTop: "24px",
              borderRadius: "8px",
              border: "1px solid #E8E8E8",
            }}
          >
            <div className="mid-searchbar">
              <SearchBar
                searchvalue={searchvalue}
                setsearchvalue={setsearchvalue}
              />
              <Button
                onClick={handleClickaction}
                className="tertiarybtn"
                endIcon={
                  <img src="../Images/Arrow-Down.svg" alt="arrow down" />
                }
              >
                Actions
              </Button>
              <Menu
                anchorEl={anchorEl2}
                open={open}
                onClose={handleCloseaction}
              >
                <PopupDeleteAll delete={handlebulkDelete} />
              </Menu>
            </div>
            <div style={{ height: "700px" }}>
              <DataGrid
                rowHeight={54}
                sx={{ mt: 2 }}
                rows={notificationList}
                columns={columns}
                pageSize={listlength}
                rowsPerPageOptions={[listlength]}
                getRowId={(row) => row.id}
                checkboxSelection
                pagination
                // paginationMode="server"
                keepNonExistentRowsSelected
                components={{
                  Pagination: CustomPagination,
                }}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
              />
            </div>
          </div>
        </TabPanel>
      </TabContext>
    </article>
  );
}

export default Notification;
