import React, { useEffect, useRef, useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TextEditor from "../../Components/TextEditor/TextEditor";
import DropdownSelect from "../../Components/DropdownSelect/DropdownSelect";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import "./Popup.css";
import useAxios from "../../Helper/Client/useAxios";
import { client } from "../../Helper/Client/Client";
import AlertPopup from "../../Components/AlertPopup/AlertPopup";
import DragDrop from "../../Components/DragDrop/DragDrop";

export default function Addshipping(props) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");
  const initialData = {
    productname: "",
    productprice: "",
    detailsTitle: "",
    settingTitle: "",
  };
  const [inputs, setinputs] = useState(initialData);
  const [status, setstatus] = useState("");
  const [validationError, setvalidationError] = useState(false);
  const [adddetails, setAdddetails] = useState(true);
  const [editdetails, setEditdetails] = useState(true);
  const [addsetting, setAddsetting] = useState(true);
  const [editsetting, setEditsetting] = useState(true);
  const [texteditor, settexteditor] = useState("");
  const [texteditorSetting, settexteditorSetting] = useState("");
  const [producttype, setproducttype] = useState("");
  const [List, setList] = useState([]);
  const [typeList, settypeList] = useState([]);
  const [tagvalue, settagvalue] = useState([]);
  const [equipmentypeList, setequipmentypeList] = useState([]);
  const [equipmenttagvalue, setequipmenttagvalue] = useState([]);

  const [file, setfile] = useState([]);
  const [filelist, setfilelist] = useState([]);
  const [deleteImage, setdeleteImage] = useState(null);
  const [detailsList, setdetailsList] = useState([]);
  const [EditdetailsID, setEditdetailsID] = useState(null);
  const [settingsList, setsettingsList] = useState([]);
  const [settingsList1, setsettingsList1] = useState([]);
  const [EditsettingID, setEditsettingID] = useState(null);

  function handleChanged(e) {
    const { name, value } = e.target;
    setinputs((inputs) => ({ ...inputs, [name]: value }));
  }

  useEffect(()=>{

    client.get("/api/product_shippingmethods").then((response) => {
      // console.log("settingsList222",settingsList.concat(response?.data?.data))
      setsettingsList1(response?.data?.data);
      
    })



  },[]);

  useEffect(() => {
    setfilelist(file);
  }, [file]);

  useEffect(() => {
    deleteImage && setfilelist(filelist.filter((item) => item !== deleteImage));
  }, [deleteImage]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };




  const handlesubmitshipping = () => {
    handleClose();
    client
    .post(`api/product_shippingmethods_add`, settingsList)
    .then(() => {
      client.get("/api/product_shippingmethods").then((response) => {
        // console.log("settingsList2",response?.data?.data)
        setsettingsList1(response?.data?.data)
        // console.log("settingsListsettingsList",settingsList)

      });

      setalertOptions({
        alertType: "success",
        alertOpen: true,
        alertContent: "Product Shipping Submitted Successfully!",
      });
      setsettingsList([]);
    })
    .catch(() => {
      setalertOptions({
        alertType: "error",
        alertOpen: true,
        alertContent: "Product Shipping Submission Failed!",
      });
    });
  }

  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });

  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };

  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    dragItem.current = position;
   
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
   
  };

  const drop = (e) => {
    const copyListItems = [...filelist];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setfilelist(copyListItems);
  };
  // console.log("FileList", filelist);

  const handleAddNewSettings = () => {
    setAddsetting(false);
  };
  const handleAddNewDetails = () => {
    setAdddetails(false);
  };


  function handleSettingAdd1() {
    if (inputs.settingTitle === "") {
      setvalidationError(true);
      return;
    } else {
      setvalidationError(false);
    }
    setsettingsList([
      ...settingsList,
      {
   
        pickup_way: inputs.settingTitle,
        description: texteditorSetting,
      },
    ]);
    client
    .post(`api/product_shippingmethods_add`,[...settingsList,{
      pickup_way: inputs.settingTitle,
      description: texteditorSetting,
    }])
    .then(() => {
      client.get("/api/product_shippingmethods").then((response) => {
        // console.log("settingsList2",response?.data?.data)
        setsettingsList1(response?.data?.data)
        // console.log("settingsListsettingsList",settingsList)

      });

      setsettingsList([]);
      setAddsetting(true);
      setinputs((inputs) => ({ ...inputs, ["settingTitle"]: "" }));
      settexteditorSetting("");
    })
  
  }




const [idd, setidd] = useState();
  function handleEditSetting(i) {
    setEditsetting(false);
    // console.log("i",i)
    // console.log("i15151",inputs)
    // console.log("settingsListsettingsList",settingsList1)
    settingsList1.concat(settingsList).map((item) => {
      if (item.id === i) {
        setinputs((inputs) => ({
          ...inputs,
          ["settingTitle"]: item.pickup_way,
        }));
        settexteditorSetting(item.description);
        setEditsettingID(i);
        setidd(i);
        
      }
      
    });
  }
  
  function handleDeleteSetting(i) {
    if(!i){
      setsettingsList(settingsList.filter((item) => item.dateid !== i));
 
  }
  else{
    client
    .delete(`api/product_shippingmethod_delete/${i}`)
    .then(() => {
      client.get("/api/product_shippingmethods").then((response) => {
        setsettingsList1(response?.data?.data)
      })
    })
  }
    
  }
  function handleSettingEditSave() {
    if (inputs.settingTitle === "") {
      setvalidationError(true);
      return;
    } else {
      setvalidationError(false);
    }


    client.put(`api/product_shippingmethods_update/${idd}`,{
      pickup_way: inputs.settingTitle,
      description: texteditorSetting,
    }).then(()=>{
      client.get("/api/product_shippingmethods").then((response) => {
          setsettingsList1(response?.data?.data)
      })
    })

   
    setEditsetting(true);
    setinputs((inputs) => ({ ...inputs, ["settingTitle"]: "" }));
    settexteditorSetting("");
    setEditsettingID(null);
  }

  function handlecanceledit() {
    setEditsetting(!editsetting);
    setEditsettingID(null);
    setinputs((inputs) => ({ ...inputs, ["settingTitle"]: "" }));
    settexteditorSetting("");
  }


  function handleDetailsAdd() {
    if (inputs.detailsTitle === "") {
      setvalidationError(true);
      return;
    } else {
      setvalidationError(false);
    }
    setdetailsList([
      ...detailsList,
      {
        dateid: Date.now(),
        title: inputs.detailsTitle,
        description: texteditorSetting,
      },
    ]);
    setAdddetails(true);
    setinputs((inputs) => ({ ...inputs, ["detailsTitle"]: "" }));
    settexteditorSetting("");
  }


  function handleEditDetails(i) {
    setEditdetails(false);
    detailsList.map((item) => {
      if (item.dateid === i) {
        setinputs((inputs) => ({ ...inputs, ["detailsTitle"]: item.title }));
        settexteditorSetting(item.description);
        setEditdetailsID(i);
      }
    });
  }





  const [
    productcategoryresponse,
    productcategoryisError,
    productcategoryisLoading,
  ] = useAxios({
    url: "/api/product_category_list",
    method: "get",
    
  });
  const [producttagsresponse, producttagsisError, producttagsisLoading] =
    useAxios({
      url: "/api/product_tags_list",
      method: "get",
      
    });

  // console.log("Product Category Resp", productcategoryresponse);
  // console.log("Product Tag Resp", producttagsresponse);

  useEffect(() => {
    if (productcategoryresponse && productcategoryresponse.data.results) {
      const alteredcategory = productcategoryresponse.data.results.map(
        (item) => {
          const updatedItem = {
            ...item,
            value: item.id,
            name: item.category,
          };
          // console.log("U", updatedItem);
          return updatedItem;
        }
      );
      setList(alteredcategory);
    }

    if (producttagsresponse && producttagsresponse.data.results) {
      settypeList(producttagsresponse.data.results);
    }

    return () => {
    
    };
  }, [productcategoryresponse, producttagsresponse]);

  // console.log("Product Category List", List);
  // console.log("Ptype", producttype);
  // console.log("Ptag", typeList);

  

  return (
    <div>
      <div style={{ float: "right" }}>

        <button 
        className="shipping-popup-btn"
         onClick={handleClickOpen}>
            Shipping
          
        </button>
      </div>
      <Dialog fullWidth open={open} onClose={handleClose} className="dialog">
        <Box>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >

                <Tab label="SHIPPING" value="1" />
                <img
                  onClick={handleClose}
                  src="/Images/close.png"
                  height="32px"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "8px",
                  }}
                />
              </TabList>
            </Box>

            <TabPanel sx={{ p: 0 }} value="1">
              <DialogTitle>Shipping</DialogTitle>
              <DialogContent
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div
                  style={{ color: "#3B3B3B" }}
                  className={
                    addsetting && editsetting === true
                      ? "settings-display"
                      : "settings-none"
                  }
                >
                  {settingsList1 &&
                    settingsList1.concat(settingsList).map((item) => (
                      <div className="setting_section">
                        <p>{item.pickup_way}</p>
                        <ul>
                          <li>
                            <img
                              src="../Images/edit.png"
                              onClick={() => handleEditSetting(item.id)}
                              height="16px"
                            />
                          </li>
                          <li>
                            <img
                              src="../Images/Delete.svg"
                              onClick={() => handleDeleteSetting(item.id)}
                              height="16px"
                            />
                          </li>
                        </ul>
                      </div>
                    ))}
                  <button
                    onClick={handleAddNewSettings}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      color: "#FE9900",
                      marginBlockStart: "15px",
                      marginBlockEnd: "15px",
                    }}
                  >
                    <img src="../Images/plus.svg" /> Add
                  </button>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      className="secondarybtn"
                      onClick={() => setValue("3")}
                      sx={{visibility:"hidden"}}
                    >
                      Previous
                    </Button>
                    <Button
                      className="primarybtn"
                      onClick={
                        handlesubmitshipping
                       
                      }
                    >
                      Submit
                    </Button>
                  </div>
                </div>
                <div
                  className={
                    addsetting === false ? "settings-display" : "settings-none"
                  }
                >
                  <label style={{ width: "100%" }}>
                    Pick up way
                    <input
                      className={
                        validationError && inputs.settingTitle === ""
                          ? "validationerror"
                          : ""
                      }
                      style={{ width: "100%" }}
                      name="settingTitle"
                      onChange={handleChanged}
                      value={inputs.settingTitle}
                      maxLength={30}
                      required
                    />
                  </label>
                  <label
                    style={{
                      color: "grey",
                      marginBlockEnd: "20px",
                      width: "100%",
                    }}
                  >
                    Description
                    <TextEditor
                      settexteditor={settexteditorSetting}
                      texteditor={texteditorSetting}
                    />
                  </label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: "10px",
                      gap: "10px",
                    }}
                  >
                    <Button className="primarybtn" onClick={handleSettingAdd1}>
                      Save
                    </Button>
                    <Button
                      className="secondarybtn"
                      onClick={() => setAddsetting(!addsetting)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
                <div
                  className={
                    editsetting === false ? "settings-display" : "settings-none"
                  }
                >
                  <label style={{ width: "100%" }}>
                    Pick up way
                    <input
                      className={
                        validationError && inputs.settingTitle === ""
                          ? "validationerror"
                          : ""
                      }
                      style={{ width: "100%" }}
                      name="settingTitle"
                      onChange={handleChanged}
                      value={inputs.settingTitle}
                      maxLength={30}
                      required
                    />
                  </label>
                  <label
                    style={{
                      color: "grey",
                      marginBlockEnd: "20px",
                      width: "100%",
                    }}
                  >
                    Description
                    <TextEditor
                      settexteditor={settexteditorSetting}
                      texteditor={texteditorSetting}
                    />
                  </label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: "10px",
                      gap: "10px",
                    }}
                  >
                    <Button
                      className="primarybtn"
                      onClick={handleSettingEditSave}
                    >
                      Save
                    </Button>
                    <Button className="secondarybtn" onClick={handlecanceledit}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </DialogContent>
            </TabPanel>
      
          </TabContext>
        </Box>
      </Dialog>
      <AlertPopup alertOptions={alertOptions} handleClose={handleClosep} />
    </div>
  );
}
