import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Pagination, Tab } from '@mui/material';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { client } from "../../Helper/Client/Client";
import { UserContext } from '../../Helper/usercontext';
import {  gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import { DataGrid} from '@mui/x-data-grid';

const SearchResult = () => {
  const [vendorlist, setvendorlist] = useState([])
  const [customerlist, setcustomerlist] = useState([])
  const [venuelist, setvenuelist] = useState([])
  const [value, setValue] = useState("1");

  const mainsearchValue = useContext(UserContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // console.log(newValue)
  };

  useEffect(() => {
    client.get(`api/global_search?query=${mainsearchValue}`).then((response)=>{
      setvendorlist(response.data.results.vendor_list)
      setvenuelist(response.data.results.venue_list)
      setcustomerlist(response.data.results.customer_list)
    })
  }, [mainsearchValue])
  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
    return (
      <Pagination
      className='pagination'
      color='warning'
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }

  function getNameEmail(params) {
    const firstname = params.row.first_name;
    const lastname = params.row.last_name;
    const email = params.row.email;
    const image = params.row.user_image;
    return firstname + "+" + lastname + "+" + email + "+" + image;
  }

  function getCityCountry(params) {
    const city = params.row.city;
    const country = params.row.country && ", " + params.row.country;
    return `${city + country}`;
  }

  const columns = [
    {
      field: "name&email",
      headerName: "VENDOR NAME",
      width: 200,
      valueGetter: getNameEmail,
      renderCell: (params) => (
        <>
          {params.value.split("+")[3] !== "null" ? (
            <img
              src={params.value.split("+")[3]}
              height="30px"
              width="30px"
              alt=""
            />
          ) : (
            <img
              src="/Images/placeholder.png"
              height="30px"
              width="30px"
              alt="profile placholder"
            />
          )}
          <div style={{ marginLeft: "10px" }}>
            <b style={{ color: "rgb(53, 53, 53)" }}>
              {params.value.split("+")[0]} {params.value.split("+")[1]}
            </b>
            <small style={{ color: "grey", display: "block" }}>
              {params.value.split("+")[2]}
            </small>
          </div>
        </>
      ),
    },
    {
      field: "city&country",
      headerName: "LOCATION",
      width: 200,
      valueGetter: getCityCountry,
    },
    {
      field: "phone_1",
      headerName: "PHONE",
      width: 150,
    },
    {
      field: "date_joined",
      headerName: "DATE",
      align: "center",
      headerAlign: "center",
      type: "date",
      width: 150,
      renderCell: (params) =>
        params.value.split("T")[0].split("-")[2] +
        "." +
        params.value.split("T")[0].split("-")[1] +
        "." +
        params.value.split("T")[0].split("-")[0],
    },
    {
      field: "is_active",
      headerName: "STATUS",
      headerAlign: "center",
      align: "center",
      width: 120,
      valueGetter: (params) => (params.row.is_active ? "Active" : "Blocked"),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            width: "100px",
            padding: "7px",
            backgroundColor: params.value === "Active" ? "#e8f9ef" : "#ffe7e6",
            color: params.value === "Active" ? "#20A144" : "#EB5A52",
          }}
        >
          {params.value}{" "}
        </span>
      ),
    },
   
  ];
  
  const columnscustomer = [
    {
      field: "name&email",
      headerName: "CUSTOMER NAME",
      width: 200,
      valueGetter: getNameEmail,
      renderCell: (params) => (
        <>
          {params.value.split("+")[3] !== "null" ? (
            <img
              src={params.value.split("+")[3]}
              height="30px"
              width="30px"
              alt=""
            />
          ) : (
            <img
              src="/Images/placeholder.png"
              height="30px"
              width="30px"
              alt="profile placholder"
            />
          )}
          <div style={{ marginLeft: "10px" }}>
            <b style={{ color: "rgb(53, 53, 53)" }}>
              {params.value.split("+")[0]} {params.value.split("+")[1]}
            </b>
            <small style={{ color: "grey", display: "block" }}>
              {params.value.split("+")[2]}
            </small>
          </div>
        </>
      ),
    },
    {
      field: "city&country",
      headerName: "LOCATION",
      width: 200,
      valueGetter: getCityCountry,
    },
    {
      field: "phone_1",
      headerName: "PHONE",
      width: 150,
    },
    {
      field: "date_joined",
      headerName: "DATE",
      align: "center",
      headerAlign: "center",
      type: "date",
      width: 150,
      renderCell: (params) =>
        params.value.split("T")[0].split("-")[2] +
        "." +
        params.value.split("T")[0].split("-")[1] +
        "." +
        params.value.split("T")[0].split("-")[0],
    },
    {
      field: "is_active",
      headerName: "STATUS",
      headerAlign: "center",
      align: "center",
      width: 120,
      valueGetter: (params) => (params.row.is_active ? "Active" : "Blocked"),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            width: "100px",
            padding: "7px",
            backgroundColor: params.value === "Active" ? "#e8f9ef" : "#ffe7e6",
            color: params.value === "Active" ? "#20A144" : "#EB5A52",
          }}
        >
          {params.value}{" "}
        </span>
      ),
    },
   
  ];
  function getTitleDesc(params) {
    const title = params.row.title
    const desc = params.row.description
    const image = params.row.image_list[0]?.image_url
    // console.log('image',image)
    return title + '+' + desc + '+' + image;
  }
  function getCityCountryvenue(params) {
    const city = params.row.area
    const country = params.row.location && (', ' + params.row.location)
    return `${city + country}`;
  }

  const columnsvenue = [
    {
      field: 'title&desc',
      headerName: 'VENUE NAME',
      width: 200,
      valueGetter: getTitleDesc,
      renderCell: (params) => (
        <>{params.value.split('+')[2] !== 'undefined' ? <img src={params.value.split('+')[2]} height='30px' width='30px' alt='' /> : <img src="/Images/venueplaceholder.png" height='30px' width='30px' alt='profile placholder' />}<div style={{ marginLeft: '10px' }}><b style={{ color: 'rgb(53, 53, 53)' }}>{params.value.split('+')[0]}</b><small style={{ color: 'grey', display: 'block' }}>{params.value.split('+')[1]}</small></div></>)
    },
    {
      field: 'city&country',
      headerName: 'LOCATION',
      width: 400,
      valueGetter: getCityCountryvenue,
    },
  
    {
      field: 'status',
      headerName: 'STATUS', headerAlign: 'center', align: 'center',
      width: 120,
      valueGetter: (params) =>
        params.row.status ? 'Active' : 'Disable',
      renderCell: (params) => (
        <span style={{ textAlign: 'center', width: '100px', padding: '7px', backgroundColor: params.value === "Active" ? "#e8f9ef" : "#ffe7e6", color: params.value === 'Active' ? '#20A144' : '#EB5A52' }}>{params.value} </span>)

    },
   
  ];
  

  return (
    <article>
    <h4>Search</h4>
    <TabContext  value={value} >
      <TabList onChange={handleChange}  >
        <Tab label="Vendor" value="1"/>
        <Tab label="Customer" value="2"/>
        <Tab label="Venue" value="3"/>
      </TabList>
      <TabPanel value="1">
        <div  style={{ height: "650px" }}>
      <DataGrid
                sx={{ mt: 2 }}
                rows={vendorlist}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowId={(row) => row.id}
                checkboxSelection
                pagination
                components={{
                  Pagination: CustomPagination,
                }}
              />
        </div>
      </TabPanel>
      <TabPanel value="2">
      <div  style={{ height: "650px" }}>
      <DataGrid
                sx={{ mt: 2 }}
                rows={customerlist}
                columns={columnscustomer}
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowId={(row) => row.id}
                checkboxSelection
                pagination
                components={{
                  Pagination: CustomPagination,
                }}
              />
        </div>
        </TabPanel>
        <TabPanel value="3">
        <div style={{ height: '650px' }}>
              <DataGrid
                sx={{ mt: 2 }}
                rows={venuelist}
                columns={columnsvenue}
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowId={(row) => row.id}
                checkboxSelection
                pagination
                components={{
                  Pagination: CustomPagination
                }}
              />
            </div>
        
        </TabPanel>
    </TabContext>
  </article>
  )
}

export default SearchResult