
import React, { useState, useEffect } from "react";
import { Button, Dialog, MenuItem } from "@mui/material";
import "./Popup.css";
import DropdownSelect from "../../Components/DropdownSelect/DropdownSelect";
import useAxios from "../../Helper/Client/useAxios";
import { client } from "../../Helper/Client/Client";

const PopupProductDetail = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");
  const [status, setstatus] = useState("");
  const [productdetails, setProductdetails] = useState([]);
  const [productimage, setproductimage] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.handleClose();
    setOpen(false);
  };

  const [response, isError, isLoading] = useAxios({
    url: `/api/product_details/${props.detailid}`,
    method: "get",
  });
  useEffect(() => {
    if (response && response.data.results) {

      setProductdetails(response.data.results);
      setstatus(
        response.data.status === "" ? "no reply" : response.data.status
      );
      setproductimage(response.data.results.product_images);
    }
   
  }, [response]);
  // console.log("Product Details", productdetails);
  // console.log("Product Imgs", productimage);
  function handleSubmit() {
    const body = {
      customer_id: productdetails.customer_id,
      status: status,
      address: productdetails.address,
      message: productdetails.message,
    };
    client
      .put(`/api/contactus_update/${props.detailid}`, body)
      .then((response) => {
        handleClose();
        client.get("/api/contactus_paginated_list").then((response) => {
          props.setpage(1);
          props.setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          );
          props.setContactlist(response.data.results);
        });
      });
  }

  // Formatting A Specific Date
  const build_date = productdetails.buildup_date;
  // console.log("Build Date", build_date);

  const end_date = productdetails.edit_date;
  // console.log("End Date", end_date);

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return (
      [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join("-") +
      " " +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(":")
    );
  }

  const bdate = formatDate(new Date(build_date));
  // console.log("Product Build Date", bdate);

  const edate = formatDate(new Date(end_date));
  // console.log("Product End Date", edate);

  //
  return (
    <div>
   
      <MenuItem onClick={handleClickOpen}>View Details</MenuItem>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        className="dialog"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px", 
            },
          },
        }}
      >
        <div style={{ height: "796px", padding: "24px" }}>
          <img
            onClick={handleClose}
            src="/Images/close.png"
            height="32px"
            style={{
              position: "absolute",
              right: "10px",
              top: "8px",
            }}
          />
          <button
            style={{
              marginTop: "30px",
              marginLeft: "30px",
              marginRight: "30px",
              marginBottom: "30px",
            }}
            className={
              productdetails?.status
                ? "bannerDetails-btnG"
                : "bannerDetails-btnR"
            }
          >
            {productdetails?.status ? "Active" : "Disable"}
          </button>
          <div style={{ display: "flex", flexDirection: "row", gap: "30px" }}>
            {productimage.length > 0 ? (
              <>
                <div style={{ display: "grid" }}>
                  <img
                    src={productdetails.product_images[0]?.image_url}
                    height="180px"
                    width="180px"
                  />
                </div>
              </>
            ) : (
              <div>
                <img
                  src="/Images/product1.png"
                  height="150px"
                  alt="Demo Product Image"
                />
                <p>Demo Product Image</p>
              </div>

            )}
          

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <span>
                Tags:{" "}
                {productdetails.product_tags?.map((item) => (
                  <span key={item.id}>#{item.tag_name} </span>
                ))}
              </span>
           
              <h5>{productdetails.product_name}</h5>
        
              <h5>{productdetails.product_price}</h5>

              {productdetails.product_details?.map((item) => (
  
                <span style={{ paddingTop: "15px",width:"400px",whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis" }}>{item.description}</span>
              ))}
            </div>
            
          </div>
          <div
            style={{
              margin: "30px",
            }}
          ></div>
        
          <p className="heading">Details</p>
        
          <div>
            <div
              style={{
                borderBottom: "solid #E8E8E8",
                display: "grid",
                gridTemplateColumns: "200px auto",
                paddingTop: "10px",
                paddingBottom: "10px",
                alignItems: "center",
              }}
            >
              <span>Product code</span>
              <p
                style={{
                  margin: "0px",
                  padding: "10px",
                }}
              >
                {productdetails.product_code_number}
              </p>
            </div>
            <div
              style={{
                borderBottom: "solid #E8E8E8",
                display: "grid",
                gridTemplateColumns: "200px auto",
                alignItems: "center",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <span>Category</span>
              <p
                style={{
                  margin: "0px",
                  padding: "10px",
                }}
              >
                {productdetails.product_category_name}
              </p>
            </div>
            <div
              style={{
                borderBottom: "solid #E8E8E8",
                display: "grid",
                gridTemplateColumns: "200px auto",
                alignItems: "center",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <span>Shipping</span>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {productdetails.shipping_methods?.map((item) => (
                  <div
                    style={{
                      margin: "0px",
                      padding: "10px",
                      
                    }}
                  >
                    {item.description}
                  </div>
                ))}
              </div>

              
            </div>
            <div
              style={{
                borderBottom: "solid #E8E8E8",
                display: "grid",
                gridTemplateColumns: "200px auto",
                alignItems: "center",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <span>Editor</span>
              <p
                style={{
                  margin: "0px",
                  padding: "10px",
                }}
              >
                {productdetails.editor}
              </p>
            </div>
            <div
              style={{
                borderBottom: "solid #E8E8E8",
                display: "grid",
                gridTemplateColumns: "200px auto",
                alignItems: "center",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <span>Edit date</span>
              <p
                style={{
                  margin: "0px",
                  padding: "10px",
                }}
              >
                {edate}
              </p>
            </div>
            <div
              style={{
                borderBottom: "solid #E8E8E8",
                display: "grid",
                gridTemplateColumns: "200px auto",
                alignItems: "center",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <span>Build up date</span>
              <p
                style={{
                  margin: "0px",
                  padding: "10px",
                }}
              >
                {bdate}
              </p>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default PopupProductDetail;