import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React, { useEffect, useState, useRef } from "react";
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Autocomplete, Button, DialogContent, TextField } from "@mui/material";
import DropdownSelect from "../../Components/DropdownSelect/DropdownSelect";
import { useParams } from "react-router-dom";
import useAxios from "../../Helper/Client/useAxios";
import Productshippingpopup from "../Popup/Productshippingpopup";
import Productdetailspopup from "../Popup/Productdetailspopup";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { client } from "../../Helper/Client/Client";
import AlertPopup from "../../Components/AlertPopup/AlertPopup";
import { styled } from "@mui/system";
import Productshippingpopupedit from "../Popup/Productshippingpopupedit";
import Productdetailspopupedit from "../Popup/Productdetailspopupedit";
import DragDrop from "../../Components/DragDrop/DragDrop";

function ProductDetails() {
  let { id } = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [productDetail, setproductDetail] = useState([]);
  const [value, setValue] = React.useState("1");

  const [file, setfile] = useState([]);
  const [filelist, setfilelist] = useState([]);
  const [imagelist, setimagelist] = useState([]);
  const [deleteImage, setdeleteImage] = useState(null);
  const [removeImageIdList, setremoveImageIdList] = useState([]);

  const [getimagelist, setgetimagelist] = useState([]);

  const [producttype, setproducttype] = useState("");

  const [edit, setedit] = useState(false);

  const [featureList, setfeatureList] = useState([]);
  const [typeList, settypeList] = useState([]);
  const [tagvalue, settagvalue] = useState([]);


  const [status, setstatus] = useState("");
  const [validationError, setvalidationError] = useState(false);

  const [shippingList, setshippingList] = useState([]);
  const [shippingEdited, setshippingEdited] = useState(false);

  const [detailsList, setdetailsList] = useState([]);
  const [detailsEdited, setdetailsEdited] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //

  const dragItem = useRef();
  const dragOverItem = useRef();
  const dragStart = (e, position) => {
    dragItem.current = position;

  };
  const drop = (e) => {
    const copyListItems = [...filelist];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setfilelist(copyListItems);
  };

  useEffect(() => {
    setfilelist(file);
  }, [file]);

  useEffect(() => {
    deleteImage && setfilelist(filelist.filter((item) => item !== deleteImage));
  }, [deleteImage]);

  function handleRemoveImage(id) {
    setimagelist(imagelist.filter((item) => item.id !== id));
    setremoveImageIdList(removeImageIdList.concat(id));
  }

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    
  };

  const [editbar, setEditbar] = useState("");
  const showEditbar = (event, id) => {
    setAnchorEl(event.currentTarget);
    // console.log(id);
    setEditbar(id);
  };
  const handleClose = () => {
    setEditbar(null);
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl2);
  const handleClickaction = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseaction = () => {
    setAnchorEl2(null);
  };
  // **** Images
  const fileChange = (event) => {
    setfilelist(filelist.concat(event.target.files[0]));
  };

  const handleSubmitimage = () => {
    let form_data = new FormData();
    form_data.append("product_id", id);
  
    filelist[0] && form_data.append("image_url", filelist[0]);
   
    client
      .post(`api/product_image_update/${id}`, form_data)
      .then(() => {
        client.get(`/api/product_details/${id}`).then((response) => {
          setalertOptions({
            alertType: "success",
            alertOpen: true,
            alertContent: "Submitted Successfully!",
          });
          setfilelist([]);
          let list = [];
          response.data.results.product_images.map((item) => {
            list.push({ image: item.image_url, id: item.id });
       
          });
          setgetimagelist(list);
        });
      })
      .catch(() => {
        setalertOptions({
          alertType: "error",
          alertOpen: true,
          alertContent: "Submission Failed!",
        });
      });
  };



  function handleImageDelete(i) {
    client.delete(`/api/product_image_delete/${i}`).then(() =>
      client.get(`/api/product_details/${id}`).then((response) => {
        let list = [];
        response.data.results.product_images.map((item) => {
          list.push({ image: item.image_url, id: item.id });
    
        });
        setgetimagelist(list);
      })
    );

  }

  function handlefiledelete() {
    setfilelist([]);
  }

  // ****
  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });

  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };

  const handleSubmit = () => {
     if (inputs.productname === "" || inputs.productprice === ""||(imagelist.length === 0 && filelist.length===0)) {
        setvalidationError(true);
        console.log("true")
        console.log(imagelist.length)
        return;
      } else {
        console.log("false")
        setvalidationError(false);
      }
    let form_data = new FormData();
    form_data.append("product_name", inputs.productname);
    form_data.append("product_price", inputs.productprice);
    form_data.append("offer_tag", "Basic Essentials");
    form_data.append("product_category_id", producttype);
    form_data.append(
      "product_tags",
      JSON.stringify(
        tagvalue.map((item) => {
          const updatedItem = {
            ...item,
            tag_id: item.id,
            product_id: item.productid,
            id: undefined,
          };
          return updatedItem;
        })
      )
    );
    form_data.append("status", status ? 1 : 0);
    filelist.length > 0 &&
      filelist.map((item) => form_data.append("image_url", item));
      console.log(imagelist.length)
     
    client
      .put(`api/product_update/${id}`, form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(() => {
        setalertOptions({
          alertType: "success",
          alertOpen: true,
          alertContent: "Submitted Successfully!",
        });
        setedit(false);
        client
          .get(`/api/product_details/${id}`)
          .then((response) => setproductDetail(response.data.results));
      })
      .catch(() => {
        setalertOptions({
          alertType: "error",
          alertOpen: true,
          alertContent: "Submission Failed!",
        });
      });
  };

  function handleCancel() {
    setedit(false);
  }


  const [response, isError, isLoading] = useAxios({
    url: `/api/product_details/${id}`,
    method: "get",
 
  });

  const [responseShipping, isErrorShipping, isLoadingShipping] = useAxios({
    url: `/api/product_shippingmethods`,
    method: "get",
 
  });

  const [responseDetails, isErrorDetails, isLoadingDetails] = useAxios({
    url: `/api/product_details_by_product/${id}`,
    method: "get",
 
  });

  useEffect(() => {
    if (response && response.data.results) {
      setproductDetail(response.data.results);

      settagvalue(
        response.data.results.product_tags.map((item) => {
          const updatedItem = {
            id: item.tag_id,
            tag: item.tag_name,
            productid: item.product_id,
          };
          return updatedItem;
        })
      );
      setproducttype(response.data.results.product_category_id);
      setstatus(response.data.results.status);
      setimagelist(response.data.results.product_images);
    }
    if (responseShipping && responseShipping.data.data) {
      setshippingList(responseShipping.data.data);
    }
    if (responseDetails && responseDetails.data.data) {
      setdetailsList(responseDetails.data.data);
    }
  }, [response, responseShipping, responseDetails]);

  const [categoryresponse, categoryisError, categoryisLoading] = useAxios({
    url: "/api/product_category_list",
    method: "get",
 
  });
  const [tagresponse, tagisError, tagisLoading] = useAxios({
    url: "/api/product_tags_list",
    method: "get",
 
  });

  useEffect(() => {
    if (categoryresponse && categoryresponse.data.results) {
      const alteredcategory = categoryresponse.data.results.map((item) => {
        const updatedItem = {
          ...item,
          value: item.id,
          name: item.category,
        };
   
        return updatedItem;
      });
      setfeatureList(alteredcategory);
    }

    if (tagresponse && tagresponse.data.results) {
      settypeList(tagresponse.data.results);
    }

    return () => {
 
    };
  }, [categoryresponse, tagresponse]);

  // console.log("Product List", productDetail);
  // console.log("Product Category", featureList);

  const initialData = {
    productname: "",
    productprice: "",
  };
  const [inputs, setinputs] = useState(initialData);

  useEffect(() => {
    setinputs((inputs) => ({
      ...inputs,
      productname: productDetail.product_name,
      productprice: productDetail.product_price,
      productcategory: productDetail.product_category_name,
    }));
  }, [productDetail]);

  function handleChanged(e) {
    const { name, value } = e.target;
    setinputs((inputs) => ({ ...inputs, [name]: value }));
  }

  const Input = styled("input")({
    display: "none",
  });

  const componentRef = useRef([]);

  // console.log("taglist", typeList);
  // console.log("tagvalue", tagvalue);
  const columns = [
    {
      field: "id",
      headerName: "TRANSACTION CODE ",
      width: 200,
    },
    {
      field: "customer_name",
      headerName: "CUSTOMER NAME",
      width: 150,
    },
    {
      field: "amount",
      headerName: "PRICE",
      width: 150,
      align: "center",
      headerAlign: "center",
      type: "number",
    },
    {
      field: "date",
      headerName: "DATE",
      align: "center",
      headerAlign: "center",
      type: "date",
      width: 180,
      renderCell: (params) =>
        params.value.split("T")[0].split("-")[2] +
        "." +
        params.value.split("T")[0].split("-")[1] +
        "." +
        params.value.split("T")[0].split("-")[0],
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 160,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            width: "110px",
            padding: "7px",
            backgroundColor: params.value === "paid" ? "#e8f9ef" : "#ffe7e6",
            color: params.value === "paid" ? "#20A144" : "#EB5A52",
          }}
        >
          {"Completed"}{" "}
        </span>
      ),
    },
    {
      field: "ID",
      headerName: "",
      width: 10,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.row.id,
      renderCell: (params) => (
        <>
          <IconButton onClick={(event) => showEditbar(event, params.value)}>
            <img src="../Images/dots.png" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={editbar === params.value ? true : false}
            onClose={handleClose}
          ></Menu>
        </>
      ),
    },
  ];

  function CustomToolbar() {
    return (
      <div style={{ position: "absolute", right: "0px", top: "-210px" }}>
        <GridToolbarContainer>
          <GridToolbarExport
            sx={{
              color: "black",
              bgcolor: "#F5F5F5",
              textTransform: "capitalize",
              p: 1.2,
              pr: 3,
            }}
          />
        </GridToolbarContainer>
      </div>
    );
  }


  // console.log("IMglist", getimagelist);
  // console.log("ShL", shippingList);
  // console.log("DEl", detailsList);

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

 
  function handleShippingDelete(shippingid) {
    setshippingList(shippingList.filter((item) => item.id !== shippingid));
    setshippingEdited(true);
  }

  function handleProductShippingSubmit() {
    client
      .post(`api/product_shippingmethods_add`, shippingList)
      .then(() => {
        setalertOptions({
          alertType: "success",
          alertOpen: true,
          alertContent: "Submitted Successfully!",
        });
        setshippingEdited(false);
        client
          .get(`/api/product_shippingmethods`)
          .then((response) => setshippingList(response.data.data || []));
      })
      .catch(() => {
        setalertOptions({
          alertType: "error",
          alertOpen: true,
          alertContent: "Submission Failed!",
        });
      });
  }

  function handleShippingsCancel() {
    client
      .get(`/api/product_shippingmethods`)
      .then((response) => {
        setshippingEdited(false);
        setshippingList(response.data.data);
      });
  }

  //
  function handleDetailDelete(detailid) {
    setdetailsList(detailsList.filter((item) => item.id !== detailid));
    setdetailsEdited(true);
  }

  function handleProductDetailSubmit() {
    client
      .post(`api/product_details_add/${id}`, detailsList)
      .then(() => {
        setalertOptions({
          alertType: "success",
          alertOpen: true,
          alertContent: "Submitted Successfully!",
        });
        setdetailsEdited(false);
        client
          .get(`/api/product_details_by_product/${id}`)
          .then((response) => setdetailsList(response.data.data || []));
      })
      .catch(() => {
        setalertOptions({
          alertType: "error",
          alertOpen: true,
          alertContent: "Submission Failed!",
        });
      });
  }

  function handleDetailsCancel() {
    client.get(`/api/product_details_by_product/${id}`).then((response) => {
      setdetailsEdited(false);
      setdetailsList(response.data.data);
    });
  }
  // console.log("product Details", productDetail);

  // console.log("product image list", filelist);
 
  return (
    <article>
      <h4> {productDetail.product_name}</h4>
      <TabContext value={value} style={{ padding: "5px" }}>
        <TabList onChange={handleChange} aria-label="Product details">
          <Tab label="All Details" value="1" />
          <Tab label="Shipping" value="2" />
          <Tab label="Details" value="3" />
        </TabList>
        <TabPanel value="1">
          <DialogContent>
            <div className="productdetails-table">
              {!edit && (
                <Accordion
                  sx={{
                    border: "1px solid rgb(232, 232, 232)",
                    boxShadow: "none",
                    mb: 2,
                    pb: 1,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<img src="../Images/Arrow-Down.svg" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h5>Information</h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "60vw",
                      }}
                    >
                      <p>
                        Product Name :{" "}
                        <span className="span">
                          {productDetail.product_name}
                        </span>
                      </p>

                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "28vw 20vw 10vw",
                          gap: 20,
                        }}
                      >
                        <p>
                          Product Price :{" "}
                          <span className="span">
                            {productDetail.product_price}
                          </span>
                        </p>
                        <p>
                          Product Category :{" "}
                          <span className="span">
                            {productDetail.product_category_name}
                          </span>
                        </p>

                        <p>
                          {" "}
                          Status :{" "}
                          <span className="span">
                            {productDetail.status ? "Active" : "Blocked"}
                          </span>
                        </p>
                      </div>
                      <p>
                        Product Tags :{" "}
                        {productDetail.product_tags?.map((item) => (
                          <span key={item.id}>#{item.tag_name} </span>
                        ))}
                      </p>
                    </div>
                    <img
                      src="/Images/edit.svg"
                      style={{ float: "right" }}
                      onClick={() => setedit(true)}
                    />
                  </AccordionDetails>
                </Accordion>
              )}
            </div>
            {edit && (
              <div
                className="product-tab-details"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "15px",
                }}
              >
                <h5>Information</h5>
                <label className="mt20">
                  Product Name
                  <input
                    className={
                      validationError && inputs.productname === ""
                        ? "validationerror mt10"
                        : "mt10"
                    }
                    style={{ width: "100%" }}
                    maxlength="45"
                    type="text"
                    required
                    name="productname"
                    value={inputs.productname}
                    onChange={handleChanged}
                  />
                </label>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <label
                    className="mt20"
                    style={{ marginRight: "20px", width: "230px" }}
                  >
                    Product Price
                    <input
                      className={
                        validationError && inputs.productprice === ""
                          ? "validationerror"
                          : undefined
                      }
                      name="productprice"
                      type="text"
                      required
                      value={inputs.productprice}
                      onChange={handleChanged}
                      size="12"
                    />{" "}
                  </label>
                  <label
                    className="mt20"
                    style={{ width: "200px", marginRight: "50px" }}
                  >
                    Product Category
                    <DropdownSelect
                      value={producttype}
                      setdropDownValue={setproducttype}
                      values={featureList}
                    />
                  </label>
                </div>
                <label className="mt20">
                  Tags
                  <Autocomplete
                    className="multiselect"
                    multiple
                    style={{
                      backgroundColor: "#f5f5f5",
                      padding: "10px",
                      borderRadius: "8px",
                    }}
                    options={typeList}
                    value={tagvalue}
                    onChange={(event, newValue) => {
                      settagvalue(newValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.tag === value.tag
                    }
                    getOptionLabel={(option) => option.tag}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" />
                    )}
                  />
                </label>

                <label className="mt20">
                  Status
                  <DropdownSelect
                    validationError={validationError}
                    value={status}
                    setdropDownValue={setstatus}
                    values={[
                      { name: "Active", value: true },
                      { name: "Disable", value: false },
                    ]}
                  />
                </label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    marginTop: "30px",
                    gap: "2vw",
                  }}
                >
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto",
                      width: "50%",
                    }}
                  >
                    {!filelist.length > 0 && !imagelist.length > 0 && (
                      <DragDrop
                        deleteImage={deleteImage}
                        setfile={setfile}
                        filelist={filelist}
                        validationError={validationError}
                      />
                    )}
                    {filelist.length > 0 &&
                      filelist.map((item, index) => (
                        <div
                          key={index}
                          draggable
                          onDragStart={(e) => dragStart(e, index)}
                          onDragEnter={(e) => dragEnter(e, index)}
                          onDragEnd={drop}
                          style={{
                            display: "grid",
                            gridTemplateColumns: "2vw 9vw 9vw 5vw 4vw",
                            justifyItems: "center",
                            verticalAlign: "center",
                            border: "1px solid #636363",
                            padding: "8px",
                            borderRadius: "5px",
                            marginBottom: "10px",
                          }}
                        >
                          
                          <p></p>
                          <img
                            src={URL.createObjectURL(item)}
                            style={{ margin: "auto" }}
                            height="74px"
                            width="74px"
                          />
                         
                          <p></p>
                          <p></p>
                          <img
                            style={{ margin: "auto" }}
                            src="/Images/Delete.svg"
                            height="16px"
                            onClick={() => setdeleteImage(item)}
                          />
                        </div>
                      ))}
                    {(imagelist.length > 0 || filelist.length > 0) && (
                      <>
                        {imagelist.length > 0 &&
                          imagelist.map((item, index) => (
                            <div
                              key={index}
                              style={{
                                display: "grid",
                                gridTemplateColumns: "2vw 9vw 9vw 5vw 4vw",
                                justifyItems: "center",
                                verticalAlign: "center",
                                border: "1px solid #636363",
                                padding: "8px",
                                borderRadius: "5px",
                                marginBottom: "10px",
                              }}
                            >
                              {" "}
                            
                              <p></p>
                             
                              <img
                                src={item.image_url}
                                style={{ margin: "auto" }}
                                height="74px"
                                width="74px"
                              />
                             
                              <p></p>
                              <p></p>
                              <img
                                style={{ margin: "auto" }}
                                src="/Images/Delete.svg"
                                height="16px"
                                onClick={() => handleRemoveImage(item.id)}
                              />
                            </div>
                          ))}
                      </>
                    )}
                  </div>
                  <div></div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "10px",
                  }}
                >
                  <Button
                    className="primarybtn"
                    onClick={handleSubmit}
                    style={{ marginRight: "10px" }}
                  >
                    Save
                  </Button>
                  <Button className="secondarybtn" onClick={handleCancel}>
                    Cancel
                  </Button>
                </div>
              </div>
            )}
          </DialogContent>
        </TabPanel>

        <TabPanel
          value="2"
          style={{
            padding: "0px",
            padding: "15px",
            border: "1px solid #e8e8e8",
          }}
        >
          <div style={{ color: "#3B3B3B" }}>
            <h5>Shipping</h5>
            {shippingList.length > 0 &&
              shippingList.map((item) => (
                <div className="setting_section" style={{ width: "70%" }}>
                  <p>{item.pickup_way}</p>
                  <ul>
                    <li>
                      <Productshippingpopupedit
                        setshippingEdited={setshippingEdited}
                        setshippingList={setshippingList}
                        shippingList={shippingList}
                        item={item}
                      />
                    </li>
                    <li>
                      <img
                        src="../Images/Delete.svg"
                        height="16px"
                        onClick={() => handleShippingDelete(item.id)}
                        className="pointer"
                      />
                    </li>
                  </ul>
                </div>
              ))}
            <Productshippingpopup
              setshippingEdited={setshippingEdited}
              setshippingList={setshippingList}
              shippingList={shippingList}
            />
            {shippingEdited && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "20px",
                }}
              >
                <Button
                  className="primarybtn"
                  onClick={handleProductShippingSubmit}
                  style={{ marginRight: "10px" }}
                >
                  Save
                </Button>
                <Button
                  className="secondarybtn"
                  onClick={handleShippingsCancel}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </TabPanel>
        <TabPanel
          value="3"
          style={{
            padding: "0px",
            padding: "15px",
            border: "1px solid #e8e8e8",
          }}
        >
          <div style={{ color: "#3B3B3B" }}>
            <h5>Details</h5>
            {detailsList.length > 0 &&
              detailsList.map((item) => (
                <div className="setting_section" style={{ width: "70%" }}>
                  <p>{item.title}</p>
                  <ul>
                    <li>
                      <Productdetailspopupedit
                        setdetailsEdited={setdetailsEdited}
                        setdetailsList={setdetailsList}
                        detailsList={detailsList}
                        item={item}
                      />
                    </li>
                    <li>
                      <img
                        src="../Images/Delete.svg"
                        height="16px"
                        onClick={() => handleDetailDelete(item.id)}
                        className="pointer"
                      />
                    </li>
                  </ul>
                </div>
              ))}
            <Productdetailspopup
              setdetailsEdited={setdetailsEdited}
              setdetailsList={setdetailsList}
              detailsList={detailsList}
            />
            {detailsEdited && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "20px",
                }}
              >
                <Button
                  className="primarybtn"
                  onClick={handleProductDetailSubmit}
                  style={{ marginRight: "10px" }}
                >
                  Save
                </Button>
                <Button className="secondarybtn" onClick={handleDetailsCancel}>
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </TabPanel>
      </TabContext>
      <AlertPopup alertOptions={alertOptions} handleClose={handleClosep} />
    </article>
  );
}
export default ProductDetails;
