import { Navigate } from 'react-router-dom';

const PrivateRoute=({ children, redirectTo }) =>{
    return (
        localStorage.getItem('userhk')?
         children
            : 
            <Navigate to={redirectTo} />
            )
}
export default PrivateRoute;