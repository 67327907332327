import React, { useEffect, useState } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button, Pagination } from '@mui/material';
import SearchBar from '../../Components/SearchBar/SearchBar';
import PopupGift from '../Popup/PopupGift';
import PopupDelete from '../Popup/PopupDelete';
import PopupGiftEdit from '../Popup/PopupGiftEdit';
import { client } from '../../Helper/Client/Client';
import useAxios from '../../Helper/Client/useAxios';
import { history } from '../../Helper/History/history';
import PopupDeleteAll from '../Popup/PopupDeleteAll';


function Gift() {
  const [giftList, setgiftList] = useState([])
  const [pageCount, setpageCount] = useState(0)
  const [page, setpage] = useState(1)
  const [listlength, setlistlength] = useState(0)
  const [value, setValue] = React.useState('1');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [editbar, setEditbar] = useState('');
  const [selectionModel, setSelectionModel] = useState([]);
  const [searchvalue, setsearchvalue] = useState('')

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // table view(edit/delete)

  const showEditbar = (event, id) => {
    setAnchorEl(event.currentTarget);
 
    setEditbar(id);
  };
  const handleClose = () => {
    setEditbar(null);
  };

  const open = Boolean(anchorEl2);
  const handleClickaction = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseaction = () => {
    setAnchorEl2(null);
  };



  useEffect(() => {
    client.get(`/api/gift_list?search=${searchvalue}`).then((response) => {
        if (response.data.results) {
          setgiftList(response.data.results);
          setpage(1)
          setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          );
        } else {
          setgiftList([]);
        }
    })
  }, [searchvalue])

  useEffect(() => {
    if (giftList?.length !== undefined) {
      setlistlength(giftList.length)
    }
  }, [giftList])

  function CustomPagination() {

    return (
      <Pagination
        className='pagination'
        color='warning'
        count={pageCount}
        page={page}
        onChange={(event, value) => {
          client.get(`/api/gift_paginated_list?page=${value}`).then((response) => {
            setgiftList(response.data.results);
          })
          setpage(value)
        }}
        showFirstButton showLastButton

      />
    );
  }

  function handleDelete(i) {
    client.delete(`/api/gift_delete/${i}`).then(() => {
      handleClose()
      client.get(`/api/gift_paginated_list`).then((response) => {
        if(response.data.results){
          setpage(1);
          setgiftList(response.data.results);
      setpageCount(
          Math.ceil(response.data.count / response.data.results.length)
        )
        }
        else{
          setpage(0);
          setpageCount(0)
          setgiftList([])
        }

      })
    })
  }

  const handlebulkDelete = () => {
    client.delete(`/api/gift_bulk_delete`, { data: { "gift_id": selectionModel } }).then(() => {
      setAnchorEl2(null);
      client.get(`/api/gift_paginated_list`).then((response) => {
        if(response.data.results){
          setpage(1);
          setgiftList(response.data.results);
      setpageCount(
          Math.ceil(response.data.count / response.data.results.length)
        )
        }
        else{
          setpage(0);
          setpageCount(0)
          setgiftList([])
        }
      })
    })
  }
  function giftNameandCount(params){
    const name=params.row.gift_name
    const count=params.row.unseen_purchased_count
    return name +'+'+count
  }

  

  const columns = [
    {
      field: 'gift_name',
      headerName: 'TITLE',
      width: 200,
      valueGetter:giftNameandCount,
      renderCell:(params)=>(<div style={{positon:'relative'}}>{params.value.split("+")[0]}{params.value.split("+")[1]>0 && <span style={{position:'absolute',left:'232px',background: '#FD7972',borderRadius: "4px",color:'white',width:"18px",height:'18px',textAlign:'center',fontWeight: '600',fontSize: "10px"}}>{params.value.split("+")[1]}</span>}</div>)
    },
    {
      field: 'upload_date',
      headerName: 'UPLOAD DATE',
      width: 150,
      align: 'center', headerAlign: 'center', type: 'date',
      renderCell: (params) => (params.value.split('T')[0].split('-')[2] + '.' + params.value.split('T')[0].split('-')[1] + '.' + params.value.split('T')[0].split('-')[0])
    },
    {
      field: 'points_required',
      headerName: 'CREDIT',
      width: 150,
      align: 'center', headerAlign: 'center'
    },
    {
      field: 'status',
      headerName: 'STATUS',
      align: 'center', headerAlign: 'center',
      width: 160,
      renderCell: (params) => (
        <span style={{ textAlign: 'center', width: '100px', padding: '7px', backgroundColor: params.value ? "#e8f9ef" : "#ffe7e6", color: params.value ? '#20A144' : '#EB5A52' }}>{params.value ? 'Active' : 'Disable'} </span>)

    },
    {
      field: "id",
      headerName: "",
      width: 50,
      renderCell: (params) => (
        <>
          <IconButton onClick={(event) => showEditbar(event, params.value)}>
            <img src="../Images/dots.png" />
          </IconButton>
          <Menu anchorEl={anchorEl}
            open={editbar === params.value ? true : false}
            onClose={handleClose}>
            <MenuItem onClick={() => { history.push(`giftdetails/${params.value}`) }}>View Details</MenuItem>
            <PopupGiftEdit setgiftList={setgiftList} page={page} detailid={params.value} handleClose={handleClose} />
            <PopupDelete delete={() => handleDelete(params.value)} />
          </Menu>
        </>
      ),
    },
  ];


  return (
    <article>
      <div className="buttondiv">
        <PopupGift setgiftList={setgiftList} setpage={setpage} setpageCount={setpageCount} />
      </div>
      <h4>Gift</h4>
      <TabContext value={value}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab label="All" value="1" />
        
        </TabList>
        <TabPanel value="1">
          <div style={{ width: '100%', paddingTop: '24px', borderRadius: '8px', border: '1px solid #E8E8E8' }}>
            <div className="mid-searchbar">
              <SearchBar searchvalue={searchvalue} setsearchvalue={setsearchvalue}  />
              <Button onClick={handleClickaction}
                className='tertiarybtn' endIcon={<img src="../Images/Arrow-Down.svg" />}>Actions</Button>
              <Menu anchorEl={anchorEl2}
                open={open}
                onClose={handleCloseaction}>
                <PopupDeleteAll delete={handlebulkDelete} />
              </Menu>
            </div>
            <div style={{ height: '650px' }}>
              <DataGrid
                sx={{ mt: 2 }}
                rows={giftList}
                columns={columns}
                pageSize={listlength}
                rowsPerPageOptions={[listlength]}
                getRowId={(row) => row.id}
                checkboxSelection
                pagination
                // paginationMode="server"
                keepNonExistentRowsSelected
                components={{
                  Pagination: CustomPagination
                }}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
              />
            </div>
          </div>
        </TabPanel>
     
      </TabContext>
    </article>
  )
}

export default Gift