import React, { useEffect, useState } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button, Pagination } from '@mui/material';
import SearchBar from '../../Components/SearchBar/SearchBar';
import PopupCoupon from '../Popup/PopupCoupon';
import { client } from '../../Helper/Client/Client';
import PopupDelete from '../Popup/PopupDelete';
import PopupDeleteAll from '../Popup/PopupDeleteAll';
import { history } from '../../Helper/History/history';
import PopupCouponEdit from '../Popup/PopupCouponEdit';
function CreatedCouponList() {
  
  // tab property
  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [editbar, setEditbar] = useState('');
  const [couponList, setcouponList] = useState([])
  const [selectionModel, setSelectionModel] = useState([]);
  const [pageCount, setpageCount] = useState(0)
  const [page, setpage] = useState(1)
  const [listlength, setlistlength] = useState(0)
  const [searchvalue, setsearchvalue] = useState('')

  useEffect(() => {
    if (couponList?.length !== undefined) {
      setlistlength(couponList.length)
    }
  }, [couponList])

  useEffect(() => {
    client.get(`/api/coupons_searchby_name?search=${searchvalue}`).then((response) => {
      if(response.data.results ){
        setcouponList(response.data.results) 
        setpage(1)
        setpageCount(Math.ceil(response.data.count / response.data.results.length))}
        else{ setcouponList([])}
    }).catch((err)=>{
      if ( err?.response?.status === 401||403) {
        history.push('/login')
      }
  })
  }, [searchvalue])

  const showEditbar = (event, id) => {
    setAnchorEl(event.currentTarget);
    // console.log(id);
    setEditbar(id);
  };
  const handleClose = () => {
    setEditbar(null);
  };

  const open = Boolean(anchorEl2);
  const handleClickaction = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseaction = () => {
    setAnchorEl2(null);
  };

  function CustomPagination() {

    return (
      <Pagination
        className='pagination'
        color='warning'
        count={pageCount}
        page={page}
        onChange={(event, value) => {
          client.get(`/api/coupons_list?page=${value}`).then((response) => {
            setcouponList(response.data.results);
          })
          setpage(value)
        }}
        showFirstButton showLastButton
      />
    );
  }

  function handleDeleteCoupon(i) {
    client.delete(`/api/coupon_delete/${i}`).then(() => {
      handleClose()
      client.get('/api/coupons_list').then((response) => {
        if(response.data.results){
          setpage(1);
          setcouponList(response.data.results);
      setpageCount(
          Math.ceil(response.data.count / response.data.results.length)
        )
        }
        else{
          setpage(0);
          setpageCount(0)
          setcouponList([])
        }
      })
    })
  }

  const handlebulkDelete = () => {
    client.delete(`/api/coupons_bulk_delete`, { data: { "coupon_id": selectionModel } }).then(() => {
      setAnchorEl2(null);
      client.get(`/api/coupons_list`).then((response) => {
        if(response.data.results){
          setpage(1);
          setcouponList(response.data.results);
      setpageCount(
          Math.ceil(response.data.count / response.data.results.length)
        )
        }
        else{
          setpage(0);
          setpageCount(0)
          setcouponList([])
        }
      })
    })
  }

  const columns = [
    {
      field: 'name',
      headerName: 'COUPON TYPE',
      width: 250,
     
    },
    {
      field: 'value',
      headerName: 'COUPON VALUE',
      width: 250,
      // renderCell: RenderCellExpand
    },
    {
      field: 'created_date',
      headerName: 'SET UP DATE',
      width: 150,
      align: 'center', headerAlign: 'center', type: 'date',
      renderCell: (params) => (params.value && (params.value.split('T')[0].split('-')[2] + '.' + params.value.split('T')[0].split('-')[1] + '.' + params.value.split('T')[0].split('-')[0]))
    },
    {
      field: "id",
      headerName: "",
      width: 30,
      renderCell: (params) => (
        <>
          <IconButton onClick={(event) => showEditbar(event, params.value)}>
            <img src="../Images/dots.png" />
          </IconButton>
          <Menu anchorEl={anchorEl}
            open={editbar === params.value ? true : false}
            onClose={handleClose}>
             
            
            <PopupCouponEdit setcouponList={setcouponList} page={page} detailid={params.value} handleClose={handleClose} />
            <PopupDelete delete={() => handleDeleteCoupon(params.value)} />
          </Menu>
        </>
      ),
    },
  ];
// console.log("Coupon List",couponList);

  return (
    <article> 
        <div className="buttondiv">
        
        <PopupCoupon
        setcouponList={setcouponList} setpage={setpage} setpageCount={setpageCount} 
        />
      </div>
      <h4>Coupon Creation</h4>
        <TabContext value={value}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="All" value="1" />
              
            </TabList>
          <TabPanel value="1">
            <div style={{ width: '100%',paddingTop:'24px',borderRadius:'8px',border:'1px solid #E8E8E8' }}>
              <div className="mid-searchbar">
              <SearchBar searchvalue={searchvalue} setsearchvalue={setsearchvalue}  />
              <Button
                onClick={handleClickaction}
                className="tertiarybtn"
                endIcon={
                  <img src="../Images/Arrow-Down.svg" alt="arrow down" />
                }
              >
                Actions
              </Button>
              <Menu
                anchorEl={anchorEl2}
                open={open}
                onClose={handleCloseaction} 
              >
                <PopupDeleteAll 
                delete={handlebulkDelete} 
                />
               
              </Menu>
              </div>
              <div style={{height:'650px'}}>
              
              <DataGrid
            rowHeight={54}
            sx={{ mt: 2 }}
            rows={couponList}
            columns={columns}
            pageSize={listlength}
            rowsPerPageOptions={[listlength]}
            getRowId={(row) => row.id}
            checkboxSelection
            pagination
            // paginationMode="server"
            keepNonExistentRowsSelected
            components={{
              Pagination: CustomPagination
            }}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
          />
              </div>
            </div>
          </TabPanel>
          
        </TabContext>
    </article>
  )
}

export default CreatedCouponList;