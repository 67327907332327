import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React, { useEffect, useState, useRef } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Button, Pagination } from "@mui/material";
import DropdownSelect from "../../Components/DropdownSelect/DropdownSelect";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SearchBar from "../../Components/SearchBar/SearchBar.js";
import useAxios from "../../Helper/Client/useAxios";
import { useParams } from "react-router-dom";
import { client } from "../../Helper/Client/Client";
import AlertPopup from "../../Components/AlertPopup/AlertPopup";
import { history } from "../../Helper/History/history";
import baseURL from "../../Helper/Client/baseURL";
import ReactToPrint from "react-to-print";

function Customerdetails() {
  let { id } = useParams();

  const [customerDetail, setcustomerDetail] = useState([]);
  const [transactionList, settransactionList] = useState([]);
  const [monthlyIncomeList, setmonthlyIncomeList] = useState([]);
  const initialData = {
    firstname: "",
    lastname: "",
    phone: "",
    status: "",
    address1: "",
    address2: null,
    phone2: null,
    profile: "",
    stateregion: "",
    postcode: "",
    city: "",
    country: "",
  };
  const [inputs, setinputs] = useState(initialData);

  const [status, setstatus] = useState(0);
  const [edit, setedit] = useState(false);
  const [validationError, setvalidationError] = useState(false);

  const [pageCount, setpageCount] = useState(0);
  const [page, setpage] = useState(1);
  const [listlength, setlistlength] = useState(0);
  const [searchvalue, setsearchvalue] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const [editbar, setEditbar] = useState("");

  function handleChanged(e) {
    const { name, value } = e.target;
    setinputs((inputs) => ({ ...inputs, [name]: value }));
  }

  useEffect(() => {
    client
      .get(`/api/payment_list_customer/${id}?search=${searchvalue}`)
      .then((response) => {
        if (response.data.results) {
          settransactionList(response.data.results);
          setpage(1);
          setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          );
        } else {
          settransactionList([]);
        }
      });
  }, [searchvalue]);



  const [response, isError, isLoading] = useAxios({
    url: `/api/customer_details/${id}`,
    method: "get",

  });



  const [mothlyIncomeresponse, mothlyIncomeisError, mothlyIncomeisLoading] =
    useAxios({
      url: `/api/monthly_invoice_customer/${id}`,
      method: "get",
  
    });

  useEffect(() => {
    if (response && response.data.data) {
      setcustomerDetail(response.data.data);
      setstatus(response.data.data.is_active ? 1 : 0);
    }
   
    if (mothlyIncomeresponse && mothlyIncomeresponse.data.data) {
      setmonthlyIncomeList(mothlyIncomeresponse.data.data);
    }

  }, [response, mothlyIncomeresponse]);
  useEffect(() => {
    if (transactionList?.length !== undefined) {
      setlistlength(transactionList.length);
    }
  }, [transactionList]);

  function CustomPagination() {
    return (
      <Pagination
        className="pagination"
        color="warning"
        count={pageCount}
        page={page}
        onChange={(event, value) => {
          client
            .get(
              `/api/payment_list_customer/${id}?search=${searchvalue}&page=${value}`
            )
            .then((response) => {
              settransactionList(response.data.results);
            });
          setpage(value);
        }}
        showFirstButton
        showLastButton
      />
    );
  }

  useEffect(() => {
    setinputs((inputs) => ({
      ...inputs,
      firstname: customerDetail.first_name,
      lastname: customerDetail.last_name,
      phone: customerDetail.phone_1,
      phone2: customerDetail.phone_2,
      status: customerDetail.is_active,
      address1: customerDetail.street_1,
      address2: customerDetail.street_2,
      profile: customerDetail.user_image,
      stateregion: customerDetail.state,
      postcode: customerDetail.zip_code,
      city: customerDetail.city,
      country: customerDetail.country,
    }));
  }, [customerDetail]);
  

  function handleCancel() {
    setedit(false);
    setvalidationError(false);
  }

  function handleSubmit() {
    if (
      inputs.firstname === "" ||
      inputs.postcode === "" ||
      inputs.address1 === "" ||
      inputs.phone === ""
    ) {
      setvalidationError(true);
      return;
    } else {
      setvalidationError(false);
    }
    let form_data = new FormData();

    form_data.append("first_name", inputs.firstname);
    form_data.append("last_name", inputs.lastname);
    form_data.append("street_1", inputs.address1);
    form_data.append(
      "street_2",
      inputs.address2 === "null" || inputs.address2 === null
        ? ""
        : inputs.address2
    );
    form_data.append("city", inputs.city);
    form_data.append("state", inputs.stateregion);
    form_data.append("country", inputs.country);
    form_data.append("zip_code", inputs.postcode);
    form_data.append("phone_1", inputs.phone);
    form_data.append(
      "phone_2",
      inputs.phone2 === "null" || inputs.address2 === null ? "" : inputs.phone2
    );
    
    form_data.append("is_active", status);
   
    client
      .put(`api/updateprofile/${id}`, form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(() => {
        setalertOptions({
          alertType: "success",
          alertOpen: true,
          alertContent: "Submitted Successfully!",
        });
        setedit(false);
        client
          .get(`/api/customer_details/${id}`)
          .then((response) => setcustomerDetail(response.data.data));
      })
      .catch(() => {
        setalertOptions({
          alertType: "error",
          alertOpen: true,
          alertContent: "Submission Failed!",
        });
      });
  }

  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });

  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };

  // tab property
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const showEditbar = (event, id) => {
    setAnchorEl(event.currentTarget);
    console.log(id);
    setEditbar(id);
  };
  const handleClose = () => {
    setEditbar(null);
  };
  const handleDetailView = (i) => {
    history.push(`/transactiondetail/${i}`);
  };

  // top actions
  
  const open = Boolean(anchorEl2);
  const handleClickaction = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseaction = () => {
    setAnchorEl2(null);
  };

  const componentRef = useRef([]);

  const columns = [
    {
      field: "id",
      headerName: "TRANSACTION CODE ",
      width: 200,
    },
    {
      field: "venue_name",
      headerName: "VENUE",
      width: 150,
    },
    {
      field: "amount",
      headerName: "PRICE",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "date",
      headerName: "DATE",
      align: "center",
      headerAlign: "center",
      type: "date",
      width: 180,
      renderCell: (params) =>
        params.value.split("T")[0].split("-")[2] +
        "." +
        params.value.split("T")[0].split("-")[1] +
        "." +
        params.value.split("T")[0].split("-")[0],
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 160,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            width: "110px",
            padding: "7px",
            backgroundColor: params.value === "paid" ? "#e8f9ef" : "#ffe7e6",
            color: params.value === "paid" ? "#20A144" : "#EB5A52",
          }}
        >
          {"Completed"}{" "}
        </span>
      ),
    },
    {
      field: "ID",
      headerName: "",
      width: 10,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.row.id,
      renderCell: (params) => (
        <>
          <IconButton onClick={(event) => showEditbar(event, params.value)}>
            <img src="../Images/dots.png" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={editbar === params.value ? true : false}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleDetailView(params.value)}>
              Detailed View
            </MenuItem>
            
          </Menu>
        </>
      ),
    },
  ];

  function CustomToolbar() {
    return (
      <div style={{ position: "absolute", right: "0px", top: "-210px" }}>
        <GridToolbarContainer>
          <GridToolbarExport
            sx={{
              color: "black",
              bgcolor: "#F5F5F5",
              textTransform: "capitalize",
              p: 1.2,
              pr: 3,
            }}
          />
        </GridToolbarContainer>
      </div>
    );
  }
  

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <article>
      <h4>
        Customer - {customerDetail.first_name} {customerDetail.last_name}
      </h4>
      <TabContext value={value} style={{ padding: "5px" }}>
        <TabList onChange={handleChange} aria-label="Customer details">
          <Tab label="Details" value="1" />
          <Tab label="Transaction records" value="2" />
          <Tab label="Total Amount" value="3" />
        </TabList>
        <TabPanel value="1">
          {!edit && (
            <div className="venuedetails-table">
              <Accordion
                sx={{
                  border: "1px solid rgb(232, 232, 232)",
                  boxShadow: "none",
                  mb: 1,
                  pb: 1,
                }}
              >
                <AccordionSummary
                  expandIcon={<img src="../Images/Arrow-Down.svg" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h5>Address</h5>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto auto auto",
                    }}
                  >
                    <div>
                      <p>
                        Name:{" "}
                        <span className="span">
                          {customerDetail.first_name}
                        </span>
                      </p>
                      <p>
                        Phone:{" "}
                        <span className="span">
                          {customerDetail.phone_1},{customerDetail.phone_2}
                        </span>
                      </p>
                      {/* <p>
                        Last name:{" "}
                        <span className="span">
                          {customerDetail.last_name}{" "}
                        </span>
                      </p> */}
                      <p>
                        Address:{" "}
                        <span className="span">
                          {customerDetail.street_1},{customerDetail.street_2}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        State/Region:{" "}
                        <span className="span"> {customerDetail.state}</span>
                      </p>
                      <p>
                        City:{" "}
                        <span className="span">{customerDetail.city}</span>
                      </p>
                      <p>
                        Country:{" "}
                        <span className="span">{customerDetail.country}</span>{" "}
                      </p>
                    </div>
                    <div>
                      <p>
                        Email:{" "}
                        <span className="span">{customerDetail.email}</span>
                      </p>
                      <p>
                        Postcode:{" "}
                        <span className="span">{customerDetail.zip_code}</span>
                      </p>
                    </div>
                  </div>
                  <img
                    src="/Images/edit.svg"
                    style={{ float: "right" }}
                    onClick={() => setedit(true)}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          )}
          {edit && (
            <div
              className="venue-tab-details"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <h5>Information</h5>
              <label className="mt20">
                Address line 1
                <input
                  className={
                    validationError && inputs.address1 === ""
                      ? "validationerror mt10"
                      : "mt10"
                  }
                  style={{ width: "100%" }}
                  type="text"
                  name="address1"
                  value={inputs.address1}
                  
                  onChange={handleChanged}
                  required
                />
              </label>
              <label className="mt20">
                Address line 2
                <input
                  className="mt10"
                  style={{ width: "100%" }}
                  type="text"
                  placeholder="Optional"
                  onChange={handleChanged}
                  name="address2"
                  value={inputs.address2}
                  
                />
              </label>
              <div
                style={{ display: "grid", gridTemplateColumns: "auto auto" }}
              >
                <label className="mt20" style={{ marginRight: "50px" }}>
                  Name
                  <input
                    className={
                      validationError && inputs.firstname === ""
                        ? "validationerror mt10"
                        : "mt10"
                    }
                    style={{ width: "100%" }}
                    type="text"
                    required
                    name="firstname"
                    onChange={handleChanged}
                    value={inputs.firstname}
                  />
                </label>
                <label className="mt20">
                Phone
                <input
                  className={
                    validationError && inputs.phone === ""
                      ? "validationerror mt10"
                      : "mt10"
                  }
                  style={{ width: "100%" }}
                  type="tel"
                  name="phone"
                  
                  onChange={handleChanged}
                  value={inputs.phone}
                  required
                />
              </label>
                {/* <label className="mt20">
                  Last name
                  <input
                    className="mt10"
                    style={{ width: "100%" }}
                    type="text"
                    name="lastname"
                    onChange={handleChanged}
                    value={inputs.lastname}
                 
                    required
                  />
                </label> */}
                <label className="mt20" style={{ marginRight: "50px" }}>
                  State/Region
                  <input
                    className="mt10"
                    style={{ width: "100%" }}
                    type="text"
                    name="stateregion"
                    value={inputs.stateregion}
                    
                    onChange={handleChanged}
                    required
                  />
                </label>
                <label className="mt20">
                  Postcode
                  <input
                    className={
                      validationError && inputs.postcode === ""
                        ? "validationerror mt10"
                        : "mt10"
                    }
                    style={{ width: "100%" }}
                    type="text"
                    name="postcode"
                    value={inputs.postcode}
                    
                    onChange={handleChanged}
                    required
                  />
                </label>
                <label className="mt20" style={{ marginRight: "50px" }}>
                  City
                  <input
                    className="mt10"
                    style={{ width: "100%" }}
                    type="text"
                    name="city"
                    value={inputs.city}
                    onChange={handleChanged}
                   
                    required
                  />
                </label>
                <label className="mt20">
                  Country
                  <input
                    className="mt10"
                    style={{ width: "100%" }}
                    type="text"
                    name="country"
                    value={inputs.country}
                  
                    onChange={handleChanged}
                    required
                  />
                </label>
              </div>
              <label className="mt20">
                Status
                <DropdownSelect
                  value={status}
                  setdropDownValue={setstatus}
                  values={[
                    { name: "Active", value: 1 },
                    { name: "Blocked", value: 0 },
                  ]}
                />
              </label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "10px",
                }}
              >
                <Button
                  className="primarybtn"
                  onClick={handleSubmit}
                  style={{ marginRight: "10px" }}
                >
                  Save
                </Button>
                <Button className="secondarybtn" onClick={handleCancel}>
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </TabPanel>
        <TabPanel value="2">
          <div
            style={{
              width: "100%",
              paddingTop: "24px",
              borderRadius: "8px",
              border: "1px solid #E8E8E8",
            }}
          >
            <div className="mid-searchbar">
              <SearchBar
                searchvalue={searchvalue}
                setsearchvalue={setsearchvalue}
              />
              <Button
                onClick={handleClickaction}
                className="tertiarybtn"
                endIcon={<img src="../Images/Arrow-Down.svg" />}
              >
                Actions
              </Button>
              <Menu
                anchorEl={anchorEl2}
                open={open}
                onClose={handleCloseaction}
              >
                <MenuItem onClick={handleCloseaction}>Edit Item</MenuItem>
                <MenuItem onClick={handleCloseaction}>Delete Item</MenuItem>
              </Menu>
            </div>
            <div style={{ height: "650px" }}>
              <DataGrid
                sx={{ mt: 2 }}
                rows={transactionList}
                columns={columns}
                pageSize={listlength}
                rowsPerPageOptions={[listlength]}
                getRowId={(row) => row.id}
                checkboxSelection
                pagination
                // paginationMode="server"
                keepNonExistentRowsSelected
                components={{
                  Pagination: CustomPagination,
                  Toolbar: CustomToolbar,
                }}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value="3">
          {monthlyIncomeList.map((item, i) => (
            <Accordion
              sx={{
                border: "1px solid rgb(232, 232, 232)",
                boxShadow: "none",
                pb: 1,
                mb: 1,
              }}
              ref={(el) => (componentRef.current[i] = el)}
            >
              <AccordionSummary
                expandIcon={<img src="../Images/Arrow-Down.svg" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h5>
                  Total Amount -{" "}
                  {month[Number(item.date?.split("/")[1]) - 1] +
                    " " +
                    item.date?.split("/")[0]}
                </h5>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto auto",
                  }}
                >
                  <p className="accordion_p">
                    Income: <span className="span">{item.income}</span>
                  </p>
                  <p className="accordion_p">
                    
                  </p>
                  <p className="accordion_p">
                    Total: <span className="span">{item.total}</span>
                  </p>
                  <div
                    style={{
                      textAlign: "center",
                      margin: "10px",
                    }}
                  >
                    <ReactToPrint
                      trigger={() => (
                        <img src="../Images/print.svg" className="print_btn" />
                      )}
                      content={() => componentRef.current[i]}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </TabPanel>
      </TabContext>
      <AlertPopup alertOptions={alertOptions} handleClose={handleClosep} />
    </article>
  );
}
export default Customerdetails;
