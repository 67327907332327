import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React, { useEffect, useState, useRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import { useParams } from "react-router-dom";
import useAxios from "../../Helper/Client/useAxios";
import ReactToPrint from "react-to-print";
import {

  DialogContent,

} from "@mui/material";

function OrderDetails() {
  let { id } = useParams();
  const ref = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [orderDetail, setorderDetail] = useState([]);
  const [customerList, setcustomerList] = useState([]);
  const [customerDetail, setcustomerDetail] = useState([]);
  const [transactionList, settransactionList] = useState([]);
  const [productList, setproductList] = useState([]);
  const [value, setValue] = React.useState("1");

  const componentRef = useRef([]);
  const [validationError, setvalidationError] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const showEditbar = (event, id) => {
    setAnchorEl(event.currentTarget);
    // console.log(id);
  };

  const [response, isError, isLoading] = useAxios({
    url: `/api/product_order_details/${id}`,
    method: "get",
  
  });
  useEffect(() => {
    if (response && response.data.results) {
      setorderDetail(response.data.results);
      setcustomerList(response.data.results.customer_details);
      setcustomerDetail(response.data.results.customer_details);
      settransactionList(response.data.results.transactions_list);
    }
  }, [response]);
  useEffect(() => {
    if (transactionList && transactionList.length > 0) {
      setproductList(transactionList[0].product_list);
    }
  }, [transactionList]);
  // console.log("Order Details API Response", orderDetail);

  // console.log("Customer List", customerList);
  // console.log("Transaction List ", transactionList);
  // console.log("Product List", productList);

  const initialData = {
    paymentmethod: "",
    deliverymethod: "",
    status: "",
    paymentstatus: "",
    paymentno: "",
    paymentdate: "",
    totalamount: "",
  };
  const [inputs, setinputs] = useState(initialData);

  useEffect(() => {
    setinputs((inputs) => ({
      ...inputs,
      paymentmethod: transactionList[0]?.payment_method,
      deliverymethod: orderDetail.delivery_method,
      paymentstatus: orderDetail.order_status,
      status: transactionList[0]?.status,
     
    }));
  }, [customerDetail, transactionList]);

  // Formatting A Specific Date
  const invoice_date = orderDetail.order_date;
  // console.log("Invoice Date", invoice_date);

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("-");
  }

  const invodate = formatDate(new Date(invoice_date));

  var string = customerDetail[0]?.first_name;

  var string1 = string?.charAt(0).toUpperCase();
  // console.log("Customer Name", string1);
  // console.log("Order Invoice Date", invodate);
  // console.log("Order Payment Method", inputs.paymentmethod);
  return (
    <article>
      <h4>Order - {orderDetail.order_number}</h4>
      <TabContext value={value} style={{ padding: "5px" }}>
        <TabList onChange={handleChange}>
          <Tab label="Details" value="1" />
          <Tab label="INVOICE" value="2" />
        </TabList>
        <TabPanel value="1">
          <DialogContent>
            <div className="venuedetails-table">
              <table
             
                style={{
                  width: "100%",
                  borderBottom: "1px solid #e8e8e8",
                  marginBottom: "20px",
                }}
              >
                <thead>
                  <tr>
                    <th>Customer</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Location</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto auto ",
                        gridGap: "10px",
                      }}
                    >
                      <p
                        style={{
                          background: "rgba(254, 159, 94, 0.3)",
                          color: "rgb(254, 159, 94)",
                          borderRadius: "15px",
                          width: "fit-content",
                          padding: "14px 20px",
                          margin: "auto",
                        }}
                      >
                        {" "}
                        {string1}
                      </p>
                      <p
                        style={{
                          
                          margin: "auto",
                        }}
                      >
                        {customerDetail[0]?.first_name}{" "}
                      </p>
                    </td>
                   
                    <td>{customerDetail[0]?.email}</td>
                    <td>{customerDetail[0]?.phone_1}</td>

                    <td>
                      {customerDetail[0]?.city}
                      {","}
                      {customerDetail[0]?.country}
                      {","}
                      {customerDetail[0]?.state}
                    
                    </td>
                  </tr>
                </tbody>
              </table>
              <Accordion
                sx={{
                  border: "1px solid rgb(232, 232, 232)",
                  boxShadow: "none",
                  mb: 1,
                }}
              >
                <AccordionSummary
                  expandIcon={<img src="/Images/Arrow-Down.svg" />}
                >
                  <h5>Address</h5>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto auto auto",
                      gridGap: "30px",
                    }}
                  >
                    <div>
                      <p>
                        First name:{" "}
                        <span> {customerDetail[0]?.first_name}</span>
                      </p>
                      <p>
                        Last name: <span>{customerDetail[0]?.last_name}</span>
                      </p>
                      <p>
                        Address:{" "}
                        <span>
                          {customerDetail[0]?.city}
                          {","}
                          {customerDetail[0]?.country}
                          {","}
                          {customerDetail[0]?.state}
                          {","}
                          {customerDetail[0]?.street_1}
                          {","}
                          {customerDetail[0]?.street_2}
                          {"."}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        State/Region: <span>{customerDetail[0]?.state}</span>
                      </p>
                      <p>
                        City: <span>{customerDetail[0]?.city}</span>
                      </p>
                      <p>
                        Country: <span>{customerDetail[0]?.country}</span>{" "}
                      </p>
                    </div>
                    <div>
                      <p>
                        Phone: <span>{customerDetail[0]?.phone_1}</span>
                      </p>
                      <p>
                        Email: <span>{customerDetail[0]?.email}</span>
                      </p>
                      <p>
                        Postcode: <span>{customerDetail[0]?.zip_code}</span>
                      </p>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  border: "1px solid rgb(232, 232, 232)",
                  boxShadow: "none",
                  mb: 1,
                }}
              >
                <AccordionSummary
                  expandIcon={<img src="/Images/Arrow-Down.svg" />}
                >
                  <h5>Payment method</h5>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto auto auto auto",
                    }}
                  >
                    <label
                      className="mt20"
                      style={{ marginRight: "30px", width: "230px" }}
                    >
                      Payment method
                      <input
                        className={
                          validationError && inputs.paymentmethod === ""
                            ? "validationerror mt10"
                            : "mt10"
                        }
                        style={{ width: "75%" }}
                        type="text"
                        name="paymentmethod"
                        defaultValue={inputs.paymentmethod}
                        readOnly
                      />{" "}
                    </label>

                    <label
                      className="mt20"
                      style={{ width: "200px", marginRight: "30px" }}
                    >
                      Delivery method
                      <input
                        className={
                          validationError && inputs.deliverymethod === ""
                            ? "validationerror mt10"
                            : "mt10"
                        }
                        style={{ width: "80%" }}
                        type="text"
                        name="deliverymethod"
                        defaultValue={inputs.deliverymethod}
                        readOnly
                      />
                    </label>

                    <label
                      className="mt20"
                      style={{ marginRight: "30px", width: "230px" }}
                    >
                      Status
                      <input
                        className={
                          validationError && inputs.paymentstatus === ""
                            ? "validationerror mt10"
                            : "mt10"
                        }
                        style={{ width: "81%" }}
                        type="text"
                        name="paymentstatus"
                        defaultValue={inputs.paymentstatus}
                        readOnly
                      />{" "}
                    </label>

                    <label className="mt20">
                      Payment Status
                      <br />
                      <input
                        className={
                          validationError && inputs.status === ""
                            ? "validationerror mt10"
                            : "mt10"
                        }
                        style={{ width: "90%" }}
                        type="text"
                        name="status"
                        defaultValue={inputs.status}
                        readOnly
                      />{" "}
                    </label>
                  </div>
                  <div>
                    <p>
                      Payment no.: <span>{transactionList[0]?.id}</span>
                    </p>
                    <p>
                      Date: <span>{invodate}</span>
                    </p>
                    <p>
                      Total: <span>{transactionList[0]?.amount}</span>
                    </p>
                  </div>
                </AccordionDetails>
              </Accordion>
              <br></br>
              
            </div>
          </DialogContent>
        </TabPanel>
        <TabPanel value="2">
          {productList.length > 0 ? (
            <>
              {customerDetail.length > 0 &&
                customerDetail.map((item, i) => (
                  <>
                    <div
                      ref={(el) => (componentRef.current[i] = el)}
                      style={{
                        margin: "6% 7%",
                      }}
                      key={item.id}
                    >
                      <h5>Order Invoice</h5>
                      <div
                        style={{
                          float: "right",
                          backgroundColor: "#FFD454",
                          padding: "10px",
                          textAlign: "center",
                          color: "white",
                        }}
                      >
                        <p>INVOICE</p>
                        <p>{orderDetail.order_number}</p>
                      </div>

                      <div id="blueDIV">{invodate}</div>
                      <div>
                        <br />
                        <p>PICKME STUDIO</p>
                        <span style={{ color: "#3B3B3B", opacity: "0.7" }}>
                          Whatsapp: 6204 2839
                        </span>
                        <br />
                        <span style={{ color: "#3B3B3B", opacity: "0.7" }}>
                          email：pickmestudiohk@gmail.com
                        </span>
                        <br />
                      </div>
                      <div>
                        <br />
                        <p>PAYER :</p>
                        <p>
                          {customerDetail.first_name} {customerDetail.last_name}
                        </p>
                        <span style={{ color: "#3B3B3B", opacity: "0.7" }}>
                          {customerDetail.phone_1}
                        </span>
                        <br />
                        <span style={{ color: "#3B3B3B", opacity: "0.7" }}>
                          <span>
                            {customerDetail.city}
                            {","}
                            {customerDetail.country}
                            {","}
                            {customerDetail.state}
                            {","}
                            {customerDetail.street_1}
                            {","}
                            {customerDetail.street_2}
                            {"."}
                          </span>
                        </span>
                      </div>
                      <br />
                      <br />
                      <br />
                      <div className="venuedetails-table">
                        <table
                          style={{
                            width: "80%",
                            margin: "auto",
                            border: "1px solid #e8e8e8",
                          }}
                        >
                          <thead>
                            <tr>
                              <th>PRODUCT NAME</th>
                              <th>PRICE</th>
                              <th>QUANTITY</th>
                              <th>TOTAL</th>
                            </tr>
                          </thead>

                          <tbody>
                            {productList.map((item, i) => (
                              <tr key={item.id}>
                                <td>{item.product_name}</td>
                                <td>{item.amount_per_quantity}</td>
                                <td>{item.quantity}</td>
                                <td>{item.price}</td>
                              </tr>
                            ))}
                            <tr>
                              <td></td>
                              <td></td>
                              <td>TOTAL</td>
                              <td>{transactionList[0]?.amount}</td>
                            </tr>
                          </tbody>
                        </table>
                        <br />
                        <br />
                        <ReactToPrint
                          trigger={() => (
                            <img
                              src="../Images/print.svg"
                              className="print_btn"
                            />
                          )}
                          content={() => componentRef.current[i]}
                        />
                     
                      </div>
                    </div>
                  </>
                ))}
            </>
          ) : null}
        </TabPanel>
      </TabContext>
    </article>
  );
}
export default OrderDetails;
