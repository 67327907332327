
import React, { useEffect, useRef, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import {  IconButton, Menu, MenuItem } from "@mui/material";

import { useParams } from "react-router-dom";
import useAxios from "../../Helper/Client/useAxios";

import { useReactToPrint } from "react-to-print";

function TransactionDetails() {
  const [value, setValue] = React.useState("1");
  let { id } = useParams();

  const [transactionDetail, settransactionDetail] = useState(null);

  const [response, isError, isLoading] = useAxios({
    url: `/api/transaction_details/${id}`,
    method: "get",

  });

  useEffect(() => {
    if (response && response.data.data) {
      settransactionDetail(response.data.data);
    }
  }, [response]);
  // console.log("DETAi",transactionDetail);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const showEditbar = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <article ref={componentRef}>
      <div className="buttondiv print_btn">
        <img src="../Images/Download2.svg" onClick={handlePrint} />
      </div>
      <h4>Venue Transaction</h4>

      <div className="venuedetails-table">
        <table style={{ width: "100%", marginBottom: "20px" }}>
          <thead>
            <tr>
              <th>Customer</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Location</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{transactionDetail?.customer_name}</td>
              <td>{transactionDetail?.customer_email}</td>
              <td>{transactionDetail?.customer_phone}</td>
              <td>{transactionDetail?.customer_location}</td>
            </tr>
          </tbody>
        </table>

        <Accordion
          sx={{
            border: "1px solid rgb(232, 232, 232)",
            boxShadow: "none",
            mb: 1,
          }}
        >
          <AccordionSummary expandIcon={<img src="/Images/Arrow-Down.svg" />}>
            <h5>Chosen Venue</h5>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <img src="/Images/img.png" />
              <p>{transactionDetail?.venue_name}</p>
              <p>{transactionDetail?.venue_location}</p>
              <p>{transactionDetail?.Vendor_phone}</p>
              <p>
                <span
                  style={{
                    textAlign: "center",
                    width: "100px",
                    padding: "7px",
                    backgroundColor: transactionDetail?.venue_status
                      ? "#e8f9ef"
                      : "#ffe7e6",
                    color: transactionDetail?.venue_status
                      ? "#20A144"
                      : "#EB5A52",
                  }}
                >
                  {transactionDetail?.venue_status ? "ACTIVE" : "DISABLE"}{" "}
                </span>
              </p>
              <>
                <IconButton onClick={(event) => showEditbar(event)}>
                  <img src="../Images/dots.png" />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={anchorEl ? true : false}
                  onClose={handleClose}
                >
                  <MenuItem
             
                  >
                    Edit Item
                  </MenuItem>
       
                </Menu>
              </>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            border: "1px solid rgb(232, 232, 232)",
            boxShadow: "none",
            mb: 1,
          }}
        >
          <AccordionSummary expandIcon={<img src="/Images/Arrow-Down.svg" />}>
            <h5>Transaction</h5>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{ display: "grid", gridTemplateColumns: "auto auto auto" }}
            >
              <div>
                <p>
                  Transaction Code:{" "}
                  <span className="span"> {transactionDetail?.id}</span>
                </p>
                <p>
                  Order number:{" "}
                  <span className="span">{transactionDetail?.order_id}</span>
                </p>
                <p>
                  Payment id: <span className="span">PAY12345</span>
                </p>
              </div>
              <div>
                <p>
                  Booking date:{" "}
                  <span className="span">
                    {transactionDetail?.date.split("T")[0].split("-")[2] +
                      "." +
                      transactionDetail?.date
                        .split("T")[0]
                        .split("-")[1] +
                      "." +
                      transactionDetail?.date.split("T")[0].split("-")[0]}
                  </span>
                </p>
                <p>
                  Total amount:{" "}
                  <span className="span">{transactionDetail?.amount}</span>
                </p>
                <p>
                  Transaction Type:{" "}
                  <span className="span">
                    {transactionDetail?.payment_method}
                  </span>{" "}
                </p>
              </div>
              <div>
                <p></p>
                <p>
                  Status:{" "}
                  <span className="span">
                   
                    {transactionDetail?.status}
                  </span>
                </p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            border: "1px solid rgb(232, 232, 232)",
            boxShadow: "none",
            mb: 1,
          }}
        >
          <AccordionSummary expandIcon={<img src="/Images/Arrow-Down.svg" />}>
            <h5>Remarks</h5>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa
              varius nisl, enim vitae dolor id praesent amet sodales. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit. Massa varius
              nisl, enim vitae dolor id praesent amet sodales. Lorem ipsum dolor
              sit amet, consectetur adipiscing elit. Massa varius nisl, enim
              vitae dolor id praesent amet sodales.{" "}
            </p>
          </AccordionDetails>
        </Accordion>
      </div>
    </article>
  );
}
export default TransactionDetails;
