import * as React from "react";
import PropTypes from "prop-types";
import InputUnstyled, { inputUnstyledClasses } from "@mui/base/InputUnstyled";
import { styled } from "@mui/system";
import { Popover, Typography } from "@mui/material";
import DropdownSelect from "../../Components/DropdownSelect/DropdownSelect";
import RangeSlider from "../../Components/RangeSlider/RangeSlider";
import { Button, Dialog } from "@mui/material";

const StyledInputRoot = styled("div")(
  ({ theme }) => `
  display: flex;
  border-radius: 8px;
  background:#f5f5f5;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
  &.${inputUnstyledClasses.focused} {
    outline: solid #fe9900;
  }
`
);

const StyledInputElement = styled("input")(
  ({ theme }) => `
 
  flex-grow: 1;
  background: #f5f5f5!important;
  padding: 10px 10px;
  outline: 0;
`
);

const InputAdornment = styled("div")`
  margin: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const CustomInput = React.forwardRef(function CustomInput(props, ref) {
  const { components, ...other } = props;
  return (
    <InputUnstyled
      components={{
        Root: StyledInputRoot,
        Input: StyledInputElement,
        ...components,
      }}
      {...other}
      ref={ref}
    />
  );
});

CustomInput.propTypes = {
  /**
   * The components used for each slot inside the InputBase.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  components: PropTypes.shape({
    Input: PropTypes.elementType,
    Root: PropTypes.elementType,
    Textarea: PropTypes.elementType,
  }),
};

export default function SearchBar(props) {
  // const [values, setValues] = React.useState({
  //   amount: '',
  //   weight: '',
  //   weightRange: '',
  // });
  function onhandleChange(e) {
    props.setsearchvalue(e.target.value);
  }
  const [anchorElPop, setAnchorElPop] = React.useState(null);

  const handleClickPop = (event) => {
    setAnchorElPop(event.currentTarget);
  };

  // console.log("anchorElPop", anchorElPop);

  const handleClosePop = () => {
    setAnchorElPop(null);
  };

  const openPop = Boolean(anchorElPop);
  const idPop = openPop ? "simple-popover" : undefined;

  const [status, setstatus] = React.useState("");
  return (
    <CustomInput
      value={props.searchvalue}
      onChange={onhandleChange}
      onKeyPress={props.onKeyPress}
      id="outlined-start-adornment"
      startAdornment={
        <InputAdornment>
          <img src="/Images/search.svg" />
        </InputAdornment>
      }
      endAdornment={
        <>
          <InputAdornment>
            {/* <img
              src="/Images/Filter.svg"
              aria-describedby={idPop}
              variant="contained"
              onClick={handleClickPop}
            /> */}
            {/* <Popover
              id={idPop}
              open={openPop}
              anchorEl={anchorElPop}
              onClose={handleClosePop}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div style={{ width: "280px", height: "550px", padding: "24px" }}>
                <h4>Filter</h4>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label className="mt20">
                    Category
                    <DropdownSelect
                      value={status}
                      setdropDownValue={setstatus}
                      values={[
                        { name: "Active", value: true },
                        { name: "Blocked", value: false },
                      ]}
                    />
                  </label>
                  <label className="mt20">
                    Status
                    <DropdownSelect
                      value={status}
                      setdropDownValue={setstatus}
                      values={[
                        { name: "Active", value: true },
                        { name: "Blocked", value: false },
                      ]}
                    />
                  </label>
                  <label className="mt20">
                    Date
                    <input
                      style={{ width: "100%" }}
                      type="date"
                      id="birthday"
                      name="birthday"
                    ></input>
                  </label>
                  <label className="mt20">
                    Price
                    <RangeSlider />
                  </label>
                  <Button
                    className="primarybtn"
                    style={{ width: "100%", marginTop: "20px" }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Popover> */}
          </InputAdornment>
        </>
      }
    />
  );
}
