import * as React from 'react';
import { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { history } from '../../Helper/History/history';
import {
  Badge,
} from "@mui/material";
import '../Navbar/Navbar.css'
import { Link, NavLink } from 'react-router-dom';
import { UserContext } from '../../Helper/usercontext';
import { client } from '../../Helper/Client/Client';
const drawerWidth = 300;



const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  backgroundColor: '#F5F5F5',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: '#F5F5F5',
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function New(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [mainsearchValue, setmainsearchValue] =  React.useState('')
  const [giftnotifycount, setgiftnotifycount] = React.useState(0)
  const [contactVideopromotionnotifycount, setcontactVideopromotionnotifycount] = React.useState()
  const [totalNumber, settotalNumber] = React.useState(0)
  const [isVideoPromotionMessageViewed, setIsVideoPromotionMessageViewed] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  function handlelogout() {
    history.push('/login');
  }
  function handleChange(e){
    setmainsearchValue(e.target.value)
    if(window.location.pathname!=='/search'){
      history.push('/search')
    }
  }
  function handleVideoPromotionMessageView() {
  
    setIsVideoPromotionMessageViewed(true);
  }
  

  React.useEffect(() => {
    
    client
    .get(`/api/sum_unseen_purchased_count`)
    .then((response) => {
      setgiftnotifycount(response.data.results);
      console.log("gift",response.data.results)
    }).catch((err)=>{
      if ( err?.response?.status === 401||403) {
        history.push('/login')
      }
  })
    client
    // .get(`/api/contactus_videopromo_unseencount`)
    .get(`/api/contactus_promomessage_unseencount`)
    .then((response) => {
      setcontactVideopromotionnotifycount(response.data.data);
      console.log("Videopromotionnotifycount",response.data.data)
    }).catch((err)=>{
      if ( err?.response?.status === 401||403) {
        history.push('/login')
      }
  })
  }, [])
  React.useEffect(() => {
    var total=giftnotifycount+contactVideopromotionnotifycount?.videopromounseen_count+contactVideopromotionnotifycount?.contactusunseen_count
    settotalNumber(total||0)
  }, [giftnotifycount,contactVideopromotionnotifycount])
  
// console.log(totalNumber)
  

  return (
    <Box sx={{ display: 'flex' }} >
      <div className="toggle-sidebar">
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <img onClick={handleDrawerOpen} src="../Images/menu.svg"
              style={{ height: '12px', cursor: 'pointer', paddingRight: '30px' }} />
            <input placeholder="Search"
            onChange={handleChange}
              style={{
                width: '100%',
                backgroundColor: '#E8E8E8'
              }}
            />
            {/* <img src="/Images/settings.svg" style={{ marginLeft: '20px' }} />
            <Badge  className="icon-top" badgeContent={totalNumber} color="error">
              <span  style={{ color: "#7A7A7A" }} className="material-icons">
                notifications_none
              </span>
            </Badge> */}
            <img
              style={{
                margin: "10px 20px",
                // borderLeft: "1px solid grey",
                paddingLeft: "20px",
                height: '27px',
                cursor: 'pointer'
              }}
              src="/Images/logout.png"
              onClick={handlelogout}
            />

            {/* </Typography> */}
          </Toolbar>
        </AppBar>

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}>
          <div className="sidebarLogo">
            <img src="../Images/PickMeLogo.png" alt="logo" />
            <img style={{ paddingLeft: '12px' }} src="../Images/Full.svg" alt="" />
          </div>
          <div className="side-menu">
            <NavLink className='p-15' to='/' >
              <span className="material-icons">grid_view</span>
              Overview
            </NavLink>
            <NavLink className='p-15' to='/venue' >
              <span className="material-icons">event</span>
              Venue Management
            </NavLink>
            <NavLink className='p-15' to='/customer'>
              <span className="material-icons">person_outline</span>
              Customer List
            </NavLink>
            <NavLink className='p-15' to='/vendor'>
              <span className="material-icons">person_outline</span>
              Vendor List
            </NavLink>
            <Link className='p-15 nohover' to='#'>
              <span className="material-icons">event</span>
              Home Page Management
            </Link>
            <NavLink className='p-15' to='/category'>
              <span style={{ lineHeight: '10px', fontSize: '60px' }}>&#8901;</span>  Categories
            </NavLink>
            <NavLink className='p-15' to='/homepagemanagement'>
              <span style={{ lineHeight: '10px', fontSize: '60px' }}>&#8901;</span>  Promotion
            </NavLink>
            <NavLink className='p-15' to='/videopromotionmessage' onClick={handleVideoPromotionMessageView}>
              <span style={{ lineHeight: '10px', fontSize: '60px' }}>&#8901;</span>  Video promo msg 
              {(!isVideoPromotionMessageViewed && contactVideopromotionnotifycount?.videopromounseen_count > 0) &&
              <span style={{position:'absolute',right:'20px',background: '#FD7972',
              borderRadius: "4px",color:'white',height:'18px',fontWeight: '600',fontSize: "10px",
              lineHeight:'13px',paddingLeft:'7px'}}>
              {contactVideopromotionnotifycount?.videopromounseen_count}</span>}
            </NavLink>
            <Link className='p-15 nohover' to='#'>
              <span className="material-icons">event</span>Product
            </Link>
            <NavLink className='p-15' to='/productflowscreens'>
              <span style={{ lineHeight: '10px', fontSize: '60px' }}>&#8901;</span>  Product Menu
            </NavLink>
            <NavLink className='p-15' to='/orderlistscreens'>
              <span style={{ lineHeight: '10px', fontSize: '60px' }}>&#8901;</span> Order List
            </NavLink>
            <Link className='p-15 nohover' to='#'>
              <span className="material-icons">content_paste</span>
              Payment Management
            </Link>
            
            <NavLink className='p-15' to='/venuetransaction'>
              <span style={{ lineHeight: '10px', fontSize: '60px' }}>&#8901;</span>Venue Transaction
            </NavLink>
            <NavLink className='p-15' to='/vendortransaction'>
              <span style={{ lineHeight: '10px', fontSize: '60px' }}>&#8901;</span>Vendor Transaction
            </NavLink>
            <NavLink className='p-15' to='/producttransaction'>
              <span style={{ lineHeight: '10px', fontSize: '60px' }}>&#8901;</span>Product Transaction
            </NavLink>
            <NavLink className='p-15' to='/blogpost'>
              <span className="material-icons">content_paste</span>
              Blog Post
            </NavLink>
            <NavLink className='p-15' to='/gift'>
              <span className="material-icons">grade</span>Rewards & Gifts{giftnotifycount>0 && <span style={{position:'absolute',right:'20px',background: '#FD7972',borderRadius: "4px",color:'white',height:'18px',fontWeight: '600',fontSize: "10px",lineHeight:'13px',paddingLeft:'7px'}}>{giftnotifycount}</span>}
            </NavLink>
            <Link className='p-15 nohover' to='#'>
              <span className="material-icons">grade</span>
              Coupon
            </Link>
            <NavLink className='p-15' to='/createcoupon'>
              <span style={{ lineHeight: '10px', fontSize: '60px' }}>&#8901;</span> Coupon Creation
            </NavLink>
            <NavLink className='p-15' to='/sendcoupon'>
              <span style={{ lineHeight: '10px', fontSize: '60px' }}>&#8901;</span> Coupon Sending
            </NavLink>
            <Link className='p-15 nohover' to='#'>
              <span className="material-icons">tune</span>Others
            </Link>
            <NavLink className='p-15' to='/contact'>
              <span style={{ lineHeight: '10px', fontSize: '60px' }}>&#8901;</span>Contact Us {contactVideopromotionnotifycount?.contactusunseen_count>0 && <span style={{position:'absolute',right:'20px',background: '#FD7972',borderRadius: "4px",color:'white',height:'18px',fontWeight: '600',fontSize: "10px",lineHeight:'13px',paddingLeft:'7px'}}>{contactVideopromotionnotifycount?.contactusunseen_count}</span>}
            </NavLink>
            <NavLink className='p-15' to='/termsandcondition'>
              <span style={{ lineHeight: '10px', fontSize: '60px' }}>&#8901;</span> T&C
            </NavLink>
            <NavLink className='p-15' to='/FAQ'>
              <span style={{ lineHeight: '10px', fontSize: '60px' }}>&#8901;</span>  FAQ
            </NavLink>
            <NavLink className='p-15' to='/notification'>
              <span style={{ lineHeight: '10px', fontSize: '60px' }}>&#8901;</span>  Notification
            </NavLink>
          </div>
        </Drawer>
      </div>
      
      <Main open={open} style={{ marginTop: '6%', padding: '0px' }}>
      <UserContext.Provider value={mainsearchValue}>
        {props.component}
      </UserContext.Provider>
      </Main>
   
    </Box>
   
  );
}
