import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import "./Popup.css";
import React, { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import { Button, Pagination } from "@mui/material";
import SearchBar from "../../Components/SearchBar/SearchBar";
import useAxios from "../../Helper/Client/useAxios";
import { history } from "../../Helper/History/history";
import { client } from "../../Helper/Client/Client";
import PopupDelete from "../Popup/PopupDelete";
import AlertPopup from "../../Components/AlertPopup/AlertPopup";

const PopupPopularVenue = (props) => {
  const [customerList, setcustomerList] = useState([]);
  const [searchvalue, setsearchvalue] = useState("");
  const [opendialog, setopendialog] = useState(false);
  const [pageCount, setpageCount] = useState(0);
  const [page, setpage] = useState(1);
  const [listlength, setlistlength] = useState(0);
  const [show, setShow] = useState(true);
  useEffect(() => {
    client.get(`/api/venue_details?search=${searchvalue}`).then((response) => {
      if (response.data.results) {
        setcustomerList(response.data.results);
        setpage(1);
        setpageCount(
          Math.ceil(response.data.count / response.data.results.length)
        );
      } else {
        setcustomerList([]);
      }
    });
  }, [searchvalue]);

  function CustomPagination() {
    return (
      <Pagination
        className="pagination"
        color="warning"
        count={pageCount}
        page={page}
        onChange={(event, value) => {
          searchvalue === ""
            ? client
                .get(`/api/venue_paginated_listview?page=${value}`)
                .then((response) => {
                  setcustomerList(response.data.results);
                })
            : client
                .get(`/api/venue_details?page=${value}&search=${searchvalue}`)
                .then((response) => {
                  setcustomerList(response.data.results);
                });
          setpage(value);
        }}
        showFirstButton
        showLastButton
      />
    );
  }

  const [response, isError, isLoading] = useAxios({
    url: "/api/venue_paginated_listview",
    method: "get",
  });

  useEffect(() => {
    if (response && response.data.results) {
      setcustomerList(response.data.results);
      setpageCount(
        Math.ceil(response.data.count / response.data.results.length)
      );
    }
    return () => {};
  }, [response]);

  useEffect(() => {
    if (customerList?.length !== undefined) {
      setlistlength(customerList.length);
    }
  }, [customerList]);

  useEffect(() => {
    let venuelistid = [];
    props.popularVenueList.map((item) => {
      venuelistid.push(item.id);
    });
    setShow(true);
    setSelectionModel(venuelistid);
  }, [props.popularVenueList]);

  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function getTitleDesc(params) {
    const title = params.row.title;
    const desc = params.row.description;
    const strippedHtml = desc?.replace(/<[^>]+>/g, "");
    console.log(strippedHtml)
    const image  = params.row.room_image_list[0].room_image!==undefined?
    params.row.room_image_list[0].room_image
    :params.row.room_image_list[0][0].room_image
    return title + "+" + strippedHtml + "+" + image;
  }

  function getCityCountry(params) {
    // const city = params.row.area;
    // const country = params.row.location && ", " + params.row.location;
    const country = params.row.location
    return `${country}`;
    // return `${city + country}`;
  }

  const columns = [
    {
      field: "title&desc",
      headerName: "VENUE NAME",
      width: 200,
      valueGetter: getTitleDesc,
      renderCell: (params) => (
        <>
          {params.value.split("+")[2] !== "undefined" ? (
            <img
              src={params.value.split("+")[2]}
              height="30px"
              width="30px"
              alt=""
            />
          ) : (
            <img
              src="/Images/venueplaceholder.png"
              height="30px"
              width="30px"
              alt="profile placholder"
            />
          )}
          <div style={{ marginLeft: "10px" }}>
            <b style={{ color: "rgb(53, 53, 53)" }}>
              {params.value.split("+")[0]}
            </b>
            <small style={{ color: "grey", display: "block" }}>
              {params.value.split("+")[1]}
            </small>
          </div>
        </>
      ),
    },
    {
      // field: "city&country",
      field:"country",
      headerName: "LOCATION",
      width: 200,
      valueGetter: getCityCountry,
    },
    {
      field: "status",
      headerName: "STATUS",
      headerAlign: "center",
      align: "center",
      width: 120,
      valueGetter: (params) => (params.row.status ? "Active" : "Disable"),
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            width: "100px",
            padding: "7px",
            backgroundColor: params.value === "Active" ? "#e8f9ef" : "#ffe7e6",
            color: params.value === "Active" ? "#20A144" : "#EB5A52",
          }}
        >
          {params.value}{" "}
        </span>
      ),
    },
  ];

  const handleClickOpen = () => {
    setopendialog(true);
  };
  const handleClosedialog = () => {
    setopendialog(false);
  };
  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };

  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });
  const handleSubmit = () => {
    const body = {
      venue_id: selectionModel,
    };
    handleClosedialog();
    client
      .post(`/api/${props.submitUrl}`, body)
      .then(() => {
        setalertOptions({
          alertType: "success",
          alertOpen: true,
          alertContent: "Submitted Successfully!",
        });
        client.get(`/api/${props.refreshUrl}`).then((response) => {
          props.setpopularVenueList(response.data.results);
        });
      })
      .catch(() => {
        setalertOptions({
          alertType: "error",
          alertOpen: true,
          alertContent: "Submission Failed!",
        });
      });
  };

  const [selectionModel, setSelectionModel] = useState([]);

  function handleSelection(newSelectionModel) {
    console.log("new",newSelectionModel)
    if (newSelectionModel.length > 30) {
      newSelectionModel.pop();
    }
    setSelectionModel(newSelectionModel);
  }
  return (
    <div>
      <button className="exportbtnpluslg" onClick={handleClickOpen}>
        <span
          className="material-icons"
          style={{ color: "white", fontSize: "15px", paddingTop: "4px" }}
        >
          add
        </span>
      </button>
      <Dialog
        fullWidth
        open={opendialog}
        onClose={handleClosedialog}
        className="dialog"
      >
        <Box>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange}>
                <Tab label="CHOOSE VENUE" value="1" />
              </TabList>
            </Box>
            <TabPanel sx={{ p: 0 }} value="1">
              <DialogTitle>Choose Venue</DialogTitle>
              <DialogContent>
                <div
                  style={{
                    width: "100%",
                    paddingTop: "24px",
                    borderRadius: "8px",
                    border: "1px solid #E8E8E8",
                  }}
                >
                  <div className="mid-searchbar">
                    <SearchBar
                      searchvalue={searchvalue}
                      setsearchvalue={setsearchvalue}
                    />
                  </div>
                  <div style={{ height: "650px" }}>
                    <DataGrid
                      sx={{ mt: 2 }}
                      rows={customerList}
                      columns={columns}
                      pageSize={listlength}
                      rowsPerPageOptions={[listlength]}
                      getRowId={(row) => row.id}
                      checkboxSelection
                      pagination
                      // onSelectionModelChange={(ids) => {
                      //   const selectedIDs = new Set(ids);
                      //   const selectedRows = data.rows.filter((row) =>
                      //     selectedIDs.has(row.id),
                      //   );
              
                      //   setSelectedRows(selectedRows);
                      // }}
                      components={{
                        Pagination: CustomPagination,
                      }}
                      onSelectionModelChange={(newSelectionModel) => {
                        handleSelection(newSelectionModel);
                         
                      }}
                      selectionModel={selectionModel}
                      keepNonExistentRowsSelected
                    />
                  </div>
                </div>
              </DialogContent>
              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginBlockStart: "20px",
                  padding: "20px",
                }}
              >
                <Button className="primarybtn" onClick={handleSubmit}>
                  Save
                </Button>
                <Button className="secondarybtn" onClick={handleClosedialog}>
                  Cancel
                </Button>
              </DialogActions>
            </TabPanel>
          </TabContext>
        </Box>
      </Dialog>
      <AlertPopup alertOptions={alertOptions} handleClose={handleClosep} />
    </div>
  );
};

export default PopupPopularVenue;
