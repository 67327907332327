import { useEffect, useState } from "react";
import { history } from "../History/history";
import { client } from "./Client";

const useAxios = (configParams) => {
    const [res, setRes] = useState('');
    const [err, setErr] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
       fetchDataUsingAxios(configParams);
       return () => {
        setLoading(false);
      }
    }, []);

    const fetchDataUsingAxios = async() => {
        
        await client.request(configParams)
        .then(res => setRes(res))
        .catch(err =>{
            setErr(err)
            if ( err?.response?.status === 401||403) {
                history.push('/login')
              }
        } 
        )
        .finally(() => setLoading(false))}

    return [res, err, loading];
}
export default useAxios;