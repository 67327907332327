import React, { useState} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Tab,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import "./Popup.css";
import DropdownSelect from "../../Components/DropdownSelect/DropdownSelect";
import { client } from "../../Helper/Client/Client";
import AlertPopup from "../../Components/AlertPopup/AlertPopup";
import { WithContext as ReactTags } from "react-tag-input";

const AddCategory = (props) => {
  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const [tags, setTags] = React.useState([]);
  
  // console.log("Tags", tags);

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {
    // console.log("The tag at index " + index + " was clicked");
  };

  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");
  const initialData = {
    categoryname: "",
    categorytheme: "",
    lastname: "",
    email: "",
    phone: "",
    status: "",
    address1: "",
    address2: "",
    profile: null,
    postcode: "",
    city: "",
    stateregion: "",
    country: "",
  };
  const [inputs, setinputs] = useState(initialData);
  const [status, setstatus] = useState("");
  const [validationError, setvalidationError] = useState(false);
  // console.log(props.roleid)
  function handleChanged(e) {
    const { name, value } = e.target;
    setinputs((inputs) => ({ ...inputs, [name]: value }));
  }
  function handleChangedfiles(event) {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setinputs((inputs) => ({ ...inputs, profile: img }));
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function handleSubmit() {
    if (
      inputs.categoryname === "" ||
      tags === "" ||
      status === ""
    ) {
      setvalidationError(true);
      return;
    } else {
      setvalidationError(false);
    }
    let form_data = new FormData();
    form_data.append("type", inputs.categoryname);
    form_data.append("venue_theme",JSON.stringify(
      tags.map((item) => {
        return item.text;
      })
    ),);
    form_data.append("status", status);
    inputs.profile && form_data.append("icon", inputs.profile);
    handleClose();
    client
      .post("/api/venue_type", form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(() => {
        setValue("1");
        setinputs(initialData);
        setstatus("");
        setTags("");
        setalertOptions({
          alertType: "success",
          alertOpen: true,
          alertContent: "Submitted Successfully!",
        });
        client.get("/api/venue_type_listview").then((response) => {
          props.setpage(1);
          props.setcategoriesList(response.data.results);
          props.setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          );
        });
      })
      .catch((err) => {
        setalertOptions({
          alertType: "error",
          alertOpen: true,
          alertContent: "Submitted UnSuccessfull!",
        });
      });
  }

  // console.log(inputs)
  const Input = styled("input")({
    display: "none",
  });

  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });

  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };

  return (
    <>
      <button className="exportbtnpluslg" onClick={handleClickOpen}>
        <span
          className="material-icons"
          style={{
            color: "white",
            fontSize: "15px",
            paddingTop: "4px",
          }}
        >
          add
        </span>
      </button>
      <Dialog fullWidth open={open} onClose={handleClose} className="dialog">
        <Box>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Information" value="1" />

              
                <img
                  onClick={handleClose}
                  src="/Images/close.png"
                  height="32px"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "8px",
                  }}
                />
              </TabList>
            </Box>
            <TabPanel sx={{ p: 0 }} value="1">
              <DialogTitle>Category</DialogTitle>
              <div
                style={{
                  textAlign: "center",
                  position: "relative",
                  minHeight: "150px",
                }}
              >
                {inputs.profile ? (
                  <img
                    src={URL.createObjectURL(inputs.profile)}
                    height="150px"
                    width="150px"
                    style={{ borderRadius: "100px" }}
                  />
                ) : (
                  <img src="/Images/dropimg.svg" height="150px" width="140px" />
                )}
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    bottom: "-10px",
                    right: 0,
                  }}
                >
                  <label htmlFor="icon-button-file">
                    <Input
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      name="profile"
                      onChange={handleChangedfiles}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <img src="/Images/edit.svg" />
                    </IconButton>
                  </label>
                </div>
              </div>
              <DialogContent
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div
                  style={{ display: "grid", gridTemplateColumns: "auto auto" }}
                >
                  <label className="mt20" style={{ width: "100%" }}>
                    Category Name
                    <input
                      className={
                        validationError &&
                        inputs.categoryname === "" &&
                        "validationerror"
                      }
                      type="text"
                      name="categoryname"
                      onChange={handleChanged}
                      value={inputs.categoryname}
                      //  placeholder="Enter your Name"
                      required
                      size="50"
                    />
                  </label>
                  <br />

                  <label className="mt20" style={{ width: "100%" }}>
                    Category Theme
                    <ReactTags
                    className={
                      validationError &&
                      tags === "" &&
                      "validationerror"
                    }
                      tags={tags}
                      // suggestions={suggestions}
                      delimiters={delimiters}
                      handleDelete={handleDelete}
                      handleAddition={handleAddition}
                      handleDrag={handleDrag}
                      handleTagClick={handleTagClick}
                      inputFieldPosition="bottom"
                      autocomplete
                      editable
                    />
                 
                  </label>
                  
                </div>

               
                <label className="mt20">
                  Status
                  <DropdownSelect
                    validationError={validationError}
                    value={status}
                    setdropDownValue={setstatus}
                    values={[
                      { name: "Active", value: true },
                      { name: "Blocked", value: false },
                    ]}
                  />
                </label>
              </DialogContent>

              <DialogActions style={{ textAlign: "end", padding: "20px" }}>
              
                <Button className="primarybtn" onClick={handleSubmit}>
                  Submit
                </Button>
              </DialogActions>
            </TabPanel>
            <TabPanel sx={{ p: 0 }} value="2">
              <DialogTitle>Address</DialogTitle>
              <DialogContent
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label className="mt20">
                  Address Line 1
                  <input
                    className={
                      validationError &&
                      inputs.address1 === "" &&
                      "validationerror"
                    }
                    type="text"
                    name="address1"
                    value={inputs.address1}
                    onChange={handleChanged}
                    
                    required
                  />
                </label>
                <label className="mt20">
                  Address Line 2
                  <input
                    type="text"
                    name="address2"
                    value={inputs.address2}
                    onChange={handleChanged}
                    placeholder="Optional"
                    required
                  />
                </label>
                <label className="mt20">
                  City
                  <input
                    type="text"
                    name="city"
                    value={inputs.city}
                    onChange={handleChanged}
                    
                    required
                  />
                </label>
                <label className="mt20">
                  Country
                  <input
                    type="text"
                    name="country"
                    value={inputs.country}
                    onChange={handleChanged}
                    
                    required
                  />
                </label>
                <div
                  style={{
                    display: "flex",
                    marginBlockEnd: "20px",
                    marginBlockEnd: "20px",
                    flexDirection: "row",
                  }}
                >
                  <label className="mt20" style={{ marginRight: "15px" }}>
                    State/Region
                    <input
                      type="text"
                      name="stateregion"
                      value={inputs.stateregion}
                      onChange={handleChanged}
                      
                      required
                    />
                  </label>
                  <label className="mt20">
                    Postcode
                    <input
                      className={
                        validationError &&
                        inputs.postcode === "" &&
                        "validationerror"
                      }
                      type="number"
                      name="postcode"
                      value={inputs.postcode}
                      onChange={handleChanged}
                      
                      required
                    />
                  </label>
                </div>
              </DialogContent>
              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "20px",
                }}
              >
                <Button
                  className="secondarybtn"
                  onClick={() => {
                    setValue("1");
                  }}
                >
                  Previous
                </Button>
                <Button
                  className="primarybtn"
                  onClick={() => {
                    if (inputs.postcode === "" || inputs.address1 === "") {
                      setvalidationError(true);
                      return;
                    } else {
                      setvalidationError(false);
                    }
                    setValue("3");
                  }}
                >
                  Next Step
                </Button>
              </DialogActions>
            </TabPanel>
            <TabPanel sx={{ p: 0 }} value="3">
              <DialogTitle>Submission</DialogTitle>
              <DialogContent className="submission">
                <h6>Profile Details</h6>
                <p>
                  <span className="span">Name:</span> {inputs.categoryname}{" "}
                  {inputs.lastname}
                </p>
                <p>
                  <span className="span">Email:</span> {inputs.email}
                </p>
                <p>
                  <span className="span">Phone:</span> {inputs.phone}
                </p>
                <hr style={{ color: "#E8E8E8", height: "1px" }} />
                <h6>Address Details</h6>
                <p>
                  <span className="span">Address Line 1:</span>{" "}
                  {inputs.address1} {inputs.address2}
                </p>
                <p>
                  <span className="span">City:</span> {inputs.city}
                </p>
                <p>
                  <span className="span">Country:</span>
                  {inputs.country}
                </p>
                <p>
                  <span className="span">State/Region:</span>{" "}
                  {inputs.stateregion}
                </p>
                <p>
                  <span className="span">Postcode:</span> {inputs.postcode}
                </p>
              </DialogContent>
              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBlockStart: "20px",
                  padding: "20px",
                }}
              >
                <Button
                  className="secondarybtn"
                  onClick={() => {
                    setValue("2");
                  }}
                >
                  Previous
                </Button>
                <Button className="primarybtn" onClick={handleSubmit}>
                  Submit
                </Button>
              </DialogActions>
            </TabPanel>
          </TabContext>
        </Box>
      </Dialog>
      <AlertPopup alertOptions={alertOptions} handleClose={handleClosep} />
    </>
  );
};

export default AddCategory;
