import React, { useState } from 'react'
import {
  Button,
  Dialog,
  MenuItem,
} from "@mui/material";
import './Popup.css'

const PopupDelete = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>
        Delete Item
      </MenuItem>
      <Dialog open={open} onClose={handleClose} className='dialog'>
        <div style={{ width: '500px', height: '174px', padding: '24px'}}>
          <h4>Deleting Item</h4>
          <p>Are you sure you want to delete this item?</p>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: 'end', gap: '20px' }}>
            <Button className='secondarybtn' onClick={handleClose} >Cancel</Button>
            <Button className='confirmbtn' onClick={props.delete} >Yes</Button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default PopupDelete