import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { Button, Pagination } from '@mui/material';
import SearchBar from '../../Components/SearchBar/SearchBar';
import { RenderCellExpand } from '../../Components/RenderCellExpand/RenderCellExpand';
import PopupBlog from '../Popup/PopupBlog';
import PopupBlogEdit from '../Popup/PopupBlogEdit';
import useAxios from '../../Helper/Client/useAxios';
import { client } from '../../Helper/Client/Client';
import PopupDelete from '../Popup/PopupDelete';
import PopupDeleteAll from '../Popup/PopupDeleteAll';
import { history } from '../../Helper/History/history';


function BlogPost() {
  // table view(edit/delete)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [editbar, setEditbar] = useState('');
  const [blogList, setblogList] = useState([])
  const [selectionModel, setSelectionModel] = useState([]);
  const [pageCount, setpageCount] = useState(0)
  const [page, setpage] = useState(1)
  const [listlength, setlistlength] = useState(0)
  const [searchvalue, setsearchvalue] = useState('')


  useEffect(() => {
    if (blogList?.length !== undefined) {
      setlistlength(blogList.length)
    }
  }, [blogList])

  useEffect(() => {
    client.get(`/api/blog_search_by_title?search=${searchvalue}`).then((response) => {
      if(response.data.results ){
        setblogList(response.data.results) 
        setpage(1)
        setpageCount(Math.ceil(response.data.count / response.data.results.length))}
        else{ setblogList([])}
    }).catch((err)=>{
      if ( err?.response?.status === 401||403) {
        history.push('/login')
      }
  })
  }, [searchvalue])

  const showEditbar = (event, id) => {
    setAnchorEl(event.currentTarget);
    // console.log(id);
    setEditbar(id);
  };
  const handleClose = () => {
    setEditbar(null);
  };

  const open = Boolean(anchorEl2);
  const handleClickaction = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseaction = () => {
    setAnchorEl2(null);
  };

  function CustomPagination() {

    return (
      <Pagination
        className='pagination'
        color='warning'
        count={pageCount}
        page={page}
        onChange={(event, value) => {
          client.get(`/api/blog_paginated_list?page=${value}`).then((response) => {
            setblogList(response.data.results);
          })
          setpage(value)
        }}
        showFirstButton showLastButton
      />
    );
  }

  function handleDeleteBlog(i) {
    client.delete(`/api/blog_delete/${i}`).then(() => {
      handleClose()
      client.get('/api/blog_paginated_list').then((response) => {
        if(response.data.results){
          setpage(1);
          setblogList(response.data.results);
      setpageCount(
          Math.ceil(response.data.count / response.data.results.length)
        )
        }
        else{
          setpage(0);
          setpageCount(0)
          setblogList([])
        }
      })
    })
  }

  const handlebulkDelete = () => {
    client.delete(`/api/blog_bulk_delete`, { data: { "blog_id": selectionModel } }).then(() => {
      setAnchorEl2(null);
      client.get(`/api/blog_paginated_list`).then((response) => {
        if(response.data.results){
          setpage(1);
          setblogList(response.data.results);
      setpageCount(
          Math.ceil(response.data.count / response.data.results.length)
        )
        }
        else{
          setpage(0);
          setpageCount(0)
          setblogList([])
        }
      })
    })
  }

  const columns = [
    {
      field: 'blog_title',
      headerName: 'TITLE',
      width: 250,
      renderCell: RenderCellExpand
    },
    {
      field: 'cover_image',
      headerName: 'IMAGE',
      align: 'center', headerAlign: 'center',
      width: 150,
      renderCell: (params) => (
        <>{params.value ? <img src={params.value} height={70} style={{ borderRadius: '8px' }} /> : <img src='../Images/img.png' height={70} style={{ borderRadius: '8px' }} />}</>)

    },
    {
      field: 'blog_date',
      headerName: 'DATE',
      width: 150,
      align: 'center', headerAlign: 'center', type: 'date',
      renderCell: (params) => (params.value && (params.value.split('T')[0].split('-')[2] + '.' + params.value.split('T')[0].split('-')[1] + '.' + params.value.split('T')[0].split('-')[0]))
    },
    {
      field: 'status',
      headerName: 'STATUS',
      align: 'center', headerAlign: 'center',
      width: 150,
      renderCell: (params) => (
        <span style={{ textAlign: 'center', width: '110px', padding: '7px', backgroundColor: params.value ? "#e8f9ef" : "#ffe7e6", color: params.value ? '#20A144' : '#EB5A52' }}>{params.value} </span>)

    },
    {
      field: "id",
      headerName: "",
      width: 30,
      renderCell: (params) => (
        <>
          <IconButton onClick={(event) => showEditbar(event, params.value)}>
            <img src="../Images/dots.png" />
          </IconButton>
          <Menu anchorEl={anchorEl}
            open={editbar === params.value ? true : false}
            onClose={handleClose}>
            <PopupBlogEdit page={page} handleClose={handleClose} setblogList={setblogList} blogid={params.value} />
            <PopupDelete delete={() => handleDeleteBlog(params.value)} />
          </Menu>
        </>
      ),
    },
  ];
// console.log("Blog",blogList);

  return (
    <article>
      <div className="buttondiv">
        <PopupBlog setblogList={setblogList} setpage={setpage} setpageCount={setpageCount} />
      </div>
      <h4>Blog Post</h4>
      <br />
      <div style={{ width: '100%', paddingTop: '24px', borderRadius: '8px', border: '1px solid #E8E8E8' }}>
        <div className="mid-searchbar">
          <SearchBar searchvalue={searchvalue} setsearchvalue={setsearchvalue}  />
          <Button onClick={handleClickaction}
            className='tertiarybtn' endIcon={<img src="../Images/Arrow-Down.svg" />}>Actions</Button>
          <Menu anchorEl={anchorEl2}
            open={open}
            onClose={handleCloseaction}>
            <PopupDeleteAll delete={handlebulkDelete} />
          </Menu>
        </div>
        <div style={{ height: '650px' }}>
          <DataGrid
            rowHeight={100}
            sx={{ mt: 2 }}
            rows={blogList}
            columns={columns}
            pageSize={listlength}
            rowsPerPageOptions={[listlength]}
            getRowId={(row) => row.id}
            checkboxSelection
            pagination
            // paginationMode="server"
            keepNonExistentRowsSelected
            components={{
              Pagination: CustomPagination
            }}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
          />
        </div>
      </div>
    </article>
  )
}

export default BlogPost;