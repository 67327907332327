import React,{useEffect, useState} from 'react'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TextEditor from '../../Components/TextEditor/TextEditor';
import {
 
  Button,
  Dialog,

} from "@mui/material";
import "./Popup.css";


export default function Productshippingpopupedit(props) {
    const [open, setOpen] = useState(false);
    const [value, setValue] = React.useState("1");
    const [texteditor, settexteditor] = useState('')
    const [inputs, setinputs] = useState({pickup_way:'',id:''})
    const [validationError, setvalidationError] = useState(false)
  
    useEffect(() => {
      if (props && props.item) {
        setinputs({pickup_way:props.item.pickup_way,id:props.item.id})
        settexteditor(props.item.description)
      }

    }, [props.item]);
    // console.log("inputs",inputs);
    // console.log("texteditor",texteditor);
    function handleChanged(e) {
      const { name, value } = e.target;
      setinputs((inputs) => ({ ...inputs, [name]: value }));
    }
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleSubmit=()=>{
      if (inputs.pickup_way === '') {
        setvalidationError(true)
        return
      }
      else {
        setvalidationError(false)
      }
      props.setshippingList(props.shippingList.map(item => {
        if (item.id === inputs.id) {
          const updatedItem = {
            ...item,
            "pickup_way": inputs.pickup_way,
            "description": texteditor
          }
          // console.log("updatedItem",updatedItem);
          // console.log("Item",item);
          return updatedItem
        } return item
      }))
      props.setshippingEdited(true)
      handleClose()
    }

  return (
    <div>
      <img src="../Images/edit.png" height='16px' onClick={handleClickOpen} className='pointer'/>
        
      <Dialog  open={open} onClose={handleClose} className='settingsdialog'>
        <TabContext value={value} style={{padding:'5px'}}>
            <TabList onChange={handleChange} aria-label="Venue details">
             
                <Tab label="Shipping" value="1" />
            </TabList>
          
            <TabPanel value="1" className="setting-addnew">
              <h4>
                Shipping
              </h4>
              <label style={{ color: 'grey', marginBlockEnd: '20px',width:'100%'}}>Pick up Way
               <br/> <input
               value={inputs.pickup_way}
               className={(validationError && inputs.pickup_way === '') ? "validationerror" : ""}
                      style={{ width:'100%'}}
                      name="pickup_way"
                      maxLength={50}
                      required
                      onChange={handleChanged}
                    />
              </label>
              <label style={{ color: 'grey', marginBlockEnd: '20px',width:'100%' }}>Description
              <TextEditor  settexteditor={settexteditor} texteditor={texteditor} />
              </label>
              <div style={{display:'flex',flexDirection:'row',paddingTop:'10px',gap:10}}>
                  <Button className="primarybtn" onClick={handleSubmit}>Save</Button>
                  <Button className="secondarybtn" onClick={handleClose}>Cancel</Button>
              </div>
            </TabPanel>
        </TabContext>
      </Dialog>
    </div>
  )
}
