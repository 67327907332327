import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React, { useEffect, useRef, useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Button, styled, Pagination } from "@mui/material";
import DropdownSelect from "../../Components/DropdownSelect/DropdownSelect3";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SearchBar from "../../Components/SearchBar/SearchBar.js";
import useAxios from "../../Helper/Client/useAxios";
import { useParams } from "react-router-dom";
import { client } from "../../Helper/Client/Client";
import AlertPopup from "../../Components/AlertPopup/AlertPopup";
import PopupDelete from "../Popup/PopupDelete";
import AddVenue from "../Popup/AddVenue";
import { history } from "../../Helper/History/history";
import baseURL from "../../Helper/Client/baseURL";
import ReactToPrint from "react-to-print";
import Select from "@mui/material/Select";
import {FormControl} from "@mui/material";

function Vendordetails() {
  let { id } = useParams();

  const [customerDetail, setcustomerDetail] = useState([]);
  const [transactionList, settransactionList] = useState([]);
  const [status, setstatus] = useState(0);
  const initialData = {
    firstname: "",
    lastname: "",
    phone: "",
    status: "",
    address1: "",
    address2: null,
    phone2: null,
    profile: "",
    stateregion: "",
    postcode: "",
    city: "",
    country: "",
  };
  const [inputs, setinputs] = useState(initialData);
  const [approved, setapproved] = useState();
  const [edit, setedit] = useState(false);
  const [validationError, setvalidationError] = useState(false);
  const [monthlyIncomeList, setmonthlyIncomeList] = useState([]);
  const [getimage, setgetimage] = useState(null);
  const [pageCount, setpageCount] = useState(0);
  const [page, setpage] = useState(1);
  const [listlength, setlistlength] = useState(0);
  const [searchvalue, setsearchvalue] = useState("");
  const [dropdownvalue, setdropdownvalue] = useState("10%");

  function handleChanged(e) {
    const { name, value } = e.target;
    setinputs((inputs) => ({ ...inputs, [name]: value }));
  }

  const Input = styled("input")({
    display: "none",
  });

  useEffect(() => {
    client
      .get(`/api/transaction_list_vendor/${id}?search=${searchvalue}`)
      .then((response) => {
        if (response.data.results) {
          settransactionList(response.data.results);
          setpage(1);
          setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          );
        } else {
          settransactionList([]);
        }
      });
  }, [searchvalue]);

  const [response, isError, isLoading] = useAxios({
    url: `/api/vendor_details/${id}`,
    method: "get",
    
  });
  const [transactionresponse, transactionisError, transactionisLoading] = useAxios({
    url: `/api/transaction_list_vendor/${id}?search=${searchvalue}`,
    method: "get",
    
  });
  const [mothlyIncomeresponse, mothlyIncomeisError, mothlyIncomeisLoading] =
    useAxios({
      url: `/api/monthly_income_vendor/${id}`,
      method: "get",
     
    });

  useEffect(() => {
    if (response && response.data.data) {
      setcustomerDetail(response.data.data);
    }
    if (transactionresponse && transactionresponse.data.results) {
      settransactionList(transactionresponse.data.results);
      setpageCount(Math.ceil(transactionresponse.data.count/transactionresponse.data.results.length))
    }
    if (mothlyIncomeresponse && mothlyIncomeresponse.data.results) {
      setmonthlyIncomeList(mothlyIncomeresponse.data.results);
    }

  }, [response, mothlyIncomeresponse,transactionresponse]);
  useEffect(() => {
    if (transactionList?.length !== undefined) {
      setlistlength(transactionList.length);
    }
  }, [transactionList]);

  function CustomPagination() {
    return (
      <Pagination
        className="pagination"
        color="warning"
        count={pageCount}
        page={page}
        onChange={(event, value) => {
          client
            .get(`/api/gift_paginated_list?search=${searchvalue}&page=${value}`)
            .then((response) => {
              settransactionList(response.data.results);
            });
          setpage(value);
        }}
        showFirstButton
        showLastButton
      />
    );
  }

  useEffect(() => {
    setinputs((inputs) => ({
      ...inputs,
      firstname: customerDetail.first_name,
      lastname: customerDetail.last_name,
      phone: customerDetail.phone_1,
      phone2: customerDetail.phone_2,
      status: customerDetail.is_active,
      approved: customerDetail.approved,
      address1: customerDetail.street_1,
      address2: customerDetail.street_2,
      profile: customerDetail.user_image,
      stateregion: customerDetail.state,
      postcode: customerDetail.zip_code,
      city: customerDetail.city,
      country: customerDetail.country,
      companyname: customerDetail.br_company_name,
      brnumber: customerDetail.br_number,
      brdate: customerDetail.contract_date,
    }));
    setgetimage(customerDetail.br_file);
    setstatus(customerDetail.is_active ? 1 : 0);
    setapproved(customerDetail.approved ? 1 : 0);
  }, [customerDetail]);
 
  // console.log("customerDetail1", response?.data?.data);
  // console.log("BR File", getimage);
  // console.log("Vendor Active", status);
  // console.log("Vendor Approved", approved);

  function handleCancel() {
    setedit(false);
    setvalidationError(false);
  }

  function handleSubmit() {
    if (inputs.brdate === null && filelist.length !== 0) {
      setvalidationError(true);
      return;
    } else {
      setvalidationError(false);
    }

    if (
      inputs.firstname === "" ||
      inputs.postcode === "" ||
      inputs.address1 === "" ||
      inputs.phone === ""
    ) {
      setvalidationError(true);
      return;
    } else {
      setvalidationError(false);
    }
    let form_data = new FormData();
    form_data.append("first_name", inputs.firstname);
    form_data.append("last_name", inputs.lastname);
    form_data.append("street_1", inputs.address1);
    form_data.append(
      "street_2",
      inputs.address2 === "null" || inputs.address2 === null
        ? ""
        : inputs.address2
    );
    form_data.append("city", inputs.city);
    form_data.append("state", inputs.stateregion);
    form_data.append("country", inputs.country);
    form_data.append("zip_code", inputs.postcode);
    form_data.append("phone_1", inputs.phone);
    form_data.append(
      "phone_2",
      inputs.phone2 === "null" || inputs.address2 === null ? "" : inputs.phone2
    );
    {customerDetail.approved ? form_data.append("is_active", status) : form_data.append("approved", approved)}
    // form_data.append("is_active", status);
    // form_data.append("approved", approved);
    (getimage || filelist[0]) &&
      form_data.append(
        "br_file",
        filelist[0] ? filelist[0] : getimage?.replace(`${baseURL}/media`, "")
      );
    form_data.append("br_company_name", inputs.companyname);
    form_data.append("br_number", inputs.brnumber);
    inputs.brdate && form_data.append("contract_date", inputs.brdate);
    client
      .put(`api/updateprofile/${id}`, form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(() => {
        setalertOptions({
          alertType: "success",
          alertOpen: true,
          alertContent: "Submitted Successfully!",
        });
        setfilelist([]);
        setedit(false);
        setstatus("");
        setapproved("");
        client
          .get(`/api/vendor_details/${id}`)
          .then((response) => setcustomerDetail(response.data.data));
      })
      .catch(() => {
        setalertOptions({
          alertType: "error",
          alertOpen: true,
          alertContent: "Submission Failed!",
        });
      });
  }
  const [venueList, setvenueList] = useState([]);
// console.log("Venue List",venueList);
  

  const [venueresponse, venueisError, venueisLoading] = useAxios({
    url: `/api/venuelist_by_vendor/${id}`,
    method: "get",
 
  });

  useEffect(() => {
    if (venueresponse && venueresponse.data.data) {
     
      setvenueList(venueresponse.data.data);
    }

    return () => {

    };
  }, [venueresponse]);

  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });

  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };

  // tab property
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // table view(edit/delete)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);

  const [editbar, setEditbar] = useState("");
  const [editbar2, setEditbar2] = useState("");
  const showEditbar = (event, id) => {
    setAnchorEl(event.currentTarget);
    // console.log(id);
    setEditbar(id);
  };
  const showEditbar2 = (event, id) => {
    setAnchorEl3(event.currentTarget);
    // console.log(id);
    setEditbar2(id);
  };

  const handleClose = () => {
    setEditbar(null);
  };

  // top actions

  const open = Boolean(anchorEl2);
  const handleClickaction = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseaction = () => {
    setAnchorEl2(null);
  
  };
  const handleCloseaction2 = () => {
    setAnchorEl3(null);
    setEditbar2("");
  };

  const handleEdit = (i) => {
    history.push(`/venue_details/${i}`);
  };
  const handleDetailView = (i) => {
    history.push(`/transactiondetail/${i}`);
  };
  function handleDelete(i) {
    client.delete(`/api/venue_delete/${i}`).then(() => {
      handleClose();
      client.get(`/api/venuelist_by_vendor/${id}`).then((response) => {
        setvenueList(response.data.data);
      });
    });
  }
  const [filelist, setfilelist] = useState([]);

  const fileChange = (event) => {
    setfilelist(filelist.concat(event.target.files[0]));
    
  };

  const componentRef = useRef([]);

  const componentRef2 = useRef();

  const columns = [
    {
      field: "id",
      headerName: "TRANSACTION CODE ",
      width: 200,
    },
    {
      field: "venue_name",
      headerName: "VENUE",
      width: 150,
    },
    {
      field: "amount",
      headerName: "PRICE",
      width: 150,
      align: "center",
      headerAlign: "center",
      type: "number",
    },
    {
      field: "date",
      headerName: "DATE",
      align: "center",
      headerAlign: "center",
      type: "date",
      width: 180,
      renderCell: (params) =>
        params.value.split("T")[0].split("-")[2] +
        "." +
        params.value.split("T")[0].split("-")[1] +
        "." +
        params.value.split("T")[0].split("-")[0],
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 160,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <span
          style={{
            textAlign: "center",
            width: "110px",
            padding: "7px",
            backgroundColor: params.value === "paid" ? "#e8f9ef" : "#ffe7e6",
            color: params.value === "paid" ? "#20A144" : "#EB5A52",
          }}
        >
          {"Completed"}{" "}
        </span>
      ),
    },
    {
      field: "ID",
      headerName: "",
      width: 10,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.row.id,
      renderCell: (params) => (
        <>
          <IconButton onClick={(event) => showEditbar(event, params.value)}>
            <img src="../Images/dots.png" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={editbar === params.value ? true : false}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleDetailView(params.value)}>
              Detailed View
            </MenuItem>
           
          </Menu>
        </>
      ),
    },
  ];

  function CustomToolbar() {
    return (
      <div style={{ position: "absolute", right: "0px", top: "-210px" }}>
        <GridToolbarContainer>
          <GridToolbarExport
            sx={{
              color: "black",
              bgcolor: "#F5F5F5",
              textTransform: "capitalize",
              p: 1.2,
              pr: 3,
            }}
          />
        </GridToolbarContainer>
      </div>
    );
  }

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

 
  // console.log("C Venue List", venueList);
  
  return (
    <article>
      {value === "1" && (
        <AddVenue vendorid={customerDetail.id} setvenueList={setvenueList} />
      )}
      <h4>
        Vendor - {customerDetail.first_name} {customerDetail.last_name}
      </h4>

      <TabContext value={value} style={{ padding: "5px" }}>
        <TabList onChange={handleChange} aria-label="Customer details">
          <Tab label="Details" value="1" />
          <Tab label="Transaction" value="2" />
          <Tab label="Contract" value="3" />
          <Tab label="Total Amount" value="4" />
        </TabList>
        <TabPanel value="1">
          {!edit && (
            <div className="venuedetails-table">
              <Accordion
                sx={{
                  border: "1px solid rgb(232, 232, 232)",
                  boxShadow: "none",
                  mb: 2,
                  pb: 1,
                }}
              >
                <AccordionSummary
                  expandIcon={<img src="../Images/Arrow-Down.svg" />}
                >
                  <h5>Address</h5>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto auto auto",
                    }}
                  >
                    <div>
                      <p>
                        Name:{" "}
                        <span className="span">
                          {customerDetail.first_name}
                        </span>
                      </p>
                      <p>
                        Phone:{" "}
                        <span className="span">
                          {customerDetail.phone_1},{customerDetail.phone_2}
                        </span>
                      </p>
                      {/* <p>
                        Last name:{" "}
                        <span className="span">
                          {customerDetail.last_name}{" "}
                        </span>
                      </p> */}
                      <p>
                        Address:{" "}
                        <span className="span">
                          {customerDetail.street_1},{customerDetail.street_2}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        State/Region:{" "}
                        <span className="span"> {customerDetail.state}</span>
                      </p>
                      <p>
                        City:{" "}
                        <span className="span">{customerDetail.city}</span>
                      </p>
                      <p>
                        Country:{" "}
                        <span className="span">{customerDetail.country}</span>{" "}
                      </p>
                    </div>
                    <div>
                      <p>
                        Email:{" "}
                        <span className="span">{customerDetail.email}</span>
                      </p>
                      <p>
                        Postcode:{" "}
                        <span className="span">{customerDetail.zip_code}</span>
                      </p>
                    </div>
                  </div>
                  <img
                    src="/Images/edit.svg"
                    style={{ float: "right" }}
                    onClick={() => setedit(true)}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  border: "1px solid rgb(232, 232, 232)",
                  boxShadow: "none",
                  mb: 2,
                  pb: 1,
                }}
              >
                <AccordionSummary
                  expandIcon={<img src="../Images/Arrow-Down.svg" />}
                >
                  <h5>BR</h5>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto auto auto",
                    }}
                  >
                   
                    <div
                      style={{
                        textAlign: "center",
                        margin: "10px",
                        backgroundColor: "#f5f5f5",
                        width: "250px",
                        height: "130px",
                        borderRadius: "8px",
                        paddingTop: "25px",
                      }}
                    >
                      <img src="../Images/image.svg" />
                      <a
                        href={getimage}
                        style={{
                          padding: "20px",
                          fontSize: "12px",
                          display: "block",
                        }}
                        download
                        target="_blank"
                        rel="noopener"
                      >
                        下載BR(download)
                      </a>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  border: "1px solid rgb(232, 232, 232)",
                  boxShadow: "none",
                  mb: 2,
                  pb: 1,
                }}
              >
                <AccordionSummary
                  expandIcon={<img src="/Images/Arrow-Down.svg" />}
                >
                  <h5>Venue List</h5>
                </AccordionSummary>
                <AccordionDetails>
                  {venueList.map((item) => (
                    <div
                      key={item.id}
                      style={{
                        display: "grid",
                        gridTemplateColumns: "10vw 12vw 12vw 120px 100px 30px",
                        alignItems: "center",
                        gap: 20,
                        marginBlockEnd: "20px",
                        borderBottom: "1px solid rgb(232, 232, 232)",
                        paddingBlockEnd: "20px",
                      }}
                    >
                      
                    
                      <p>{item.title}</p>
                      <p>{item.location}</p>
                      <p>{customerDetail.phone_1}</p>
                      <span
                        style={{
                          textAlign: "center",
                          width: "100px",
                          padding: "7px",
                          backgroundColor: item.status ? "#e8f9ef" : "#ffe7e6",
                          color: item.status ? "#20A144" : "#EB5A52",
                        }}
                      >
                        {item.status ? "Active" : "Blocked"}{" "}
                      </span>
                      <>
                        <IconButton
                          onClick={(event) => showEditbar2(event, item.id)}
                        >
                          <img src="../Images/dots.png" />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl3}
                          open={editbar2 === item.id ? true : false}
                          onClose={handleCloseaction2}
                        >
                          <MenuItem onClick={() => handleEdit(item.id)}>
                            Edit Item
                          </MenuItem>
                       
                          <PopupDelete delete={() => handleDelete(item.id)} />
                        </Menu>
                      </>
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
              <label className="mt20" style={{width:'150px'}}>
                Status <br></br>
                {customerDetail.approved ? <DropdownSelect
                  value={status}
                  setdropDownValue={setstatus}
                  values={[
                    { name: "Active", value: 1 },
                    { name: "Blocked", value: 0 },
                  ]}
                /> : <DropdownSelect
                value={approved}
                setdropDownValue={setapproved}
                values={[
                  { name: "Approved", value: 1 },
                  { name: "Disapproved", value: 0 },
                ]}
              />}
              </label>
              <Button
                  className="primarybtn"
                  onClick={handleSubmit}
                  style={{ marginLeft: "15px",marginTop:'-4px' }}
                >
                  Save
                </Button>
            </div>
          )}
          {edit && (
            <div
              className="venue-tab-details"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <h5>Information</h5>
              <label className="mt20">
                Address line 1
                <input
                  className={
                    validationError && inputs.address1 === ""
                      ? "validationerror mt10"
                      : "mt10"
                  }
                  style={{ width: "100%" }}
                  type="text"
                  name="address1"
                  value={inputs.address1}
                  
                  onChange={handleChanged}
                  required
                />
              </label>
              <label className="mt20">
                Address line 2
                <input
                  className="mt10"
                  style={{ width: "100%" }}
                  type="text"
                  placeholder="Optional"
                  onChange={handleChanged}
                  name="address2"
                  value={inputs.address2}
                
                />
              </label>
              <div
                style={{ display: "grid", gridTemplateColumns: "auto auto" }}
              >
                <label className="mt20" style={{ marginRight: "50px" }}>
                  Name
                  <input
                    className={
                      validationError && inputs.firstname === ""
                        ? "validationerror mt10"
                        : "mt10"
                    }
                    style={{ width: "100%" }}
                    type="text"
                    required
                    name="firstname"
                    onChange={handleChanged}
                    value={inputs.firstname}
                  />
                </label>
                <label className="mt20">
                Phone
                <input
                  className={
                    validationError && inputs.phone === ""
                      ? "validationerror mt10"
                      : "mt10"
                  }
                  style={{ width: "100%" }}
                  type="tel"
                  name="phone"
                  
                  onChange={handleChanged}
                  value={inputs.phone}
                  required
                />
              </label>
                {/* <label className="mt20">
                  Last name
                  <input
                    className="mt10"
                    style={{ width: "100%" }}
                    type="text"
                    name="lastname"
                    onChange={handleChanged}
                    value={inputs.lastname}
                   
                    required
                  />
                </label> */}
                <label className="mt20" style={{ marginRight: "50px" }}>
                  State/Region
                  <input
                    className="mt10"
                    style={{ width: "100%" }}
                    type="text"
                    name="stateregion"
                    value={inputs.stateregion}
                   
                    onChange={handleChanged}
                    required
                  />
                </label>
                <label className="mt20">
                  Postcode
                  <input
                    className={
                      validationError && inputs.postcode === ""
                        ? "validationerror mt10"
                        : "mt10"
                    }
                    style={{ width: "100%" }}
                    type="text"
                    name="postcode"
                    value={inputs.postcode}
                   
                    onChange={handleChanged}
                    required
                  />
                </label>
                <label className="mt20" style={{ marginRight: "50px" }}>
                  City
                  <input
                    className="mt10"
                    style={{ width: "100%" }}
                    type="text"
                    name="city"
                    value={inputs.city}
                    onChange={handleChanged}
                  
                    required
                  />
                </label>
                <label className="mt20">
                  Country
                  <input
                    className="mt10"
                    style={{ width: "100%" }}
                    type="text"
                    name="country"
                    value={inputs.country}
                   
                    onChange={handleChanged}
                    required
                  />
                </label>
              </div>
              <label className="mt20">
                Status
                {customerDetail.approved ? <DropdownSelect
                  value={status}
                  setdropDownValue={setstatus}
                  values={[
                    { name: "Active", value: 1 },
                    { name: "Blocked", value: 0 },
                  ]}
                /> : <DropdownSelect
                value={approved}
                setdropDownValue={setapproved}
                values={[
                  { name: "Approved", value: 1 },
                  { name: "Disapproved", value: 0 },
                ]}
              />}
                
                
              </label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "10px",
                }}
              >
                <Button
                  className="primarybtn"
                  onClick={handleSubmit}
                  style={{ marginRight: "10px" }}
                >
                  Save
                </Button>
                <Button className="secondarybtn" onClick={handleCancel}>
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </TabPanel>
        <TabPanel value="2">
          <div
            style={{
              width: "100%",
              paddingTop: "24px",
              borderRadius: "8px",
              border: "1px solid #E8E8E8",
            }}
          >
            <div className="mid-searchbar">
              <SearchBar
                searchvalue={searchvalue}
                setsearchvalue={setsearchvalue}
              />
              <Button
                onClick={handleClickaction}
                className="tertiarybtn"
                endIcon={<img src="../Images/Arrow-Down.svg" />}
              >
                Actions
              </Button>
              <Menu
                anchorEl={anchorEl2}
                open={open}
                onClose={handleCloseaction}
              >
                <MenuItem onClick={handleCloseaction}>Edit Item</MenuItem>
                <MenuItem onClick={handleCloseaction}>Delete Item</MenuItem>
              </Menu>
            </div>
            <div style={{ height: "650px" }}>
              <DataGrid
                sx={{ mt: 2 }}
                rows={transactionList}
                columns={columns}
                pageSize={listlength}
                rowsPerPageOptions={[listlength]}
                getRowId={(row) => row.id}
                checkboxSelection
                pagination
                // paginationMode="server"
                keepNonExistentRowsSelected
                components={{
                  Pagination: CustomPagination,
                  Toolbar: CustomToolbar,
                }}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value="3">
          <Accordion
            ref={componentRef2}
            sx={{ border: "1px solid rgb(232, 232, 232)", boxShadow: "none" }}
          >
            <AccordionSummary
              expandIcon={<img src="../Images/Arrow-Down.svg" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h5>Contract</h5>
            </AccordionSummary>
            <AccordionDetails>
              <div
                id="divcontents"
                style={{
                  display: "grid",
                  gridTemplateColumns: "20vw 20vw 20vw",
                  gap: 20,
                  marginBlockEnd: 30,
                }}
              >
                <label className="mt20">
                  Company Name
                  <input
                    value={inputs.companyname}
                    onChange={handleChanged}
                    className="mt10"
                    style={{ width: "100%" }}
                    type="text"
                    name="companyname"
                    required
                  />
                </label>
                <label className="mt20">
                  BR number
                  <input
                    value={inputs.brnumber}
                    onChange={handleChanged}
                    className="mt10"
                    style={{ width: "100%" }}
                    type="text"
                    name="brnumber"
                    required
                  />
                </label>
                <label className="mt20">
                  Date
                  <input
                    value={inputs.brdate?.split("T")[0]}
                    onChange={handleChanged}
                    className={
                      validationError && inputs.brdate === null
                        ? "validationerror mt10"
                        : "mt10"
                    }
                    style={{ width: "100%" }}
                    type="date"
                    name="brdate"
                    required
                  />
                </label>
                <div
                  className="print_btn"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f5f5f5",
                    height: "130px",
                    borderRadius: "8px",
                    paddingTop: "25px",
                  }}
                >
                  <ReactToPrint
                    trigger={() => <img src="../Images/print2.svg" />}
                    content={() => componentRef2.current}
                  />
            
                </div>
                {getimage && (
                  <div
                    style={{
                      textAlign: "center",
                      backgroundColor: "#f5f5f5",
                      height: "130px",
                      borderRadius: "8px",
                      paddingTop: "25px",
                    }}
                  >
                    <img src="../Images/image.svg" height="18px" />
                    <a
                      href={getimage}
                      style={{
                        padding: "10px",
                        fontSize: "12px",
                        display: "block",
                      }}
                      download
                      target="_blank"
                      rel="noreferrer"
                    >
                      下載BR(download)
                    </a>
                  </div>
                )}
                <div
                  className="print_btn"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f5f5f5",
                    height: "130px",
                    borderRadius: "8px",
                    paddingTop: "25px",
                  }}
                >
                  {!filelist.length > 0 ? (
                    <label htmlFor="icon-button-file">
                      <Input
                        id="icon-button-file"
                        type="file"
                        onChange={fileChange}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        sx={{ borderRadius: 0 }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <img src="../Images/image.svg" height="18px" />
                          <p style={{ padding: "10px", fontSize: "12px" }}>
                            上傳檔案拖曳至此(upload)
                          </p>
                        </div>
                      </IconButton>
                    </label>
                  ) : (
                    <>
                      <img src="../Images/image.svg" height="18px" />
                      <p style={{ padding: "10px", fontSize: "12px" }}>
                        {filelist[0].name}
                      </p>
                    </>
                  )}

                
                </div>
              </div>
              <div
                className="print_btn"
                style={{ float: "right", paddingBottom: "12px" }}
              >
                <Button
                  className="primarybtn"
                  style={{ marginRight: "10px" }}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </div>
            </AccordionDetails>
          </Accordion>
        </TabPanel>

        <TabPanel value="4">
          {monthlyIncomeList.map((item, i) => (
            <Accordion
              sx={{
                border: "1px solid rgb(232, 232, 232)",
                boxShadow: "none",
                pb: 1,
                mb: 1,
              }}
              ref={(el) => (componentRef.current[i] = el)}
            >
              <AccordionSummary
                expandIcon={<img src="../Images/Arrow-Down.svg" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h5>
                  Total Amount -{" "}
                  {month[Number(item.month) - 1] + " " + item.year}{" "}
                </h5>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto auto",
                  }}
                >
                  <p className="accordion_p">
                    Income: <span className="span">{item.income}</span>
                  </p>
                  <p className="accordion_p">
                    Refund: <span className="span">{item.refund}</span>
                  </p>
                  <p className="accordion_p">
                    Total: <span className="span">{item.total}</span>
                  </p>
                  <span className="accordion_p">
                    Commision
                    <br />{" "}
                    <DropdownSelect
                      apiurl={`/api/commission_update_vendor/${(item.id)}`}
                      value={item.commission}
                      setdropDownValue={setdropdownvalue}
                      values={[
                        // { name: `5%`, value: 5 },
                        // { name: `7%`, value: 7 },
                        { name: `10%`, value: 10 },
                      ]}
                    />
                  </span>
                  <div
                    style={{
                      textAlign: "center",
                      margin: "10px",
                    }}
                  >
                    <ReactToPrint
                      trigger={() => (
                        <img src="../Images/print.svg" className="print_btn" />
                      )}
                      content={() => componentRef.current[i]}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </TabPanel>
      </TabContext>
      <AlertPopup alertOptions={alertOptions} handleClose={handleClosep} />
    </article>
  );
}
export default Vendordetails;