import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import './Popup.css'
import TextEditor from '../../Components/TextEditor/TextEditor';
import { client } from '../../Helper/Client/Client';
import AlertPopup from '../../Components/AlertPopup/AlertPopup';
import DragDrop from '../../Components/DragDrop/DragDrop';
import WysiwygEditor from "../../Components/TextEditor/WysiwygEditor";

const PopupGift = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");
  const [status, setstatus] = useState('')
  const [texteditor, settexteditor] = useState('')
  const [texteditor2, settexteditor2] = useState('')
  const [texteditor3, settexteditor3] = useState('')
  const [inputs, setinputs] = useState({ giftname: '', ExchangeTitle: '', TermsTitle: '' })
  const [validationError, setvalidationError] = useState(false)
  const [file, setfile] = useState([])
  const [filelist, setfilelist] = useState([])
  const [deleteImage, setdeleteImage] = useState(null)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };

  function handleChanged(e) {
    const { name, value } = e.target;
    setinputs((inputs) => ({ ...inputs, [name]: value }));
  }

  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });

  useEffect(() => {
    setfilelist(file)
  }, [file])

  useEffect(() => {
    deleteImage && setfilelist(filelist.filter(item => item !== deleteImage))
  }, [deleteImage])

  function handleSubmit() {
    if (inputs.giftname === '' || inputs.ExchangeTitle === '' || inputs.TermsTitle === ''||filelist.length===0) {
      setvalidationError(true)
      return
    }
    else {
      setvalidationError(false)
    }
    let form_data = new FormData();
    form_data.append("credit", inputs.pointsrequired);
    form_data.append("gift_description", texteditor);
    form_data.append("gift_name", inputs.giftname);
    form_data.append("status", 1);
    form_data.append("exchangemethod_title", inputs.ExchangeTitle);
    form_data.append("exchangemethod_description", texteditor2);
    form_data.append("tac_title", inputs.TermsTitle);
    form_data.append("tac_description", texteditor3);
    form_data.append("points_required", inputs.pointsrequired);
    form_data.append("expiry", inputs.expiry);    
    filelist.length>0 && form_data.append("gift_image", filelist[0])
   
    
    handleClose()
    client.post('/api/gift_create', form_data).then(() => {
      setalertOptions({
        alertType: "success",
        alertOpen: true,
        alertContent: "Submitted Successfully!",
      })
      setValue('1')
      setinputs({ giftname: '', ExchangeTitle: '', TermsTitle: '' })
      settexteditor('')
      settexteditor2('')
      settexteditor3('')
      client.get(`/api/gift_list`).then((response) => {
        props.setpage(1)
        props.setgiftList(response.data.results);
        props.setpageCount(Math.ceil(response.data.count / response.data.results.length))
      })
    }).catch(() => {
      setalertOptions({
        alertType: "error",
        alertOpen: true,
        alertContent: "Submission Failed!",
      })
    })
  }

  return (
    <div>
      <button className="exportbtnpluslg" onClick={handleClickOpen}>
        <span className="material-icons" style={{
          color: "white", fontSize: '15px',
          paddingTop: '4px'
        }}>add</span>
      </button>
      <Dialog fullWidth open={open} onClose={handleClose} className='dialog'>
        <Box >
          <TabContext value={value} >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="INFORMATION" value="1" />
                <Tab label="EXCHANGE METHOD" value="2" />
                <Tab label="T&C" value="3" />
                <Tab label="IMAGES" value="4" />
                <img onClick={handleClose} src='/Images/close.png' height='32px' style={{
                  position: 'absolute',
                  right: '10px',
                  top: '8px'
                }} />
              </TabList>
            </Box>
            <TabPanel sx={{ p: 0 }} value="1">
              <DialogTitle >Information</DialogTitle>
              <DialogContent style={{ display: 'flex', flexDirection: 'column' }} >
                <label className="mt20">Gift Name
                  <input
                    className={(validationError && inputs.giftname === '') ? "validationerror" : ""}
                    type="text"
                    name="giftname"
                    onChange={handleChanged}
                    value={inputs.giftname}
                    
                    required
                  />
                </label>
                <div
                  style={{ display: "grid", gridTemplateColumns: "auto auto" }}
                >
                <label className="mt20" style={{ marginRight: "15px" }}>Points Required
                  <input
                    className={(validationError && inputs.pointsrequired === undefined||inputs.pointsrequired === '') ? "validationerror" : ""}
                    type="number"
                    min="0"
                    step="1"
                    name="pointsrequired"
                    onChange={handleChanged}
                    value={inputs.pointsrequired}
                    
                    required
                  />
                </label>
                <label className="mt20">Expiry
                  <input
                    className={(validationError && inputs.expiry === undefined ||inputs.expiry === '') ? "validationerror" : ""}
                    type="number"
                    min="0"
                    step="1"
                    name="expiry"
                    onChange={handleChanged}
                    value={inputs.expiry}
                    
                    required
                  />
                </label>
                </div>
                <label className="mt20">Gift Description
                
                  {texteditor !== null && (
                    <WysiwygEditor
                      settexteditor={settexteditor}
                      texteditor={texteditor}
                    />
                  )}
                </label>
              </DialogContent>
              <DialogActions style={{ textAlign: "end", padding: '20px' }}>
                <Button
                  className="primarybtn"
                  onClick={() => {
                    if (inputs.giftname === ''||inputs.pointsrequired === undefined||
                    inputs.pointsrequired === ''||
                    inputs.expiry === undefined||inputs.expiry === '') {
                      setvalidationError(true)
                      return
                    }
                    else {
                      setvalidationError(false)
                    }
                    setValue("2");
                  }}
                >
                  Next Step
                </Button>
              </DialogActions>
            </TabPanel>
            <TabPanel sx={{ p: 0 }} value="2">
              <DialogTitle >Exchange method</DialogTitle>
              <DialogContent style={{ display: 'flex', flexDirection: 'column' }} >
                <label className="mt20">Title
                  <input
                    className={(validationError && inputs.ExchangeTitle === '') ? "validationerror" : ""}
                    value={inputs.ExchangeTitle}
                    type="text"
                    name="ExchangeTitle"
                    onChange={handleChanged}
                    
                    required
                  />
                </label>
                <label className="mt20">Description
                 
                  {texteditor2 !== null && (
                    <WysiwygEditor
                      settexteditor={settexteditor2} texteditor={texteditor2} />
                  )}
                </label>
              </DialogContent>
              <DialogActions
                style={{ display: "flex", justifyContent: "space-between", padding: "20px" }}
              >
                <Button
                  className="secondarybtn"
                  onClick={() => {
                    setValue("1");
                  }}
                >
                  Previous
                </Button>
                <Button
                  className="primarybtn"
                  onClick={() => {
                    if (inputs.ExchangeTitle === '') {
                      setvalidationError(true)
                      return
                    }
                    else {
                      setvalidationError(false)
                    }
                    setValue("3");
                  }}
                >
                  Next Step
                </Button>
              </DialogActions>
            </TabPanel>
            <TabPanel sx={{ p: 0 }} value="3">
              <DialogTitle >Terms and Conditions</DialogTitle>
              <DialogContent style={{ display: 'flex', flexDirection: 'column' }} >
                <label className="mt20">Title
                  <input
                    className={(validationError && inputs.TermsTitle === '') ? "validationerror" : ""}
                    value={inputs.TermsTitle}
                    type="text"
                    name="TermsTitle"
                    onChange={handleChanged}
                    
                    required
                  />
                </label>
                <label className="mt20">Description
                 
                  {texteditor3 !== null && (
                    <WysiwygEditor
                      settexteditor={settexteditor3} texteditor={texteditor3} />
                  )}
                </label>
              </DialogContent>
              <DialogActions style={{
                display: 'flex', justifyContent: 'space-between',
                marginBlockStart: "20px",
                padding: "20px",
              }}>

                <Button
                  className="secondarybtn"
                  onClick={() => {
                    setValue("2");
                  }}
                >
                  Previous
                </Button>
                <Button
                  className="primarybtn"
                  onClick={() => {
                    setValue("4");
                  }}
                >
                 Next Step
                </Button>
              </DialogActions>
            </TabPanel>
            <TabPanel sx={{ p: 0 }} value="4">
              <DialogTitle >Images</DialogTitle>
              <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
              {!filelist.length > 0 &&   <DragDrop deleteImage={deleteImage} setfile={setfile} filelist={filelist} validationError={validationError}/>}
              {filelist.length > 0 &&
                    <><div style={{ display: 'grid', gridTemplateColumns: '30px 100px 180px 150px 50px', justifyItems: 'center', marginTop: '30px' }}>
                      
                    </div>
                      {filelist.map((item, index) => (<div key={index}
                      style={{ display: 'grid', gridTemplateColumns: '30px 100px 180px 150px  50px', justifyItems: 'center', verticalAlign: "center", border: '1px solid #636363', padding: '8px', borderRadius: '5px', marginBottom: '10px' }}>
                        <p></p>
                        
                        <img src={URL.createObjectURL(item)} style={{ margin: 'auto' }} height='74px' width="74px" />

                        <p></p>
                        <p></p>
                        <img style={{ margin: 'auto' }} src='/Images/Delete.svg' height='16px' onClick={() => setdeleteImage(item)} />
                      </div>))}</>}
              </DialogContent>
              <DialogActions style={{ display: 'flex', justifyContent: 'space-between',padding:'20px' }}>
                  <Button className='secondarybtn' onClick={()=>{setValue("3")}}>Previous</Button>
                  <Button className='primarybtn'  onClick={handleSubmit}>Submit</Button>
                </DialogActions>
            </TabPanel>
          </TabContext>
        </Box>
      </Dialog>
      <AlertPopup alertOptions={alertOptions} handleClose={handleClosep} />
    </div>
  )
}

export default PopupGift