import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import useAxios from "../../Helper/Client/useAxios";
import PopupPromotionBanner from "../Popup/PopupPromotionBannerI";
import PopupPromotionBannerV from "../Popup/PopupPromotionBannerV";
import PopupPopularVenue from "../Popup/PopupPopularVenue";
import { history } from "../../Helper/History/history";
import "./HomePageManagement.css";
import { client } from "../../Helper/Client/Client";
import PopupPopularBlogs from "../Popup/PopupPopularBlogs";
import PopupDelete2 from "../Popup/PopupDelete2";
import { Button } from "@mui/material";


const HomePageManagement = () => {
  const [value, setValue] = useState("1");
  const [bannerList, setbannerList] = useState([]);
  const [videopromotionList, setvideopromotionList] = useState([]);
  const [bestSelectionList, setbestSelectionList] = useState([]);
  const [popularVenueList, setpopularVenueList] = useState([]);
  const [topBlogList, settopBlogList] = useState([]);
  const [bannerheaderList, setbannerheaderList] = useState([]);
  const [inputs, setinputs] = useState({ bannerheader: "" });

  const handleChange = (event, newValue) => {
    setValue(newValue);
   
  };


  const [response, isError, isLoading] = useAxios({
    url: "/api/banner_list",
    method: "get",
    
  });

  const [
    videoPromotionresponse,
    videoPromotionisError,
    videoPromotionisLoading,
  ] = useAxios({
    url: "/api/video_promo_list",
    method: "get",
    
  });

  const [bestSelectionresponse, bestSelectionisError, bestSelectionisLoading] =
    useAxios({
      url: "/api/venue_best_selection",
      method: "get",
     
    });

  const [popularVenueresponse, popularVenueisError, popularVenueisLoading] =
    useAxios({
      url: "/api/popular_venue_get",
      method: "get",
     
    });

  const [topBlogresponse, topBlogisError, topBlogisLoading] = useAxios({
    url: "/api/top10_blog_get",
    method: "get",
   
  });

  const [bannerHeaderresponse, bannerHeaderisError, bannerHeaderisLoading] =
    useAxios({
      url:"/api/bannerheader_list",
      
      method: "get",
     
    });

  useEffect(() => {
    if (response && response.data.data) {
      setbannerList(response.data.data);
    }
    if (videoPromotionresponse && videoPromotionresponse.data) {
      setvideopromotionList(videoPromotionresponse.data.results);
    }
    if (bestSelectionresponse && bestSelectionresponse.data.data) {
      setbestSelectionList(bestSelectionresponse.data.data);
    }
    if (popularVenueresponse && popularVenueresponse.data) {
      setpopularVenueList(popularVenueresponse.data.results);
    }
    if (topBlogresponse && topBlogresponse.data.data) {
      settopBlogList(topBlogresponse.data.data);
    }
    if (bannerHeaderresponse && bannerHeaderresponse.data) {
      
      setbannerheaderList(bannerHeaderresponse.data.results[0]);
    }
    return () => {

    };
  }, [
    response,
    videoPromotionresponse,
    bestSelectionresponse,
    popularVenueresponse,
    topBlogresponse,
    bannerHeaderresponse,
  ]);
// console.log("Room Images",bestSelectionList[0])
  // console.log("Banner Header List", bannerheaderList);
  function handleDeleteBanner(i) {
    client.delete(`/api/banner_delete/${i}`).then(() => {
      client.get("/api/banner_list").then((response) => {
        response.data.data
          ? setbannerList(response.data.data)
          : setbannerList([]);
      });
    });
  }
  function handleDeletePromotionVideo(i) {
    client.delete(`/api/video_promo_delete/${i}`).then(() => {
      client.get("/api/video_promo_list").then((response) => {
        response.data.results
          ? setvideopromotionList(response.data.results)
          : setvideopromotionList([]);
      });
    });
  }

  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    dragItem.current = position;
    
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    
  };

  const drop = (e) => {
    const copyListItems = [...popularVenueList];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setpopularVenueList(copyListItems);
    const arrangedId = copyListItems.map((i) => i.id);
    // console.log(arrangedId);
    client.put("api/popular_venue_dragdrop", { venue_ids: arrangedId });
  };

  const drop2 = (e) => {
    const copyListItems = [...topBlogList];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    settopBlogList(copyListItems);
    const arrangedId = copyListItems.map((i) => i.id);
    // console.log(arrangedId);
    client.put("/api/top10_blog_dragdrop", { blog_ids: arrangedId });
  };

  const drop3 = (e) => {
    const copyListItems = [...bannerList];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setbannerList(copyListItems);
    const arrangedId = copyListItems.map((i) => i.id);
    // console.log("Banner Drag Drop", arrangedId);
    client.put("/api/banner_dragdrop", { banner_ids: arrangedId });
  };

  const drop4 = (e) => {
    const copyListItems = [...videopromotionList];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setvideopromotionList(copyListItems);
    const arrangedId = copyListItems.map((i) => i.id);
    // console.log(arrangedId);
    client.put("api/video_promo_dragdrop", { videopromo_ids: arrangedId });
  };

// Banner Header
  const [edit, setedit] = useState(false);

  function handleClickOpen() {
    setedit(true);
  }

  function handleClose() {
    setedit(false);
  }

  useEffect(() => {
    setinputs((inputs) => ({
      ...inputs,
      bannerheader: bannerheaderList?.header,
    }));
  }, [bannerheaderList]);

  function handleChanged(e) {
    const { name, value } = e.target;
    setinputs((inputs) => ({ ...inputs, [name]: value }));
  }

  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });

  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };

  const handleBannerHeaderEdit = () => {
    let form_data = new FormData();
    form_data.append("header", inputs.bannerheader);
      
    client.put(`api/bannerheader_update/1`, form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(() => {
        setalertOptions({
          alertType: "success",
          alertOpen: true,
          alertContent: "Submitted Successfully!",
        });
        setedit(false);
        client
      
          .get(`/api/bannerheader_list`)
          .then((response) => setbannerheaderList(response.data.results[0]));
      })
      .catch(() => {
        setalertOptions({
          alertType: "error",
          alertOpen: true,
          alertContent: "Submission Failed!",
        });
      });
  };
 
// console.log("Popular venue",popularVenueList);
  // 
  return (
    <article>
      <div className="buttondiv">
        
        <div>
          {value === "1" ? (
            <PopupPromotionBanner
              bannerList={bannerList}
              setbannerList={setbannerList}
            />
          ) : value === "2" ? (
            <PopupPromotionBannerV
              videopromotionList={videopromotionList}
              setvideopromotionList={setvideopromotionList}
            />
          ) : value === "4" ? (
            <PopupPopularVenue
              popularVenueList={popularVenueList}
              setpopularVenueList={setpopularVenueList}
              submitUrl='popular_venue_add'
              refreshUrl='popular_venue_get'
            />
          ) : value === "5" ? (
            <PopupPopularBlogs
              topBlogList={topBlogList}
              settopBlogList={settopBlogList}
            />
          ) : (
            ""
          )}
        </div>
        
      </div>
      <h4>Promotion - Banner</h4>
      <TabContext value={value}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab label="BANNER" value="1" />
          <Tab label="VIDEO PROMOTION" value="2" />
          <Tab label="BEST SELECTION" value="3" />
          <Tab label="POPULAR VENUE" value="4" />
          <Tab label="TOP 10 BLOGS" value="5" />
          {/* <Tab label="Banner Header" value="6" /> */}
        </TabList>
        <TabPanel value="1">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto auto auto",
            }}
          >
            {bannerList.map((item, i) => (
              <div
                key={item.id}
                className="contact-card"
                draggable
                onDragStart={(e) => dragStart(e, i)}
                onDragEnter={(e) => dragEnter(e, i)}
                onDragEnd={drop3}
              >
                <div style={{ textAlign: "end" }}>
                  <PopupDelete2 delete={() => handleDeleteBanner(item.id)} />
                </div>
                {item.image_list[0] ? (
                  <img
                    src={item.image_list[0].image_url}
                    height="107px"
                    width="107px"
                    style={{ borderRadius: "8px" }}
                    className="img-center"
                    onClick={() => {
                      history.push(`/homepagedetails/${item.id}`);
                    }}
                  />
                ) : (
                  <img
                    src="../Images/bannerplacehonder.svg"
                    height="107px"
                    width="107px"
                    style={{ borderRadius: "8px" }}
                    className="img-center"
                    
                  />
                )}
                <p
                  className="heading name pointer"
                  style={{ width: "13vw", margin: "auto" }}
                  onClick={() => {
                    history.push(`/homepagedetails/${item.id}`);
                  }}
                >
                  {item.banner_title}
                </p>
                <div style={{ textAlign: "center" }}>
                  <button className="exportbtnplus" onClick={() => {
                      history.push(`/homepagedetails/${item.id}`);
                    }}>{i + 1}</button>
                </div>

                <p
                  className="paddingZero"
                  style={{ width: "13vw", margin: "auto" }}
                >
                  <span className="span">Vendor Name:</span>
                  <span className="span"> {item.vendor_name}</span>
                </p>
                <p
                  className="paddingZero"
                  style={{ width: "13vw", margin: "auto" }}
                >
                  <span className="span">Tel:</span>
                  <span className="span"> {item.tel}</span>
                </p>
                <p
                  className="paddingZero"
                  style={{ width: "13vw", margin: "auto" }}
                >
                  <span className="span">Location:</span>
                  <span className="span"> {item.location}</span>
                </p>
                
              </div>
            ))}
          </div>
        </TabPanel>
        <TabPanel value="2">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto auto auto",
            }}
          >
            {videopromotionList.length > 0 &&
              videopromotionList.map((item, i) => (
                <div
                  key={item.id}
                  className="contact-card"
                  style={{ position: "relative" }}
                  draggable
                  onDragStart={(e) => dragStart(e, i)}
                  onDragEnter={(e) => dragEnter(e, i)}
                  onDragEnd={drop4}
                >
                  <div style={{ textAlign: "end" }}>
                    <PopupDelete2
                      delete={() => handleDeletePromotionVideo(item.id)}
                    />
                  </div>
                 
                  <p
                    className="heading name pointer"
                    style={{ width: "13vw", margin: "auto" }}
                    onClick={() => {
                      history.push(`/homedetailsvideo/${item.id}`);
                    }}
                  >
                    {item.video_title}
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <button className="exportbtnplus" >{i + 1}</button>
                  </div>
                  <p
                    className="paddingZero"
                    style={{ width: "13vw", margin: "auto" }}
                  >
                    <span className="span">Vendor Name:</span>
                    <span className="span"> {item.vendor_name}</span>
                  </p>
                  <p
                    className="paddingZero"
                    style={{ width: "13vw", margin: "auto" }}
                  >
                    <span className="span">Tel:</span>
                    <span className="span"> {item.tel}</span>
                  </p>
                  <p
                    className="paddingZero"
                    style={{ width: "13vw", margin: "auto" }}
                  >
                    <span className="span">Location:</span>
                    <span className="span"> {item.location}</span>
                  </p>
        
                </div>
              ))}
          </div>
        </TabPanel>
        <TabPanel value="3">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto auto auto",
            }}
          >
            {bestSelectionList.length > 0 &&
              bestSelectionList.map((item, i) => (
                <div
                  key={item.id}
                  className="contact-card"
                  style={{ position: "relative" }}
                >
                  
                  <p></p>
                  {item.room_image_list[0].length > 0 ? (
                    <img
                      src={item.room_image_list[0][0].room_image}
                      height="107px"
                      width="107px"
                      style={{ borderRadius: "8px" }}
                      className="img-center"
                    />
                  ) : (
                    <img
                      src="../Images/bannerplacehonder.svg"
                      height="107px"
                      width="107px"
                      style={{ borderRadius: "8px" }}
                      className="img-center"
                    />
                  )}
                  <p
                    className="heading name"
                    style={{ width: "13vw", margin: "auto" }}
                  >
                    {item.title}
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <button className="exportbtnplus">{i + 1}</button>
                  </div>
                  <p
                    className="paddingZero"
                    style={{ width: "13vw", margin: "auto" }}
                  >
                    <span className="span">Vendor Name:</span>
                    <span className="span"> {item.vendor_name}</span>
                  </p>
                  <p
                    className="paddingZero"
                    style={{ width: "13vw", margin: "auto" }}
                  >
                    <span className="span">Tel:</span>
                    <span className="span"> {item.vendor_phone}</span>
                  </p>
                  <p
                    className="paddingZero"
                    style={{ width: "13vw", margin: "auto" }}
                  >
                    <span className="span">Location:</span>
                    <span className="span"> {item.location}</span>
                  </p>
               
                </div>
              ))}
          </div>
        </TabPanel>
        <TabPanel value="4">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto auto auto",
            }}
          >
            {popularVenueList.map((item, i) => (
              <div
                className="contact-card"
                key={item.id}
                draggable
                onDragStart={(e) => dragStart(e, i)}
                onDragEnter={(e) => dragEnter(e, i)}
                onDragEnd={drop}
              >
               
                <p></p>
                {item.room_list? (
                  <img
                  
                    src={item.room_list[0].room_image_list[0]?.room_image}
                    height="107px"
                    width="107px"
                    style={{ borderRadius: "8px" }}
                    className="img-center"
                  />
                ) : (
                  <img
                    src="../Images/bannerplacehonder.svg"
                    height="107px"
                    width="107px"
                    style={{ borderRadius: "8px" }}
                    className="img-center"
                  />
                )}
                <p
                  className="heading name"
                  style={{ width: "13vw", margin: "auto" }}
                >
                  {item.title}
                </p>
                <div style={{ textAlign: "center" }}>
                  <button className="exportbtnplus">{i + 1}</button>
                </div>
                <p
                  className="paddingZero"
                  style={{ width: "13vw", margin: "auto" }}
                >
                  <span className="span">Vendor Name:</span>
                  <span className="span"> {item.vendor_name}</span>
                </p>
                <p
                  className="paddingZero"
                  style={{ width: "13vw", margin: "auto" }}
                >
                  <span className="span">Tel:</span>
                  <span className="span"> {item.phone}</span>
                </p>
                <p
                  className="paddingZero"
                  style={{ width: "13vw", margin: "auto" }}
                >
                  <span className="span">Location:</span>
                  <span className="span"> {item.location}</span>
                </p>
              </div>
            ))}
          </div>
        </TabPanel>
        <TabPanel value="5">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto auto auto",
            }}
          >
            {topBlogList.map((item, i) => (
              <div
                className="contact-card"
                key={item.id}
                draggable
                onDragStart={(e) => dragStart(e, i)}
                onDragEnter={(e) => dragEnter(e, i)}
                onDragEnd={drop2}
              >
               
                {item.cover_image ? (
                  <img
                    src={item.cover_image}
                    height="107px"
                    width="107px"
                    style={{ borderRadius: "8px" }}
                    className="img-center"
                  />
                ) : (
                  <img
                    src="/Images/img.png"
                    width="107px"
                    height="107px"
                    style={{ borderRadius: "8px" }}
                    className="img-center"
                  />
                )}
                <p></p>
               
                <div style={{ textAlign: "center" }}>
                  <button className="exportbtnplus">{i + 1}</button>
                </div>

                <p></p>
                <p style={{ width: "13vw", margin: "auto" }}>
                  <span className="span">{item.blog_title}</span>
                </p>
                <p>
                  <span className="span">
                    {item.blog_date &&
                      item.blog_date.split("T")[0].split("-")[2] +
                        "." +
                        item.blog_date.split("T")[0].split("-")[1] +
                        "." +
                        item.blog_date.split("T")[0].split("-")[0]}
                  </span>
                </p>
              </div>
            ))}
          </div>
        </TabPanel>
        {/* <TabPanel value="6">
          {bannerheaderList?.id === "" && bannerheaderList?.header === "" ? (
            <button
              className="exportbtnpluslg"
              id={edit == true ? "tc-none" : "tc-display"}
              onClick={handleClickOpen}
              style={{ float: "right" }}
            >
              <span
                className="material-icons"
                style={{
                  color: "white",
                  fontSize: "15px",
                  paddingTop: "4px",
                }}
              >
                add
              </span>
            </button>
          ) : (
            <img
              src="/Images/edit.svg"
              onClick={handleClickOpen}
              style={{ float: "right", display: edit ? "none" : "" }}
            />
          )}
          <h4 style={{ height: "40px" }}>
            
            {bannerheaderList?.header}
          </h4>
          <hr />
          {edit && (
            <>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label className="mt20">
                  Title
                  <input
                    style={{ width: "100%" }}
                    type="text"
                    name="bannerheader"
                    value={inputs.bannerheader}
                    onChange={handleChanged}
                    maxLength={40}
                    required
                  />
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginBlockStart: "20px",
                  padding: "20px",
                  gap: 10,
                }}
              >
                <Button className="primarybtn" onClick={handleBannerHeaderEdit}>
                  Save
                </Button>
                <Button className="secondarybtn" onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </>
          )}
        
        </TabPanel> */}
      </TabContext>
    </article>
  );
};

export default HomePageManagement;
