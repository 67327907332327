import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Tab,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import "./Popup.css";
import Toggle from "../../Components/TextEditor/TextEditor";
import DragDrop from "../../Components/DragDrop/DragDrop";
import { TagInput } from "../../Components/Tags/Tags";
import DropdownSelect from "../../Components/DropdownSelect/DropdownSelect";
import { client } from "../../Helper/Client/Client";
import useAxios from "../../Helper/Client/useAxios";
import AlertPopup from "../../Components/AlertPopup/AlertPopup";

const PopupFAQ = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");
  const [status, setstatus] = useState('')
  const [texteditor, settexteditor] = useState('')
  const [inputs, setinputs] = useState({ title: '', description: '' })
  const [validationError, setvalidationError] = useState(false)

  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });

  const [response, isError, isLoading] = useAxios({
    url: `/api/faq_details_view/${props.detailid}`,
    method: "get",
    // signal: controller.signal
  });


  useEffect(() => {
    if (response && response.data.data) {
      setinputs({ title: response.data.data.title, description: response.data.data.description })
      setstatus(response.data.data.status)
    }
   
  }, [response]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.handleClose()
    setOpen(false);

  };
  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };


  function handleChanged(e) {
    const { name, value } = e.target;
    setinputs((inputs) => ({ ...inputs, [name]: value }));
  }

  function handleSubmit() {
    if (inputs.title === '' || status === '') {
      setvalidationError(true)
      return
    }
    else {
      setvalidationError(false)
    }
    const body = {
      "title": inputs.title,
      "description": inputs.description,
      "status": status
    }
    handleClose();
    client.put(`/api/faq_update/${props.detailid}`, body).then(() => {
      setalertOptions({
        alertType: "success",
        alertOpen: true,
        alertContent: "Submitted Successfully!",
      })
      client.get(`/api/faq_pagination?page=${props.page}`).then((response) => {
        props.setcustomerList(response.data.results);
      })
    })
      .catch(() => {
        setalertOptions({
          alertType: "error",
          alertOpen: true,
          alertContent: "Submission Failed!",
        })
      })
  }

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>
        Edit Item
      </MenuItem>
      <Dialog fullWidth open={open} onClose={handleClose} className="dialog">
        <Box>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="ADD FAQ" value="1" />
                <img
                  onClick={handleClose}
                  src="/Images/close.png"
                  height="32px"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "8px",
                  }}
                />
              </TabList>
            </Box>
            <TabPanel sx={{ p: 0 }} value="1">
              <DialogTitle>ADD FAQ</DialogTitle>
              <DialogContent
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label className="mt20">
                  Title
                  <input
                    className={(validationError && inputs.title === '') ? "validationerror" : ""}
                    value={inputs.title}
                    type="text"
                    name="title"
                    onChange={handleChanged}
                    required
                  />
                </label>
                <label className="mt20">
                  Description
                  <textarea name="description" value={inputs.description} onChange={handleChanged} style={{ borderRadius: '8px' }} />
                </label>

                <label className="mt20">
                  Status
                  <DropdownSelect validationError={validationError} value={status} setdropDownValue={setstatus} values={[{ name: 'Active', value: 'Active' }, { name: 'Disable', value: 'Disable' }]} />
                </label>
              </DialogContent>
              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginBlockStart: "20px",
                  padding: "20px",
                }}
              >
                <Button className="primarybtn" onClick={handleSubmit}>
                  Save
                </Button>
                <Button className="secondarybtn" onClick={handleClose}>
                  Cancel
                </Button>
              </DialogActions>
            </TabPanel>
          </TabContext>
        </Box>
      </Dialog>
      <AlertPopup alertOptions={alertOptions} handleClose={handleClosep} />
    </div>
  );
};

export default PopupFAQ;
