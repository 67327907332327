import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import "./Popup.css";
import useAxios from "../../Helper/Client/useAxios";
import { client } from "../../Helper/Client/Client";
import AlertPopup from "../../Components/AlertPopup/AlertPopup";
import DropdownSelect from "../../Components/DropdownSelect/DropdownSelect";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import PopupCustomerList from "./PopupCustomerList";

const PopupSendCoupon = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");
  const [show, setShow] = useState(true);
  const [isChecked, setIsChecked] = React.useState(false);
  const [inputs, setinputs] = useState({
    couponname: "",
    couponvalue: "",
    startdate: "",
    enddate: "",
    uploaddate: null,
    uploadtime: null,
    remarks: "",
  });
  const [validationError, setvalidationError] = useState(false);
  const [areatype, setareatype] = useState("");
  const [alist, setList] = useState([]);
  const [areaList, setareaList] = useState([]);
  const options = [
    "All Customers",
    "By Registration Date",
    "Individual",
  ];
  const [distributiontype, setDistributiontype] = useState(options[0]);
  const [optionvalue, setOptionvalue] = useState("");
  const [data, setData] = useState([]);
  const [Obj, setObj] = useState([]);
  const validstartdate=new Date().toISOString().split('T')[0]

  const childToParent = (childdata) => {
    setData(childdata);
  };

  const childToParent2 = (childdata) => {
    setObj(JSON.parse(childdata));
  };
  // console.log("data", data);
  // console.log("distributiontype", distributiontype);

  useEffect(() => {
    try {
      setObj(JSON.parse(data));
    } catch (err) {
      // console.log("error", err);
    }
  }, [data]);

  // console.log("ObjectEEEE", Obj);

  let ids = Obj.map((item) => item.id);
  let name = Obj.map((item) => item.full_name);
  let values = Obj.map((item) => item.email);

  // console.log("ids", ids);
  // console.log("Full Name", name);
  // console.log("Email", values);

  let res = [];
  res = Obj.map(({ full_name }) => ({ full_name }));
  // console.log("res", res);

  const [defaultList, setDefaultList] = useState([]);

  useEffect(() => {
    setDefaultList(Obj.map(({ id, full_name }) => ({ id, full_name })));
  }, [Obj]);

  // console.log("defaultList", defaultList);
  const [list, updateList] = useState();

  useEffect(() => {
    updateList(defaultList);
  }, [defaultList]);

  // console.log("List", list);

  const handleRemoveItem = (e) => {
    const name = e.target.getAttribute("name");
    updateList(list.filter((item) => item.full_name !== name));
  };



  const [arearesponse, areaisError, areaisLoading] = useAxios({
    url: "/api/coupons_name_list",
    method: "get",
  });

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      backgroundColor: "#F5F5F5",
      padding: "15px 26px 10px 12px",
      borderRadius: 8,
      fontFamily: [].join(","),
      "&:focus": {},
    },
  }));

  useEffect(() => {
    if (arearesponse && arearesponse.data.results) {
      const alteredcategory = arearesponse.data.results.map((item) => {
        const updatedItem = {
          ...item,
          value: item.id,
          name: item.name,
        };
  
        return updatedItem;
      });
      setareaList(alteredcategory);
      setList(arearesponse.data.results);
    }

    return () => {

    };
  }, [arearesponse]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelect = (e) => {
    setDistributiontype(e.target.value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleHide = () => {
    setShow(false);
  };
  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };

  function handleChanged(e) {
    const { name, value } = e.target;
    setinputs((inputs) => ({ ...inputs, [name]: value }));
  }

  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });

  function handleSubmit() {
    if (distributiontype === '' || areatype === '') {
      setvalidationError(true)
      return
    }
    else {
      setvalidationError(false)
    }
    handleClose();
    const body = {
      coupon_type: areatype,
      distribution_method: distributiontype,
      sendout_date: inputs.uploaddate,
      sendout_time: inputs.uploadtime,
      send_immediately: isChecked,
      remarks: inputs.remarks,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      customers_list: list.map((item) => item.id),
      registration_date_from: inputs.startdate,
      registration_date_to: inputs.enddate,
    };
    client
      .post(`/api/coupon_send_create`, body)
      .then((response) => {
        setalertOptions({
          alertType: "success",
          alertOpen: true,
          alertContent: "Submitted Successfully!",
        });
        setValue("1");
        setareatype("");
        setinputs({
          couponname: "",
          couponvalue: "",
          remarks: "",
        });
        setDistributiontype(options[0]);
        setIsChecked(false);
        setShow(true);
        updateList([]);
        setOptionvalue("");
        client.get(`/api/coupon_send_list`).then((response) => {
          props.setpage(1);
          props.setcouponList(response.data.results);
          props.setpageCount(
            Math.ceil(response.data.count / response.data.results.length)
          );
        });
      })
      .catch(() => {
        setalertOptions({
          alertType: "error",
          alertOpen: true,
          alertContent: "Submission Failed!",
        });
      });
  }


  // console.log("Coupon List123",alist);
  return (
    <div>
      <button className="exportbtnpluslg" onClick={handleClickOpen}>
        <span
          className="material-icons"
          style={{
            color: "white",
            fontSize: "15px",
            paddingTop: "4px",
          }}
        >
          add
        </span>
      </button>
      <Dialog fullWidth open={open} onClose={handleClose} className="dialog">
        <Box>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="COUPON" value="1" />

                <img
                  onClick={handleClose}
                  src="/Images/close.png"
                  height="32px"
                  alt="Close"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "8px",
                  }}
                />
              </TabList>
            </Box>
            <TabPanel sx={{ p: 0 }} value="1">
              <DialogTitle>Coupon</DialogTitle>
              <DialogContent
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label className="mt20">
                  Coupon Type
                  <DropdownSelect
                    validationError={validationError}
                    value={areatype}
                    setdropDownValue={setareatype}
                    values={areaList}
                  />
                </label>

                <label className="mt20">Distribution Method</label>

                <FormControl sx={{ minWidth: 120 }}>
                  <Select
                    value={distributiontype}
                    onChange={(e) => setDistributiontype(e.target.value)}
              
                    displayEmpty
                    input={<BootstrapInput />}
                  >
                    <MenuItem
                      value="All Customers"
                      onClick={() => setOptionvalue("10")}
                    >
                      All Customers
                    </MenuItem>
                    <MenuItem
                      value="By Registration Date"
                      onClick={() => setOptionvalue("11")}
                    >
                      By Registration Date
                    </MenuItem>
                    <MenuItem
                      value="Individual"
                      onClick={() => setOptionvalue("12")}
                    >
                      Individual
                    </MenuItem>
                  </Select>
                </FormControl>
                

                {list && (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto auto auto",
                      gap: 5,
                      marginTop: 20,
                    }}
                  >
                    {list.map((item) => {
                      return (
                        <>
                          <p key={item.full_name} className="button">
                            {item.full_name}
                            <span
                              class="close"
                              name={item.full_name}
                              onClick={handleRemoveItem}
                            >
                              &times;
                            </span>
                          </p>
                        </>
                      );
                    })}
                  </div>
                )}

                {optionvalue === "12" ? (
                  <PopupCustomerList
                    childToParent={childToParent}
                    childToParent2={childToParent2}
                  />
                ) : (
                  <div></div>
                )}
                {optionvalue === "11" ? (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto auto",
                      gap: "30px",
                    }}
                  >
                    <label className="mt20">
                      Registration Date (From)
                      <input
                        type="date"
                        id="birthday"
                        name="startdate"
                        value={inputs.startdate}
                        min={validstartdate}
                        className={
                          validationError &&
                          inputs.startdate === "" &&
                          "validationerror"
                        }
                        onChange={handleChanged}
                      ></input>
                    </label>
                    <label className="mt20">
                      Registration Date (To)
                      <input
                        type="date"
                        id="birthday"
                        name="enddate"
                        value={inputs.enddate}
                        
                        className={
                          validationError &&
                          inputs.enddate === "" &&
                          "validationerror"
                        }
                        onChange={handleChanged}
                      ></input>
                    </label>
                  </div>
                ) : (
                  <div></div>
                )}

                <label className="mt20">
                  Send Out Date & Time
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "150px 200px",
                      gap: 2,
                    }}
                    onClick={handleHide}
                  >
                    <input
                      type="time"
                      id="birthday"
                      name="uploadtime"
                      value={inputs.uploadtime}
                      onChange={handleChanged}
                      disabled={isChecked}
                    ></input>
                    <input
                      type="date"
                      id="birthday"
                      name="uploaddate"
                      value={inputs.uploaddate}
                      onChange={handleChanged}
                      disabled={isChecked}
                      min={validstartdate}
                    ></input>
                  </div>
                </label>
                <br />
                {show ? (
                  <FormControl component="fieldset">
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          checked={isChecked}
                        
                          onChange={(e) => setIsChecked(e.target.checked)}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{
                            "&.Mui-checked": {
                              color: "#FE9900",
                            },
                          }}
                        />
                      }
                      label="Send Immediately"
                      labelPlacement="end"
                    />
                  </FormControl>
                ) : (
                  <div></div>
                )}

                <label className="mt20">
                  Remarks
                  <input
                    
                    type="text"
                    name="remarks"
                    onChange={handleChanged}
                    value={inputs.remarks}
                    
                    required
                  />
                </label>
              </DialogContent>
              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginBlockStart: "20px",
                  padding: "20px",
                }}
              >
                <Button className="primarybtn" onClick={handleSubmit}>
                  Save
                </Button>
                <Button className="secondarybtn" onClick={handleClose}>
                  Cancel
                </Button>
              </DialogActions>
            </TabPanel>
          </TabContext>
        </Box>
      </Dialog>
      <AlertPopup alertOptions={alertOptions} handleClose={handleClosep} />
    </div>
  );
};

export default PopupSendCoupon;
