import React, { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import Popup from "../Popup/Popup";
import { Button, Pagination } from "@mui/material";
import SearchBar from "../../Components/SearchBar/SearchBar";
import useAxios from "../../Helper/Client/useAxios";
import { history } from "../../Helper/History/history";
import { client } from "../../Helper/Client/Client";
import PopupDelete from "../Popup/PopupDelete";
import PopupDeleteAll from "../Popup/PopupDeleteAll";
import AddProduct from "../Popup/AddProduct";
import PopupProductDetail from "../Popup/PopupProductDetail";
import { useParams } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/system";

function Payment() {
  const [payment, setpaymentlist] = useState([]);
  const [searchvalue, setsearchvalue] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [page, setpage] = useState(1);
  const [listlength, setlistlength] = useState(0);

  const [month, setMonth] = useState("1");
  console.log("Selected Month", month);

  const options = [
    "2022",
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
    "2031",
    "2032",
    "2033",
    "2034",
    "2035",
    "2036",
    "2037",
    "2038",
    "2039",
    "2040",
    "2041",
    "2042",
    "2043",
    "2044",
    "2045",
    "2046",
    "2047",
    "2048",
    "2049",
    "2050",
  ];
  // const [selected, setSelected] = useState(options[0]);

  const [year, setYear] = useState(options[0]);
  console.log("Selected Year", year);

  function handleYYMM() {
    console.log("786");
    const body = {
      year: year,
      month: month,
    };
    client.post(`/api/payment_by_year_month`, body).then((response) => {
      if (response.data.results) {
        setpaymentlist(response.data.results);
      } else {
        setpaymentlist([]);
      }
      console.log("Hello");
    });
  }
  useEffect(() => {
    client.get(`/api/payment_searchby_name?name=${searchvalue}`).then((response) => {
      if (response.data.results) {
        setpaymentlist(response.data.results);
        setpage(1)
        setpageCount(
          Math.ceil(response.data.count / response.data.results.length)
        );
      } else {
        setpaymentlist([]);
      }
    }).catch((err)=>{
      if ( err?.response?.status === 401||403) {
        history.push('/login')
      }
  })
  }, [searchvalue])
  console.log("Payment",payment);
// *****
  function CustomPagination() {
    return (
    
      <Pagination
        className="pagination"
        color="warning"
        count={pageCount}
        page={page}
        onChange={(event, value) => {
          searchvalue === ""
          ?
           client
              .get(`/api/payment_list_paginated?page=${value}`)
              .then((response) => {
                setpaymentlist(response.data.results);
              }) 
              : client
              .get(`/api/payment_searchby_name?page=${value}&name=${searchvalue}`)
              .then((response) => {
                setpaymentlist(response.data.results);
              });
          setpage(value);
        }}
        showFirstButton
        showLastButton
      />
    );
  }

  useEffect(() => {
    if (payment?.length !== undefined) {
      setlistlength(payment.length);
    }
  }, [payment]);

    // tab property
    const [value, setValue] = React.useState("1");
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

      // table view(edit/delete)
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [editbar, setEditbar] = useState("");

  const showEditbar = (event, id) => {
    setAnchorEl(event.currentTarget);
    console.log(id);
    setEditbar(id);
  };
  const handleClose = () => {
    setEditbar(null);
  };
    // top actions
  // const [action, setAction] = React.useState(null);
  const open = Boolean(anchorEl2);
  const handleClickaction = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCloseaction = () => {
    setAnchorEl2(null);
  };

  // const handlebulkDelete = () => {
  //   setAnchorEl2(null);
  //   client
  //     .delete(`/api/product_bulk_delete`, {
  //       data: { product_id: selectionModel },
  //     })
  //     .then(() => {
  //       setAnchorEl2(null);
  //       client.get("/api/product_list").then((response) => {
  //         if(  response.data.results){
  //           setpage(1);
  //         setpaymentlist(response.data.results);
  //       setpageCount(
  //           Math.ceil(response.data.count / response.data.results.length)
  //         )
  //         }
  //         else{
  //           setpage(0);
  //           setpageCount(0)
  //           setpaymentlist([])
  //         }
  //       });
  //     });
  // };

  // const handleEdit = (i) => {
  //   history.push(`/productdetails/${i}`);
  // };

  // function handleDelete(i) {
  //   client.delete(`/api/product_delete/${i}`).then(() => {
  //     handleClose();
  //     client.get("/api/product_list").then((response) => {
  //       if(  response.data.results){
  //         setpage(1);
  //       setpaymentlist(response.data.results);
  //     setpageCount(
  //         Math.ceil(response.data.count / response.data.results.length)
  //       )
  //       }
  //       else{
  //         setpage(0);
  //         setpageCount(0)
  //         setpaymentlist([])
  //       }
  //     });
  //   });
  // }

  function getNameEmail(params) {
    const vendorname = params.row.vendor_name;
    
    const vendoremail = params.row.vendor_email;
    // const image = params.row.user_image;
    return vendorname + "+" + vendoremail ;
    // + "+" + image;
  }

  function getPaymentDetails(params) {
    // const productname = params.row.product_name;
    const paymentamount = params.row.amount;
    const paymentid = params.row.payment_id;
    const paymentdate = params.row.date;
    // const productimage = params.row.product_images;
    // console.log('Pimage',productimage)
    return paymentamount + "+" + paymentid + "+" + paymentdate;
  }

  const columns = [
    {
      field: 'date',
      headerName: "DATE",
      align: "center",
      headerAlign: "center",
      type: "date",
      width: 150,
      renderCell: (params) =>
        params.value.split("T")[0].split("-")[2] +
        "." +
        params.value.split("T")[0].split("-")[1] +
        "." +
        params.value.split("T")[0].split("-")[0],
    },
    {
      field: "name&email",
      headerName: "VENDOR NAME",
      width: 200,
      headerAlign: "center",
      valueGetter: getNameEmail,
      renderCell: (params) => (
        <>
          {/* {params.value.split("+")[3] !== "null" ? (
            <img
              src={params.value.split("+")[3]}
              height="30px"
              width="30px"
              alt=""
            />
          ) : (
            <img
              src="/Images/placeholder.png"
              height="30px"
              width="30px"
              alt="profile placholder"
            />
          )} */}
          <div style={{ marginLeft: "10px" }}>
            <b style={{ color: "rgb(53, 53, 53)" }}>
              {params.value.split("+")[0]} 
              {/* {params.value.split("+")[1]} */}
            </b>
            <small style={{ color: "grey", display: "block" }}>
              {params.value.split("+")[1]}
            </small>
          </div>
        </>
      ),
    },
    {
      field: 'payment_id',
      headerName: 'PAYMENT ID',
      align: "center",
      headerAlign: "center",
      width: 150,
      // valueGetter: getPaymentDetails,
    },
    {
      field: 'amount',
      headerName: "AMOUNT",
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "id",
      headerName: "",
      width: 100,
      renderCell: (params) => (
        <>
        <IconButton onClick={(event)=>showEditbar(event,params.value)}>
          <img src="../Images/dots.png" />
        </IconButton>
        <Menu anchorEl={anchorEl}
        open={editbar === params.value ? true :false}
        onClose={handleClose}>
          <MenuItem onClick={handleClose}>Edit Item</MenuItem>
          <MenuItem onClick={handleClose}>Delete Item</MenuItem>
      </Menu>
      </>
      ),
    },
  ];

  function CustomToolbar() {
    return (
      <div style={{ position: "absolute", right: "30px", top: "-210px" }}>
        <GridToolbarContainer>

                  <FormControl sx={{ width: 100,marginRight:3 }}>
                    <Select
                      name="RoomCapacity"
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                      // onChange={handleSelect}
                      displayEmpty
                      input={<BootstrapInput />}
                    >
                      <MenuItem value="2022">2022</MenuItem>
                      <MenuItem value="2023">2023</MenuItem>
                      <MenuItem value="2024">2024</MenuItem>
                      <MenuItem value="2025">2025</MenuItem>
                      <MenuItem value="2026">2026</MenuItem>
                      <MenuItem value="2027">2027</MenuItem>
                      <MenuItem value="2028">2028</MenuItem>
                      <MenuItem value="2029">2029</MenuItem>
                      <MenuItem value="2030">2030</MenuItem>
                      <MenuItem value="2031">2031</MenuItem>
                      <MenuItem value="2032">2032</MenuItem>
                      <MenuItem value="2033">2033</MenuItem>
                      <MenuItem value="2034">2034</MenuItem>
                      <MenuItem value="2035">2035</MenuItem>
                      <MenuItem value="2036">2036</MenuItem>
                      <MenuItem value="2037">2037</MenuItem>
                      <MenuItem value="2038">2038</MenuItem>
                      <MenuItem value="2039">2039</MenuItem>
                      <MenuItem value="2040">2040</MenuItem>
                      
                    </Select>
                  </FormControl>
                  
                  <FormControl sx={{ width: 130,marginRight:3 }}>
                    <Select
                      name="RoomCapacity"
                      value={month}
                      onChange={(e) => setMonth(e.target.value)}
                      // onChange={handleSelect}
                      displayEmpty
                      input={<BootstrapInput />}
                    >
                      <MenuItem value="1">January</MenuItem>
                      <MenuItem value="2">February</MenuItem>
                      <MenuItem value="3">March</MenuItem>
                      <MenuItem value="4">April</MenuItem>
                      <MenuItem value="5">May</MenuItem>
                      <MenuItem value="6">June</MenuItem>
                      <MenuItem value="7">July</MenuItem>
                      <MenuItem value="8">August</MenuItem>
                      <MenuItem value="9">September</MenuItem>
                      <MenuItem value="10">October</MenuItem>
                      <MenuItem value="11">November</MenuItem>
                      <MenuItem value="12">December</MenuItem>
                      
                      
                    </Select>
                  </FormControl>
                
          {/* <button className="exportbtnpluslg" onClick={handleYYMM} style={{
          marginRight: "10px",
          }}>
        <span
          className="material-icons"
          style={{
            color: "white",
            fontSize: "15px",
            paddingTop: "4px",
          }}
        >
          add
        </span>
      </button> */}
          <Button
            className="primarybtn"
            onClick={handleYYMM}
            style={{ marginRight: "10px" }}
          >
            Show Data
          </Button>
          <GridToolbarExport
            sx={{
              color: "black",
              bgcolor: "#F5F5F5",
              textTransform: "capitalize",
              p: 1.2,
              pr: 3,
            }}
          />
        </GridToolbarContainer>
      </div>
    );
  }

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      backgroundColor: "#F5F5F5",
      padding: "15px 26px 10px 12px",
      borderRadius: 8,
      fontFamily: [].join(","),
      "&:focus": {},
    },
  }));

  return (
    <article>
      <div className="buttondiv">
        {/* <Button className='tertiarybtn' startIcon={<img src="../Images/download.svg"  alt='download' />} endIcon={<img src="../Images/Arrow-Down.svg" alt='arrow down' />}>Export</Button> */}
        {/* <Popup
          roleid={3}
          setpaymentlist={setpaymentlist}
          setpage={setpage}
          setpageCount={setpageCount}
        /> */}
 
      </div>
      <h4>Payment</h4>
      <TabContext value={value}>
        <TabList onChange={handleChange}>
          <Tab label="All" value="1" />
          {/* <Tab label="ACTIVE" value="2" />
         <Tab label="BLOCKED" value="3" /> */}
        </TabList>
        <TabPanel value="1">
          <div
            style={{
              width: "100%",
              paddingTop: "24px",
              borderRadius: "8px",
              border: "1px solid #E8E8E8",
            }}
          >
            <div className="mid-searchbar">
              <SearchBar
                searchvalue={searchvalue}
                setsearchvalue={setsearchvalue}
              />
              <Button
                onClick={handleClickaction}
                className="tertiarybtn"
                endIcon={
                  <img src="../Images/Arrow-Down.svg" alt="arrow down" />
                }
              >
                Actions
              </Button>
              <Menu
                anchorEl={anchorEl2}
                open={open}
                onClose={handleCloseaction} 
              >
                <PopupDeleteAll 
                // delete={handlebulkDelete} 
                />
                
              </Menu>
            </div>
            <div style={{ height: "650px" }}>
              <DataGrid
                sx={{ mt: 2 }}
                rows={payment}
                columns={columns}
                pageSize={listlength}
                rowsPerPageOptions={[listlength]}
                getRowId={(row) => row.payment_id}
                checkboxSelection
                pagination
                // paginationMode="server"
                keepNonExistentRowsSelected
                components={{
                  Pagination: CustomPagination,
                  Toolbar: CustomToolbar,
                }}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
              />
            </div>
          </div>
        </TabPanel>
      </TabContext>
    </article>
  );
}

export default Payment;