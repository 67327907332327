import React from 'react'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert'
import { forwardRef } from "react";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
const AlertPopup = (props) => {
  return (
    <>
      <Snackbar open={props.alertOptions?.alertOpen} autoHideDuration={10000} onClose={props.handleClose}>
              <Alert onClose={props.handleClose} severity={props.alertOptions?.alertType} sx={{ width: '100%' }}>
                 { props.alertOptions?.alertContent}
              </Alert>
          </Snackbar></>
  )
}

export default AlertPopup