import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import "./Popup.css";
import DropdownSelect from "../../Components/DropdownSelect/DropdownSelect";
import useAxios from "../../Helper/Client/useAxios";
import { client } from "../../Helper/Client/Client";
import AlertPopup from "../../Components/AlertPopup/AlertPopup";

const PopupPromotionBannerV = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("1");
  const [status, setstatus] = useState('')
  const [vendorlist, setVendorlist] = useState([])
  const [vendorname,setVendorname]= useState('')
  const [venuelist, setVenuelist] = useState([])
  const [venuename,setVenuename]= useState('')
  const initialData={ videotitle: "", startdate: "",enddate: "",videolink:""}
  const [inputs, setinputs] = useState(initialData);
  const [validationError, setvalidationError] = useState(false)

  function handleChanged(e) {
    const { name, value } = e.target;
    setinputs((inputs) => ({ ...inputs, [name]: value }));
  }

  const [alertOptions, setalertOptions] = useState({
    alertType: "",
    alertOpen: false,
    alertContent: "",
  });
  
  const handleClosep = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setalertOptions({ ...alertOptions, alertOpen: false });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [response, isError, isLoading] = useAxios({
    url: '/api/vendor_list',
    method: "get",
   
  });

  useEffect(() => {
    if (response && response.data.data) {
      const vendornamelist = response.data.data.map((item) => {
        const updatedItem = {
          ...item,
          value: item.id,
          name: item.email
        }
        return updatedItem
      })
      setVendorlist(vendornamelist);
    }
    
    if (vendorname) {
     client.get(`/api/venuelist_by_vendor/${vendorlist.filter(item=>item.name===vendorname)[0].value}`).then((venueresponse)=>{
      const venuenamelist = venueresponse.data.data.map((item) => {
        const updatedItem = {
          ...item,
          value: item.id,
          name: item.title
        }
        return updatedItem
      })
      setVenuelist(venuenamelist)
    })
    
    }

    return () => {

    }
  }, [response, vendorname]);

  function handleNext(){
    if (inputs.videotitle===''||inputs.startdate===''||inputs.enddate===''||status===''){
      setvalidationError(true)
      return
    }
    else{
      setvalidationError(false)
    }
    setValue("2")
  }

  function handleSubmit(){
    if (inputs.videotitle===''||inputs.startdate===''||inputs.enddate===''||status===''||inputs.videolink===''){
      setvalidationError(true)
      return
    }
    else{
      setvalidationError(false)
    }
    let form_data = new FormData();
         form_data.append("video_title", inputs.videotitle);
         form_data.append("start_date", inputs.startdate);
         form_data.append("end_date", inputs.enddate);
         form_data.append("status", status?1:0);
         form_data.append("vendor_id", vendorlist.filter(item=>item.name===vendorname)[0].value);
         form_data.append("venue_id", venuelist.filter(item=>item.name===venuename)[0].value);
         form_data.append("video_link", inputs.videolink);
         form_data.append("thumbnail_image", '')
       
         handleClose()
    client.post('/api/video_promo_create',form_data,{headers: {
      'content-type': 'multipart/form-data'
    }}).then(()=>{
      setinputs(initialData)
      setstatus('')
    
      setVendorname('')
      setValue("1")
      setalertOptions({
      alertType: "success",
      alertOpen: true,
      alertContent: "Submitted Successfully!",
    })
    
    client.get('/api/video_promo_list').then((response) => {
      props.setvideopromotionList(response.data.results);
    })
  }).catch(()=>{
    setalertOptions({
      alertType: "error",
      alertOpen: true,
      alertContent: "Submission Failed!",
    })
  })
  }

  const todayDate=new Date()
  todayDate.setDate(todayDate.getDate()+1)
  var validenddate=todayDate.toISOString().split('T')[0]
  const validstartdate=new Date().toISOString().split('T')[0]
  if(inputs.startdate!==""){
    const futuredate=new Date(inputs.startdate)
    futuredate.setDate(futuredate.getDate()+1)
    validenddate=futuredate.toISOString().split('T')[0]
  }
  if(inputs.startdate>inputs.enddate){
    inputs.enddate=''
  }

  return (
    <div>
      <button className="exportbtnpluslg" onClick={handleClickOpen}>
        <span
          className="material-icons"
          style={{ color: "white", fontSize: "15px", paddingTop: "4px" }}
        >
          add
        </span>
      </button>
      <Dialog
       fullWidth
        open={open}
        onClose={handleClose}
        className="dialog"
      >
        <Box>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="PROFILE" value="1" />
                <Tab label="Video" value="2" />
                
                <img
                  onClick={handleClose}
                  src="/Images/close.png"
                  height="32px"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "8px",
                  }}
                />
              </TabList>
            </Box>
            <TabPanel sx={{ p: 0 }} value="1">
              <DialogTitle>INFORMATION</DialogTitle>
              <DialogContent
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label className="mt20">
                  Video Title
                  <input
                     value={inputs.videotitle}
                     type="text"
                     name="videotitle"
                     onChange={handleChanged}
                    
                     required
                     className={validationError && inputs.videotitle==='' && "validationerror"}
                  />
                </label>
                <div
                  style={{ display: "grid", gridTemplateColumns: "auto auto" }}
                >
                <label className="mt20" style={{ marginRight: "15px" }}>
                  Vendor Email 
                  <Autocomplete
                      className={validationError && vendorname==='' && "validationerror"}
                     value={vendorname}
                     onChange={(event, newValue) => {
                      setVendorname(newValue);
                      setVenuename('')
                     }}
        options={vendorlist.map(
          (item) => `${item.name}`
        )}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <input type="text" {...params.inputProps}/>
          </div>
        )}
      />
                  </label>
                  <label className="mt20">
                  Venue  
                  <Autocomplete
                      className={validationError && venuename==='' && "validationerror"}
                     value={venuename}
                     onChange={(event, newValue) => {
                      setVenuename(newValue);
                     }}
        options={venuelist.map(
          (item) => `${item.name}`
        )}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <input type="text" {...params.inputProps}/>
          </div>
        )}
      />
                  </label>
                  </div>
            
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto",
                    gap: "30px",
                  }}
                >
                  <label className="mt20">
                    Start Date
                    <input type="date" id="birthday" name="startdate" value={inputs.startdate} min={validstartdate}
                  className={validationError && inputs.startdate==='' && "validationerror"} onChange={handleChanged}></input>
                  </label>
                  <label className="mt20">
                    End Date
                    <input type="date" id="birthday" name="enddate" value={inputs.enddate} min={validenddate}
                     className={validationError && inputs.enddate==='' && "validationerror"} onChange={handleChanged}></input>
                  </label>
                </div>
                <label className="mt20">
                  Status
                  <DropdownSelect validationError={validationError}  value={status} setdropDownValue={setstatus} values={[{name:'Active',value:true},{name:'Disable',value:false}]}/>
                </label>
              </DialogContent>
              <DialogActions style={{ textAlign: "end", padding: "20px" }}>
                <Button
                  className="primarybtn"
                  onClick={
                    handleNext
                  }
                >
                  Next Step
                </Button>
              </DialogActions>
            </TabPanel>
            <TabPanel sx={{ p: 0 }} value="2">
              <DialogTitle>Video</DialogTitle>
              <DialogContent
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label className="mt20">
                  Video Link
                  <input
                    type='url'
                    name="videolink"
                    className={validationError && inputs.videolink==='' && "validationerror"} 
                    value={inputs.videolink}
                     onChange={handleChanged}
                   
                    required
                  />
                </label>
              </DialogContent>
              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "20px",
                }}
              >
                <Button
                  className="secondarybtn"
                  onClick={() => {
                    setValue("1");
                  }}
                >
                  Previous
                </Button>
                <Button className="primarybtn" onClick={handleSubmit}>
                  Submit
                </Button>
              </DialogActions>
            </TabPanel>
          </TabContext>
        </Box>
      </Dialog>
      <AlertPopup alertOptions={alertOptions} handleClose={handleClosep} />
    </div>
  );
};

export default PopupPromotionBannerV;
